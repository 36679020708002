import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgSqft({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="sqft_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.sqft_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="sqft_svg__cls-1"
        d="M50.21 43.9V10.5a5.83 5.83 0 00-5.8-5.84H10.8A5.83 5.83 0 005 10.5v33.4a5.82 5.82 0 005.8 5.83h33.61a5.82 5.82 0 005.8-5.83zm-42-26.31h4.2c-.72 1.53-2 3.54-4.2 4.17zM32 25.76h-8.13a1.5 1.5 0 00-1.5 1.5v7.06h-2.78a1.5 1.5 0 100 3h2.78v9.23H10.8a2.64 2.64 0 01-2.63-2.65v-6.58h3.69a1.5 1.5 0 000-3H8.17v-9.49c5.92-1 7.75-8.07 7.83-8.38a1.5 1.5 0 00-1.47-1.86H8.17V10.5a2.64 2.64 0 012.63-2.65h11.57v11.93a1.5 1.5 0 001.5 1.5h15.27a1.5 1.5 0 100-3H25.38V7.85h19A2.64 2.64 0 0147 10.5v15.28h-7.54a1.5 1.5 0 000 3h7.58V43.9a2.64 2.64 0 01-2.63 2.65h-19V28.76H32a1.5 1.5 0 100-3zM38.67 53.12h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM50.73 53.12h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM44.7 53.12h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM26.61 53.12h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM8.52 53.12h-2a.75.75 0 100 1.5h2a.75.75 0 100-1.5zM14.55 53.12h-2a.75.75 0 000 1.5h2a.75.75 0 100-1.5zM32.64 53.12h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM20.58 53.12h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM54.25 46.63a.75.75 0 00-.76.75v2a.76.76 0 00.76.75.75.75 0 00.75-.75v-2a.74.74 0 00-.75-.75zM54.25 10.62a.75.75 0 00-.76.75v2a.76.76 0 00.76.75.75.75 0 00.75-.75v-2a.74.74 0 00-.75-.75zM54.25 4.62a.75.75 0 00-.76.75v2a.76.76 0 00.76.75.75.75 0 00.75-.75v-2a.74.74 0 00-.75-.75zM54.25 16.62a.75.75 0 00-.76.75v2a.76.76 0 00.76.75.75.75 0 00.75-.75v-2a.74.74 0 00-.75-.75zM54.25 28.62a.75.75 0 00-.76.75v2a.76.76 0 00.76.75.75.75 0 00.75-.75v-2a.74.74 0 00-.75-.75zM54.25 34.62a.75.75 0 00-.76.75v2a.76.76 0 00.76.75.75.75 0 00.75-.75v-2a.74.74 0 00-.75-.75zM54.25 40.63a.75.75 0 00-.76.75v2a.76.76 0 00.76.75.75.75 0 00.75-.75v-2a.74.74 0 00-.75-.75zM54.25 22.62a.75.75 0 00-.76.75v2a.76.76 0 00.76.75.75.75 0 00.75-.75v-2a.74.74 0 00-.75-.75z"
      />
    </svg>
  );
}

export default SvgSqft;
