import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { UpdateInterestedMarketsRequestBody } from '../_base/generated/data-contracts';

// PUT /api/user/markets/interested
export const updateInterestedMarkets = (body: UpdateInterestedMarketsRequestBody) => {
  return api.userMarketControllerUpdateInterestedMarkets(body);
};

export const useUpdateInterestedMarkets = () => {
  return useMutation({ mutationFn: updateInterestedMarkets });
};
