import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { CompleteSignUpRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/complete-signup
export const completeSignup = (data: CompleteSignUpRequestBody) => {
  return api.authControllerCompleteSignup(data);
};

export const useCompleteSignup = () => {
  return useMutation({ mutationFn: completeSignup });
};
