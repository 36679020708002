import { isBefore, differenceInHours, isFuture, formatDistanceStrict } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { DateTime } from 'luxon';

const defaultTimeZone = 'America/Los_Angeles';

export const convertToDateWithZone = (
  date: string,
  IANAZone = defaultTimeZone,
  keepLocalTime = true
): DateTime => {
  return DateTime.fromISO(date).setZone(IANAZone, { keepLocalTime: keepLocalTime });
};

export const formatDate = (
  date: string,
  IANAZone = defaultTimeZone,
  keepLocalTime = true,
  format = 'L/d/yy'
): string => {
  return convertToDateWithZone(date, IANAZone, keepLocalTime).toFormat(format);
};

export const formatStripeDate = (
  seconds: number,
  IANAZone = defaultTimeZone,
  keepLocalTime = true,
  format = 'MM/dd/yyyy'
): string => {
  return DateTime.fromMillis(seconds * 1000)
    .setZone(IANAZone, { keepLocalTime })
    .toFormat(format);
};

export const formatDateToTime = (
  date: string,
  IANAZone = defaultTimeZone,
  keepLocalTime = true,
  format = 't ZZZZ'
): string => {
  return convertToDateWithZone(date, IANAZone, keepLocalTime).toFormat(format);
};

export const convertToNoonDate = (date: string, IANAZone = defaultTimeZone): Date => {
  const jsDate = toDate(`${date}T12:00:00`, { timeZone: IANAZone });

  return jsDate;
};

export const isTimeLeftADate = (closeDate: Date, currentTime = new Date()) => {
  return isFuture(closeDate) && differenceInHours(closeDate, currentTime) > 23;
};

export const calculateOffersDue = (
  closeDate: Date,
  currentTime = new Date(),
  placeholderTime = '00:00:00'
) => {
  if (isBefore(closeDate, currentTime)) return placeholderTime;
  if (differenceInHours(closeDate, currentTime) > 23)
    return formatDate(closeDate.toISOString(), undefined, undefined, 'L/d');
  const timeLeft = new Date(closeDate.getTime() - currentTime.getTime());
  const hours = `0${timeLeft.getUTCHours()}`.slice(-2);
  const mins = `0${timeLeft.getUTCMinutes()}`.slice(-2);
  const secs = `0${timeLeft.getUTCSeconds()}`.slice(-2);

  return `${hours}:${mins}:${secs}`;
};

export const calculateTimeLeft = (
  closeDate: Date,
  currentTime = new Date(),
  placeholderTime = '00:00:00'
) => {
  if (isBefore(closeDate, currentTime)) return placeholderTime;
  if (differenceInHours(closeDate, currentTime) > 23)
    return formatDistanceStrict(closeDate, currentTime);
  const timeLeft = new Date(closeDate.getTime() - currentTime.getTime());
  const hours = `0${timeLeft.getUTCHours()}`.slice(-2);
  const mins = `0${timeLeft.getUTCMinutes()}`.slice(-2);
  const secs = `0${timeLeft.getUTCSeconds()}`.slice(-2);

  return `${hours}:${mins}:${secs}`;
};
