/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AppResponseBody,
  BeginStripePaymentResponseBody,
  CancelDowngradeSubscriptionResponseBody,
  ConfirmMarketSelectionRequestBody,
  ConfirmMarketSelectionResponseBody,
  CreatePropertyOfferRequestBody,
  CreatePropertyOfferResponseBody,
  CreateSellerLeadRequestBody,
  CreateSellerLeadResponseBody,
  CreateSellerQuestionAnswerRequestBody,
  CreateSellerQuestionAnswerResponseBody,
  DisableAutoOffersByIdResponseBody,
  FindMpaLinkByUuidResponseBody,
  FindSellerByIdResponseBody,
  FindSellerListingInfoByLeadIdResponseBody,
  GetBillingDetailsResponseBody,
  GetBuyBoxResponseBody,
  GetPropertySupportResponseBody,
  GetSubscriptionResponseBody,
  GetSubscriptionTiersResponseBody,
  HasMembershipResponseBody,
  ListingSortBy,
  ListPropertiesResponseBodyItem,
  ListPropertyOffersResponseBody,
  ListSavedOpportunitiesResponseBodyItem,
  ListVestingEntitiesResponseBody,
  MediaDeleteImagesRequestBody,
  MediaDeleteImagesResponseBody,
  MediaUploadImagesRequestBody,
  MediaUploadImagesResponseBody,
  OfferDashboardSortBy,
  OfferStatus,
  PaymentHistoryResponseBody,
  PhysicalMailUnsubscribeRequestBody,
  PhysicalMailUnsubscribeResponseBody,
  PreviewSubscriptionUpdateDowngradeResponseBody,
  PreviewSubscriptionUpdateResponseBody,
  PropertyDetailsResponseBody,
  PropertyStatus,
  RealTimeEventsLogEventRequestBody,
  RealTimeEventsLogEventResponseBody,
  ResendNewEmailCodeResponseBody,
  ResendUpdatedPhoneCodeResponseBody,
  SavedOpportunitiesSortBy,
  SaveVestingEntityRequestBody,
  SellerTerritoryInfoResponseBody,
  SetPropertyFavoriteStatusRequestBody,
  SortOrder,
  SyncSubscriptionResponseBody,
  TerritoryInfoResponseBody,
  UpdateActiveMarketsRequestBody,
  UpdateActiveMarketsResponseBody,
  UpdateBillingDetailsRequestBody,
  UpdateBillingDetailsResponseBody,
  UpdateBuyBoxRequestBody,
  UpdateInterestedMarketsRequestBody,
  UpdateInterestedMarketsResponseBody,
  UpdateInvestmentStrategyRequestBody,
  UpdateProfileRequestBody,
  UpdateSellerLeadRequestBody,
  UpdateSubscriptionRequestBody,
  UpdateSubscriptionResponseBody,
  UpdateUserEmailRequestBody,
  UpdateUserEmailResponseBody,
  UpdateUserPhoneRequestBody,
  UpdateUserPhoneResponseBody,
  UpdateUserRequestBody,
  UpdateUserResponseBody,
  UpdateUserSMSPreferencesRequestBody,
  UpdateUserSMSPreferencesResponseBody,
  VerifyNewEmailCodeRequestBody,
  VerifyNewEmailCodeResponseBody,
  VerifyUpdatedPhoneRequestBody,
  VerifyUpdatedPhoneResponseBody,
  VestingEntity
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags User
   * @name UserControllerUpdateUserEmail
   * @summary Update email for a user
   * @request PUT:/api/user/update-email
   * @secure
   */
  userControllerUpdateUserEmail = (data: UpdateUserEmailRequestBody, params: RequestParams = {}) =>
    this.http.request<UpdateUserEmailResponseBody, any>({
      path: `/api/user/update-email`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerResendNewEmailCode
   * @summary Resend email verification code
   * @request POST:/api/user/resend-new-email-code
   * @secure
   */
  userControllerResendNewEmailCode = (params: RequestParams = {}) =>
    this.http.request<ResendNewEmailCodeResponseBody, any>({
      path: `/api/user/resend-new-email-code`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerVerifyNewEmailCode
   * @summary Submit email verification code
   * @request POST:/api/user/verify-new-email-code
   * @secure
   */
  userControllerVerifyNewEmailCode = (
    data: VerifyNewEmailCodeRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifyNewEmailCodeResponseBody, any>({
      path: `/api/user/verify-new-email-code`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerUpdateUserPhone
   * @summary Update phone number for a user
   * @request PUT:/api/user/phone
   * @secure
   */
  userControllerUpdateUserPhone = (data: UpdateUserPhoneRequestBody, params: RequestParams = {}) =>
    this.http.request<UpdateUserPhoneResponseBody, any>({
      path: `/api/user/phone`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerResendUpdatedPhoneCode
   * @summary Resend phone verification code
   * @request POST:/api/user/phone/resend-code
   * @secure
   */
  userControllerResendUpdatedPhoneCode = (params: RequestParams = {}) =>
    this.http.request<ResendUpdatedPhoneCodeResponseBody, any>({
      path: `/api/user/phone/resend-code`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerVerifyUpdatedPhone
   * @summary Submit phone verification code
   * @request POST:/api/user/phone/verify
   * @secure
   */
  userControllerVerifyUpdatedPhone = (
    data: VerifyUpdatedPhoneRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifyUpdatedPhoneResponseBody, any>({
      path: `/api/user/phone/verify`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Real Time Events
   * @name RealTimeEventsControllerLogEvent
   * @summary Log a user event interaction
   * @request POST:/api/real-time-events/logEvent
   * @secure
   */
  realTimeEventsControllerLogEvent = (
    data: RealTimeEventsLogEventRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<RealTimeEventsLogEventResponseBody, any>({
      path: `/api/real-time-events/logEvent`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerGetSubscription
   * @summary Get subscription
   * @request GET:/api/user/subscription
   * @secure
   */
  subscriptionControllerGetSubscription = (params: RequestParams = {}) =>
    this.http.request<GetSubscriptionResponseBody, any>({
      path: `/api/user/subscription`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerUpdateSubscription
   * @summary Update subscription
   * @request PATCH:/api/user/subscription
   * @secure
   */
  subscriptionControllerUpdateSubscription = (
    data: UpdateSubscriptionRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<UpdateSubscriptionResponseBody, any>({
      path: `/api/user/subscription`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerGetSubscriptionTiers
   * @summary Get subscription tiers
   * @request GET:/api/user/subscription/tiers
   * @secure
   */
  subscriptionControllerGetSubscriptionTiers = (params: RequestParams = {}) =>
    this.http.request<GetSubscriptionTiersResponseBody, any>({
      path: `/api/user/subscription/tiers`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerBeginStripePayment
   * @summary Begin payment process for subscription
   * @request POST:/api/user/subscription/begin-payment
   * @secure
   */
  subscriptionControllerBeginStripePayment = (params: RequestParams = {}) =>
    this.http.request<BeginStripePaymentResponseBody, any>({
      path: `/api/user/subscription/begin-payment`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerPreviewSubscriptionUpdate
   * @summary Preview the billable subscription
   * @request GET:/api/user/subscription/preview
   * @secure
   */
  subscriptionControllerPreviewSubscriptionUpdate = (
    query: {
      address1?: string;
      address2?: string;
      city?: string;
      state?: string;
      country?: string;
      postalCode?: string;
      priceId: string;
      couponCode?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      PreviewSubscriptionUpdateResponseBody,
      PreviewSubscriptionUpdateDowngradeResponseBody
    >({
      path: `/api/user/subscription/preview`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerCancelSubscriptionDowngrade
   * @summary Cancel or downgrade subscription
   * @request POST:/api/user/subscription/cancel-downgrade
   * @secure
   */
  subscriptionControllerCancelSubscriptionDowngrade = (params: RequestParams = {}) =>
    this.http.request<CancelDowngradeSubscriptionResponseBody, any>({
      path: `/api/user/subscription/cancel-downgrade`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerSyncSubscription
   * @summary Sync subscription
   * @request POST:/api/user/subscription/sync
   * @secure
   */
  subscriptionControllerSyncSubscription = (params: RequestParams = {}) =>
    this.http.request<SyncSubscriptionResponseBody, any>({
      path: `/api/user/subscription/sync`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerPaymentHistory
   * @summary Payment History
   * @request GET:/api/user/subscription/history
   * @secure
   */
  subscriptionControllerPaymentHistory = (
    query?: {
      endingBefore?: string;
      limit?: number;
      startingAfter?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PaymentHistoryResponseBody, any>({
      path: `/api/user/subscription/history`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerBillingDetails
   * @summary Get Billing Details
   * @request GET:/api/user/subscription/billing
   * @secure
   */
  subscriptionControllerBillingDetails = (params: RequestParams = {}) =>
    this.http.request<GetBillingDetailsResponseBody, any>({
      path: `/api/user/subscription/billing`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name SubscriptionControllerUpdateBillingDetails
   * @summary Update Billing Details
   * @request POST:/api/user/subscription/billing
   * @secure
   */
  subscriptionControllerUpdateBillingDetails = (
    data: UpdateBillingDetailsRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<UpdateBillingDetailsResponseBody, any>({
      path: `/api/user/subscription/billing`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name UserControllerHasMembership
   * @summary Check membership for a user
   * @request GET:/api/user/has-membership
   * @secure
   */
  userControllerHasMembership = (params: RequestParams = {}) =>
    this.http.request<HasMembershipResponseBody, any>({
      path: `/api/user/has-membership`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerUpdateProfile
   * @summary Update profile
   * @request PATCH:/api/update-profile
   * @secure
   */
  userControllerUpdateProfile = (data: UpdateProfileRequestBody, params: RequestParams = {}) =>
    this.http.request<number[], any>({
      path: `/api/update-profile`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerUpdateUser
   * @summary Update a user
   * @request PATCH:/api/user
   * @secure
   */
  userControllerUpdateUser = (data: UpdateUserRequestBody, params: RequestParams = {}) =>
    this.http.request<UpdateUserResponseBody, any>({
      path: `/api/user`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerUpdateUserSmsPreferences
   * @summary Update user SMS preferences
   * @request PATCH:/api/user/sms-preferences
   * @secure
   */
  userControllerUpdateUserSmsPreferences = (
    data: UpdateUserSMSPreferencesRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<UpdateUserSMSPreferencesResponseBody, any>({
      path: `/api/user/sms-preferences`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserControllerUpdateInvestmentStrategy
   * @summary Update investment strategy
   * @request PUT:/api/user/investment-strategy
   * @secure
   */
  userControllerUpdateInvestmentStrategy = (
    data: UpdateInvestmentStrategyRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<UpdateInvestmentStrategyRequestBody, any>({
      path: `/api/user/investment-strategy`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Vesting Entity
   * @name VestingEntityControllerSaveVestingEntity
   * @summary Save a vesting entity
   * @request POST:/api/save-vesting-entity
   * @secure
   */
  vestingEntityControllerSaveVestingEntity = (
    data: SaveVestingEntityRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VestingEntity, any>({
      path: `/api/save-vesting-entity`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Vesting Entity
   * @name VestingEntityControllerListVestingEntities
   * @summary List vesting entities
   * @request GET:/api/vesting-entities
   * @secure
   */
  vestingEntityControllerListVestingEntities = (params: RequestParams = {}) =>
    this.http.request<ListVestingEntitiesResponseBody, any>({
      path: `/api/vesting-entities`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Buy Box
   * @name BuyBoxControllerGetBuyBox
   * @summary Get buy box
   * @request GET:/api/buy-box
   * @secure
   */
  buyBoxControllerGetBuyBox = (params: RequestParams = {}) =>
    this.http.request<GetBuyBoxResponseBody, any>({
      path: `/api/buy-box`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Buy Box
   * @name BuyBoxControllerUpdateBuyBox
   * @summary Update buy box
   * @request PUT:/api/buy-box
   * @secure
   */
  buyBoxControllerUpdateBuyBox = (data: UpdateBuyBoxRequestBody, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/api/buy-box`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Property
   * @name PropertyControllerCreatePropertyOffer
   * @summary Create an offer on a property
   * @request POST:/api/property/offer
   * @secure
   */
  propertyControllerCreatePropertyOffer = (
    data: CreatePropertyOfferRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<CreatePropertyOfferResponseBody, any>({
      path: `/api/property/offer`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Property
   * @name PropertyControllerSetPropertyFavoriteStatus
   * @summary Set favorite status on a property
   * @request POST:/api/property/{publishId}/favorite
   * @secure
   */
  propertyControllerSetPropertyFavoriteStatus = (
    publishId: string,
    data: SetPropertyFavoriteStatusRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/api/property/${publishId}/favorite`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Property
   * @name PropertyControllerListPropertyOffers
   * @summary List offers on a property
   * @request GET:/api/properties/offers
   * @secure
   */
  propertyControllerListPropertyOffers = (
    query?: {
      sortBy?: OfferDashboardSortBy;
      sortOrder?: SortOrder;
      territory?: string;
      offerStatus?: OfferStatus;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<ListPropertyOffersResponseBody, any>({
      path: `/api/properties/offers`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Property
   * @name PropertyControllerListSavedOpportunities
   * @summary List saved opportunities
   * @request GET:/api/properties/saved-opportunities
   * @secure
   */
  propertyControllerListSavedOpportunities = (
    query?: {
      sortBy?: SavedOpportunitiesSortBy;
      sortOrder?: SortOrder;
      propertyStatus?: PropertyStatus;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<ListSavedOpportunitiesResponseBodyItem[], any>({
      path: `/api/properties/saved-opportunities`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Property
   * @name PropertyControllerListProperties
   * @summary List properties
   * @request GET:/api/properties
   * @secure
   */
  propertyControllerListProperties = (
    query?: {
      sortBy?: ListingSortBy;
      sortOrder?: SortOrder;
      propertyStatus?: PropertyStatus;
      territory?: string;
      includeImages?: boolean;
      'distance[include]'?: boolean;
      'distance[fromLatitude]'?: number;
      'distance[fromLongitude]'?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<ListPropertiesResponseBodyItem[], any>({
      path: `/api/properties`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Property
   * @name PropertyControllerGetPropertyDetailsForMarketExpert
   * @summary Get details for a property
   * @request GET:/api/property/{propertyId}/me-detail
   * @secure
   */
  propertyControllerGetPropertyDetailsForMarketExpert = (
    propertyId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<PropertyDetailsResponseBody, any>({
      path: `/api/property/${propertyId}/me-detail`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Property
   * @name PropertyControllerGetPropertyDetails
   * @summary Get details for a property
   * @request GET:/api/property/{propertyId}/detail
   * @secure
   */
  propertyControllerGetPropertyDetails = (propertyId: string, params: RequestParams = {}) =>
    this.http.request<PropertyDetailsResponseBody, any>({
      path: `/api/property/${propertyId}/detail`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Property
   * @name PropertyControllerGetPropertySupportUser
   * @summary Get support user for a property
   * @request GET:/api/property/{propertyId}/support
   * @secure
   */
  propertyControllerGetPropertySupportUser = (propertyId: string, params: RequestParams = {}) =>
    this.http.request<GetPropertySupportResponseBody, any>({
      path: `/api/property/${propertyId}/support`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Physical Mail
   * @name PhysicalMailControllerUnsubscribe
   * @summary Unsubscribe from receiving physical mail to a specified address.
   * @request POST:/api/physical-mail/unsubscribe
   */
  physicalMailControllerUnsubscribe = (
    data: PhysicalMailUnsubscribeRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<PhysicalMailUnsubscribeResponseBody, any>({
      path: `/api/physical-mail/unsubscribe`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Offer
   * @name UserControllerDisableAutoOffersById
   * @summary Disable auto offers by ID
   * @request DELETE:/api/user/automatic-offers/{autoOfferId}
   * @secure
   */
  userControllerDisableAutoOffersById = (autoOfferId: string, params: RequestParams = {}) =>
    this.http.request<DisableAutoOffersByIdResponseBody, any>({
      path: `/api/user/automatic-offers/${autoOfferId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Territory
   * @name TerritoryControllerTerritoryInfo
   * @summary List territories grouped by state
   * @request GET:/api/territories
   * @secure
   */
  territoryControllerTerritoryInfo = (
    query?: {
      includePropertyCount?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<TerritoryInfoResponseBody, any>({
      path: `/api/territories`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserMarketControllerConfirmMarketSelection
   * @summary Confirm market selection for a user
   * @request POST:/api/user/markets/confirm
   * @secure
   */
  userMarketControllerConfirmMarketSelection = (
    data: ConfirmMarketSelectionRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<ConfirmMarketSelectionResponseBody, any>({
      path: `/api/user/markets/confirm`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserMarketControllerUpdateActiveMarkets
   * @summary Update active markets for a user
   * @request PUT:/api/user/markets/active
   * @secure
   */
  userMarketControllerUpdateActiveMarkets = (
    data: UpdateActiveMarketsRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<UpdateActiveMarketsResponseBody, any>({
      path: `/api/user/markets/active`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserMarketControllerUpdateInterestedMarkets
   * @summary Update interested markets for a user
   * @request PUT:/api/user/markets/interested
   * @secure
   */
  userMarketControllerUpdateInterestedMarkets = (
    data: UpdateInterestedMarketsRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<UpdateInterestedMarketsResponseBody, any>({
      path: `/api/user/markets/interested`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Seller
   * @name SellerControllerCreateLead
   * @summary Beginning of seller experience flow. Captures contact information for initial Lead record
   * @request POST:/api/sellers/leads
   */
  sellerControllerCreateLead = (data: CreateSellerLeadRequestBody, params: RequestParams = {}) =>
    this.http.request<CreateSellerLeadResponseBody, any>({
      path: `/api/sellers/leads`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Seller
   * @name SellerControllerPatchLead
   * @summary Patches seller Lead information
   * @request PATCH:/api/sellers/leads/{leadId}
   */
  sellerControllerPatchLead = (
    leadId: string,
    data: UpdateSellerLeadRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<AppResponseBody, any>({
      path: `/api/sellers/leads/${leadId}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Seller
   * @name SellerControllerFindById
   * @summary Fetches a single seller lead by id
   * @request GET:/api/sellers/leads/{leadId}
   */
  sellerControllerFindById = (leadId: string, params: RequestParams = {}) =>
    this.http.request<FindSellerByIdResponseBody, any>({
      path: `/api/sellers/leads/${leadId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Seller
   * @name SellerControllerCreateAnswer
   * @summary Persists the responses to the questions the seller has answered as part of the intial inquiry
   * @request POST:/api/sellers/leads/{leadId}/questions
   */
  sellerControllerCreateAnswer = (
    leadId: string,
    data: CreateSellerQuestionAnswerRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<CreateSellerQuestionAnswerResponseBody, any>({
      path: `/api/sellers/leads/${leadId}/questions`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Seller
   * @name SellerControllerFindSellerListingInfoByLeadId
   * @summary Fetches all info for seller dashboard by lead id
   * @request GET:/api/sellers/listings
   * @secure
   */
  sellerControllerFindSellerListingInfoByLeadId = (params: RequestParams = {}) =>
    this.http.request<FindSellerListingInfoByLeadIdResponseBody, any>({
      path: `/api/sellers/listings`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Seller
   * @name SellerControllerTerritoryInfo
   * @summary Fetches territory info for current seller territory
   * @request GET:/api/sellers/territoryInfo
   * @secure
   */
  sellerControllerTerritoryInfo = (
    query: {
      territoryId: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<SellerTerritoryInfoResponseBody, any>({
      path: `/api/sellers/territoryInfo`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Seller
   * @name SellerControllerGetMpa
   * @summary Fetches a single mpa link by property uuid
   * @request GET:/api/sellers/mpa/{uuid}
   */
  sellerControllerGetMpa = (uuid: string, params: RequestParams = {}) =>
    this.http.request<FindMpaLinkByUuidResponseBody, any>({
      path: `/api/sellers/mpa/${uuid}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Media
   * @name MediaControllerUploadImages
   * @summary Upload multiple images
   * @request POST:/api/media/images
   * @secure
   */
  mediaControllerUploadImages = (data: MediaUploadImagesRequestBody, params: RequestParams = {}) =>
    this.http.request<MediaUploadImagesResponseBody, any>({
      path: `/api/media/images`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Media
   * @name MediaControllerDeleteImages
   * @summary Delete multiple images
   * @request DELETE:/api/media/images
   * @secure
   */
  mediaControllerDeleteImages = (data: MediaDeleteImagesRequestBody, params: RequestParams = {}) =>
    this.http.request<MediaDeleteImagesResponseBody, any>({
      path: `/api/media/images`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
}
