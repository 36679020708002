import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { GetUserInfoResponseBody } from '../../api/_base/generated/data-contracts';
import { GenericResponse, isAxiosError } from '../../api/helpers';
import { getMessageFromError, isApiErrorWithMessage } from '../../api/user';
import { confirmMarketSelection } from '../../api/user/confirmMarketSelection';
import { updateActiveAndPrimaryMarkets } from '../../api/user/updateActiveAndPrimaryMarkets';
import modalStyles from '../../common/modal.module.scss';
import { AlertBox } from '../../components/AlertBox';
import { Modal } from '../../components/Modal';
import { Button, Select } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';
import { useTerritoryOptions } from '../../hooks/useTerritoryOptions';
import { captureApiException } from '../../logging';
import { selectTypeaheadActiveMarkets } from '../../store/territory';
import { getUserInfo } from '../../utils/localStorage.utils';

import { MarketManagementModalContents } from './MarketManagementModalContents';
import styles from './MarketManagementModals.module.scss';

export const EDIT_PRIMARY_MARKETS_MODAL_NAME = 'EditPrimaryMarketsModal';

export const EditPrimaryMarketsModal = () => {
  const userInfo: GetUserInfoResponseBody | undefined = getUserInfo();

  const { territoryOptions: initialPrimaryMarketList } = useTerritoryOptions(
    userInfo?.onboardingPrimaryOperatingMarket || ''
  );

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isOpen, , close] = useModalByName(EDIT_PRIMARY_MARKETS_MODAL_NAME);
  const typeAheadActiveMarkets = useSelector(selectTypeaheadActiveMarkets);
  const router = useRouter();

  const territoryDropdownItems = useMemo(
    () =>
      typeAheadActiveMarkets.map((market) => ({
        label: market.name,
        value: market.territoryId || ''
      })),
    [typeAheadActiveMarkets]
  );

  // Set initialPrimaryMarket to be default from sign up unless edited then should be first
  // active market
  const defaultPrimaryMarket = initialPrimaryMarketList[0]?.territoryId;
  const defaultPrimaryMarketExists = useMemo(
    () =>
      territoryDropdownItems.find((activeMarket) => activeMarket.value === defaultPrimaryMarket),
    [territoryDropdownItems, defaultPrimaryMarket]
  );
  const initialPrimaryMarket =
    (defaultPrimaryMarketExists ? defaultPrimaryMarket : territoryDropdownItems[0]?.value) || '';
  const [primaryMarket, setPrimaryMarket] = useState<string>(initialPrimaryMarket);

  useEffect(() => {
    setPrimaryMarket(initialPrimaryMarket);
  }, [initialPrimaryMarket]);

  const handleUpdateMarkets = async () => {
    try {
      if (!primaryMarket) return;

      const marketSelectionParams = {
        primaryMarketId: primaryMarket,
        secondaryMarketIds: territoryDropdownItems
          .map((dropdownItem) => dropdownItem.value)
          .filter((value): value is string => typeof value === 'string')
      };

      try {
        await updateActiveAndPrimaryMarkets(marketSelectionParams);
      } catch (e: unknown) {
        if (
          isAxiosError(e) &&
          (e.response?.data as GenericResponse).errorCode !== 'max_market_updates_reached'
        ) {
          await confirmMarketSelection(marketSelectionParams);
        } else {
          throw e;
        }
      }

      close();
      router.reload();
    } catch (e: unknown) {
      captureApiException(e);

      if (isApiErrorWithMessage(e)) {
        setErrorMessage(getMessageFromError(e));
      }
    }
  };

  return (
    <Modal isShowing={isOpen} modalClassNames={classNames(modalStyles.modal)} hide={close}>
      <MarketManagementModalContents close={close} headerText="Edit your primary market">
        <div className={modalStyles.text}>
          <p>
            Your primary market is the default location filter when you log in and the focus of your
            email communications.
          </p>
          <Select
            items={territoryDropdownItems}
            className={styles.select}
            onChange={(event) => setPrimaryMarket(event.currentTarget.value)}
            value={primaryMarket}
          />
        </div>

        {!!errorMessage && <AlertBox>{errorMessage}</AlertBox>}

        <Button onClick={handleUpdateMarkets} size="large" fullWidth>
          Confirm
        </Button>
      </MarketManagementModalContents>
    </Modal>
  );
};
