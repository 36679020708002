import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { UpdateUserRequestBody } from '../_base/generated/data-contracts';

// PATCH /api/user
export const updateUser = (body: UpdateUserRequestBody) => {
  return api.userControllerUpdateUser(body);
};

export const useUpdateUser = () => {
  return useMutation({ mutationFn: updateUser });
};
