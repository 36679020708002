import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgKey({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#key_svg__clip0)" fill="#4B4B4B">
        <path d="M0 5.553a5.452 5.452 0 001.106 3.205 5.772 5.772 0 002.819 2.002v.87l.38.97-.38 1.18v1.067l.348.645-.348.747v.8l.414.896c.044 1.788 1.05 2.06 1.658 2.06a2.205 2.205 0 001.495-.68c.386-.407.59-.945.567-1.497V10.76a5.784 5.784 0 002.82-2.002 5.464 5.464 0 001.11-3.205 5.592 5.592 0 00-1.808-3.97A6.004 6.004 0 005.997 0 6.005 6.005 0 001.81 1.58 5.592 5.592 0 000 5.554zm10.756 0a4.386 4.386 0 01-1.098 2.823 4.677 4.677 0 01-2.704 1.51h-.117v7.932a.936.936 0 01-.193.697c-.156.199-.386.332-.641.37-.343 0-.415-.57-.415-1.067v-.117l-.414-.891v-.315l.475-1.019-.475-.875v-.661l.447-1.371-.447-1.137v-1.53h-.116a4.672 4.672 0 01-2.713-1.506 4.38 4.38 0 01-1.101-2.827 4.522 4.522 0 011.449-3.146A4.851 4.851 0 016 1.133c1.234 0 2.42.462 3.307 1.29a4.522 4.522 0 011.45 3.146v-.016z" />
        <path d="M4.637 3.393c.003.26.085.514.236.73.152.215.366.382.616.48.25.098.525.122.789.07a1.38 1.38 0 00.696-.365c.19-.185.318-.42.37-.676.05-.256.022-.52-.083-.76a1.33 1.33 0 00-.505-.589 1.4 1.4 0 00-1.723.175 1.3 1.3 0 00-.396.935zm1.896 0a.515.515 0 01-.094.464.549.549 0 01-.44.21.57.57 0 01-.438-.21.525.525 0 01-.094-.464.515.515 0 01.094-.464.549.549 0 01.439-.21.57.57 0 01.439.21.525.525 0 01.094.464z" />
      </g>
      <defs>
        <clipPath id="key_svg__clip0">
          <path fill="#fff" transform="rotate(90 6 6)" d="M0 0h20v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgKey;
