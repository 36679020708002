import classNames from 'classnames';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import * as authApi from '../../../api/auth';
import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { SignupClosingProps } from '../../../types/onBoarding';
import { AlertBox } from '../../AlertBox';
import { NumberInput } from '../../NumberInput/NumberInput';
import { SubmitButton } from '../../SubmitButton';
import styles from '../SignUp.module.scss';

import socialStepStyles from './VerifySocialStep.module.scss';

type VerifySocialStepProps = Pick<SignupClosingProps, 'initialFormData' | 'jumpStep'>;

const PLACEHOLDER = 'Last 4 digits of social security number*';

export const VerifySocialStep = ({ jumpStep, initialFormData }: VerifySocialStepProps) => {
  const [disableSubmitSsn, setDisableSubmitSsn] = useState(true);
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [failCounter, setFailCounter] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loader, setLoader] = useState(false);

  const handleSubmitSsn = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();

    setLoader(true);

    try {
      await authApi.verifyIdentityWithPhoneNumberAndSocial({
        ...initialFormData,
        socialSecurityNumber
      });
      setLoader(false);
      jumpStep('targetMarketsStep');
      toast.success('Code confirmed.');
    } catch (error: unknown) {
      setLoader(false);
      const newFailCounter = failCounter + 1;
      setFailCounter(newFailCounter);

      if (isApiErrorWithMessage(error)) {
        setErrorMessage(getMessageFromError(error));
      } else {
        setErrorMessage('Unable to verify your SSN. Please try again.');
      }

      if (newFailCounter >= 3) {
        jumpStep('targetMarketsStep');
      }
    }
  };

  const handleOptOut = () => {
    jumpStep('targetMarketsStep');
  };

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.title}>Verify your social security number</div>
        <span className={styles.subTitle}>
          Please enter the last 4 digits of your social security number to verify your identity.
          <strong>
            Your information will not be stored and is for verification purposes only.
          </strong>
        </span>
      </div>
      <form className={socialStepStyles.wrapper} onSubmit={handleSubmitSsn}>
        <NumberInput
          inputLength={4}
          inputType="password"
          disableCallback={setDisableSubmitSsn}
          inputValue={socialSecurityNumber}
          setInputValue={setSocialSecurityNumber}
          placeholder={PLACEHOLDER}
          label="SSN"
        />
        {errorMessage && <AlertBox>{errorMessage}</AlertBox>}
        <SubmitButton disabled={disableSubmitSsn} loading={loader} type="submit">
          Verify SSN
        </SubmitButton>

        <p>
          <button onClick={handleOptOut} className={classNames(socialStepStyles.underlineButton)}>
            Opt out and talk to an Onboarding Advisor
          </button>
        </p>
      </form>
    </>
  );
};
