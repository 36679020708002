import { Event, EventHint } from '@sentry/nextjs';

import { isAxiosError } from '../api/helpers';

export const beforeSend = (event: Event, hint: EventHint) => {
  const { originalException } = hint;

  // Don't send an event to sentry if the error is a 401
  if (!(isAxiosError(originalException) && originalException.response?.status === 401))
    return event;
};
