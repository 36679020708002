import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgExclamationDanger({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M36 21a3 3 0 00-3 3v12a3 3 0 006 0V24a3 3 0 00-3-3zm0 24a3 3 0 100 5.999A3 3 0 0036 45zm29.13-22.32L49.32 6.87A3.15 3.15 0 0047.19 6H24.81a3.15 3.15 0 00-2.13.87L6.87 22.68A3.15 3.15 0 006 24.81v22.38a3.15 3.15 0 00.87 2.13l15.81 15.81a3.15 3.15 0 002.13.87h22.38a3.15 3.15 0 002.13-.87l15.81-15.81a3.15 3.15 0 00.87-2.13V24.81a3.15 3.15 0 00-.87-2.13zM60 45.93L45.93 60H26.07L12 45.93V26.07L26.07 12h19.86L60 26.07v19.86z"
        fill="#C01C30"
      />
    </svg>
  );
}

export default SvgExclamationDanger;
