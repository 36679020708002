import classNames from 'classnames';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Spinner } from 'reactstrap';

import { checkUserAvailability } from '../../../api/auth';
import { isApiErrorWithErrorCode, isApiErrorWithMessage } from '../../../api/user';
import { useUserEmail } from '../../../hooks/store/misc';
import { useUserPhone } from '../../../hooks/store/misc/useUserPhone';
import { SignupProps } from '../../../types/onBoarding';
import { AlertBox } from '../../AlertBox';
import SupportLink from '../../SupportLink';
import { Button } from '../../design-system';
import styles from '../SignUp.module.scss';

import StartScreen, { StartScreenFormData } from './StartScreen';

interface CreateAccountProps extends SignupProps {
  showLoginModal: () => void;
  showForgotPasswordModal: () => void;
  autoApprovalToken: string | undefined;
}

type ErrorObject = {
  type: string;
  message: string;
};

type CreateAccountFormData = StartScreenFormData;

type LocalUserData = CreateAccountFormData & {
  uuidInvestorPortalUser?: string;
};

const CreateAccount = (props: CreateAccountProps) => {
  const {
    showLoginModal,
    showForgotPasswordModal,
    autoApprovalToken,
    stepNavigate,
    initialFormData,
    updateFormData
  } = props;
  const { handleSubmit } = useFormContext();

  const [userData, setUserData] = useState<LocalUserData>();
  const [errorMessage, setErrorMessage] = useState<ErrorObject | null>(null);
  const [loader, setLoader] = useState(false);
  const { setUserPhone } = useUserPhone();
  const [, setUserEmail] = useUserEmail();
  const {
    formState: { errors }
  } = useFormContext<StartScreenFormData>();

  const checkAuth = (data: CreateAccountFormData) => {
    setErrorMessage(null);
    setLoader(true);

    checkUserAvailability({
      email: data.email,
      phone: data.phone
    })
      .then((response) => {
        setLoader(false);

        if (userData) {
          setUserData({ ...data, uuidInvestorPortalUser: response.data.uuidInvestorPortalUser });
        }

        setUserPhone(data.phone);
        setUserEmail(data.email);
        updateFormData({
          ...initialFormData,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone + '',
          password: data.password + '',
          autoApprovalToken,
          marketingReferralSource: data.marketingReferralSource,
          initialTermsAndConditionsAcceptedOn: new Date().getTime().toString()
        });

        stepNavigate('next');
      })
      .catch((error: unknown) => {
        setLoader(false);

        if (isApiErrorWithErrorCode(error)) {
          const errorData = error.response.data;

          if (errorData.errorCode === 'invalid_signup') {
            setErrorMessage({ type: 'accountExistMessage', message: '' });
          } else if (errorData.errorCode === 'not_approved') {
            setErrorMessage({ type: 'accountApprovalPending', message: '' });
          } else if (errorData.errorCode === 'invalid_signup_phone') {
            setErrorMessage({ type: 'phoneValidationMessage', message: '' });
          }
        } else if (isApiErrorWithMessage(error)) {
          setErrorMessage({ type: 'other', message: 'Password contains user information' });
        } else {
          setErrorMessage({ type: 'noCreateError', message: '' });
        }
      });
  };

  const submitStart = (data: CreateAccountFormData) => {
    setUserData(data);
    checkAuth(data);
  };

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.title}>
          Become a member
          <br /> for free
        </div>
        <span className={styles.subTitle}>Access exclusive and vetted investment properties.</span>
      </div>
      <div className="text-center px-4">
        <form onSubmit={handleSubmit(submitStart)}>
          <div className={styles.signupTextUp}>
            {'Have an account? '}
            <a onClick={showLoginModal} className={classNames(styles.underlined)}>
              Log In
            </a>
          </div>
          <StartScreen initialFormData={initialFormData} />

          {(errorMessage || !!errors.initialTermsAndConditionsAcceptedOn) && (
            <AlertBox>
              {errorMessage?.type === 'accountExistMessage' && (
                <div>
                  This email already exists, if you have forgotten your password please click{' '}
                  <a href="#" onClick={showForgotPasswordModal}>
                    here
                  </a>
                  {' to reset your password.'}
                </div>
              )}
              {errorMessage?.type === 'accountApprovalPending' && (
                <div>
                  Account approval for your account is still pending. Please contact <SupportLink />{' '}
                  if you have any questions.
                </div>
              )}
              {errorMessage?.type === 'phoneValidationMessage' && (
                <div>
                  Phone number already exists within the portal, please choose a different phone
                  number.
                </div>
              )}
              {errorMessage?.type === 'noCreateError' && (
                <div>
                  We are unable to create this account - please contact us at <SupportLink /> and we{' '}
                  will be in touch soon.
                </div>
              )}
              {errorMessage?.type === 'other' && <div>{errorMessage?.message}</div>}
            </AlertBox>
          )}
          <Button variant="primary" size="large" fullWidth type="submit" className={'mt-4'}>
            {loader ? <Spinner /> : 'Get Started'}
          </Button>
        </form>

        <div className={styles.signupTextDown}>
          {'Have an account? '}
          <a onClick={showLoginModal} className={classNames(styles.underlined)}>
            Log In
          </a>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
