import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgBill({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g
        clipPath="url(#bill_svg__clip0_3336_4112)"
        stroke="#4A4A4A"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6 7h6M6 11.5h3.75M6 16h3.75M16.5 7H18M13.89 16.81c.44.58 1.13.91 1.86.88 1.14 0 2.06-.7 2.06-1.55 0-.85-.92-1.55-2.06-1.55-1.14 0-2.06-.69-2.06-1.55 0-.86.92-1.54 2.06-1.54.72-.03 1.42.3 1.86.87M15.75 17.69v1.03M15.75 10.47v1.03" />
        <path d="M21 1l-3 3-3-3-3 3-3-3-3 3-3-3v22.5l3-3 3 3 3-3 3 3 3-3 3 3V1z" />
      </g>
      <defs>
        <clipPath id="bill_svg__clip0_3336_4112">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBill;
