import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgBannerPromo = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#banner-promo_svg__a)">
      <path
        d="M25.365 1.598a2.025 2.025 0 0 0-2.73 0l-15.85 14.47v27.827a1.013 1.013 0 0 0 1.012 1.012h32.349a1.013 1.013 0 0 0 1.012-1.012V16.018L25.365 1.598Z"
        fill="#fff"
      />
      <path
        d="M25.365 1.598a2.025 2.025 0 0 0-2.73 0l-15.85 14.47V22.8l15.85-14.47a2.025 2.025 0 0 1 2.73 0l15.797 14.417v-6.728L25.365 1.598Z"
        fill="#FFEDED"
      />
      <path
        d="M6.785 22.582v21.266a1.013 1.013 0 0 0 1.012 1.013h14.178M.709 21.57 22.635 1.545a2.025 2.025 0 0 1 2.73 0L47.291 21.57"
        stroke="#DB3D55"
        strokeWidth={1.418}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.025 10.424h-4.05c-.56 0-1.013.454-1.013 1.013v4.05c0 .56.453 1.013 1.013 1.013h4.05c.56 0 1.013-.453 1.013-1.012v-4.051c0-.56-.454-1.013-1.013-1.013Z"
        fill="#F4CCCC"
        stroke="#DB3D55"
        strokeWidth={1.418}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.14 46.886a12.151 12.151 0 1 0 0-24.303 12.151 12.151 0 0 0 0 24.303Z"
        fill="#F4CCCC"
      />
      <path
        d="M35.14 22.582a12.141 12.141 0 0 0-7.987 21.297l17.13-17.13a12.11 12.11 0 0 0-9.144-4.167Z"
        fill="#FFEDED"
      />
      <path
        d="M35.14 46.886a12.151 12.151 0 1 0 0-24.303 12.151 12.151 0 0 0 0 24.303Z"
        stroke="#DB3D55"
        strokeWidth={1.418}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.177 28.693h-4.125a2.719 2.719 0 0 0-1.013 5.241l4.178 1.671a2.718 2.718 0 0 1-1.012 5.24H32.1M35.14 42.87v-2.026M35.14 28.693v-2.026"
        stroke="#DB3D55"
        strokeWidth={1.418}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="banner-promo_svg__a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBannerPromo;
