import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgHome({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M17.073 18h-5.317a.576.576 0 01-.556-.572l-.04-4.064H8.88l-.04 4.064a.576.576 0 01-.576.572H2.927a.58.58 0 01-.576-.58V8.7H.571a.572.572 0 01-.54-.4.588.588 0 01.184-.644L9.644.123a.576.576 0 01.712 0l9.429 7.54a.588.588 0 01.184.645.572.572 0 01-.54.4h-1.78v8.72a.58.58 0 01-.576.572zm-4.753-1.16h4.18V8.12a.574.574 0 01.573-.58h.708L10 1.32 2.22 7.54h.707a.576.576 0 01.573.58v8.72h4.18l.04-4.06a.576.576 0 01.572-.58h3.416a.576.576 0 01.572.572l.04 4.068z"
        fill="#4B4B4B"
      />
    </svg>
  );
}

export default SvgHome;
