import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgArrowDown({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="22"
      {...props}
      height="12"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M20 2L11 10L2 2"
        stroke="#DB3D55"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgArrowDown;
