import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCheckboxChecked({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.707.293a1 1 0 010 1.414L5.04 8.374a1 1 0 01-1.414 0L.293 5.04a1 1 0 111.414-1.414l2.626 2.626 5.96-5.96a1 1 0 011.414 0z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgCheckboxChecked;
