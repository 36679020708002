import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { ConfirmMarketSelectionRequestBody } from '../_base/generated/data-contracts';

// POST /api/user/markets/confirm
export const confirmMarketSelection = (body: ConfirmMarketSelectionRequestBody) => {
  return api.userMarketControllerConfirmMarketSelection(body);
};

export const useConfirmMarketSelection = () => {
  return useMutation({ mutationFn: confirmMarketSelection });
};
