import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWhatIfInvestorReachesOut = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 109 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#what-if-investor-reaches-out_svg__a)">
      <path
        d="M54.5 108c29.823 0 54-24.177 54-54S84.323 0 54.5 0 .5 24.177.5 54s24.177 54 54 54Z"
        fill="#32BEA6"
      />
      <path d="M54.5 27.01 30.935 44.888v28.75h47.127v-28.75L54.499 27.01Z" fill="#fff" />
      <path
        d="M84.538 47.332a1.963 1.963 0 0 1-2.751.378L54.5 27.01l-27.286 20.7a1.965 1.965 0 0 1-3.1-1.06 1.962 1.962 0 0 1 .727-2.069l28.473-21.6a1.965 1.965 0 0 1 2.374 0l28.473 21.6a1.965 1.965 0 0 1 .377 2.751Z"
        fill="#DF2C2C"
      />
      <path
        d="M42.718 50.195a.859.859 0 0 1-.859.86h-5.154a.859.859 0 0 1-.86-.86v-5.154a.86.86 0 0 1 .86-.86h5.154a.86.86 0 0 1 .86.86v5.154Zm0 14.727a.86.86 0 0 1-.859.86h-5.154a.86.86 0 0 1-.86-.86v-5.154a.86.86 0 0 1 .86-.86h5.154a.86.86 0 0 1 .86.86v5.154Zm15.515-14.557a.884.884 0 0 1-.883.884h-5.3a.884.884 0 0 1-.884-.884v-5.3a.884.884 0 0 1 .884-.883h5.3a.883.883 0 0 1 .883.883v5.3Z"
        fill="#40C9E7"
      />
      <path
        d="M67.8 39.264c9.24 0 16.552 7.698 16.145 16.864-.538 12.147-13.8 19.263-24.185 13.274L46.02 83.18c-3.361-1.115-5.603-3.352-6.727-6.71l13.983-14.018A16.077 16.077 0 0 1 51.68 54.7c.383-8.668 7.53-15.436 16.12-15.436Zm0-1.955a18.065 18.065 0 0 0-18.076 17.304 18.172 18.172 0 0 0 1.225 7.4L37.907 75.09l-.854.856.384 1.147c1.325 3.958 4.005 6.63 7.965 7.945l1.147.38.854-.856 12.726-12.76c11.318 5.346 25.184-2.324 25.771-15.587a17.987 17.987 0 0 0-4.731-13.024c-3.515-3.841-8.336-5.882-13.37-5.882Z"
        fill="#fff"
      />
      <path
        d="M84.033 56.203c.396-8.945-6.534-16.518-15.48-16.913-8.945-.396-16.517 6.534-16.913 15.479a16.144 16.144 0 0 0 1.602 7.783L39.205 76.625c1.13 3.37 3.38 5.616 6.753 6.736l13.796-13.83c10.436 6.018 23.739-1.124 24.279-13.328Z"
        fill="#9CE5F4"
      />
      <path
        d="M51.64 54.769a16.144 16.144 0 0 0 1.602 7.783L39.205 76.625c.628 1.878 1.62 3.393 2.945 4.572l37.15-37.15c-9.866-9.892-27.032-3.464-27.66 10.722Z"
        fill="#C9F2F8"
      />
      <path
        d="M67.836 67.066c6.396 0 11.58-5.184 11.58-11.58s-5.184-11.58-11.58-11.58c-6.395 0-11.58 5.184-11.58 11.58s5.185 11.58 11.58 11.58Z"
        fill="#40C9E7"
      />
      <path
        d="M68.349 43.917c-6.39-.283-11.799 4.667-12.082 11.056-.15 3.385 1.179 6.486 3.402 8.704l16.359-16.36a11.521 11.521 0 0 0-7.68-3.4Z"
        fill="#6FDAF1"
      />
      <path
        d="M53.242 62.55 39.205 76.626c1.13 3.37 3.38 5.616 6.753 6.736l13.796-13.83a16.283 16.283 0 0 1-6.512-6.98Z"
        fill="#84462D"
      />
      <path
        d="M53.242 62.55 39.205 76.626c.628 1.878 1.62 3.393 2.945 4.573l14.248-14.249a16.192 16.192 0 0 1-3.156-4.398Z"
        fill="#9C6144"
      />
      <path
        d="m56.774 72.517 2.98-2.987a16.27 16.27 0 0 1-6.51-6.978l-3.222 3.23 6.752 6.735Z"
        fill="#F3B607"
      />
      <path
        d="m53.242 62.55-3.222 3.231 3.777 3.768 2.6-2.6a16.208 16.208 0 0 1-3.155-4.398Z"
        fill="#FBE158"
      />
    </g>
    <defs>
      <clipPath id="what-if-investor-reaches-out_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h108v108H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWhatIfInvestorReachesOut;
