import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgBackArrowFilled({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M30 5a25 25 0 1025 25A25 25 0 0030 5zm6.74 39.69a1.68 1.68 0 01-1.29 2.77 1.65 1.65 0 01-1.3-.61L21 31.08a1.69 1.69 0 010-2.16l13.15-15.77a1.69 1.69 0 112.59 2.16L24.5 30z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgBackArrowFilled;
