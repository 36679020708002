import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerMapFull = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-map-full_svg__a)">
      <path
        d="M8 4 1.5 1.5 1 17l7.5 3 3.5-1.5 3.5-2 6.529-3.917A2 2 0 0 0 23 10.868V4l-7-3-8 3Z"
        fill="#858585"
        stroke="#858585"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6 2.55c.4.2.75.45 1 .85.25.35.4.8.4 1.25V9c0 .4-.35.75-.75.75S22.5 9.4 22.5 9V4.65c0-.15-.05-.3-.1-.4-.1-.15-.2-.25-.35-.3l-5.55-2.2v6.5c0 .4-.35.75-.75.75S15 8.65 15 8.25v-6.5L9.1 4.1c-.029.029-.041.041-.056.046-.01.004-.023.004-.044.004v14.9l2.45-1c.4-.15.85 0 1 .4.15.4 0 .85-.4 1l-2.9 1.15c-.3.1-.55.15-.85.15-.3 0-.6-.05-.85-.15l-6-2.4C1 18 .65 17.7.4 17.35s-.4-.8-.4-1.25V1.75c0-.25.05-.5.15-.7.15-.2.3-.4.5-.55.2-.15.45-.2.7-.25.2-.05.45 0 .7.1l5.9 2.35c.1.05.2.05.3.05.1 0 .2 0 .3-.05l6.4-2.55c.5-.2 1.1-.2 1.65 0l6 2.4ZM1.6 16.5c.1.15.2.25.35.3L7.5 19V4.1h-.1L1.5 1.75V16.1c0 .15.05.3.1.4Zm18.25-.65a1.1 1.1 0 1 1-2.2 0 1.1 1.1 0 0 1 2.2 0Z"
        fill="#4A4A4A"
      />
      <path
        d="M23 15.5c.8-2.8-2.667-4-4.5-4-.8-.8-3 2.167-4 4L19 23c1-1.5 3.2-4.7 4-7.5Z"
        fill="#858585"
        stroke="#858585"
      />
      <path
        d="M18.75 10.55c-1.4 0-2.7.55-3.7 1.55s-1.55 2.3-1.55 3.7c0 2 2.2 5.25 4.05 7.6.15.2.3.35.55.45.2.1.45.15.65.15s.45-.05.65-.15c.2-.1.4-.25.55-.45C21.8 21.05 24 17.8 24 15.8c0-1.4-.55-2.7-1.55-3.7s-2.3-1.55-3.7-1.55Zm0 11.95C16.45 19.55 15 17 15 15.8c0-1 .4-1.95 1.1-2.65a3.746 3.746 0 0 1 6.4 2.65c0 1.2-1.45 3.75-3.75 6.7Z"
        fill="#4A4A4A"
      />
      <path
        d="M23.6 3.4c-.25-.4-.6-.65-1-.85l-6-2.4c-.55-.2-1.15-.2-1.65 0L8.55 2.7c-.1.05-.2.05-.3.05-.1 0-.2 0-.3-.05L2.05.35c-.25-.1-.5-.15-.7-.1C1.1.3.85.35.65.5c-.2.15-.35.35-.5.55-.1.2-.15.45-.15.7V16.1c0 .45.15.9.4 1.25s.6.65 1.05.85l6 2.4c.25.1.55.15.85.15.3 0 .55-.05.85-.15l2.9-1.15c.4-.15.55-.6.4-1-.15-.4-.6-.55-1-.4l-2.45 1V4.15c.05 0 .05 0 .1-.05L15 1.75v6.5c0 .4.35.75.75.75s.75-.35.75-.75v-6.5l5.55 2.2c.15.05.25.15.35.3.05.1.1.25.1.4V9c0 .4.35.75.75.75S24 9.4 24 9V4.65c0-.45-.15-.9-.4-1.25ZM1.95 16.8c-.15-.05-.25-.15-.35-.3-.05-.1-.1-.25-.1-.4V1.75L7.4 4.1h.1V19l-5.55-2.2Z"
        fill="#4A4A4A"
      />
      <path d="M18.75 16.95a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2Z" fill="#fff" />
    </g>
    <defs>
      <clipPath id="seller-map-full_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerMapFull;
