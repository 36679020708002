import { SetOfferAction } from '../actions';
import { SET_OFFERS } from '../actions/types';
import { ListingListWithOfferEntryApiModel } from '../api/_base/generated/data-contracts';

import { RootState } from '.';
export interface OfferState {
  count: number;
  total: number; // total amount offered on all properties
  offers: ListingListWithOfferEntryApiModel[];
}

export const selectOfferState = (rootState: RootState) => rootState.offers;

const initialState: OfferState = { count: 0, total: 0, offers: [] };

const offersReducer = (state = initialState, action: SetOfferAction) => {
  switch (action.type) {
    case SET_OFFERS:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};

export default offersReducer;
