import { useEffect, useState } from 'react';

export const useIsDocumentReady = () => {
  const [isDocumentReady, setIsDocumentReady] = useState(false);

  useEffect(() => {
    setIsDocumentReady(true);
  }, []);

  return isDocumentReady;
};
