import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { UpdateActiveMarketsRequestBody } from '../_base/generated/data-contracts';

// PUT /api/user/markets/active
export const updateActiveAndPrimaryMarkets = (body: UpdateActiveMarketsRequestBody) => {
  return api.userMarketControllerUpdateActiveMarkets(body);
};

export const useUpdateActiveAndPrimaryMarkets = () => {
  return useMutation({ mutationFn: updateActiveAndPrimaryMarkets });
};
