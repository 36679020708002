import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';

// POST /oauth/resend-confirmation-code
export const resendConfirmationCode = (email: string) => {
  return api.authControllerResendConfirmationCode({ email });
};

export const useResendConfirmationCode = () => {
  return useMutation({ mutationFn: resendConfirmationCode });
};
