import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../store';
import { selectMiscState } from '../../../store/misc';
import * as actions from '../../../store/misc';

export const useUserPhone = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userPhone = useSelector(selectMiscState).phone;

  const setUserPhone = (phone?: string) => {
    dispatch(actions.setUserPhone(phone));
  };

  return {
    userPhone,
    setUserPhone
  };
};
