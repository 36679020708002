import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgGroupCircleBlue({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g opacity={0.9}>
        <circle cx={30} cy={30} r={22.73} fill="#1d52a0" />
        <path
          d="M30 55a25 25 0 1125-25 25 25 0 01-25 25zm0-45.45A20.45 20.45 0 1050.45 30 20.47 20.47 0 0030 9.55z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgGroupCircleBlue;
