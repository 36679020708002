import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import { getMessageFromError, isApiErrorWithMessage } from '../../api/user';
import { updateInterestedMarkets } from '../../api/user/updateInterestedMarkets';
import { StaticSubscription } from '../../helpers/subscriptionHelper';
import { useModalManager } from '../../hooks/useModalManager';
import { useTerritoryOptions } from '../../hooks/useTerritoryOptions';
import { captureApiException } from '../../logging';
import { AreaOption } from '../../types/onBoarding';
import { getUserInfo } from '../../utils/localStorage.utils';

import { ENABLED_SUCCESS_MODAL_NAME } from './EnabledSuccessModal';
import { TypeAheadMarketSelectionModal } from './TypeAheadMarketSelectionModal';

interface InterestedMarketsModalProps {
  isOpen: boolean;
  close: () => void;
  headerText: string;
  bodyText: string;
  showSkip?: boolean;
  initialMarkets?: AreaOption[];
  showSuccess?: boolean;
  subscription?: StaticSubscription;
  onComplete?: () => void;
}

export const InterestedMarketsModal = ({
  isOpen,
  close,
  headerText,
  bodyText,
  showSkip,
  showSuccess = true,
  subscription,
  onComplete
}: InterestedMarketsModalProps) => {
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState();

  const user = getUserInfo();
  const { territoryOptions } = useTerritoryOptions(user?.areaOfInterestMarketIds || '');

  const initialMarkets = useMemo(
    () =>
      territoryOptions.map((market) => ({
        name: market.name,
        sfId: market.territoryId,
        territoryId: market.territoryId,
        latitude: market.latitude,
        longitude: market.longitude,
        stateShort: market.stateShort || '',
        isActive: market.isActive,
        id: market.id
      })),
    [territoryOptions]
  );

  const [selectedMarkets, setSelectedMarkets] = useState<AreaOption[]>(initialMarkets || []);

  useEffect(() => {
    if (initialMarkets) {
      setSelectedMarkets(initialMarkets);
    }
  }, [initialMarkets]);

  const { showModal } = useModalManager();

  const handleInterestedMarketsSubmit = async () => {
    try {
      const interestedMarketIds = selectedMarkets
        .map((selectedMarket) => selectedMarket.sfId)
        .filter((selectedMarket): selectedMarket is string => typeof selectedMarket === 'string');
      await updateInterestedMarkets({ interestedMarketIds });

      if (showSuccess) {
        showModal(ENABLED_SUCCESS_MODAL_NAME, { context: subscription });
      } else {
        router.reload();
      }

      close();
    } catch (e: unknown) {
      captureApiException(e);

      if (isApiErrorWithMessage(e)) {
        setErrorMessage(getMessageFromError(e));
      }
    }
  };

  const handleSkip = () => {
    showModal(ENABLED_SUCCESS_MODAL_NAME, { context: subscription });
    close();
  };

  const handleClose = () => {
    onComplete && onComplete();
    close();
  };

  return (
    <TypeAheadMarketSelectionModal
      isOpen={isOpen}
      close={handleClose}
      onSubmit={handleInterestedMarketsSubmit}
      headerText={headerText}
      bodyText={bodyText}
      errorMessage={errorMessage}
      marketSelectionText="Select your areas of interest"
      selectedMarkets={selectedMarkets}
      handleSetSelectedMarkets={setSelectedMarkets}
      onSkip={showSkip ? handleSkip : undefined}
      isSubmitDisabled={false}
      activeOnly={false}
    />
  );
};
