import classNames from 'classnames';
import React from 'react';

import modalStyles from '../../common/modal.module.scss';
import { Modal } from '../../components/Modal';
import { rangeMinBidMap } from '../../components/OfferSubmitContents/components/BidIncrementSection/BidIncrementSection';
import { SubmitButton } from '../../components/SubmitButton';
import { useModalByName } from '../../hooks/useModalByName';

import { AutoOfferInfoModalContents } from './AutoOfferInfoModalContents';
import styles from './BidIncrementModal.module.scss';

export const BID_INCREMENT_MODAL_NAME = 'BidIncrementModal';

export const BidIncrementModal = () => {
  const [isOpen, , close] = useModalByName(BID_INCREMENT_MODAL_NAME);

  return (
    <Modal hide={close} isShowing={isOpen} modalClassNames={modalStyles.modal}>
      <AutoOfferInfoModalContents close={close} headerText="AutoOffer Increment">
        <ul>
          <li className={classNames(styles.bidIncrementHeader, styles.bidIncrementRow)}>
            <div>Marketplace Asking Price</div>
            <div>Min. AutoOffer Increment</div>
          </li>
          {rangeMinBidMap.map((bidIncrement) => (
            <li key={bidIncrement.range} className={styles.bidIncrementRow}>
              <div>{bidIncrement.range}</div>
              <div>{bidIncrement.minIncrement}</div>
            </li>
          ))}
        </ul>

        <SubmitButton onClick={close}>Close</SubmitButton>
      </AutoOfferInfoModalContents>
    </Modal>
  );
};
