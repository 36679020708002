import classNames from 'classnames';
import React from 'react';

import { CheckEmailIcon } from '../../components/Icons';

import styles from './Forgot.module.scss';

const SuccessMessage = () => {
  return (
    <div className={classNames(styles.formContent, 'mt-5', 'pt-5')}>
      <CheckEmailIcon fontSize={83} />
      <div className={styles.title}>Reset password link sent.</div>
      <div className={styles.subTitle}>Check your email for a link to reset your password.</div>
    </div>
  );
};

export default SuccessMessage;
