import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHowDoOffersCompare = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 109 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#how-do-offers-compare_svg__a)">
      <path
        d="M54.5 108c29.823 0 54-24.177 54-54S84.323 0 54.5 0 .5 24.177.5 54s24.177 54 54 54Z"
        fill="#32BEA6"
      />
      <path
        d="M84.537 47.332a1.963 1.963 0 0 1-2.751.378L54.5 27.01l-27.287 20.7a1.964 1.964 0 1 1-2.373-3.129l28.473-21.6a1.964 1.964 0 0 1 2.374 0l28.473 21.6a1.964 1.964 0 0 1 .377 2.751Z"
        fill="#DF2C2C"
      />
      <path d="M60.39 63.819a5.89 5.89 0 1 0-11.78 0v9.818h11.78v-9.818Z" fill="#9C6144" />
      <path d="M54.5 27.01 30.936 44.888v28.75h47.128v-28.75L54.5 27.01Z" fill="#fff" />
      <path
        d="M42.718 50.195a.859.859 0 0 1-.859.86h-5.154a.859.859 0 0 1-.86-.86v-5.154a.86.86 0 0 1 .86-.86h5.154a.86.86 0 0 1 .86.86v5.154Zm0 14.727a.86.86 0 0 1-.859.86h-5.154a.859.859 0 0 1-.86-.86v-5.154a.86.86 0 0 1 .86-.86h5.154a.86.86 0 0 1 .86.86v5.154Zm15.515-14.557a.884.884 0 0 1-.884.884h-5.3a.884.884 0 0 1-.883-.884v-5.3a.883.883 0 0 1 .883-.883h5.3a.884.884 0 0 1 .884.883v5.3Zm14.727-.34a.834.834 0 0 1-.834.835h-5.01a.834.834 0 0 1-.834-.835v-5.009c0-.461.374-.834.834-.834h5.01c.461 0 .834.374.834.834v5.01Z"
        fill="#40C9E7"
      />
      <path d="M57.445 67.746a.982.982 0 1 0 0-1.964.982.982 0 0 0 0 1.964Z" fill="#FACB1B" />
      <path
        d="M64.318 44.918c10.421 0 18.9 8.479 18.9 18.9 0 10.42-8.479 18.9-18.9 18.9-10.42 0-18.9-8.478-18.9-18.9 0-10.422 8.48-18.9 18.9-18.9Zm0-2.7c-11.929 0-21.6 9.67-21.6 21.6 0 11.929 9.671 21.6 21.6 21.6 11.93 0 21.6-9.671 21.6-21.6 0-11.93-9.67-21.6-21.6-21.6Z"
        fill="#FCF6C2"
      />
      <path
        d="M64.318 82.718c-10.42 0-18.9-8.479-18.9-18.9s8.48-18.9 18.9-18.9c10.421 0 18.9 8.479 18.9 18.9s-8.479 18.9-18.9 18.9Z"
        fill="#FACB1B"
      />
      <path
        d="M64.318 44.918c-10.422 0-18.9 8.479-18.9 18.9 0 5.364 2.25 10.208 5.85 13.651l26.701-26.701c-3.443-3.6-8.287-5.85-13.65-5.85Z"
        fill="#FBE158"
      />
      <path
        d="M45.418 63.818c0-10.421 8.48-18.9 18.9-18.9 5.35 0 10.184 2.237 13.625 5.822l1.916-1.916c-3.929-4.07-9.437-6.606-15.54-6.606-11.93 0-21.6 9.67-21.6 21.6 0 6.104 2.535 11.612 6.606 15.54l1.915-1.916c-3.584-3.44-5.822-8.274-5.822-13.624Z"
        fill="#fff"
      />
      <path
        d="M64.798 50.342c.778 0 1.394.615 1.394 1.393v.713c1.911.227 3.531.842 5.022 1.782.518.291.972.777.972 1.555 0 1.004-.81 1.782-1.815 1.782-.324 0-.648-.097-.972-.292-1.134-.68-2.236-1.166-3.337-1.425v6.222c4.925 1.23 7.032 3.207 7.032 6.674 0 3.564-2.787 5.93-6.902 6.319v.675c0 .778-.616 1.394-1.394 1.394-.777 0-1.425-.616-1.425-1.394V75c-2.43-.259-4.666-1.134-6.643-2.527-.551-.356-.875-.875-.875-1.555a1.757 1.757 0 0 1 1.782-1.782c.386-.001.761.124 1.07.356 1.522 1.102 3.012 1.847 4.794 2.171V65.28c-4.731-1.231-6.933-3.013-6.933-6.675 0-3.467 2.754-5.865 6.804-6.189v-.68c0-.779.649-1.394 1.426-1.394Zm-1.297 11.05v-5.736c-2.073.195-3.11 1.296-3.11 2.722 0 1.36.616 2.203 3.11 3.013Zm2.56 4.504v5.897c2.074-.227 3.208-1.264 3.208-2.82 0-1.425-.712-2.3-3.207-3.077Z"
        fill="#8E6134"
      />
    </g>
    <defs>
      <clipPath id="how-do-offers-compare_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h108v108H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHowDoOffersCompare;
