import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import * as api from '../../api/auth/forgotPassword';
import { isApiErrorWithMessage, getMessageFromError } from '../../api/user';
import commonStyles from '../../common/modal.module.scss';
import { ExitIcon } from '../../components/Icons';
import { useUserEmail } from '../../hooks/store/misc';
import { useModalManager } from '../../hooks/useModalManager';
import { COGNITO_RESET_PASSWORD_MODAL_NAME } from '../../modals/ResetPasswordModal';

import styles from './Forgot.module.scss';
import { ErrorType, ForgotPasswordContent, FormField } from './ForgotPasswordContent';

interface IProps {
  close: () => void;
  isOpen: boolean;
}

const ForgotPassword = (props: IProps) => {
  const [isLinkSent, setIsLinkSent] = useState(false);
  const { isOpen, close } = props;

  const [isError, setIsError] = useState<ErrorType>(null);
  const [loading, setLoading] = useState(false);

  const { showModal } = useModalManager();
  const [, setUserEmail] = useUserEmail();

  const handleClose = () => {
    setIsLinkSent(false);
    close();
  };

  const sendForgotPassword = (data: FormField) => {
    api
      .forgotPassword(data.email)
      .then((response) => {
        setLoading(false);

        if (response.data && response.data.type === 'cognito') {
          handleClose();
          showModal(COGNITO_RESET_PASSWORD_MODAL_NAME);
        } else {
          setIsLinkSent(true);
        }
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (isApiErrorWithMessage(error)) {
          setIsError({ error: true, message: getMessageFromError(error) });
        } else {
          setIsError({ error: true, message: '' });
        }
      });
  };

  const onSubmit = (data: FormField) => {
    setLoading(true);
    setIsError(null);
    setUserEmail(data.email);
    sendForgotPassword(data);
  };

  return (
    <Modal id="loginModal" isOpen={isOpen} toggle={handleClose} className={styles.forgotModal}>
      <ModalBody id="model-body">
        <ExitIcon onClick={handleClose} className={commonStyles.closeButton} />
        <ForgotPasswordContent
          onSubmit={onSubmit}
          loading={loading}
          isError={isError}
          isLinkSent={isLinkSent}
        />
      </ModalBody>
    </Modal>
  );
};

export default ForgotPassword;
