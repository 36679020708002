import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgHouse({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="house_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.house_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="house_svg__cls-1"
        d="M15.18 57.18h.43v.43h-.43zM47.68 52.5H34.39A1.44 1.44 0 0133 51.07l-.1-10.16h-5.7l-.1 10.16a1.44 1.44 0 01-1.44 1.43H12.32a1.45 1.45 0 01-1.44-1.45v-21.8H6.43a1.43 1.43 0 01-1.35-1 1.47 1.47 0 01.46-1.61L29.11 7.81a1.44 1.44 0 011.78 0l23.57 18.85a1.47 1.47 0 01.46 1.61 1.43 1.43 0 01-1.35 1h-4.45v21.8a1.45 1.45 0 01-1.44 1.43zM35.8 49.6h10.45V27.8a1.44 1.44 0 011.43-1.45h1.77L30 10.8 10.55 26.35h1.77a1.44 1.44 0 011.43 1.45v21.8H24.2l.1-10.15A1.44 1.44 0 0125.73 38h8.54a1.44 1.44 0 011.43 1.43z"
      />
    </svg>
  );
}

export default SvgHouse;
