import React, { useState } from 'react';

import { Subscription } from '../../contexts/SubscriptionProvider';
import {
  getFeatureSpecificInfoText,
  StaticSubscription,
  STATIC_SUBSCRIPION_METADATA,
  getStaticSubscriptionMetaDataByNameWithInterval
} from '../../helpers/subscriptionHelper';
import { getExternalInvestorLink } from '../../utils/url.utils';
import { InlineNotification } from '../InlineNotification';
import { MembershipCard } from '../MembershipCard';
import { Button } from '../design-system';

import styles from './MembershipTierSelection.module.scss';

type MembershipTierSelectionProps = React.PropsWithChildren<{
  subscriptions: Array<Subscription>;
  currentUserSubscription: StaticSubscription;
  onMembershipTierSelection: (staticSubscription: StaticSubscription) => void;
  featureContext?: string;
  isFirstLogin: boolean;
}>;

const onMembershipPlanRoute = () => {
  window.open(getExternalInvestorLink('investor/pricing'), '_blank');
};

export const MembershipTierSelection: React.FC<MembershipTierSelectionProps> = ({
  subscriptions = [],
  onMembershipTierSelection,
  currentUserSubscription,
  featureContext,
  isFirstLogin = false
}: MembershipTierSelectionProps) => {
  // Feature context is set in cases a user triggers the upgrade from a members only feature
  const isUserUpgrading = !!featureContext;

  const filteredSubscriptions: Array<StaticSubscription> = isUserUpgrading
    ? STATIC_SUBSCRIPION_METADATA.filter((sub) => {
        return sub.tier > currentUserSubscription.tier;
      })
    : STATIC_SUBSCRIPION_METADATA;

  const [currentStaticSubscription, setCurrentStaticSubscription] = useState<StaticSubscription>(
    filteredSubscriptions.find(({ tier }) => tier === currentUserSubscription?.tier) ||
      filteredSubscriptions[0]
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const buttonContent = !isFirstLogin
    ? isUserUpgrading
      ? 'Become a Sundae Member'
      : 'Change My Membership'
    : 'Select Plan';

  const onMembershipSelection = (staticSubscription: StaticSubscription) => {
    setCurrentStaticSubscription(staticSubscription);
  };

  const onUpdateMembership = async (staticSubscription: StaticSubscription) => {
    if (staticSubscription) {
      setIsLoading(true);
      await onMembershipTierSelection(staticSubscription);
      setIsLoading(false);
    }
  };

  const getInlineNotifications = () => {
    if (featureContext) {
      const inlineText = getFeatureSpecificInfoText(featureContext);

      return [inlineText];
    } else {
      return (
        getStaticSubscriptionMetaDataByNameWithInterval(currentStaticSubscription.name || '')
          ?.infoStrings || []
      );
    }
  };

  return (
    <div className={styles.membershipTierSelection}>
      {!isFirstLogin && (
        <InlineNotification>
          {getInlineNotifications().map((infoString, i) => (
            <p key={i}>{infoString}</p>
          ))}
        </InlineNotification>
      )}

      {filteredSubscriptions.map((subscription) => (
        <MembershipCard
          key={subscription.name}
          onMembershipSelection={onMembershipSelection}
          subscriptions={subscriptions}
          staticSubscription={subscription}
          disabled={isLoading}
          selected={subscription.name === currentStaticSubscription.name}
        />
      ))}

      <div className={styles.buttonGroup}>
        <Button
          onClick={() => onUpdateMembership(currentStaticSubscription)}
          variant="primary"
          size="large"
          disabled={isLoading}
          fullWidth
          loading={isLoading}
        >
          {buttonContent}
        </Button>
        <Button onClick={onMembershipPlanRoute} variant="secondary" size="large" fullWidth>
          {isFirstLogin ? 'Compare Plan Features' : 'Learn more about membership plans'}
        </Button>
      </div>
    </div>
  );
};
