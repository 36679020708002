import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { VerifyEmailRequestBody } from '../_base/generated/data-contracts';

// Use these endpoints when triggering the email verification email on its own
// POST /oauth/send-email-verification-code
export const sendEmailVerificationCode = (body: VerifyEmailRequestBody) => {
  return api.authControllerSendEmailVerificationCode(body);
};

export const useSendEmailVerificationCode = () => {
  return useMutation({ mutationFn: sendEmailVerificationCode });
};
