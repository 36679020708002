import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgExit({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="exit_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <path
        fill={props.color ? props.color : '#4b4b4b'}
        d="M54 55.5a1.51 1.51 0 01-1.06-.44l-48-48a1.5 1.5 0 012.12-2.12l48 48a1.49 1.49 0 010 2.12 1.51 1.51 0 01-1.06.44z"
      />
      <path
        fill={props.color ? props.color : '#4b4b4b'}
        d="M5 56.5a1.51 1.51 0 01-1.06-.44 1.49 1.49 0 010-2.12l50-50a1.5 1.5 0 012.12 2.12l-50 50A1.51 1.51 0 015 56.5z"
      />
    </svg>
  );
}

export default SvgExit;
