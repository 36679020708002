/* eslint-disable import/order */
import Head from 'next/head';
import { Provider } from 'react-redux';
import store from '../src/store/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { Toaster } from '../src/components/Toaster';
import { AppProps } from 'next/app';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import '../src/App.scss';
import '../src/index.scss';
import '../src/datePicker.scss';
import '../src/components/UI/PropertyImageSlider/style.scss';
import { Modals } from '../src/modals';
import { StripeProvider } from '../src/services/stripe';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { getCookie, setCookie } from '../src/utils/localStorage.utils';

if (typeof window !== 'undefined') {
  posthog.init('phc_kvhgfyVu9f2nGgNagqcxE30KUT7usOudjA9R2mG74cf', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
    session_recording: {
      maskAllInputs: true,
      maskInputFn: (text: string, element?: HTMLElement): string => {
        if (element?.dataset.record === 'true') {
          return text;
        }

        return '*'.repeat(text.length);
      }
    }
  });

  if (
    location.hostname === 'localhost' ||
    location.hostname === '127.0.0.1' ||
    location.hostname.endsWith('sundae')
  ) {
    posthog.opt_out_capturing();
  }

  const { hostname, search, pathname } = window.location;
  const urlParams = new URLSearchParams(search);

  const referrerHost = document.referrer ? new URL(document.referrer).hostname : '';
  const referrer = encodeURIComponent(urlParams.get('utm_referrer') || referrerHost);

  if (!getCookie('landing_page')) {
    setCookie('landing_page', `${hostname}${pathname}`);
  }

  setCookie('url_params', `${search.replace('?', '')}`);

  if (!getCookie('utm_referrer')) {
    setCookie('utm_referrer', referrer);
  }

  [
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
    'utm_placement',
    'gclid',
    'ad_descriptor',
    'fbclid'
  ].forEach((cookieName) => {
    const cookieValue = urlParams.get(cookieName);

    if (cookieValue) {
      setCookie(cookieName, cookieValue);
    }
  });
}

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-PJSKBDL' });
  }, []);

  return (
    <>
      <Head>
        <meta content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width,user-scalable=no, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Fully vetted deals - Sundae" />
        <title>Sundae - Investor Marketplace</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <PostHogProvider client={posthog}>
          <Provider store={store}>
            <StripeProvider>
              <div className="App" id="mainCntApp">
                <Toaster />
                <Component {...pageProps} />
                <Modals />
              </div>
            </StripeProvider>
          </Provider>
        </PostHogProvider>
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
