import classNames from 'classnames';
import React, { FC } from 'react';

import { AlertIcon } from '../../Icons';

import styles from './InputLabel.module.scss';

type InputLabelProps = {
  htmlFor?: string;
  error?: boolean;
  children?: React.ReactNode;
};

export const InputLabel: FC<InputLabelProps> = ({ htmlFor, children, error }) => (
  <label htmlFor={htmlFor} className={classNames(styles.label, { [styles.error]: error })}>
    {error && <AlertIcon />}
    {children}
  </label>
);
