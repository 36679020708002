import classNames from 'classnames';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import defaultStyles from './Modal.module.scss';

export interface ModalProps {
  isShowing: boolean;
  children: React.ReactNode;
  container?: HTMLElement;
  theme?: Partial<typeof defaultStyles>;
  modalClassNames?: string;
  overlayClassNames?: string;
  style?: React.CSSProperties;
  hide: () => void;
  noCloseOnEsc?: boolean;
}

export const Modal = ({
  isShowing,
  children,
  theme,
  modalClassNames,
  overlayClassNames,
  container = document.body,
  style,
  hide,
  noCloseOnEsc
}: ModalProps) => {
  const styles = { ...defaultStyles, ...theme };

  useEffect(() => {
    if (!isShowing || noCloseOnEsc) return;

    const close = (e: KeyboardEvent) => {
      // if ESC key is pressed, close Modal
      if (e.key === 'Escape') {
        hide && hide();
      }
    };

    window.addEventListener('keydown', close);

    return () => window.removeEventListener('keydown', close);
  });

  return isShowing
    ? ReactDOM.createPortal(
        <div style={style} className={classNames(styles.overlay, overlayClassNames)} onClick={hide}>
          <div
            className={classNames(styles.modal, modalClassNames)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </div>
        </div>,
        container
      )
    : null;
};
