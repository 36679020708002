import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { BeginSignUpRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/begin-signup
export const beginSignup = (params: BeginSignUpRequestBody) => {
  return api.authControllerBeginSignUp(params);
};

export const useBeginSignup = () => {
  return useMutation({ mutationFn: beginSignup });
};
