import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerMyProperty = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9.6 12.441h-.5V19.5h-5v-9.412H1.322L12 .667l10.678 9.421H19.9V19.5h-5v-7.059H9.6Z"
      stroke="#4A4A4A"
    />
  </svg>
);
export default SvgSellerMyProperty;
