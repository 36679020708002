import classNames from 'classnames';
import React from 'react';

import { GetUserInfoResponseBody } from '../../api/_base/generated/data-contracts';
import { updateUser } from '../../api/user/updateUser';
import modalStyles from '../../common/modal.module.scss';
import { Modal } from '../../components/Modal';
import { ModalCloseButton } from '../../components/Modal/ModalCloseButton';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';
import { useModalManager } from '../../hooks/useModalManager';
import { getUserInfo } from '../../utils/localStorage.utils';
import { CHOOSE_ACTIVE_MARKETS_MODAL_NAME } from '../MarketManagementModals/ChooseActiveMarketsModal';

import styles from './Mp2Modal.module.scss';

export const MP_2_MODAL = 'Mp2Modal';

export const Mp2Modal = () => {
  const [isOpen, , close] = useModalByName(MP_2_MODAL);
  const { showModal } = useModalManager();

  const user: GetUserInfoResponseBody = getUserInfo();

  // Account creation after MP 2.0
  const showNewInvestorVideo =
    user?.hasSeenMp2 ||
    (user?.ipAccountCreationC &&
      new Date(user.ipAccountCreationC) > new Date('2024-08-29T12:00:00.000-07:00'));

  const handleCloseModal = (isSelect?: boolean) => {
    updateUser({ hasSeenMp2: true });
    close();

    if (user?.userShouldConfirmMarkets || isSelect) {
      showModal(CHOOSE_ACTIVE_MARKETS_MODAL_NAME);
    }
  };

  return (
    <Modal
      isShowing={isOpen}
      modalClassNames={classNames(modalStyles.modal, modalStyles.center)}
      hide={() => handleCloseModal()}
    >
      {<ModalCloseButton isAbsolute onClick={() => handleCloseModal()} />}
      <h2 className={modalStyles.modalHeader}>
        {showNewInvestorVideo
          ? 'Welcome to the Sundae Investor Marketplace'
          : 'Improvements to the Investor Marketplace'}
      </h2>
      <div className={styles.subtitle}>
        {showNewInvestorVideo
          ? `As you get started, watch this 3 min video from our CEO, Josh Stech, to learn how our platform works and start growing your business today.`
          : `We've heard your feedback and made some important changes. Check out this 3 min video from our CEO, Josh Stech, to learn more!`}
      </div>
      <div
        style={{
          margin: 'auto'
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            paddingBottom: '56.25%'
          }}
        >
          <iframe
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            src={
              showNewInvestorVideo
                ? 'https://player.vimeo.com/video/1003808475?h=9dd08b34f3'
                : 'https://player.vimeo.com/video/1003808447?h=9f05f4fc1d'
            }
            title={showNewInvestorVideo ? 'Welcome to Sundae' : 'Marketplace Improvements'}
            allow="autoplay; fullscreen; picture-in-picture"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      {showNewInvestorVideo ? (
        <div className={styles.info}>
          <div className={styles.infoItem}>
            <div className={styles.infoItemTitle}>What kind of properties are available?</div>
            <div className={styles.infoItemText}>
              We provide access to exclusive, off-market, single-family residential properties. All
              houses are under contract to sell and the contract is offered to you as a wholesale
              assignment.
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.infoItemTitle}>How do you place offers?</div>
            <div className={styles.infoItemText}>
              Offers are made over two rounds:
              <br />
              1. Auction: Top 3 offers move to next round.
              <br />
              2. Highest and Best: One chance to make a final offer.
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.infoItemTitle}>How do you win?</div>
            <div className={styles.infoItemText}>
              Be the top offer at, or above, asking price and the deal is yours! Use our AutoOffer
              feature to stay in top position during round one. Taking a loan from Sundae Funding to
              finance your flip will directly improve your offer position as well.
            </div>
          </div>
          <div className={styles.infoItem}>
            We look forward to working together on your next opportunity!
          </div>
        </div>
      ) : (
        <div className={styles.info}>
          <div className={styles.infoItem}>
            <div className={styles.infoItemTitle}>If you win, you transact</div>
            <div className={styles.infoItemText}>
              Every property is under contract and offered as a wholesale assignment. The top offer
              at, or above, asking price wins.
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.infoItemTitle}>Offers are placed over two rounds</div>
            <div className={styles.infoItemText}>
              Round 1: Our existing auction process, expect now the top three offers proceed to a
              final round.
              <br />
              <br />
              Round 2: One chance to place your highest and best.
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.infoItemTitle}>Improve your offer position</div>
            <div className={styles.infoItemText}>
              Using Sundae Funding will now directly affect your offer position.
            </div>
          </div>
          <div className={styles.infoItem}>
            We look forward to working together on your next opportunity!
          </div>
        </div>
      )}
      <Button variant="primary" size="large" fullWidth onClick={() => handleCloseModal(true)}>
        Browse Properties
      </Button>
    </Modal>
  );
};
