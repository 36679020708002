import { AreaOption } from '../types/onBoarding';

export const formatDisplayNum = (num: number, fractionDigits = 1, millionsFractionDigits = 1) => {
  if (!num) return '';

  let dt = String(num);

  if (num > 999 && num < 1000000) {
    dt = (num / 1000).toFixed(fractionDigits) + 'K';
  } else if (num > 1000000) {
    dt = (num / 1000000).toFixed(millionsFractionDigits) + 'M';
  }

  return dt.replace('.0', '');
};

export const trimString = (string: string, maxLength: number) => {
  return string && string.length > maxLength ? string.substring(0, maxLength - 3) + '...' : string;
};

export const getCurrencyFormatter = (
  maximumFractionDigits?: number,
  minimumFractionDigits?: number
) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minimumFractionDigits || 0,
    maximumFractionDigits: maximumFractionDigits
  }).format;

export const formatNumToCurrency = (
  amount = 0,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number
) => {
  return getCurrencyFormatter(maximumFractionDigits, minimumFractionDigits)(amount);
};

export const formatCurrencyToNum = (cur: string) => {
  const valueAsString = cur.replace('$', '').split(',').join('');

  return Number(valueAsString);
};

export const formatNumber = (number: number): string => {
  return new Intl.NumberFormat('en-US').format(number);
};

export const formatStringAsNumber = (numberAsString: string): string => {
  return formatNumber(Number(numberAsString));
};

export const formatToSquareFeet = (numberOrString: string | number) => {
  const formattedString =
    typeof numberOrString === 'string'
      ? formatStringAsNumber(numberOrString)
      : formatNumber(numberOrString);

  return `${formattedString} sq ft`;
};

export const getMarketName = (market: AreaOption): string => `${market.name}, ${market.stateShort}`;

export const getMaxMarketText = (maxMarkets: number) => {
  if (maxMarkets === 1) {
    return 'Select your Market';
  } else if (maxMarkets > 1) {
    return `Select your ${maxMarkets} Markets`;
  } else {
    return 'Select your Markets';
  }
};

export const formatDateToMonthDayYear = (dateStr: string) => {
  const date = new Date(dateStr);

  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear()
  );
};

// Copy pasted from https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};
