import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgArrowUp({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M55 41.5a1.52 1.52 0 01-.94-.33L30 21.92 5.94 41.17a1.5 1.5 0 01-1.88-2.34l25-20a1.5 1.5 0 011.88 0l25 20a1.51 1.51 0 01.23 2.11 1.48 1.48 0 01-1.17.56z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgArrowUp;
