import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../store';
import { AutoApprovalToken, selectMiscState } from '../../../store/misc';
import * as actions from '../../../store/misc';

export const useAutoApprovalToken = () => {
  const dispatch = useDispatch<AppDispatch>();
  const autoApprovalToken = useSelector(selectMiscState).autoApprovalToken;

  const setAutoApprovalToken = (token: AutoApprovalToken) => {
    dispatch(actions.setAutoApprovalToken(token));
  };

  return [autoApprovalToken, setAutoApprovalToken] as const;
};
