import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgMinimize({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="minimize_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 8"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.minimize_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path className="minimize_svg__cls-1" d="M1.5 6.49l4-5 4 5h-8" />
      <path
        className="minimize_svg__cls-1"
        d="M9.5 7.49h-8a1 1 0 01-.9-.57 1 1 0 01.12-1l4-5a1 1 0 011.56 0l4 5a1 1 0 01.12 1 1 1 0 01-.9.57zm-5.92-2h3.84L5.5 3.09z"
      />
    </svg>
  );
}

export default SvgMinimize;
