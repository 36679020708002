import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { VerifyEmailRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/verify-email-code
export const verifyEmailCode = (body: VerifyEmailRequestBody) => {
  return api.authControllerVerifyEmail(body);
};

export const useVerifyEmailCode = () => {
  return useMutation({ mutationFn: verifyEmailCode });
};
