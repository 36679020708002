import { axiosConfig, setupRequestInterceptors } from '../_client/interceptor';

import { Api } from './generated/Api';
import { Homeowner } from './generated/Homeowner';
import { Oauth } from './generated/Oauth';
import { Public } from './generated/Public';
import { V2 } from './generated/V2';
import { HttpClient } from './generated/http-client';

// Note that we could also use the --single-http-client option for swagger-typescript-api
// to be able to pass an already configured instance directly to the client constructor

const client = new HttpClient(axiosConfig);
setupRequestInterceptors(client.instance);

const apiRoutes = new Api(client);
const homeownerRoutes = new Homeowner(client);
const oauthRoutes = new Oauth(client);
const publicRoutes = new Public(client);
const v2Routes = new V2(client);

// Object.assign types are only defined for up to 3 assignments, so we assign in parts
const temp1 = Object.assign({}, apiRoutes, homeownerRoutes, oauthRoutes);
const temp2 = Object.assign({}, publicRoutes, v2Routes);

export const api = Object.assign({}, temp1, temp2);
