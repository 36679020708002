import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerMap = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-map_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6 3.4c-.25-.4-.6-.65-1-.85l-6-2.4c-.55-.2-1.15-.2-1.65 0L8.55 2.7c-.1.05-.2.05-.3.05-.1 0-.2 0-.3-.05L2.05.35c-.25-.1-.5-.15-.7-.1C1.1.3.85.35.65.5c-.2.15-.35.35-.5.55-.1.2-.15.45-.15.7V16.1c0 .45.15.9.4 1.25s.6.65 1.05.85l6 2.4c.25.1.55.15.85.15.3 0 .55-.05.85-.15l2.9-1.15c.4-.15.55-.6.4-1-.15-.4-.6-.55-1-.4l-2.45 1V4.15c.021 0 .033 0 .044-.004.015-.005.027-.017.056-.046L15 1.75v6.5c0 .4.35.75.75.75s.75-.35.75-.75v-6.5l5.55 2.2c.15.05.25.15.35.3.05.1.1.25.1.4V9c0 .4.35.75.75.75S24 9.4 24 9V4.65c0-.45-.15-.9-.4-1.25ZM1.95 16.8c-.15-.05-.25-.15-.35-.3-.05-.1-.1-.25-.1-.4V1.75L7.4 4.1h.1V19l-5.55-2.2Zm16.8-6.25c-1.4 0-2.7.55-3.7 1.55s-1.55 2.3-1.55 3.7c0 2 2.2 5.25 4.05 7.6.15.2.3.35.55.45.2.1.45.15.65.15s.45-.05.65-.15c.2-.1.4-.25.55-.45C21.8 21.05 24 17.8 24 15.8c0-1.4-.55-2.7-1.55-3.7s-2.3-1.55-3.7-1.55Zm0 11.95C16.45 19.55 15 17 15 15.8c0-1 .4-1.95 1.1-2.65a3.746 3.746 0 0 1 6.4 2.65c0 1.2-1.45 3.75-3.75 6.7Zm1.1-6.65a1.1 1.1 0 1 1-2.2 0 1.1 1.1 0 0 1 2.2 0Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="seller-map_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerMap;
