import React, { FC, ReactNode, Children } from 'react';

import { FormHelperText } from '../FormHelperText/FormHelperText';
import { InputLabel } from '../InputLabel/InputLabel';

import styles from './RadioAndCheckboxGroup.module.scss';

type RadioAndCheckboxGroupProps = {
  type: 'checkbox' | 'radio';
  label: ReactNode;
  helperText?: ReactNode;
  hasError?: boolean;
  children?: ReactNode;
};

export const RadioAndCheckboxGroup: FC<RadioAndCheckboxGroupProps> = ({
  type,
  label,
  helperText,
  hasError,
  children
}) => (
  <>
    <InputLabel error={hasError}>{label}</InputLabel>
    <div role="group" aria-labelledby={`${type}-group`}>
      {Children.map(children, (child, index) => (
        <div key={index} className={styles.inputContainer}>
          {child}
        </div>
      ))}
      {!helperText ? null : <FormHelperText error={hasError}>{helperText}</FormHelperText>}
    </div>
  </>
);
