import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { ConfirmForgotPasswordRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/reset-password
export const resetPassword = (body: ConfirmForgotPasswordRequestBody) => {
  return api.authControllerConfirmForgotPassword(body);
};

export const useResetPassword = () => {
  return useMutation({ mutationFn: resetPassword });
};
