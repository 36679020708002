import { PostHog } from 'posthog-js';

import {
  ListPropertiesResponseBodyItem,
  ListingListWithOfferEntryApiModel,
  PropertyDetailsResponseBody
} from '../../api/_base/generated/data-contracts';

import { recordPostHogEvent } from './recordPostHogEvent';

export const recordUserFavoritedPropertyEventName = 'user_favorited_property';

export const recordUserFavoritedProperty = (
  property:
    | PropertyDetailsResponseBody
    | ListPropertiesResponseBodyItem
    | ListingListWithOfferEntryApiModel,
  posthog: PostHog
) => {
  recordPostHogEvent(property, recordUserFavoritedPropertyEventName, posthog, {
    closing_date: `${property?.projectedCloseDate}`,
    address: `${property?.streetAddress}, ${property?.city}, ${property?.state}, ${property?.zipCode}`,
    territory_id: `${property?.territoryId}`
  });
};
