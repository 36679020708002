import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWhatIsMpa = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 109 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#what-is-mpa_svg__a)">
      <path
        d="M54.5 108c29.823 0 54-24.177 54-54S84.323 0 54.5 0 .5 24.177.5 54s24.177 54 54 54Z"
        fill="#25B7D3"
      />
      <path
        d="M30.936 26.352a3.77 3.77 0 0 1 3.77-3.77h27.648l15.71 13.196v45.87a3.77 3.77 0 0 1-3.77 3.77H34.706a3.77 3.77 0 0 1-3.77-3.77V26.352Z"
        fill="#fff"
      />
      <path d="M62.355 22.582v9.425a3.77 3.77 0 0 0 3.77 3.77h11.939" fill="#F0F1F1" />
      <path
        d="M63.383 61.957a.98.98 0 0 0-.975.874 7.856 7.856 0 0 1-15.612 0 .982.982 0 0 0-.975-.874H27.11a3.927 3.927 0 0 0-3.927 3.927v15.71a3.927 3.927 0 0 0 3.927 3.927h54.982a3.927 3.927 0 0 0 3.927-3.928V65.884a3.927 3.927 0 0 0-3.927-3.927h-18.71Z"
        fill="#9C6144"
      />
      <path
        d="M58.427 37.309a.982.982 0 0 1-.981.982H39.773a.982.982 0 0 1 0-1.964h17.673a.982.982 0 0 1 .981.982Zm11.782 7.855a.982.982 0 0 1-.982.981H39.773a.982.982 0 1 1 0-1.963h29.454a.981.981 0 0 1 .982.981Zm0 7.854a.981.981 0 0 1-.982.982H39.773a.982.982 0 0 1 0-1.964h29.454a.982.982 0 0 1 .982.982Z"
        fill="#E2E4E5"
      />
      <path
        d="M53.167 69.679a7.859 7.859 0 0 1-6.371-6.848.982.982 0 0 0-.975-.874H27.11a3.927 3.927 0 0 0-3.927 3.927v15.71a3.927 3.927 0 0 0 3.927 3.927h10.213l15.843-15.842Z"
        fill="#AF7653"
      />
    </g>
    <defs>
      <clipPath id="what-is-mpa_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h108v108H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWhatIsMpa;
