import classNames from 'classnames';
import React, { ButtonHTMLAttributes, ElementRef, forwardRef } from 'react';

import { Spinner } from '../Spinner';

import styles from './Button.module.scss';

const DEFAULT_TAG = 'button';
export type Variants = 'primary' | 'secondary' | 'bare';
export type Sizes = 'small' | 'medium' | 'large';
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variants;
  size?: Sizes;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
}

export const Button = forwardRef<ElementRef<typeof DEFAULT_TAG>, ButtonProps>(
  (
    {
      children,
      className,
      disabled = false,
      variant = 'primary',
      size = 'medium',
      fullWidth,
      loading,
      ...rest
    },
    ref
  ) => (
    <button
      ref={ref}
      disabled={disabled}
      className={classNames(
        styles.button,
        styles[`${variant}`],
        {
          [styles[size]]: variant !== 'bare',
          [styles.fullWidth]: fullWidth
        },
        className
      )}
      {...rest}
    >
      {loading ? <Spinner /> : children}
    </button>
  )
);

export default Button;
