import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgSeptic({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="septic_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.septic_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="septic_svg__cls-1"
        d="M19.1 35.87a.47.47 0 00.47.45h4.52a.47.47 0 00.47-.47v-.16a8.24 8.24 0 00-8.2-8.26H5.47a.47.47 0 00-.47.47v4.32a.47.47 0 00.47.48h9.22c2.83 0 4.31 1.07 4.41 3.17z"
      />
      <path
        className="septic_svg__cls-1"
        d="M49 46.93c0 2.67-1.86 4.84-4.15 4.84H15.41c-2.29 0-4.15-2.17-4.15-4.84V35.59H8.5v11.34c0 4.45 3.1 8.07 6.91 8.07h29.48c3.81 0 6.91-3.62 6.91-8.07V35.59H49zM8.5 14.53v11h2.76V21a8.69 8.69 0 014.26-.78c.54.11 1.08.24 1.61.36a13.21 13.21 0 005.8.48 16.4 16.4 0 001.88-.51 10.32 10.32 0 015.08-.55 18.6 18.6 0 012 .47 14.85 14.85 0 005.38.74 7.64 7.64 0 002.56-.73 5.69 5.69 0 012.86-.59 6.54 6.54 0 011.21.29 9.9 9.9 0 005.1.42v4.95h2.8v-11l.06-.07c.87-1 2.32-2.65 2.32-4.5v-.14C54.18 7.17 52.32 5 50 5H10.27C8 5 6.12 7.17 6.12 9.84V10c0 1.85 1.46 3.51 2.32 4.5zm.39-4.69a1.51 1.51 0 011.38-1.61H50a1.51 1.51 0 011.38 1.61V10a5.61 5.61 0 01-1.49 2.2 2.56 2.56 0 00-.89 1.72v3.32a6.17 6.17 0 01-4.21-.17 8.2 8.2 0 00-2-.43 8.61 8.61 0 00-4.25.85A4.87 4.87 0 0137 18a12.2 12.2 0 01-4.29-.63 20.09 20.09 0 00-2.35-.55 13.32 13.32 0 00-6.53.72c-.56.17-1.08.33-1.55.43a10.63 10.63 0 01-4.44-.46q-.86-.21-1.74-.39a10.32 10.32 0 00-4.87.51v-3.71a2.56 2.56 0 00-.88-1.77A6 6 0 018.89 10z"
      />
      <path
        className="septic_svg__cls-1"
        d="M54.53 27.43H43.64a8.24 8.24 0 00-8.2 8.26v.16a.47.47 0 00.47.47h4.52a.47.47 0 00.47-.45c.1-2.1 1.58-3.17 4.41-3.17h9.22a.47.47 0 00.47-.48V27.9a.47.47 0 00-.47-.47z"
      />
    </svg>
  );
}

export default SvgSeptic;
