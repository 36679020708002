import classNames from 'classnames';
import React from 'react';
import { Tooltip } from 'reactstrap';

import { PasswordStrength } from '../../types/onBoarding';

import styles from './PasswordValidationPopOver.module.scss';

interface PasswordValidationPopOverProps {
  passwordStrength: PasswordStrength;
  id: string;
  className?: string;
  passwordToolIconClassName?: string;
  tooltipOpen: boolean | undefined;
  setTooltip: () => void;
}

export const PasswordValidationPopOver = ({
  passwordStrength,
  id,
  className,
  passwordToolIconClassName,
  tooltipOpen,
  setTooltip
}: PasswordValidationPopOverProps) => {
  return (
    <div>
      <span
        className={classNames(styles.passwordToolIcon, passwordToolIconClassName, className, {
          [styles.valid]: passwordStrength.isValid
        })}
        id={id}
      >
        ⁝
      </span>
      <Tooltip
        placement="top"
        className={styles.passwordTooltip}
        isOpen={tooltipOpen}
        target={id}
        toggle={setTooltip}
      >
        <div className={styles.a0passwordPolicy}>
          <ul>
            <li className={passwordStrength.rules.has8Chars ? styles.a0Checked : ''}>
              At least 8 characters in length
            </li>
            <li className={passwordStrength.rules.fullfil3Condition ? styles.a0Checked : ''}>
              Contain at least 3 of the following 4 types of characters:
              <ul>
                <li className={passwordStrength.rules.hasLowerCase ? styles.a0Checked : ''}>
                  Lower case letters (a-z)
                </li>
                <li className={passwordStrength.rules.hasUpperCase ? styles.a0Checked : ''}>
                  Upper case letters (A-Z)
                </li>
                <li className={passwordStrength.rules.hasNumber ? styles.a0Checked : ''}>
                  Numbers (i.e. 0-9)
                </li>
                <li className={passwordStrength.rules.hasSpecialCharacter ? styles.a0Checked : ''}>
                  Special characters (e.g. !@#$%^&amp;*)
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Tooltip>
    </div>
  );
};
