import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgHelpEmail({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M48.31 13H11.69A7 7 0 005 20.25v19.5A7 7 0 0011.69 47h36.62A7 7 0 0055 39.75v-19.5A7 7 0 0048.31 13zm-7.52 16.09l11.38-7.35v16.74zm-29.1-13h36.62a3.84 3.84 0 013.45 2.34L30 32.48l-21.76-14a3.84 3.84 0 013.45-2.39zm-3.86 5.65l12.11 7.82-12.11 9.67zm40.48 22.17H11.69a3.73 3.73 0 01-3-1.55L22.6 31.28l6.59 4.25a1.49 1.49 0 001.62 0l7.34-4.74 13.45 11.1a3.79 3.79 0 01-3.29 2.02z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgHelpEmail;
