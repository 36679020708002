import classNames from 'classnames';
import React from 'react';

import modalStyles from '../../common/modal.module.scss';
import { Modal } from '../../components/Modal';
import { ModalCloseButton } from '../../components/Modal/ModalCloseButton';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';

import styles from './SundaeFundingModal.module.scss';

export const SUNDAE_FUNDING_MODAL_NAME = 'SundaeFundingModal';

export const SundaeFundingModal = () => {
  const [isOpen, , close, options] = useModalByName(SUNDAE_FUNDING_MODAL_NAME);

  const handleClose = () => {
    if (options?.onCancel) {
      options?.onCancel();
    }

    close();
  };

  return (
    <Modal hide={handleClose} isShowing={isOpen} modalClassNames={modalStyles.modal}>
      <ModalCloseButton isAbsolute onClick={close} />

      <h2 className={classNames(modalStyles.modalHeader, styles.center)}>Sundae Funding</h2>

      <p>
        Seamlessly finance your next property with low, competitive rates, and a smooth pre-approval
        and underwriting process. Sundae will even cover some of the additional fees associated with
        your transaction.
      </p>
      <p>User Sundae Funding to finance your project improves your offer position.</p>
      <p>
        Contact{' '}
        <a href="mailto:funding@sundae.com">
          <strong>funding@sundae.com</strong>
        </a>{' '}
        to get pre-approved within 24 hours.
      </p>
      <div className={modalStyles.buttonField}>
        <Button variant="primary" size="large" fullWidth onClick={handleClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};
