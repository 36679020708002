import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getMessageFromError, isApiErrorWithMessage } from '../../api/user';
import { confirmMarketSelection } from '../../api/user/confirmMarketSelection';
import { getUserInfo as getUserInfo1 } from '../../api/user/getUserInfo';
import modalStyles from '../../common/modal.module.scss';
import { AlertBox } from '../../components/AlertBox';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { Modal } from '../../components/Modal';
import { Button, Select } from '../../components/design-system';
import { StaticSubscription } from '../../helpers/subscriptionHelper';
import { useConfirmDialog } from '../../hooks/useConfirmDialog';
import { useModalByName } from '../../hooks/useModalByName';
import { useModalManager } from '../../hooks/useModalManager';
import { useTerritoryOptions } from '../../hooks/useTerritoryOptions';
import { captureApiException } from '../../logging';
import { selectTypeaheadActiveMarkets } from '../../store/territory';
import { getUserInfo } from '../../utils/localStorage.utils';

import { CHOOSE_INTERESTED_MARKETS_MODAL_NAME } from './ChooseInterestedMarketsModal';
import { MarketManagementModalContents } from './MarketManagementModalContents';
import styles from './MarketManagementModals.module.scss';

export const CHOOSE_PRIMARY_MARKETS_MODAL_NAME = 'ChoosePrimaryMarketsModal';

export const ChoosePrimaryMarketsModal = () => {
  const user = getUserInfo();
  const { territoryOptions: initialPrimaryMarketList } = useTerritoryOptions(
    user?.onboardingPrimaryOperatingMarket || ''
  );

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isOpen, , close, options] = useModalByName(CHOOSE_PRIMARY_MARKETS_MODAL_NAME);
  const { showModal } = useModalManager();
  const [subscription, setSubscription] = useState<StaticSubscription>();
  const [isConfirmDialogShowing, showConfirmDialog, hideConfirmDialog] = useConfirmDialog();

  useEffect(() => {
    options?.context && setSubscription(options?.context as StaticSubscription);
  }, [isOpen]);

  const typeaheadActiveMarkets = useSelector(selectTypeaheadActiveMarkets);
  const territoryDropdownItems = useMemo(
    () =>
      typeaheadActiveMarkets.map((market) => ({
        label: market.name,
        value: market.territoryId || ''
      })),
    [typeaheadActiveMarkets]
  );

  const defaultPrimaryMarket = initialPrimaryMarketList[0]?.territoryId;
  const defaultPrimaryMarketExists = useMemo(
    () =>
      territoryDropdownItems.find((activeMarket) => activeMarket.value === defaultPrimaryMarket),
    [territoryDropdownItems, defaultPrimaryMarket]
  );
  const initialPrimaryMarket =
    (defaultPrimaryMarketExists ? defaultPrimaryMarket : territoryDropdownItems[0]?.value) || '';
  const [primaryMarket, setPrimaryMarket] = useState<string>(initialPrimaryMarket);

  useEffect(() => {
    setPrimaryMarket(initialPrimaryMarket);
  }, [initialPrimaryMarket]);

  const handleConfirmMarket = async () => {
    try {
      if (!primaryMarket) return;

      const marketSelectionParams = {
        primaryMarketId: primaryMarket,
        secondaryMarketIds: territoryDropdownItems
          .map((dropdownItem) => dropdownItem.value)
          .filter((value): value is string => typeof value === 'string')
      };

      await confirmMarketSelection(marketSelectionParams);

      if (!options?.onComplete) {
        await getUserInfo1();
      } else if (user.userIsConfirmingMarketsForFirstTime) {
        showModal(CHOOSE_INTERESTED_MARKETS_MODAL_NAME, {
          context: subscription,
          onComplete: options?.onComplete
        });
      } else {
        options.onComplete();
      }

      close();
    } catch (e: unknown) {
      captureApiException(e);

      if (isApiErrorWithMessage(e)) {
        setErrorMessage(getMessageFromError(e));
      }
    }
  };

  const handleCloseModal = () => {
    showConfirmDialog();
  };

  const handleConfirmCloseModal = () => {
    hideConfirmDialog();
    close();
  };

  const handleCancelCloseModal = () => {
    hideConfirmDialog();
  };

  return (
    <Modal
      isShowing={isOpen}
      modalClassNames={classNames(modalStyles.modal, modalStyles.center)}
      hide={handleCloseModal}
    >
      <MarketManagementModalContents
        close={handleCloseModal}
        headerText="Select your primary market"
      >
        <div className={modalStyles.text}>
          <p>
            Your primary market is the default location filter when you log in and the focus of your
            custom email communications.
          </p>
          <Select
            items={territoryDropdownItems}
            className={styles.select}
            onChange={(event) => setPrimaryMarket(event.currentTarget.value)}
            value={primaryMarket}
          />
        </div>

        {!!errorMessage && <AlertBox>{errorMessage}</AlertBox>}

        <Button onClick={handleConfirmMarket} size="large" fullWidth>
          Confirm
        </Button>
      </MarketManagementModalContents>

      {isConfirmDialogShowing && (
        <ConfirmDialog
          isShowing={isConfirmDialogShowing}
          title="Are you sure you want to exit?"
          onCancel={handleCancelCloseModal}
          onConfirm={handleConfirmCloseModal}
          cancelText={'No, Continue Setup'}
          confirmText={'Yes, Exit'}
        >
          To finish setting up your account, select your market.
        </ConfirmDialog>
      )}
    </Modal>
  );
};
