import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCheckmark({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M23 45.22h-.14c-1-.09-1.33-.87-1.65-1.56-.7-1.55-2.58-5.67-10-9.61a1.5 1.5 0 111.4-2.65c6.13 3.24 8.94 6.69 10.32 9 3.73-7.57 11.87-16.08 24.24-25.32A1.5 1.5 0 0149 17.49c-13 9.72-21.15 18.51-24.16 26a4.61 4.61 0 01-.47 1 1.65 1.65 0 01-1.37.73z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgCheckmark;
