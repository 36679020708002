import React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import { ExitIcon } from '../Icons';

import styles from './Toaster.module.scss';
interface CloseToastProps {
  closeToast: () => void;
}
const CloseButton = ({ closeToast }: CloseToastProps) => (
  <div className={styles.closeButton} onClick={closeToast}>
    <ExitIcon color="currentColor" />
  </div>
);

export const Toaster = () => {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeButton={CloseButton}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
