import React, { useCallback } from 'react';

import buttonStyles from '../../common/buttons.module.scss';
import { useModalByName } from '../../hooks/useModalByName';
import { SignUpFormData } from '../../types/onBoarding';
import { ConfirmationCodeScreen } from '../ConfirmationCodeScreen/ConfirmationCodeScreen';
import { ExitIcon } from '../Icons';
import { Modal } from '../Modal';

import styles from './ConfirmationCodeModal.module.scss';

export const CONFIRMATION_CODE_MODAL_NAME = 'ConfirmationCode';

interface ConfirmationCodeModalProps {
  codeLength?: number;
}

export const ConfirmationCodeModal = ({ codeLength = 6 }: ConfirmationCodeModalProps) => {
  const [isOpen, , close, options] = useModalByName(CONFIRMATION_CODE_MODAL_NAME);

  const onConfirm = useCallback(async () => {
    close();

    if (options?.onComplete) {
      await options?.onComplete();
    }
  }, [close, options]);

  return (
    <Modal isShowing={isOpen} hide={close} modalClassNames={styles.confirmationCodeModal}>
      <ExitIcon className={buttonStyles.closeIcon} onClick={close} title="Close" />
      <ConfirmationCodeScreen<SignUpFormData> codeLength={codeLength} onConfirm={onConfirm} />
    </Modal>
  );
};
