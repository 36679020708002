import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import styles from './FormHelperText.module.scss';

type FormHelperTextProps = HTMLAttributes<HTMLParagraphElement> & {
  error?: boolean;
  topMargin?: boolean;
};

export const FormHelperText: FC<FormHelperTextProps> = ({ error, topMargin, ...props }) => (
  <p
    className={classNames(styles.helperText, {
      [styles.helperTextError]: error,
      [styles.topMargin]: topMargin
    })}
    {...props}
  />
);
