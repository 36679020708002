import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerMyPropertyButton = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g filter="url(#seller-my-property-button_svg__a)">
      <rect x={12} y={6} width={50} height={50} rx={25} fill="#fff" shapeRendering="crispEdges" />
      <g filter="url(#seller-my-property-button_svg__b)">
        <path d="M35 39.5v-6h4v6h5v-8h3l-10-9-10 9h3v8h5Z" fill="#1C51A0" />
      </g>
    </g>
    <defs>
      <filter
        id="seller-my-property-button_svg__a"
        x={0}
        y={0}
        width={74}
        height={74}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={6} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.290196 0 0 0 0 0.290196 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_383_104049" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_383_104049" result="shape" />
      </filter>
      <filter
        id="seller-my-property-button_svg__b"
        x={17}
        y={15}
        width={40}
        height={40}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={4} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.290196 0 0 0 0 0.290196 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_383_104049" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_383_104049" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default SvgSellerMyPropertyButton;
