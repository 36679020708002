export const baseInputStyle = {
  base: {
    color: '#4a4a4a',
    fontWeight: '400',
    fontFamily: '"Lato", sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#4a4a4a' // @todo: match this with upcoming Input component placeholder
    }
  }
};
