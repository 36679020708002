import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgExclamationWarn({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M36 48a3 3 0 100 6 3 3 0 000-6zm32.01 4.41l-24.15-42a9 9 0 00-15.72 0l-24 42A9 9 0 0011.82 66h48.36a9 9 0 007.83-13.59zm-5.19 6a3 3 0 01-2.64 1.53H11.82a2.999 2.999 0 01-2.64-1.53 3 3 0 010-3l24-42a2.999 2.999 0 015.34 0l24.15 42a3 3 0 01.15 3.06v-.06zM36 24a3 3 0 00-3 3v12a3 3 0 006 0V27a3 3 0 00-3-3z"
        fill="#FFC94D"
      />
    </svg>
  );
}

export default SvgExclamationWarn;
