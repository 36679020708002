import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgViewAsList({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="view-as-list_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.view-as-list_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="view-as-list_svg__cls-1"
        d="M21.52 8.82h32.06a1.42 1.42 0 100-2.83H21.52a1.42 1.42 0 000 2.83zM53.58 12.67H21.52a1.42 1.42 0 000 2.83h32.06a1.42 1.42 0 100-2.83zM13.83 5H8.3A3.3 3.3 0 005 8.3v5.5a3.3 3.3 0 003.3 3.3h5.53a3.3 3.3 0 003.3-3.3V8.3a3.3 3.3 0 00-3.3-3.3zm.47 8.8a.48.48 0 01-.47.47H8.3a.48.48 0 01-.47-.47V8.3a.48.48 0 01.47-.47h5.53a.48.48 0 01.47.47zM53.58 24.94H21.52a1.42 1.42 0 000 2.83h32.06a1.42 1.42 0 100-2.83zM53.58 31.62H21.52a1.42 1.42 0 000 2.83h32.06a1.42 1.42 0 100-2.83zM13.83 24H8.3A3.3 3.3 0 005 27.25v5.5a3.3 3.3 0 003.3 3.3h5.53a3.3 3.3 0 003.3-3.3v-5.5a3.3 3.3 0 00-3.3-3.25zm.47 8.8a.48.48 0 01-.47.47H8.3a.48.48 0 01-.47-.47v-5.5a.48.48 0 01.47-.47h5.53a.48.48 0 01.47.47zM53.58 43.89H21.52a1.42 1.42 0 000 2.83h32.06a1.42 1.42 0 100-2.83zM53.58 50.57H21.52a1.42 1.42 0 000 2.83h32.06a1.42 1.42 0 100-2.83zM13.83 42.9H8.3A3.3 3.3 0 005 46.2v5.5A3.3 3.3 0 008.3 55h5.53a3.3 3.3 0 003.3-3.3v-5.5a3.3 3.3 0 00-3.3-3.3zm.47 8.8a.48.48 0 01-.47.47H8.3a.48.48 0 01-.47-.47v-5.5a.48.48 0 01.47-.47h5.53a.48.48 0 01.47.47z"
      />
    </svg>
  );
}

export default SvgViewAsList;
