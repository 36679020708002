import classNames from 'classnames';
import React from 'react';

import modalStyles from '../../common/modal.module.scss';
import { AlertBox } from '../../components/AlertBox';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { Modal } from '../../components/Modal';
import { ModalCloseButton } from '../../components/Modal/ModalCloseButton';
import { SubmitButton } from '../../components/SubmitButton';
import { TerritoryTypeahead } from '../../components/TerritoryTypeahead/TerritoryTypeahead';
import { useConfirmDialog } from '../../hooks/useConfirmDialog';
import { AreaOption } from '../../types/onBoarding';

import styles from './MarketManagementModals.module.scss';

interface TypeAheadMarketSelectionModalProps {
  isOpen: boolean;
  close: () => void;
  onSubmit?: () => void;
  onSkip?: () => void;
  headerText?: string;
  bodyText?: string | JSX.Element;
  marketSelectionText?: string;
  selectedMarkets: AreaOption[];
  handleSetSelectedMarkets: (newMarkets: AreaOption[]) => void;
  errorMessage?: JSX.Element | null;
  isSubmitDisabled: boolean;
  confirmButtonText?: string;
  activeOnly?: boolean;
  showCloseConfirm?: boolean;
}

export const TypeAheadMarketSelectionModal = ({
  isOpen,
  close,
  onSubmit,
  onSkip,
  headerText,
  bodyText,
  errorMessage,
  isSubmitDisabled,
  marketSelectionText,
  selectedMarkets,
  handleSetSelectedMarkets,
  confirmButtonText = 'Confirm',
  activeOnly = true,
  showCloseConfirm = false
}: TypeAheadMarketSelectionModalProps) => {
  const [isConfirmDialogShowing, showConfirmDialog, hideConfirmDialog] = useConfirmDialog();

  const handleCloseModal = () => {
    showCloseConfirm ? showConfirmDialog() : close();
  };

  const handleConfirmCloseModal = () => {
    hideConfirmDialog();
    close();
  };

  const handleCancelCloseModal = () => {
    hideConfirmDialog();
  };

  return (
    <Modal
      isShowing={isOpen}
      modalClassNames={classNames(modalStyles.modal, styles.modal)}
      hide={handleCloseModal}
    >
      <ModalCloseButton isAbsolute onClick={handleCloseModal} />
      {headerText && <h2 className={modalStyles.modalHeader}>{headerText}</h2>}
      {bodyText && <p>{bodyText}</p>}
      {errorMessage && (
        <div className={styles.alertBoxContainer}>
          <AlertBox className={styles.alertBox} noDefaultMargin>
            <div>{errorMessage}</div>
          </AlertBox>
        </div>
      )}
      {marketSelectionText && (
        <div className={styles.marketSelectionText}>{marketSelectionText}</div>
      )}
      <TerritoryTypeahead
        selectedMarkets={selectedMarkets}
        setSelectedMarkets={handleSetSelectedMarkets}
        activeOnly={activeOnly}
      />
      <SubmitButton onClick={onSubmit} disabled={isSubmitDisabled} className={styles.confirmButton}>
        {confirmButtonText}
      </SubmitButton>
      {!!onSkip && (
        <div className={styles.skipLink} onClick={onSkip}>
          Skip and do this later
        </div>
      )}

      {isConfirmDialogShowing && (
        <ConfirmDialog
          isShowing={isConfirmDialogShowing}
          title="Are you sure you want to exit?"
          onCancel={handleCancelCloseModal}
          onConfirm={handleConfirmCloseModal}
          cancelText={'No, Continue Setup'}
          confirmText={'Yes, Exit'}
        >
          To finish setting up your account, select your market(s).
        </ConfirmDialog>
      )}
    </Modal>
  );
};
