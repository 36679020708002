import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgHomeOwnerHouse({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#homeOwnerHouse_svg__a)">
        <path d="M41.936 22.336H7.064v20.513h34.872V22.336Z" fill="#fff" />
        <path d="M41.936 22.336H7.064v3.778h34.872v-3.778Z" fill="#E8EDF5" />
        <path
          d="M25.116 5.35a1.026 1.026 0 0 0-1.219 0L3.413 20.486a1.026 1.026 0 0 0 .615 1.846h40.948a1.026 1.026 0 0 0 .615-1.846L25.115 5.351Z"
          fill="#C6D3E7"
        />
        <path
          d="M4.024 22.336h2.437L23.897 9.454a1.026 1.026 0 0 1 1.219 0L42.55 22.335h2.437a1.026 1.026 0 0 0 .616-1.847L25.116 5.351a1.026 1.026 0 0 0-1.219 0L3.413 20.485a1.026 1.026 0 0 0 .615 1.846l-.004.005Z"
          fill="#E8EDF5"
        />
        <path
          d="M36.808 28.49h-8.206c-.566 0-1.025.46-1.025 1.026v6.154c0 .566.46 1.025 1.025 1.025h8.206c.566 0 1.025-.459 1.025-1.025v-6.154c0-.567-.459-1.026-1.025-1.026Z"
          fill="#C6D3E7"
        />
        <path
          d="M37.39 28.673a1.014 1.014 0 0 0-.582-.183h-8.206a1.026 1.026 0 0 0-1.025 1.026v6.154a1.026 1.026 0 0 0 1.025 1.025h.766l8.022-8.022Z"
          fill="#E8EDF5"
        />
        <path
          d="M.91 42.85h47.18M7.064 22.336v20.513M41.936 42.849V22.336M25.116 5.35a1.026 1.026 0 0 0-1.219 0L3.413 20.486a1.026 1.026 0 0 0 .615 1.846h40.948a1.026 1.026 0 0 0 .615-1.846L25.115 5.351Z"
          stroke="#4974B3"
          strokeWidth={2.051}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.936 17.806V9.004A1.025 1.025 0 0 0 40.91 7.98h-4.102a1.025 1.025 0 0 0-1.026 1.025v4.263l6.154 4.54ZM21.423 38.747v-9.231a1.026 1.026 0 0 0-1.025-1.026h-6.154a1.026 1.026 0 0 0-1.026 1.026v9.23h8.205Z"
          fill="#C6D3E7"
          stroke="#4974B3"
          strokeWidth={2.051}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.808 28.49h-8.206c-.566 0-1.025.46-1.025 1.026v6.154c0 .566.46 1.025 1.025 1.025h8.206c.566 0 1.025-.459 1.025-1.025v-6.154c0-.567-.459-1.026-1.025-1.026Z"
          stroke="#4974B3"
          strokeWidth={2.051}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.167 42.849v-3.077a1.025 1.025 0 0 1 1.025-1.026H22.45a1.026 1.026 0 0 1 1.025 1.026v3.077H11.167Z"
          fill="#C6D3E7"
          stroke="#4974B3"
          strokeWidth={2.051}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="homeOwnerHouse_svg__a">
          <path fill="#fff" transform="translate(.5)" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgHomeOwnerHouse;
