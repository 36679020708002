import { createSelector } from 'reselect';

import { ReferralInfo } from '../types/referral';

import { RootState } from './index';

export type AutoApprovalToken = string | undefined;

export interface MiscState {
  autoApprovalToken?: AutoApprovalToken;
  email?: string;
  phone?: string;
  confirmationCode: string;
  previousConfirmationCode: string;
  hasMembership?: boolean;
  referral?: undefined | ReferralInfo;
}

export const selectMiscState = (rootState: RootState) => rootState.misc;

export const selectHasMembership = createSelector(
  selectMiscState,
  (rootState) => rootState.hasMembership
);

export const selectConfirmationCode = (rootState: RootState) => rootState.misc.confirmationCode;
export const selectPreviousConfirmationCode = (rootState: RootState) =>
  rootState.misc.previousConfirmationCode;

export const selectReferralInfo = createSelector(
  selectMiscState,
  (rootState) => rootState.referral
);

const initialState: MiscState = {
  autoApprovalToken: undefined,
  email: undefined,
  phone: undefined,
  confirmationCode: '',
  previousConfirmationCode: '',
  hasMembership: undefined,
  referral: undefined
};

/* action creators */

const SET_AUTO_APPROVAL_TOKEN = 'SET_AUTO_APPROVAL_TOKEN' as const;
const SET_USER_EMAIL = 'SET_USER_EMAIL' as const;
const SET_USER_PHONE = 'SET_USER_PHONE' as const;
const SET_CONFIRMATION_CODE = 'SET_CONFIRMATION_CODE' as const;
const SET_PREVIOUS_CONFIRMATION_CODE = 'SET_PREVIOUS_CONFIRMATION_CODE' as const;
const SET_HAS_MEMBERSHIP = 'SET_HAS_MEMBERSHIP' as const;
const SET_REFERRAL_INFO = 'SET_REFERRAL_INFO' as const;

export const setAutoApprovalToken = (token: AutoApprovalToken) => {
  return {
    type: SET_AUTO_APPROVAL_TOKEN,
    payload: {
      token: token
    }
  };
};

export const setUserEmail = (email?: string) => {
  return {
    type: SET_USER_EMAIL,
    payload: {
      email
    }
  };
};

export const setUserPhone = (phone?: string) => {
  return {
    type: SET_USER_PHONE,
    payload: {
      phone
    }
  };
};

export const setConfirmationCodeAction = (confirmationCode: string) => {
  return {
    type: SET_CONFIRMATION_CODE,
    payload: {
      confirmationCode
    }
  };
};

export const setPreviousConfirmationCodeAction = (previousConfirmationCode: string) => {
  return {
    type: SET_PREVIOUS_CONFIRMATION_CODE,
    payload: {
      previousConfirmationCode
    }
  };
};

export const setHasMembership = (hasMembership: boolean | undefined) => {
  return {
    type: SET_HAS_MEMBERSHIP,
    payload: {
      hasMembership
    }
  };
};

export const setReferralInfo = (referral: ReferralInfo | undefined) => {
  return {
    type: SET_REFERRAL_INFO,
    payload: {
      referral
    }
  };
};

type MiscAction =
  | ReturnType<typeof setAutoApprovalToken>
  | ReturnType<typeof setUserEmail>
  | ReturnType<typeof setUserPhone>
  | ReturnType<typeof setConfirmationCodeAction>
  | ReturnType<typeof setPreviousConfirmationCodeAction>
  | ReturnType<typeof setHasMembership>
  | ReturnType<typeof setReferralInfo>;

/* reducer */

const miscReducer = (state = initialState, action: MiscAction): MiscState => {
  switch (action.type) {
    case SET_AUTO_APPROVAL_TOKEN:
      return {
        ...state,
        autoApprovalToken: action.payload.token
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        email: action.payload.email
      };
    case SET_USER_PHONE:
      return {
        ...state,
        phone: action.payload.phone
      };
    case SET_CONFIRMATION_CODE:
      return {
        ...state,
        confirmationCode: action.payload.confirmationCode
      };
    case SET_PREVIOUS_CONFIRMATION_CODE:
      return {
        ...state,
        previousConfirmationCode: action.payload.previousConfirmationCode
      };
    case SET_HAS_MEMBERSHIP:
      return {
        ...state,
        hasMembership: action.payload.hasMembership
      };
    case SET_REFERRAL_INFO:
      return {
        ...state,
        referral: { ...action.payload.referral }
      };
    default:
      return state;
  }
};

export default miscReducer;
