import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgFloorPlan({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M49.81 5H10.19A5.2 5.2 0 005 10.19v39.62A5.2 5.2 0 0010.19 55h39.62A5.2 5.2 0 0055 49.81V10.19A5.2 5.2 0 0049.81 5zm-42 14.53h5.59c-.88 1.85-2.62 4.49-5.59 5.12zm42 32.64H26.87V35.36H35a1.5 1.5 0 000-3h-9.63a1.5 1.5 0 00-1.5 1.5V41h-3.52a1.5 1.5 0 000 3h3.52v8.17H10.19a2.36 2.36 0 01-2.36-2.36V44h3.44a1.5 1.5 0 000-3H7.83V27.68c7-1 9.12-8.92 9.21-9.28a1.49 1.49 0 00-1.45-1.87H7.83v-6.34a2.36 2.36 0 012.36-2.36h13.68v16a1.5 1.5 0 001.5 1.5h17.9a1.5 1.5 0 000-3h-16.4V7.83h22.94a2.36 2.36 0 012.36 2.36v22.17h-8.64a1.5 1.5 0 000 3h8.64v14.45a2.36 2.36 0 01-2.36 2.36z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgFloorPlan;
