import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgExpand({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="expand_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 8"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.expand_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path className="expand_svg__cls-1" d="M9.5 1.5l-4 5-4-5h8" />
      <path
        className="expand_svg__cls-1"
        d="M5.5 7.5a1 1 0 01-.78-.38l-4-5a1 1 0 01-.12-1A1 1 0 011.5.5h8a1 1 0 01.9.57 1 1 0 01-.12 1.05l-4 5a1 1 0 01-.78.38zm-1.92-5L5.5 4.9l1.92-2.4z"
      />
    </svg>
  );
}

export default SvgExpand;
