import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgStep3({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M30 4.65a25 25 0 1025 25 25 25 0 00-25-25zm5.66 30.54a5.18 5.18 0 01-1.28 1.68 6 6 0 01-1.9 1.06 7 7 0 01-2.32.37 7.24 7.24 0 01-2.43-.36A4.9 4.9 0 0126 37a5.4 5.4 0 01-1.15-1.48 11.13 11.13 0 01-.76-1.86l.95-.4a1.44 1.44 0 01.53-.11.93.93 0 01.44.11.63.63 0 01.29.33 5129720684561.81 5129720684561.81 0 01.1.22c.12.25.26.52.43.82a3.34 3.34 0 00.67.86 3.62 3.62 0 001.06.67 3.8 3.8 0 001.57.28 4 4 0 001.64-.31A3.7 3.7 0 0033 35.3a3.44 3.44 0 00.7-1.09 3.16 3.16 0 00.2-1.21 4.25 4.25 0 00-.19-1.32 2.35 2.35 0 00-.7-1 3.74 3.74 0 00-1.4-.69 9.05 9.05 0 00-2.3-.25V28.1a6.93 6.93 0 002-.24 3.88 3.88 0 001.32-.65 2.31 2.31 0 00.74-1 3.42 3.42 0 00.18-1.21 3.46 3.46 0 00-.24-1.35 2.6 2.6 0 00-1.68-1.51 3.9 3.9 0 00-1.25-.19 3.85 3.85 0 00-1.25.2 3.33 3.33 0 00-1 .55 3.21 3.21 0 00-.75.85 4 4 0 00-.45 1.07.94.94 0 01-.3.53.82.82 0 01-.51.16h-.28L24.65 25a6.5 6.5 0 01.68-2.17 5.06 5.06 0 011.26-1.58 5.35 5.35 0 011.77-1A6.71 6.71 0 0130.5 20a6.42 6.42 0 012.1.32 5.07 5.07 0 011.66.93 4.21 4.21 0 011.08 1.45 4.42 4.42 0 01.38 1.88 5 5 0 01-.21 1.52 3.81 3.81 0 01-.63 1.18 3.64 3.64 0 01-1 .85 5.42 5.42 0 01-1.29.57 4.8 4.8 0 012.63 1.55 4.15 4.15 0 01.9 2.75 5.17 5.17 0 01-.46 2.19z"
        fill="#c9c9c9"
      />
    </svg>
  );
}

export default SvgStep3;
