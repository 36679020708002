import classNames from 'classnames';
import React, { forwardRef, SelectHTMLAttributes } from 'react';

import { FormHelperText } from '../../../Form/FormHelperText/FormHelperText';
import { InputLabel } from '../../../Form/InputLabel/InputLabel';
import { ArrowLeftIcon } from '../../../Icons';

import styles from './Select.module.scss';

export type SelectProps = Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> & {
  className?: string;
  placeholder?: string;
  items: SelectListItemProps[];
  error?: boolean;
  disablePlaceholder?: boolean;
  size?: 'small' | 'medium';
  label?: React.ReactNode;
  helperText?: React.ReactNode;
};

export interface SelectListItemProps {
  value: string;
  label?: string;
  data?: unknown;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      placeholder,
      items,
      error = false,
      disablePlaceholder = false,
      size = 'medium',
      label,
      helperText,
      ...selectProps
    },
    ref
  ) => (
    <div className={className}>
      {label && (
        <InputLabel htmlFor={selectProps.id} error={error}>
          {label}
        </InputLabel>
      )}
      <div
        className={classNames(styles.select, {
          [styles.error]: error,
          [styles.selectSmall]: size === 'small'
        })}
      >
        <select ref={ref} {...selectProps}>
          {placeholder !== undefined && (
            <option value="" disabled={disablePlaceholder}>
              {placeholder}
            </option>
          )}
          {items.map(({ label, value }) => (
            <option key={value} value={value}>
              {label || value}
            </option>
          ))}
        </select>
        <ArrowLeftIcon className={styles.arrowIcon} />
      </div>
      {helperText ? (
        <FormHelperText error={error} topMargin>
          {helperText}
        </FormHelperText>
      ) : null}
    </div>
  )
);
