/* eslint-disable react/react-in-jsx-scope */
import { SubscriptionContext } from '../../contexts/SubscriptionProvider';

import { MembershipModal as Container } from './MembershipModal';

export const MEMBERSHIP_MODAL_NAME = 'MembershipModal';

export const MembershipModal = function () {
  return (
    <SubscriptionContext.Consumer>
      {(ctx) => <Container subscriptions={ctx.subscriptions} />}
    </SubscriptionContext.Consumer>
  );
};
