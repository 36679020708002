import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgActivityClose({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M22.52 29.11h20V5h-.25a25 25 0 000 50h.25V30.89h-20l5.36 5.36a.86.86 0 01-.6 1.46.83.83 0 01-.59-.25l-6.83-6.83a.86.86 0 010-1.21l6.83-6.88a.84.84 0 011.19 0 .86.86 0 010 1.21z"
        fill="#dc3d55"
      />
    </svg>
  );
}

export default SvgActivityClose;
