import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../store';
import {
  selectMiscState,
  setConfirmationCodeAction,
  setPreviousConfirmationCodeAction
} from '../../../store/misc';

export const useConfirmationCode = () => {
  const dispatch = useDispatch<AppDispatch>();
  const confirmationCode = useSelector(selectMiscState).confirmationCode;
  const previousConfirmationCode = useSelector(selectMiscState).previousConfirmationCode;

  const setConfirmationCode = (confirmationCode: string) => {
    dispatch(setConfirmationCodeAction(confirmationCode));
  };

  const clearConfirmationCode = () => {
    if (confirmationCode !== '') dispatch(setPreviousConfirmationCodeAction(confirmationCode));
    dispatch(setConfirmationCodeAction(''));
  };

  return {
    confirmationCode,
    previousConfirmationCode,
    setConfirmationCode,
    clearConfirmationCode
  };
};
