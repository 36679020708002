import * as Sentry from '@sentry/nextjs';

import { isAxiosError } from '../api/helpers';
import { isYupValidationError } from '../validation';

import { captureException } from './captureException';

const TIMEOUT_REGEX = /timeout of (\d+)ms exceeded/;
const NETWORK_ERROR_MESSAGE = 'Network Error';

// extraResponseDetails adds more details about the failure response
export const captureApiException = (error: unknown, extraResponseDetails?: unknown) => {
  // failure related to Sentry should not cause the caller to throw
  if (!isAxiosError(error) && !isYupValidationError(error)) {
    return captureException(error);
  }

  if (TIMEOUT_REGEX.test(error.message)) {
    return captureException(error); // don't log timeouts as API exceptions
  }

  if (error.message === NETWORK_ERROR_MESSAGE) {
    return captureException(error); // don't log network errors as API exceptions
  }

  try {
    // eslint-disable-next-line no-console
    console.log('Sentry.captureApiException capturing', error);

    Sentry.withScope((scope) => {
      scope.setExtra('original message', error.message);

      error.message = `API returned unexpected response type: ${error.message}`;

      if (isYupValidationError(error)) {
        // eslint-disable-next-line no-console
        console.log('validation errors', error.errors);
        scope.setExtra('validation errors', error.errors);

        if (extraResponseDetails) {
          scope.setExtra('extra response', extraResponseDetails);
        }
      } else if (isAxiosError(error)) {
        const response = error.response;

        scope.setExtra('response', {
          data: response?.data,
          status: response?.status,
          statusText: response?.statusText
        });
      }

      Sentry.captureException(error);
    });
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.log('Sentry.captureApiException failed', e);
  }
};
