import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { api } from '../_base';
import {
  ChangePasswordRequestBody,
  SaveVestingEntityRequestBody,
  UpdateProfileRequestBody,
  VerifyNewEmailCodeRequestBody,
  VerifyUpdatedPhoneRequestBody
} from '../_base/generated/data-contracts';

// GET /api/vesting-entities
export const getVestingEntityList = () => {
  return api.vestingEntityControllerListVestingEntities();
};

export const useVestingEntityList = () => {
  return useQuery({ queryKey: ['getVestingEntityList'], queryFn: getVestingEntityList });
};

// POST /api/save-vesting-entity
export const addVestingEntity = (body: SaveVestingEntityRequestBody) => {
  return api.vestingEntityControllerSaveVestingEntity(body);
};

export const useAddVestingEntity = () => {
  return useMutation({ mutationFn: addVestingEntity });
};

// PATCH /api/update-profile
export const editProfile = (body: UpdateProfileRequestBody) => {
  return api.userControllerUpdateProfile(body);
};

export const useEditProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUserInfo'] });
    }
  });
};

// PATCH /oauth/change-password
export const updatePassword = (body: ChangePasswordRequestBody) => {
  return api.authControllerChangePassword(body);
};

export const useUpdatePassword = () => {
  return useMutation({ mutationFn: updatePassword });
};

// PUT /api/user/update-email
export const updateEmail = async (newEmail: string) => {
  return api.userControllerUpdateUserEmail({ newEmail });
};

export const useUpdateEmail = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUserInfo'] });
    }
  });
};

// POST /api/user/verify-new-email-code
export const verifyNewEmailCode = async (body: VerifyNewEmailCodeRequestBody) => {
  return api.userControllerVerifyNewEmailCode(body);
};

export const useVerifyNewEmailCode = () => {
  return useMutation({ mutationFn: verifyNewEmailCode });
};

// PUT /api/user/phone
export const updatePhone = async (phone: string) => {
  return api.userControllerUpdateUserPhone({ phone });
};

export const useUpdatePhone = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePhone,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUserInfo'] });
    }
  });
};

// POST /api/user/phone/verify
export const verifyNewPhoneCode = (body: VerifyUpdatedPhoneRequestBody) => {
  return api.userControllerVerifyUpdatedPhone(body);
};

export const useVerifyNewPhoneCode = () => {
  return useMutation({ mutationFn: verifyNewPhoneCode });
};
