import classNames from 'classnames';
import React from 'react';

type AlertBoxProps = React.PropsWithChildren<{
  className?: string;
  noDefaultMargin?: boolean;
}>;

export const AlertBox: React.FC<AlertBoxProps> = ({ children, className, noDefaultMargin }) => (
  <div
    className={classNames('alert alert-danger fade show', { 'mt-2': !noDefaultMargin }, className)}
    role="alert"
  >
    {children}
  </div>
);
