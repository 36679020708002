import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerFaq = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-faq_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 24c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12Zm0-22.5C6.7 1.5 2 6.2 2 12s4.7 10.5 10.5 10.5S23 17.8 23 12 18.3 1.5 12.5 1.5Zm0 13.5c-.4 0-.75-.35-.75-.75v-1c0-.45.15-.9.4-1.3s.65-.65 1.1-.8c.4-.15.75-.4 1-.7.25-.3.45-.7.5-1.1.05-.4 0-.85-.15-1.2-.15-.4-.45-.7-.75-.95-.35-.25-.75-.4-1.15-.4-.4-.05-.8.05-1.2.25-.35.2-.65.5-.9.85s-.35.75-.35 1.15c0 .4-.35.75-.75.75s-.75-.35-.75-.75c0-.7.2-1.35.55-1.95.35-.6.85-1.05 1.5-1.4.6-.3 1.3-.45 2-.4.7.05 1.35.3 1.9.7.55.4 1 .95 1.3 1.55.25.65.35 1.3.25 2-.1.7-.35 1.3-.8 1.85-.45.55-1 .95-1.65 1.15-.15.05-.25.15-.35.25-.1.15-.15.3-.15.45v1c-.05.4-.4.75-.8.75Zm0 3.75a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="seller-faq_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerFaq;
