import React from 'react';

import edge from '../../public/iconsV2/edge.svg';
import edgePlus from '../../public/iconsV2/edge_plus.svg';
import { Subscription } from '../contexts/SubscriptionProvider';

export const getIntervalString = (interval?: string) => {
  if (interval === 'month') {
    return 'mo';
  }

  if (interval === 'year') {
    return 'yr';
  }
};

export type StaticSubscription = {
  id?: number; // used for uniqueness
  tier: number;
  name: string;
  markets: number;
  emphasize?: boolean; // Adds Red color to header
  infoStrings: Array<string>;
  downgradeString?: string;
  icon?: string;
  upgradeText?: string;
};

export const calculateSavings = (
  monthlyCost: number | undefined,
  annualCost: number | undefined
) => {
  return Math.floor((monthlyCost || 0) * 12 - (annualCost || 0));
};

export const BASE_TIER_MEMBERSHIP_NAME = 'Essential';
export const SUBSCRIPTION_1_TIER_MEMBERSHIP_NAME = 'Edge';
export const SUBSCRIPTION_2_TIER_MEMBERSHIP_NAME = 'Edge+';
export const VALID_SUGGESTED_SUBSCRIPTION_TIERS = ['essential', 'edge', 'edgeplus'];

export const STATIC_SUBSCRIPION_METADATA: Array<StaticSubscription> = [
  {
    id: 0,
    tier: 0,
    name: BASE_TIER_MEMBERSHIP_NAME,
    markets: 6,
    infoStrings: [
      'On the Essential plan, you’ll be able to place offers in 1 market and browse properties in all available markets.',
      'If you downgrade, your membership plan features will be active until the end of your billing date.'
    ],
    downgradeString:
      'Your payment method will no longer be charged. You will not be able to access Edge premium features after end of your current billing cycle and can only place offers in 1 market.'
  },
  {
    id: 1,
    tier: 1,
    name: SUBSCRIPTION_1_TIER_MEMBERSHIP_NAME,
    emphasize: true,
    markets: 6,
    infoStrings: [
      'On the Edge plan, you’ll be able to place offers in up to 3 markets and browse properties in all available markets.',
      'If you downgrade, your membership plan features will be active until the end of your billing date.'
    ],
    icon: edge.src,
    upgradeText:
      'Your membership has been upgraded. Enjoy all our premium and exclusive benefits with the ability to place offers and browse properties in all 3 markets.',
    downgradeString:
      'As an Edge member, you will only be able to place offers in 3 markets but you can see browse properties in all available markets.'
  },
  {
    id: 2,
    tier: 2,
    name: SUBSCRIPTION_2_TIER_MEMBERSHIP_NAME,
    emphasize: true,
    markets: -1, // Infinite
    infoStrings: [
      'On the Edge+ plan, you’ll be able to place offers in all available markets and browse properties in all available markets.'
    ],
    icon: edgePlus.src,
    upgradeText:
      'You now have access to place offers in all markets nationwide and the added benefits of your Edge+ membership.'
  }
];

export const getTierImageOrText = (tier: string | undefined) => {
  switch (tier) {
    case STATIC_SUBSCRIPION_METADATA[1].name: {
      return (
        <img
          src={STATIC_SUBSCRIPION_METADATA[1].icon}
          alt={`Sundae ${STATIC_SUBSCRIPION_METADATA[1].name}`}
          width={55}
        />
      );
    }

    case STATIC_SUBSCRIPION_METADATA[2].name: {
      return (
        <img
          src={STATIC_SUBSCRIPION_METADATA[2].icon}
          alt={`Sundae ${STATIC_SUBSCRIPION_METADATA[2].name}`}
          width={68}
        />
      );
    }

    default: {
      return <strong>{tier}</strong>;
    }
  }
};

export const mapStaticSubscriptionToStripeSubscription = (
  subscriptions: Array<Subscription>,
  staticSubscription: StaticSubscription,
  monthly = true
): Subscription | undefined => {
  if (staticSubscription.name === STATIC_SUBSCRIPION_METADATA[0].name) {
    // If essential
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-regex-literals
    return subscriptions.find((sub) => new RegExp(`Essential`, 'ig').test(sub?.name || ''));
  } else if (staticSubscription.name === STATIC_SUBSCRIPION_METADATA[2].name) {
    // If Edge+
    return subscriptions.find((sub) =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-regex-literals, no-useless-escape
      new RegExp(`Edge\\+(.){0,}${monthly ? 'Monthly' : 'Yearly'}`, 'ig').test(sub?.name || '')
    );
  } else {
    // If Edge
    return subscriptions.find((sub) =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-regex-literals
      new RegExp(`Edge(?!\\+)(.){0,}${monthly ? 'Monthly' : 'Yearly'}`, 'ig').test(sub?.name || '')
    );
  }
};

export const mapStripeSubscriptionToStaticSubscription = (subscription: Subscription) => {
  if (subscription?.name?.toLowerCase().includes('edge+')) {
    return STATIC_SUBSCRIPION_METADATA[2];
  } else if (subscription?.name?.toLowerCase().includes('edge')) {
    return STATIC_SUBSCRIPION_METADATA[1];
  } else {
    return STATIC_SUBSCRIPION_METADATA[0];
  }
};

export const AUTO_OFFER_CONTEXT = 'AUTO_OFFER_CONTEXT';
export const TRANSACTION_HISTORY_CONTEXT = 'TRANSACTION_HISTORY_CONTEXT';
export const ADDITIONAL_MARKETS_CONTEXT = 'ADDITIONAL_MARKETS_CONTEXT';
export const UPGRADE_TO_EDGE_CONTEXT = 'UPGRADE_TO_EDGE_CONTEXT';

export type FeatureContext =
  | typeof AUTO_OFFER_CONTEXT
  | typeof TRANSACTION_HISTORY_CONTEXT
  | typeof ADDITIONAL_MARKETS_CONTEXT
  | typeof UPGRADE_TO_EDGE_CONTEXT;

export const FEATURE_SPECIFIC_INFO_STRINGS = {
  [AUTO_OFFER_CONTEXT]:
    'You selected a feature only available to Edge members. If you would like access to AutoOffer, upgrade to Edge or Edge+.',
  [TRANSACTION_HISTORY_CONTEXT]:
    'You selected a feature only available to Edge members. If you would like access to our historical sales, upgrade to Edge or Edge+.',
  [ADDITIONAL_MARKETS_CONTEXT]: `You are limited to ${STATIC_SUBSCRIPION_METADATA[1].markets} markets as an ${STATIC_SUBSCRIPION_METADATA[1].name} member. Please upgrade to access more markets.`,
  [UPGRADE_TO_EDGE_CONTEXT]:
    'If you would like to place offers in more than 1 market, upgrade to Edge or Edge+.'
};

const isDefinedFeature = (featureContext: string): featureContext is FeatureContext => {
  return !!FEATURE_SPECIFIC_INFO_STRINGS[featureContext as FeatureContext];
};

export const getFeatureSpecificInfoText = (key: string) => {
  if (isDefinedFeature(key)) return FEATURE_SPECIFIC_INFO_STRINGS[key];
};

export const getStaticSubscriptionMetaDataByNameWithInterval = (nameWithInterval: string) => {
  const name = nameWithInterval.split('-')[0];
  const foundSubscription = STATIC_SUBSCRIPION_METADATA.find(
    (subscription) => subscription.name === name.trim()
  );

  return foundSubscription || STATIC_SUBSCRIPION_METADATA[0];
};

export const getStaticSubscriptionMetaDataByTier = (tier: number) => {
  if (!tier) return STATIC_SUBSCRIPION_METADATA[0];

  return STATIC_SUBSCRIPION_METADATA[tier];
};

export const canUserUpgrade = (tier: number) => {
  if (!tier) return true;

  return tier < STATIC_SUBSCRIPION_METADATA.length - 1;
};

export const isBaseTier = (tier?: number) => {
  if (!tier) return true;
};
