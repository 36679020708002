import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { VerifySmsCodeRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/homeowner/verify-sms-code
export const homeownerVerifySmsCode = (body: VerifySmsCodeRequestBody) => {
  return api.authControllerHomeownerVerifySmsCode(body);
};

export const useHomeownerVerifySmsCode = () => {
  return useMutation({ mutationFn: homeownerVerifySmsCode });
};
