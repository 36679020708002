import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgYourHomeMapMarker = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m11.177 27.382.323.273.323-.273c3.517-2.974 6.178-5.763 7.963-8.37 1.784-2.604 2.714-5.058 2.714-7.352C22.5 4.89 17.268.5 11.5.5 5.731.5.5 4.89.5 11.66c0 2.294.93 4.748 2.714 7.353C5 21.619 7.66 24.408 11.177 27.382Z"
      fill="#1C51A0"
      stroke="#1C51A0"
    />
    <path d="M10.2 17v-3.882h2.6V17h3.25v-5.177H18L11.5 6 5 11.823h1.95V17h3.25Z" fill="#fff" />
  </svg>
);
export default SvgYourHomeMapMarker;
