import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerPropertyInfo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-property-info_svg__a)">
      <path
        d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#32BEA6"
      />
      <path d="M15.709 27.345h32.582v20.946H15.709V27.345Z" fill="#fff" />
      <path
        d="M45.964 16.873a1.164 1.164 0 0 0-1.163-1.164h-2.328a1.164 1.164 0 0 0-1.163 1.164v6.981a1.164 1.164 0 0 0 1.163 1.164h2.328a1.164 1.164 0 0 0 1.163-1.164v-6.981Z"
        fill="#E2E4E5"
      />
      <path
        d="M32.715 13.627a1.164 1.164 0 0 0-1.43 0L13.832 25.263a1.164 1.164 0 0 0 .715 2.083h34.909a1.165 1.165 0 0 0 .714-2.083L32.715 13.627Z"
        fill="#DF2C2C"
      />
      <path d="M33.745 32h12.218v8.8H33.745V32Z" fill="#40C9E7" />
      <path d="M28.51 36.654a4.655 4.655 0 0 0-9.31 0v9.31h9.31v-9.31Z" fill="#84462D" />
      <path d="M26.182 41.31a1.164 1.164 0 1 0 0-2.328 1.164 1.164 0 0 0 0 2.327Z" fill="#FACB1B" />
      <path d="M13.382 48.291h37.236v2.327H13.382v-2.327Z" fill="#0D9681" />
      <path
        d="m13.382 48.291 1.816-1.816a1.746 1.746 0 0 1 1.234-.511h31.136c.463 0 .907.184 1.234.511l1.816 1.816H13.382Z"
        fill="#17B198"
      />
    </g>
    <defs>
      <clipPath id="seller-property-info_svg__a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerPropertyInfo;
