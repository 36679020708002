import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgTitle({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="title_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.title_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="title_svg__cls-1"
        d="M14.08 20.22h24a1.51 1.51 0 000-3h-24a1.51 1.51 0 000 3zM14.08 26h24a1.51 1.51 0 000-3h-24a1.51 1.51 0 000 3zM39.56 30.22a1.5 1.5 0 00-1.5-1.5h-24a1.5 1.5 0 100 3h24a1.5 1.5 0 001.5-1.5zM39.71 41.37a2.24 2.24 0 01-.61-.75 2.34 2.34 0 010-1 1.76 1.76 0 00-.07-1.33 1.8 1.8 0 00-1.24-.48 2.37 2.37 0 01-1-.26 2.3 2.3 0 01-.54-.83c-.21-.45-.43-.91-.84-1.05h-.24a2.39 2.39 0 00-1 .37 2.29 2.29 0 01-1 .36 2.27 2.27 0 01-1-.36 2.39 2.39 0 00-1-.37h-.24c-.41.14-.63.6-.84 1.05a2.3 2.3 0 01-.54.83 2.37 2.37 0 01-1 .26 1.82 1.82 0 00-1.24.48 1.74 1.74 0 00-.07 1.32 2.39 2.39 0 010 1 2.24 2.24 0 01-.61.75c-.36.35-.73.7-.73 1.15s.37.8.73 1.14a2 2 0 01.61.76 2.34 2.34 0 010 1 1.74 1.74 0 00.07 1.32 1.71 1.71 0 001.24.48 2.22 2.22 0 011 .27 2.34 2.34 0 01.54.82c.21.45.43.92.84 1a1 1 0 00.24 0 2.29 2.29 0 001-.38 2.27 2.27 0 011-.36 2.29 2.29 0 011 .36 2.29 2.29 0 001 .38 1 1 0 00.24 0c.41-.13.63-.6.84-1a2.34 2.34 0 01.54-.82 2.26 2.26 0 011-.27 1.71 1.71 0 001.24-.48 1.74 1.74 0 00.07-1.32 2.34 2.34 0 010-1 2.15 2.15 0 01.61-.76c.36-.34.73-.7.73-1.14s-.37-.79-.73-1.14z"
      />
      <path
        className="title_svg__cls-1"
        d="M51.18 5H21a5.06 5.06 0 00-5.11 4h2.9A2.35 2.35 0 0121 7.59h30.18a2.27 2.27 0 012.36 2.17v36.39a2.27 2.27 0 01-2.36 2.17h-5.42V13.79a4.92 4.92 0 00-5-4.77H11.41a4.91 4.91 0 00-5 4.77v36.43a4.91 4.91 0 005 4.77h29.31a5 5 0 005-4.07h5.5a5 5 0 005.2-4.77V9.76A5 5 0 0051.18 5zM43 50.22a2.23 2.23 0 01-2.29 2.17h-29.3a2.23 2.23 0 01-2.29-2.17V13.79a2.24 2.24 0 012.29-2.17h29.31A2.24 2.24 0 0143 13.79z"
      />
    </svg>
  );
}

export default SvgTitle;
