import classNames from 'classnames';
import React from 'react';

import modalStyles from '../../common/modal.module.scss';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';
import { getExternalInvestorLink } from '../../utils/url.utils';

import { MarketManagementModalContents } from './MarketManagementModalContents';
import styles from './MarketManagementModals.module.scss';

export const EDIT_MARKETS_INFO_MODAL_NAME = 'EditMarketsInfoModal';

export const EditMarketsInfoModal = () => {
  const [isOpen, , close] = useModalByName(EDIT_MARKETS_INFO_MODAL_NAME);

  const handleMembershipPlanRoute = () => {
    window.open(getExternalInvestorLink('pricing'), '_blank');
  };

  const handleEditMarkets = () => {
    close();
  };

  return (
    <Modal hide={close} isShowing={isOpen} modalClassNames={modalStyles.modal}>
      <MarketManagementModalContents
        close={close}
        headerText="You can only edit your markets once per year based on your plan"
      >
        <p>
          If you would like to change this multiple times you can upgrade your plan.{' '}
          <a className={styles.upgradeText} onClick={handleMembershipPlanRoute}>
            Learn more
          </a>
        </p>

        <div className={classNames(modalStyles.buttonField, modalStyles.stack)}>
          <Button size="large" onClick={handleEditMarkets}>
            Edit Markets
          </Button>

          <Button variant="secondary" size="large" onClick={close}>
            Cancel
          </Button>
        </div>
      </MarketManagementModalContents>
    </Modal>
  );
};
