import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCheckEmail({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="check-email_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130 130"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.check-email_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="check-email_svg__cls-1"
        d="M60.16 109.53A4.13 4.13 0 1056 105.4a4.14 4.14 0 004.16 4.13zm0-6.32A2.19 2.19 0 1158 105.4a2.19 2.19 0 012.16-2.19zM65.77 21.07H54.55a1.63 1.63 0 000 3.25h11.22a1.63 1.63 0 000-3.25z"
      />
      <path
        className="check-email_svg__cls-1"
        d="M100.64 46H89.35V21.93c0-4.28-3.94-6.93-7.59-6.93h-43.2C34.91 15 31 17.65 31 21.93v86.14c0 4.28 3.94 6.93 7.59 6.93h43.2c3.65 0 7.59-2.65 7.59-6.93V84h11.29a5.34 5.34 0 005.33-5.35v-27.3a5.34 5.34 0 00-5.36-5.35zm2.3 4.61L75.62 67.75 48.3 50.62a2.42 2.42 0 012.3-1.7h50a2.42 2.42 0 012.34 1.7zM63.66 63.69l-15.48 12V54zM33.88 21.93c0-2.74 2.79-4 4.68-4h43.2c1.89 0 4.68 1.28 4.68 4V28H33.88zm52.56 86.14c0 2.74-2.79 4-4.68 4h-43.2c-1.89 0-4.68-1.28-4.68-4v-9.7h52.56zm0-12.61H33.88V30.9h52.56V46H50.6a5.34 5.34 0 00-5.33 5.34v27.42A5.34 5.34 0 0050.6 84h35.84zm16.63-16.81a2.43 2.43 0 01-2.43 2.43h-50a2.43 2.43 0 01-2.33-1.79l18-14 8.56 5.37a1.52 1.52 0 00.77.22 1.5 1.5 0 00.77-.22l9.51-6 17.17 13.75zm0-3.89L88.51 63.1l14.56-9.1z"
      />
    </svg>
  );
}

export default SvgCheckEmail;
