import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCheckmarkBlueBug({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M30 5a25 25 0 1025 25A25 25 0 0030 5zm12.13 17.47c-7.73 5.77-12.55 11-14.33 15.44a3.6 3.6 0 01-.3.62l-.33.47h-.65c-.64-.06-.88-.58-1.06-1-.42-.92-1.53-3.35-5.93-5.67a1 1 0 01.94-1.77 15 15 0 016.08 5.24c2.27-4.48 7.1-9.5 14.38-14.94a1 1 0 011.4.2 1 1 0 01-.2 1.41z"
        fill="#1953a0"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCheckmarkBlueBug;
