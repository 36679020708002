import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGreenQuestion = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#green-question_svg__a)">
      <path
        d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 12.667a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1.067-4.054a.667.667 0 0 0-.4.614.667.667 0 0 1-1.334 0 2 2 0 0 1 1.2-1.834 1.333 1.333 0 1 0-1.866-1.226.667.667 0 0 1-1.334 0 2.667 2.667 0 1 1 3.734 2.446Z"
        fill="#21862F"
      />
    </g>
    <defs>
      <clipPath id="green-question_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGreenQuestion;
