import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgResizeMap({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M30 5a25 25 0 1025 25A25 25 0 0030 5zm-4.7 32.46a1.49 1.49 0 01-.19 2.11 1.49 1.49 0 01-2.11-.19L16 31a1.51 1.51 0 010-2l7-8.42a1.5 1.5 0 112.3 1.92L19.08 30zM44 31l-7 8.42a1.49 1.49 0 01-2.11.19 1.49 1.49 0 01-.19-2.11l6.22-7.5-6.22-7.46a1.5 1.5 0 112.3-1.92L44 29a1.51 1.51 0 010 2z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgResizeMap;
