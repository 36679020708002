import { getIdToken } from '../../utils/localStorage.utils';
import { api } from '../_base';
import { RealTimeEventsLogEventRequestBody } from '../_base/generated/data-contracts';

export const logEvent = ({
  userId,
  listingId,
  propertyId,
  applicationName,
  eventName,
  eventTimeStamp
}: Omit<RealTimeEventsLogEventRequestBody, 'idToken'>) => {
  const idToken = getIdToken() ?? '';
  if (!eventName) return;

  return api.realTimeEventsControllerLogEvent({
    userId,
    listingId,
    propertyId,
    applicationName,
    eventName,
    eventTimeStamp,
    idToken
  });
};
