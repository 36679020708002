import { PostHog } from 'posthog-js';

import {
  ListPropertiesResponseBodyItem,
  ListingListWithOfferEntryApiModel,
  PropertyDetailsResponseBody
} from '../../api/_base/generated/data-contracts';

import { recordMarketplaceRealTimeEvent } from './recordRealTimeEvent';

export const recordPropertySaved = async (
  property:
    | PropertyDetailsResponseBody
    | ListPropertiesResponseBodyItem
    | ListingListWithOfferEntryApiModel,
  posthog: PostHog
) => {
  await recordMarketplaceRealTimeEvent(
    {
      listingId: property.id ?? '',
      propertyId: property.propertyId,
      eventName: 'Saved Property'
    },
    posthog
  );
};

export const recordPropertyUnsaved = async (
  property:
    | PropertyDetailsResponseBody
    | ListPropertiesResponseBodyItem
    | ListingListWithOfferEntryApiModel,
  posthog: PostHog
) => {
  await recordMarketplaceRealTimeEvent(
    {
      listingId: property.id ?? '',
      propertyId: property.propertyId,
      eventName: 'Unsaved Property'
    },
    posthog
  );
};

export const recordUnconfirmedOffer = async (
  property:
    | PropertyDetailsResponseBody
    | ListPropertiesResponseBodyItem
    | ListingListWithOfferEntryApiModel,
  posthog: PostHog
) => {
  await recordMarketplaceRealTimeEvent(
    {
      listingId: property.id ?? '',
      propertyId: property.propertyId,
      eventName: 'Unconfirmed Offer (Abandoned Cart)'
    },
    posthog
  );
};

export const recordPropertyViewed = async (
  property:
    | PropertyDetailsResponseBody
    | ListPropertiesResponseBodyItem
    | ListingListWithOfferEntryApiModel,
  posthog: PostHog
) => {
  await recordMarketplaceRealTimeEvent(
    {
      listingId: property.id ?? '',
      propertyId: property.propertyId,
      eventName: 'Viewed Property'
    },
    posthog
  );
};

const eventMap = {
  inspection_report: 'Clicked Inspection Report',
  disclosures: 'Clicked Disclosures',
  prelim_title_report: 'Clicked Title Report',
  septic_inspection: 'Clicked Septic Inspection',
  floor_plan: 'Clicked Floor Plan'
};

export const recordPropertyClickEvent = async (
  property:
    | PropertyDetailsResponseBody
    | ListPropertiesResponseBodyItem
    | ListingListWithOfferEntryApiModel,
  eventType: string,
  posthog: PostHog
) => {
  const eventName = eventMap[eventType as keyof typeof eventMap];
  await recordMarketplaceRealTimeEvent(
    {
      listingId: property.id ?? '',
      propertyId: property.propertyId,
      eventName
    },
    posthog
  );
};
