import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTimer = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.75 12C.75 5.8 5.8.75 12 .75S23.25 5.8 23.25 12 18.2 23.25 12 23.25.75 18.2.75 12Zm1.5 0c0 5.4 4.35 9.75 9.75 9.75s9.75-4.35 9.75-9.75S17.4 2.25 12 2.25A9.73 9.73 0 0 0 2.25 12Zm10.5-3.75v3.45l4.5 4.45c.3.3.3.75 0 1.05-.15.15-.35.2-.55.2-.2 0-.4-.05-.55-.2l-4.7-4.7c-.05-.05-.1-.15-.15-.25-.05-.05-.05-.15-.05-.25V8.25c0-.4.35-.75.75-.75s.75.35.75.75Z"
      fill="#1C51A0"
    />
  </svg>
);
export default SvgTimer;
