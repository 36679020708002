import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerPropertyVisit = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-property-visit_svg__a)">
      <path
        d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#32BEA6"
      />
      <path d="M31.418 16.006 17.454 26.6v17.037h27.927V26.6L31.418 16.006Z" fill="#fff" />
      <path
        d="M49.218 28.05a1.164 1.164 0 0 1-1.63.223l-16.17-12.266-16.17 12.266a1.163 1.163 0 1 1-1.406-1.854l16.873-12.8a1.164 1.164 0 0 1 1.407 0l16.873 12.8a1.164 1.164 0 0 1 .223 1.63Z"
        fill="#DB3D55"
      />
      <path d="M34.91 37.818a3.49 3.49 0 1 0-6.982 0v5.818h6.982v-5.818Z" fill="#9C6144" />
      <path
        d="M24.436 29.745a.51.51 0 0 1-.51.51h-3.054a.51.51 0 0 1-.509-.51v-3.054a.51.51 0 0 1 .51-.51h3.054a.51.51 0 0 1 .509.51v3.054Zm0 8.727a.509.509 0 0 1-.51.51h-3.054a.51.51 0 0 1-.509-.51v-3.054a.51.51 0 0 1 .51-.51h3.054a.51.51 0 0 1 .509.51v3.054Zm9.194-8.626a.524.524 0 0 1-.524.524h-3.14a.524.524 0 0 1-.524-.524v-3.14a.524.524 0 0 1 .524-.524h3.14a.524.524 0 0 1 .524.523v3.14Zm8.727-.201a.494.494 0 0 1-.494.494h-2.969a.494.494 0 0 1-.494-.494v-2.969c0-.273.221-.494.494-.494h2.969c.273 0 .494.221.494.494v2.969Z"
        fill="#40C9E7"
      />
      <path d="M33.164 40.146a.582.582 0 1 0 0-1.164.582.582 0 0 0 0 1.164Z" fill="#FACB1B" />
      <path d="M44.398 36.655h-15.54l-5.585 13.963h26.71l-5.585-13.963Z" fill="#fff" />
      <path d="M24.992 49.455h4.98l.885-2.91h-4.701l-1.164 2.91Z" fill="#FACB1B" />
      <path d="M33.515 37.818h-3.869l-3.025 7.564h4.59l2.304-7.564Z" fill="#17B198" />
      <path d="m32.788 44.218-1.594 5.237h7.494l4.485-5.237H32.788Z" fill="#F99134" />
      <path
        d="M33.137 43.055h11.037l1.042-1.216-1.607-4.02h-8.878l-1.594 5.236Zm7.08 6.4h3.311L41.9 47.494l-1.683 1.96Z"
        fill="#FACB1B"
      />
      <path d="m45.705 43.055-3.038 3.543 2.38 2.857h3.217l-2.56-6.4Z" fill="#059BBF" />
      <path
        d="M40.833 33.798c0 2.279-4.125 11.002-4.125 11.002s-4.126-8.723-4.126-11.002a4.126 4.126 0 0 1 8.251 0Z"
        fill="#DB3D55"
      />
      <path
        d="M36.708 29.673a4.126 4.126 0 0 0-4.126 4.125c0 2.279 4.125 11 4.126 11.002V29.673Z"
        fill="#FA5655"
      />
      <path d="M36.707 34.909a1.745 1.745 0 1 0 0-3.491 1.745 1.745 0 0 0 0 3.49Z" fill="#fff" />
    </g>
    <defs>
      <clipPath id="seller-property-visit_svg__a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerPropertyVisit;
