import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { VerifyIdentityWithPhoneNumberAndSocialRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/identity-verification-2
export const verifyIdentityWithPhoneNumberAndSocial = (
  body: VerifyIdentityWithPhoneNumberAndSocialRequestBody
) => {
  return api.authControllerVerifyIdentityWithPhoneNumberAndSocial(body);
};

export const useVerifyIdentityWithPhoneNumberAndSocial = () => {
  return useMutation({ mutationFn: verifyIdentityWithPhoneNumberAndSocial });
};
