import classNames from 'classnames';
import React from 'react';

import { ArrowLeftIcon } from '../Icons';

import styles from './SignUp.module.scss';

interface Props {
  handleBack: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  hideLeft?: boolean;
}

const PreviousButton = (props: Props) => {
  const { handleBack, children, style } = props;

  return (
    <div className={classNames(styles.btnWrapper)} style={style}>
      {!props.hideLeft && <ArrowLeftIcon onClick={handleBack} className={styles.arrowLeftIcon} />}
      {children}
    </div>
  );
};

export default PreviousButton;
