import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerClosing = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-closing_svg__a)">
      <path
        d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#FABC3D"
      />
      <path
        d="m13.461 48.318 9.027-20.177c-.409 2.124 2.05 5.967 4.727 8.644 3.253 3.253 6.904 5.16 8.795 4.674-6.776 3.028-13.552 6.054-20.329 9.08-.464.213-1.164-.01-1.684-.536-.526-.52-.75-1.22-.536-1.685Z"
        fill="#F0F1F1"
      />
      <path
        d="m13.463 48.318 9.026-20.177c-.408 2.122 2.047 5.96 4.72 8.636L13.991 49.994c-.52-.518-.74-1.214-.528-1.676Z"
        fill="#fff"
      />
      <path d="M26.763 22.69a1.164 1.164 0 1 0 0-2.327 1.164 1.164 0 0 0 0 2.328Z" fill="#DF2C2C" />
      <path
        d="M36.072 25.018a1.164 1.164 0 1 0 0-2.328 1.164 1.164 0 0 0 0 2.328Z"
        fill="#F37A10"
      />
      <path
        d="M47.127 29.673a1.164 1.164 0 1 0 0-2.327 1.164 1.164 0 0 0 0 2.327Z"
        fill="#059BBF"
      />
      <path
        d="M47.709 42.473a1.164 1.164 0 1 0 0-2.328 1.164 1.164 0 0 0 0 2.328Z"
        fill="#FA5655"
      />
      <path
        d="M34.328 16.873a1.164 1.164 0 1 0 0-2.327 1.164 1.164 0 0 0 0 2.327Z"
        fill="#12B2A0"
      />
      <path
        d="m42.401 15.507-2.299 1.172-1.825-1.825.404 2.549-2.3 1.172 2.55.404.403 2.549 1.172-2.3 2.549.403-1.825-1.824 1.172-2.3Z"
        fill="#FA5655"
      />
      <path
        d="m41.912 34.91-.554 1.705h-1.793l1.45.964-.554 1.66 1.451-1.077 1.451 1.043-.554-1.62 1.45-.97h-1.792l-.555-1.706Z"
        fill="#F37A10"
      />
      <path
        d="M37.818 29.091c5.796 0 7.014-3.684 7.993-6.645.815-2.463 1.458-4.41 4.225-4.41a.582.582 0 0 0 0-1.163c-3.608 0-4.483 2.648-5.33 5.208-.994 3.007-1.933 5.847-6.888 5.847a.582.582 0 0 0 0 1.163Z"
        fill="#DF2C2C"
      />
      <path
        d="M30.037 25.473c4.077-3.263 2.63-5.446 1.229-7.559-.783-1.18-1.593-2.401-1.593-3.95a.582.582 0 1 0-1.163 0c0 1.9.949 3.33 1.786 4.594 1.361 2.052 2.26 3.41-.986 6.006a.582.582 0 0 0 .727.909Z"
        fill="#059BBF"
      />
      <path
        d="M50.037 36.073a.582.582 0 0 0 0-1.164c-1.663 0-2.99-.885-4.274-1.74-2.072-1.382-4.216-2.812-7.192.165a.582.582 0 0 0 .823.823c2.302-2.302 3.663-1.394 5.723-.02 1.362.907 2.904 1.936 4.92 1.936Z"
        fill="#12B2A0"
      />
      <path
        d="M22.82 47.333c-5.304 2.39 1.082-.475-4.227 1.904a40.93 40.93 0 0 1-3.62-4.294l2.162-4.833a30.669 30.669 0 0 0 5.685 7.223Z"
        fill="#DF2C2C"
      />
      <path
        d="m19.86 44.125-3.093 3.094a38.528 38.528 0 0 1-1.794-2.276l2.162-4.834a30.56 30.56 0 0 0 2.725 4.016Z"
        fill="#FA5655"
      />
      <path
        d="M27.215 36.784c3.77 3.77 8.081 5.742 9.558 4.262 1.466-1.465-.446-5.743-4.263-9.565-3.821-3.817-8.098-5.722-9.564-4.263-1.707 1.705 1.14 6.437 4.27 9.566Z"
        fill="#E2E4E5"
      />
      <path
        d="M26.369 38.724c-2.08-2.814-3.338-5.786-3.604-8.442-.284-.809-.392-1.541-.278-2.14l-3.264 7.309a30.466 30.466 0 0 0 3.147 5.263 33.775 33.775 0 0 0 4.396 4.872l4.423-1.976a23.58 23.58 0 0 1-4.82-4.886Z"
        fill="#DF2C2C"
      />
      <path
        d="m32.507 31.477-5.3 5.299c-3.126-3.13-5.966-7.855-4.262-9.558 1.467-1.458 5.74.445 9.562 4.26Z"
        fill="#F0F1F1"
      />
      <path
        d="m22.487 28.142-3.264 7.309a30.466 30.466 0 0 0 3.147 5.263c.128.172.264.336.394.506l3.15-3.15c-1.805-2.62-2.906-5.34-3.152-7.793-.281-.807-.39-1.538-.275-2.135Z"
        fill="#FA5655"
      />
    </g>
    <defs>
      <clipPath id="seller-closing_svg__a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerClosing;
