import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgStep2({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M30 4.65a25 25 0 1025 25 25 25 0 00-25-25zm6 33.45H24.05v-.76a1.46 1.46 0 01.09-.49 1.38 1.38 0 01.31-.46l5.74-5.77c.47-.48.91-.94 1.3-1.39a9.7 9.7 0 001-1.34 6.32 6.32 0 00.65-1.37 4.82 4.82 0 00.22-1.52 3.44 3.44 0 00-.25-1.38 2.61 2.61 0 00-.68-1 3.16 3.16 0 00-1-.58 4 4 0 00-1.26-.19 3.85 3.85 0 00-1.25.2 3.26 3.26 0 00-1 .56 3 3 0 00-.75.84 3.25 3.25 0 00-.43 1.07 1 1 0 01-.3.53.82.82 0 01-.53.16h-.28L24.41 25a6.5 6.5 0 01.68-2.17 5 5 0 011.27-1.58 5.3 5.3 0 011.76-1 7.07 7.07 0 014.27 0 5 5 0 011.7 1 4.74 4.74 0 011.14 1.57 5.21 5.21 0 01.41 2.1 5.48 5.48 0 01-.3 1.85 7.81 7.81 0 01-.81 1.64A12.7 12.7 0 0133.34 30c-.44.49-.91 1-1.41 1.49l-4.72 4.83a7.13 7.13 0 011-.22 5.63 5.63 0 011-.08h6a.78.78 0 01.58.21.74.74 0 01.21.55z"
        fill="#c9c9c9"
      />
    </svg>
  );
}

export default SvgStep2;
