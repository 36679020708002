import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { VerifyIdentityWithPhoneNumberRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/identity-verification-1
export const verifyIdentityWithPhoneNumber = (body: VerifyIdentityWithPhoneNumberRequestBody) => {
  return api.authControllerVerifyIdentityWithPhoneNumber(body);
};

export const useVerifyIdentityWithPhoneNumber = () => {
  return useMutation({ mutationFn: verifyIdentityWithPhoneNumber });
};
