import React from 'react';

import { Input } from '../../../components/design-system';

import styles from './EditCardModal.module.scss';

export interface NameInputGroupProps {
  firstName?: string;
  lastName?: string;
  onChange: (name: string, value: string) => void;
}

export const NameInputGroup = ({ firstName, lastName, onChange }: NameInputGroupProps) => {
  return (
    <>
      <span className={styles.label}>Name on Card</span>
      <fieldset className={styles.tempInputs}>
        <div className={styles.inputGroup}>
          <div className={styles.inputField}>
            <Input
              value={firstName}
              onChange={(e) => onChange('firstName', e.target.value)}
              placeholder="First Name"
            />
          </div>
          <div className={styles.inputField}>
            <Input
              value={lastName}
              onChange={(e) => onChange('lastName', e.target.value)}
              placeholder="Last Name"
            />
          </div>
        </div>
      </fieldset>
    </>
  );
};
