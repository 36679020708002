import classNames from 'classnames';
import React from 'react';

import { Button, ButtonProps } from '../design-system/';

import styles from './SubmitButton.module.scss';

type SubmitButtonProps = ButtonProps;

export const SubmitButton = (props: SubmitButtonProps) => {
  const { className, children, ...otherProps } = props;

  return (
    <Button
      variant="primary"
      size="large"
      fullWidth
      className={classNames(styles.submitButton, className)}
      {...otherProps}
    >
      {children}
    </Button>
  );
};
