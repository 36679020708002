import { BID_INCREMENT_MODAL_NAME } from './BidIncrementModal';
import { LEARN_MORE_MODAL_NAME } from './LearnMoreModal';
import { SUNDAE_FUNDING_MODAL_NAME } from './SundaeFundingModal';

export { AutoOfferModals } from './AutoOfferModals';

export const AUTO_OFFER_MODAL_NAMES = {
  [LEARN_MORE_MODAL_NAME]: LEARN_MORE_MODAL_NAME,
  [BID_INCREMENT_MODAL_NAME]: BID_INCREMENT_MODAL_NAME,
  [SUNDAE_FUNDING_MODAL_NAME]: SUNDAE_FUNDING_MODAL_NAME
};
