import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerPsa = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-psa_svg__a)">
      <path
        d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#FABC3D"
      />
      <path
        d="M48.29 50.036a.582.582 0 0 1-.581.582H16.291a.582.582 0 0 1-.582-.582V16.291a.582.582 0 0 1 .582-.582h31.418a.582.582 0 0 1 .582.582v33.745Z"
        fill="#fff"
      />
      <path
        d="M34.328 25.019a.583.583 0 0 0 .993.411l3.25-4.073a.583.583 0 0 1 .823 0l3.25 4.073a.581.581 0 0 0 .993-.411v-9.891a.582.582 0 0 0-.582-.582H34.91a.582.582 0 0 0-.582.582v9.89Z"
        fill="#DF2C2C"
      />
      <path
        d="M36.655 29.09a.582.582 0 0 1-.582.582H22.109a.582.582 0 0 1 0-1.163h13.964a.582.582 0 0 1 .582.582Zm6.981 4.655a.582.582 0 0 1-.581.582H22.109a.582.582 0 0 1 0-1.164h20.946a.582.582 0 0 1 .581.582Zm0 4.655a.582.582 0 0 1-.581.581H22.109a.582.582 0 0 1 0-1.163h20.946a.582.582 0 0 1 .581.582Zm0 4.654a.582.582 0 0 1-.581.582H22.109a.582.582 0 0 1 0-1.164h20.946a.582.582 0 0 1 .581.582Z"
        fill="#E2E4E5"
      />
      <path
        d="M34.91 14.546a.582.582 0 0 0-.582.582v9.89a.583.583 0 0 0 .993.412l3.25-4.073a.58.58 0 0 1 .412-.17v-6.641H34.91Z"
        fill="#FA5655"
      />
    </g>
    <defs>
      <clipPath id="seller-psa_svg__a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerPsa;
