import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import thankYouIcon from '../../../../public/images/thankyou-image.png';
import { getUserInfo } from '../../../api/user/getUserInfo';
import { attemptLogin } from '../../../helpers/signInHelpers';
import { useConfirmationCode } from '../../../hooks/store/misc';
import { useModalByName } from '../../../hooks/useModalByName';
import { useMoveToHomeScreen } from '../../../hooks/useMoveToHomeScreen';
import { selectReferralInfo } from '../../../store/misc';
import { SignUpFormData } from '../../../types/onBoarding';
import { getLoginUrlWithPropertyId } from '../../../utils/url.utils';
import { AlertBox } from '../../AlertBox';
import { SubmitButton } from '../../SubmitButton';
import style from '../SignUp.module.scss';

interface SuccessPageProps {
  isPrimaryMarketActive: boolean;
  initialFormData: SignUpFormData;
  featuredPost: {
    title: string;
    link: string;
  };
  handleCloseSignupModal: () => void;
}

interface LoginLinkProps {
  initialFormData: SignUpFormData;
  setErrorCode: (s: string) => void;
  setErrorMessage: (s: string) => void;
  handleCloseSignupModal: () => void;
}

const LoginLink = ({
  initialFormData,
  setErrorCode,
  setErrorMessage,
  handleCloseSignupModal
}: LoginLinkProps) => {
  const router = useRouter();
  const referral = useSelector(selectReferralInfo);
  const moveToHomeScreen = useMoveToHomeScreen();
  const { confirmationCode } = useConfirmationCode();
  const confirmationCodeRef = useRef<string>(confirmationCode);
  confirmationCodeRef.current = confirmationCode;

  const posthog = usePostHog();

  const [, showConfirmationCodeModal] = useModalByName('ConfirmationCode');

  const handleLogin = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (referral?.propertyId) {
      router.push(getLoginUrlWithPropertyId(referral.propertyId));
    } else {
      const { email, password } = initialFormData;

      const handleAfterLogin = async () => {
        handleCloseSignupModal();
        const userInfo = await getUserInfo();
        posthog.identify(userInfo.id, {
          ...userInfo
        });
        moveToHomeScreen();
      };

      await attemptLogin({
        email,
        password,
        showConfirmationCodeModal,
        confirmationCodeRef,
        setErrorMessage: setErrorMessage,
        setErrorCode: setErrorCode,
        handleAfterLogin
      });
    }
  };

  return (
    <Link href="/">
      <SubmitButton onClick={handleLogin}>Search the Marketplace</SubmitButton>
    </Link>
  );
};

const SuccessPage = ({
  initialFormData,
  isPrimaryMarketActive,
  handleCloseSignupModal
}: SuccessPageProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);

  return (
    <>
      <div className={classNames(style.sundaeScoop)}>
        <main className={classNames(style.main)}>
          <div className={style.stack}>
            <img
              className={classNames(style.mxAuto)}
              width="50%"
              src={thankYouIcon.src}
              alt=""
              aria-hidden
            />

            <>
              <h1 className={classNames(style.title)}>Welcome to the Marketplace!</h1>

              {isPrimaryMarketActive ? (
                <div>
                  <p>
                    Start winning properties and growing your business now. As we create your
                    account, watch this video to learn how to succeed in our marketplace.
                  </p>
                  <iframe
                    src="https://player.vimeo.com/video/740853640?h=f545304413"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <>
                  <p>
                    Sundae currently doesn&apos;t operate in your primary market, but you can still
                    access our inventory across the country by using the email and password you
                    chose during sign up.
                  </p>
                  <p>
                    You may change your Primary Market via account settings after you login to
                    receive email updates as new properties become available. We will notify you
                    once we open up our services in your primary market.
                  </p>
                </>
              )}
              <>
                {errorCode || errorMessage ? (
                  <AlertBox noDefaultMargin>
                    <div>{errorCode || errorMessage}</div>
                  </AlertBox>
                ) : (
                  <LoginLink
                    handleCloseSignupModal={handleCloseSignupModal}
                    initialFormData={initialFormData}
                    setErrorMessage={setErrorMessage}
                    setErrorCode={setErrorCode}
                  />
                )}
              </>
            </>
          </div>
        </main>
      </div>
    </>
  );
};

SuccessPage.defaultProps = {
  featuredPost: {
    title: 'Real Estate Investors Can Do This Right Now to Stay Ahead of the Competition',
    link: 'https://sundae.com/blog/investor/real-estate-investors-can-do-this-right-now-to-stay-ahead-of-the-competition/'
  }
};

export default SuccessPage;
