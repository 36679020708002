import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgInspectionDetails({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M27.41 39.17h-.93A1.78 1.78 0 0125 37.88c-.36-.79-1.32-2.9-5.2-4.94a1.51 1.51 0 011.41-2.66 15.48 15.48 0 015.26 4.23c2.29-4 6.63-8.38 13-13.1a1.5 1.5 0 111.79 2.4c-7 5.25-11.4 9.94-13 14a3.17 3.17 0 01-.33.69z"
        fill="#1d52a0"
      />
      <path
        d="M46.54 4.6H34.7v-.87a1.39 1.39 0 00-1.36-1.41h-6.68a1.39 1.39 0 00-1.36 1.41v.87H13.46A5.27 5.27 0 008 9.64V53a5.27 5.27 0 005.46 5h33.08A5.27 5.27 0 0052 53V9.64a5.27 5.27 0 00-5.46-5.04zM39 9.74h7.37v43.19h-32.7V9.74H21a3.54 3.54 0 003.51 3.13h10.9A3.54 3.54 0 0039 9.74zm-1.45-.43a2.06 2.06 0 01-2.06 2.06H24.55a2.06 2.06 0 01-2.06-2.06v-.16a2.06 2.06 0 012.06-2.05h10.9a2.06 2.06 0 012.06 2.05zM26.8 3.82h6.4v.78h-6.4zM49.82 53a3.17 3.17 0 01-3.28 3H13.46a3.17 3.17 0 01-3.28-3V9.64a3.16 3.16 0 013.28-3h8.61a3.49 3.49 0 00-.94 1.62h-8.21a.74.74 0 00-.75.75v44.67a.74.74 0 00.75.75h34.16a.74.74 0 00.75-.75V9a.74.74 0 00-.75-.75h-8.21a3.48 3.48 0 00-.94-1.62h8.61a3.16 3.16 0 013.28 3z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgInspectionDetails;
