import classNames from 'classnames';
import React, { ElementRef, forwardRef, HTMLAttributes } from 'react';

import styles from './VisuallyHidden.module.scss';

type VisuallyHiddenProps = HTMLAttributes<HTMLElement> & {
  children?: React.ReactNode | React.ReactNode[];
};

const DEFAULT_TAG = 'div';

export const VisuallyHidden = forwardRef<ElementRef<typeof DEFAULT_TAG>, VisuallyHiddenProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <>
        <div ref={ref} className={classNames(styles.visuallyHidden, className)} {...rest}>
          {children}
        </div>
      </>
    );
  }
);
