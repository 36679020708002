import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgVideo({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#clip0_420_261)">
        <path
          d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
          data-stroke="true"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.58869 4.65834C6.41269 4.54834 6.19036 4.54234 6.00869 4.64301C5.82702 4.74367 5.71436 4.93501 5.71436 5.14267V10.857C5.71436 11.0647 5.82702 11.256 6.00869 11.3567C6.19036 11.4573 6.41236 11.4517 6.58869 11.3413L11.16 8.48434C11.327 8.38001 11.4287 8.19667 11.4287 7.99967C11.4287 7.80267 11.3274 7.61967 11.16 7.51501L6.58869 4.65834Z"
          data-fill="true"
        />
      </g>
      <defs>
        <clipPath id="clip0_420_261">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgVideo;
