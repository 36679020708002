import { AxiosInstance } from 'axios';
import router from 'next/router';

import {
  getAccessToken,
  getAuth0UserId,
  logout,
  setLastRoute
} from '../../utils/localStorage.utils';
import { getCurrentRoute } from '../../utils/url.utils';

const origin = typeof window !== 'undefined' ? window.location.origin : 'http://localhost';

export const axiosConfig = {
  baseURL: `${
    process.env.NEXT_PUBLIC_REACT_APP_API_LOCATION
      ? process.env.NEXT_PUBLIC_REACT_APP_API_LOCATION
      : origin
  }`,
  timeout: 30000
};

// Note that we could alternatively modify /scripts/templates/swagger-typescript-api/http-client.eta
// to include similar logic if necessary, but keeping this code outside of the template may be
// easier to maintain.
export const setupRequestInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    function (config) {
      const token = getAccessToken();

      if (token) {
        config.headers.Authorization = token;
        config.headers.Auth0UserId = getAuth0UserId();
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error): Promise<unknown> => {
      if (error.response && error.response.status && error.response.status === 401) {
        setLastRoute(getCurrentRoute());
        logout();
        router.reload();
      }

      return Promise.reject<unknown>(error);
    }
  );

  return axiosInstance;
};
