import React from 'react';

import modalStyles from '../../common/modal.module.scss';
import { ModalCloseButton } from '../../components/Modal/ModalCloseButton';

interface AutoOfferModalProps {
  headerText: string;
  close: () => void;
  children?: React.ReactNode;
}

export const AutoOfferInfoModalContents: React.FC<AutoOfferModalProps> = ({
  children,
  headerText,
  close
}) => {
  return (
    <>
      <ModalCloseButton isAbsolute onClick={close} />

      <h2 className={modalStyles.modalHeader}>{headerText}</h2>
      {children}
    </>
  );
};
