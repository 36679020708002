/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface LoginRequestBody {
  email: string;
  /** @format password */
  password: string;
}

export interface LoginAuthenticationResultType {
  AccessToken?: string;
  ExpiresIn?: number;
  TokenType?: string;
  RefreshToken?: string;
  IdToken?: string;
  UserId?: string;
}

export interface LoginResponseBody {
  success: boolean;
  data: LoginAuthenticationResultType;
  hasMpAccount: boolean;
}

export interface LogoutResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface ForgotPasswordRequestBody {
  email: string;
}

export interface ForgotPasswordResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  type?: string;
}

export interface ChangePasswordRequestBody {
  /** @format password */
  prevPassword: string;
  /** @format password */
  newPassword: string;
}

export interface ChangePasswordResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface CheckUserAvailabilityRequestBody {
  email: string;
  phone: string;
}

export interface CheckUserAvailabilityResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  uuidInvestorPortalUser: string;
}

export interface VerifyIdentityWithPhoneNumberRequestBody {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export interface VerifyIdentityWithPhoneNumberResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface VerifyIdentityWithPhoneNumberAndSocialRequestBody {
  firstName: string;
  lastName: string;
  phone: string;
  socialSecurityNumber: string;
  email: string;
}

export interface VerifyIdentityWithPhoneNumberAndSocialResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface BeginSignUpRequestBody {
  existingUser: boolean;
  firstName: string;
  lastName: string;
  email: string;
  /** @format password */
  password: string;
  phone: string;
}

export interface BeginSignUpResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export enum InvestmentType {
  FixFlip = 'Fix & Flip',
  RehabRentLongTerm = 'Rehab & Rent (Long Term)',
  RehabRentShortTerm = 'Rehab & Rent (Short Term)',
  OwnerFinanced = 'Owner Financed',
  BuilderNewConstruction = 'Builder (New Construction)'
}

export enum RehabType {
  LightUpdating = 'Light Updating',
  FullCosmetic = 'Full Cosmetic',
  LargeFixer = 'Large Fixer',
  TearDown = 'Tear Down'
}

export enum FinancialSource {
  HardMoney = 'Hard Money',
  PrivateMoneyNonLicensedLender = 'Private Money (Non-Licensed Lender)',
  Cash = 'Cash',
  Value1031Exchange = '1031 Exchange',
  LineOfCredit = 'Line of Credit'
}

export enum ContactType {
  Flipper = 'Flipper',
  RealEstateAgent = 'Real Estate Agent'
}

export interface CompleteSignUpRequestBody {
  existingUser: boolean;
  email: string;
  /** @format password */
  password: string;
  firstName: string;
  lastName: string;
  companyName: string;
  phone: string;
  fixAndFlip: number;
  buyAndHold: number;
  shortTermRental: number;
  wholesale: number;
  otherInvestment: number;
  primaryOperatingMarketOnboarding: string;
  noOfTxnNext12MonthOnboarding: number;
  noTxnLast12MonthOnboarding: number;
  /** @pattern ^\d{12,13}$ */
  termsAndConditionsAcceptedOn: string;
  /** @pattern ^\d{12,13}$ */
  initialTermsAndConditionsAcceptedOn: string;
  vestingEntityName: string;
  vestingEntityType: string;
  marketingReferralSource: string;
  doesVestUnderPersonalName?: boolean;
  referralToken?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmSource?: string;
  utmContent?: string;
  utmMedium?: string;
  utmReferrer?: string;
  advisor?: string;
  smsOptOut: boolean;
  autoApprovalToken?: string;
  additionalOperatingMarketOnboarding?: string;
  uuidInvestorPortalUser?: string;
  investmentTypes: InvestmentType[];
  rehabTypes: RehabType[];
  financialSources: FinancialSource[];
  contactType: 'Flipper' | 'Real Estate Agent';
}

export interface CompleteSignUpResponseBody {
  isUserVerified: boolean;
  isPrimaryMarketActive: boolean;
  referralPublishId?: string;
  referralPropertyId?: string;
}

export interface HomeownerSignUpInfoResponseBody {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  existingUser: boolean;
}

export interface ExistingUserSignUpRequestBody {
  email: string;
  /** @format password */
  password: string;
  leadId: string;
  utmAccountCreationSource: string;
}

export interface HomeownerSignUpRequestBody {
  leadId: string;
  /** @format password */
  password: string;
  utmAccountCreationSource: string;
}

export interface HomeownerSignUpResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface VerifySmsCodeRequestBody {
  email: string;
  /** @format password */
  password: string;
  confirmationCode: string;
}

export interface VerifySmsCodeResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface VerifyEmailRequestBody {
  email: string;
  /** @format password */
  password: string;
  confirmationCode: string;
}

export interface VerifyEmailResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface AppResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface ConfirmForgotPasswordRequestBody {
  email: string;
  /** @format password */
  password: string;
  code: string;
}

export interface ConfirmForgotPasswordResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface ResendConfirmationCodeRequestBody {
  email: string;
}

export interface ResendConfirmationCodeResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export enum ContactStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Deactivated = 'Deactivated'
}

export enum SubscriptionBillingCycle {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export interface SubscriptionTierInfo {
  tier: 0 | 1 | 2;
  billingCycle: SubscriptionBillingCycle;
  maxAllowedSecondaryMarkets: number;
  maxAllowedAreasOfInterest: number;
  maxMarketSelectionChanges: number;
}

export interface GetUserInfoResponseBody {
  id: string;
  sfId: string | null;
  contactType: string | null;
  accountId: string | null;
  /** @format date-time */
  ipAccountCreationC: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  photoUrl: string | null;
  name: string | null;
  /** @format date-time */
  createdAt: string | null;
  activeStatus: ContactStatus | null;
  smsOptOut: boolean;
  accountIdInvestorPortal: string | null;
  onboardingInvestorPrimaryStrategy: string | null;
  onboardingAdditionalOperatingMarket: string | null;
  onboardingPrimaryOperatingMarket: string | null;
  onboardingExperienceInRealEstate: string | null;
  onboardingNoTxnLast12Month: number | null;
  onboardingNoOfTxnNext12Month: number | null;
  onboardingCompleted: boolean;
  ssoUser: boolean;
  primaryLocationLatitude: number | null;
  primaryLocationLongitude: number | null;
  isPrimaryLocationAdded: boolean;
  areaOfInterestMarketIds: string | null;
  investmentTypes: InvestmentType[] | null;
  rehabTypes: RehabType[] | null;
  financialSources: FinancialSource[] | null;
  sundaeUserId: string;
  hasSeenAutoOfferLearnMore: boolean;
  hasSeenMp2: boolean;
  showSMSOptInModal: boolean;
  userHasPaidSubscription?: boolean;
  userIsConfirmingMarketsForFirstTime?: boolean;
  userIsBilledYearly?: boolean;
  nextAllowedMarketSelectionChangeDate?: string;
  userHasReachedMarketChangeLimit?: boolean;
  userShouldConfirmMarkets?: boolean;
  userSubscriptionTierInfo?: SubscriptionTierInfo;
  preferredTimezone?: string;
}

export interface ResendEmailCodeRequestBody {
  email: string;
  /** @format password */
  password: string;
}

export interface ResendEmailCodeResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface SrnSignUpRequestBody {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  /** @format password */
  password: string;
  referralCode?: string;
}

export interface SrnVerifySmsCodeRequestBody {
  email: string;
  confirmationCode: string;
}

export interface SrnVerifyEmailCodeRequestBody {
  confirmationCode: string;
}

export interface UpdateUserEmailRequestBody {
  newEmail: string;
}

export interface UpdateUserEmailResponseBody {
  success: boolean;
}

export interface ResendNewEmailCodeResponseBody {
  success: boolean;
}

export interface VerifyNewEmailCodeRequestBody {
  confirmationCode: string;
  password: string;
}

export interface VerifyNewEmailCodeResponseBody {
  success: boolean;
}

export interface UpdateUserPhoneRequestBody {
  phone: string;
}

export interface UpdateUserPhoneResponseBody {
  success: boolean;
}

export interface ResendUpdatedPhoneCodeResponseBody {
  success: boolean;
}

export interface VerifyUpdatedPhoneRequestBody {
  code: string;
  password: string;
}

export interface VerifyUpdatedPhoneResponseBody {
  success: boolean;
}

export interface LoginV2RequestBody {
  email: string;
  /** @format password */
  password: string;
}

export enum TokenType {
  ACCESS = 'ACCESS',
  API = 'API',
  API_KEY = 'API_KEY',
  REGISTRATION = 'REGISTRATION'
}

export enum RegistrationStatus {
  USER_CREATED = 'USER_CREATED',
  PHONE_VERIFIED = 'PHONE_VERIFIED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  IDENTITY_VERIFIED = 'IDENTITY_VERIFIED',
  COMPLETED = 'COMPLETED'
}

export interface TokenResult {
  token: string;
  tokenType: TokenType;
  registrationStatus: RegistrationStatus;
}

export interface LoginV2ResponseBody {
  success: boolean;
  data: TokenResult;
}

export interface LogoutV2ResponseBody {
  success: boolean;
}

export interface ChangePasswordV2RequestBody {
  /** @format password */
  currentPassword: string;
  /** @format password */
  newPassword: string;
}

export interface ChangePasswordV2ResponseBody {
  success: boolean;
}

export interface UpdateUserEmailV2RequestBody {
  email: string;
}

export interface UpdateUserEmailV2ResponseBody {
  success: boolean;
}

export interface ResendUpdatedEmailCodeV2ResponseBody {
  success: boolean;
}

export interface VerifyUpdatedEmailV2RequestBody {
  code: string;
  password: string;
}

export interface VerifyUpdatedEmailV2ResponseBody {
  success: boolean;
}

export interface UpdateUserPhoneV2RequestBody {
  phone: string;
}

export interface UpdateUserPhoneV2ResponseBody {
  success: boolean;
}

export interface ResendUpdatedPhoneCodeV2ResponseBody {
  success: boolean;
}

export interface VerifyUpdatedPhoneV2RequestBody {
  code: string;
  password: string;
}

export interface VerifyUpdatedPhoneV2ResponseBody {
  success: boolean;
}

export interface ForgotPasswordV2RequestBody {
  email: string;
}

export interface ForgotPasswordV2ResponseBody {
  success: boolean;
}

export interface ResetPasswordV2RequestBody {
  email: string;
  /** @format password */
  newPassword: string;
  code: string;
}

export interface ResetPasswordV2ResponseBody {
  success: boolean;
}

export interface BeginRegistrationRequestBody {
  email: string;
  /** @format password */
  password: string;
  phone: string;
  firstName: string;
  lastName: string;
}

export interface BeginRegistrationResult {
  token: string;
  tokenType: TokenType;
  registrationStatus: RegistrationStatus;
  sundaeUserId: string;
}

export interface BeginRegistrationResponseBody {
  success: boolean;
  data: BeginRegistrationResult;
}

export interface RegistrationResendPhoneCodeResponseBody {
  success: boolean;
}

export interface RegistrationVerifyPhoneRequestBody {
  code: string;
}

export interface RegistrationStatusResult {
  registrationStatus: RegistrationStatus;
}

export interface RegistrationVerifyPhoneResponseBody {
  success: boolean;
  data: RegistrationStatusResult;
}

export interface RegistrationResendEmailCodeResponseBody {
  success: boolean;
}

export interface RegistrationVerifyEmailRequestBody {
  code: string;
}

export interface RegistrationVerifyEmailResponseBody {
  success: boolean;
  data: RegistrationStatusResult;
}

export enum LicenseType {
  Agent = 'agent',
  Broker = 'broker'
}

export interface RegistrationLicense {
  type: LicenseType;
  number: string;
  state: string;
}

export interface RegistrationBrokerage {
  name: string;
  licenseNumber: object;
  licenseState: string;
  brokerFirstName: string;
  brokerLastName: string;
  ein: string;
  email: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  licenses: RegistrationLicense[];
}

export interface CompleteRegistrationRequestBody {
  exigoUserId: string;
  exigoMarketExpertId: object;
  territoryName: object;
  brokerages: RegistrationBrokerage[];
}

export interface CompleteRegistrationResponseBody {
  success: boolean;
  data: RegistrationStatusResult;
}

export interface ApiListingDbStatusCheckResponseBody {
  success: boolean;
  numListings: number;
}

export interface ApiOfferServicePing {
  success: boolean;
  totalTimeTakenForApiRequest: string;
  numberOfRetriesMade: number;
}

export interface ApiStatusCheckResponseBody {
  success: boolean;
}

export interface RealTimeEventsLogEventRequestBody {
  userId?: string;
  listingId?: string;
  propertyId: string;
  applicationName: string;
  eventName: string;
  idToken: string;
  eventTimeStamp?: string;
}

export interface RealTimeEventsLogEventResponseBody {
  success: boolean;
}

export interface Downgrade {
  tier: string;
  endDate: number;
}

export interface Subscription {
  status?: string;
  currentPeriodStart?: number;
  currentPeriodEnd?: number;
  tier?: string;
  price?: number;
  downgrade?: Downgrade;
}

export interface GetSubscriptionResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data?: Subscription;
}

export interface Tier {
  id: string;
  amount: number;
  interval: string;
  intervalCount: number;
}

export interface GetSubscriptionTiersResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: {
    'Essential - Free Tier'?: Tier;
    'Edge - Yearly'?: Tier;
    'Edge - Monthly'?: Tier;
    'Edge+ - Yearly'?: Tier;
    'Edge+ - Monthly'?: Tier;
  };
}

export interface StripePayment {
  clientSecret: string;
}

export interface BeginStripePaymentResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: StripePayment;
}

export interface Line {
  amount: number;
  description?: object | null;
  proration: boolean;
}

export interface PreviewSubscription {
  downgrade: boolean;
  tax: number | null;
  taxRate?: number | null;
  discount?: number | null;
  subTotal: number;
  amountDue: number;
  prorationDate: number;
  lines: Line[];
}

export interface PreviewSubscriptionUpdateResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: PreviewSubscription;
}

export interface PreviewDowngrade {
  downgrade: boolean;
  endDate: number;
}

export interface PreviewSubscriptionUpdateDowngradeResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: PreviewDowngrade;
}

export interface UpdateSubscriptionRequestBody {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  paymentMethodId?: string;
  priceId: string;
  prorationDate?: number;
  couponCode?: string;
  hasAgreedToMembershipTermsAndConditions?: boolean;
  agreedToMembershipTermsAndConditionsOn?: number;
  attributionLinkVisitedUserId?: string;
  /** @format date-time */
  attributionLinkVisitedOn?: string;
  /** @format date-time */
  updatedOn: string;
}

export interface SubscriptionResponse {
  subscriptionId: string;
  downgrade?: Downgrade;
}

export interface UpdateSubscriptionResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data?: SubscriptionResponse;
}

export interface CancelDowngradeSubscriptionResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: SubscriptionResponse;
}

export interface SyncSubscriptionResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface StripeHistoricalPayment {
  id: string;
  amount: number;
  created: number;
  brand: string;
  last4: string;
  receiptUrl: string;
}

export interface StripeHistoricalPayments {
  hasMore: boolean;
  payments: StripeHistoricalPayment[];
}

export interface PaymentHistoryResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: StripeHistoricalPayments;
}

export interface UserAddress {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
}

export interface Card {
  brand: string;
  last4: string;
  expMonth: number;
  expYear: number;
}

export interface BillingDetails {
  firstName: string | null;
  lastName: string | null;
  address: UserAddress | null;
  card: Card | null;
}

export interface GetBillingDetailsResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: BillingDetails;
}

export interface UpdateBillingDetailsRequestBody {
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  paymentMethodId?: string;
}

export interface UpdateBillingDetailsResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
}

export interface HasMembershipResponseBody {
  success: boolean;
  hasMembership: boolean;
}

export interface UpdateProfileRequestBody {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  preferredName?: string;
}

export interface UpdateUserRequestBody {
  hasSeenAutoOfferLearnMore?: boolean;
  hasSeenMp2?: boolean;
}

export interface UpdateUserResponseBody {
  success: boolean;
}

export interface UpdateUserSMSPreferencesRequestBody {
  smsOptIn: boolean;
}

export interface UpdateUserSMSPreferencesResponseBody {
  success: boolean;
}

export interface UpdateInvestmentStrategyRequestBody {
  investmentTypes: InvestmentType[];
  rehabTypes: RehabType[];
  financialSources: FinancialSource[];
  numTransactionsLast12Month: number;
  numTransactionsNext12Month: number;
}

export interface SaveVestingEntityRequestBody {
  id?: string;
  name: string;
  type: string;
  /** @format date */
  dateOfIncorporation: string;
  ein: string;
  stateOfIncorporation: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
}

export enum VestingEntityType {
  SELF = 'SELF',
  LLC = 'LLC',
  LLP = 'LLP',
  CORP = 'CORP',
  SCORP = 'SCORP'
}

export interface VestingEntity {
  id: string;
  name: string;
  type: VestingEntityType;
  ein: string;
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
  /** @format date-time */
  incorporationDate: string;
  incorporationState: string;
}

export interface ListVestingEntitiesResponseData {
  vestingEntities: VestingEntity[];
}

export interface ListVestingEntitiesResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: ListVestingEntitiesResponseData;
}

export enum BuyBoxPropertyType {
  SingleFamilyResidence = 'Single Family Residence',
  Condos = 'Condos',
  MultiFam = 'Multi-Fam',
  MobileManufactured = 'Mobile / Manufactured',
  Land = 'Land',
  Other = 'Other'
}

export enum YesNoPreference {
  Yes = 'Yes',
  No = 'No',
  NoPreference = 'No Preference'
}

export enum BuyBoxOccupancy {
  Vacant = 'Vacant',
  OccupiedLeaseback = 'Occupied - Leaseback',
  OccupiedTenantsInPlace = 'Occupied - Tenants in Place',
  OccupiedLeaseInPlace = 'Occupied - Lease in Place'
}

export interface BuyBoxDto {
  purchasePriceMin:
    | '100000'
    | '200000'
    | '300000'
    | '400000'
    | '500000'
    | '600000'
    | '700000'
    | '800000'
    | '900000'
    | '1000000'
    | '1250000'
    | '1500000'
    | '1750000'
    | '2000000'
    | null;
  purchasePriceMax:
    | '100000'
    | '200000'
    | '300000'
    | '400000'
    | '500000'
    | '600000'
    | '700000'
    | '800000'
    | '900000'
    | '1000000'
    | '1250000'
    | '1500000'
    | '1750000'
    | '2000000'
    | null;
  finishedSqFtMin:
    | '750'
    | '1000'
    | '1250'
    | '1500'
    | '1750'
    | '2000'
    | '2250'
    | '2500'
    | '2750'
    | '3000'
    | '4000'
    | '5000'
    | null;
  finishedSqFtMax:
    | '750'
    | '1000'
    | '1250'
    | '1500'
    | '1750'
    | '2000'
    | '2250'
    | '2500'
    | '2750'
    | '3000'
    | '4000'
    | '5000'
    | null;
  lotSizeSqFtMin: '2000' | '4000' | '6000' | '8000' | '10000' | '20000' | '50000' | '100000' | null;
  lotSizeSqFtMax: '2000' | '4000' | '6000' | '8000' | '10000' | '20000' | '50000' | '100000' | null;
  bedroomsMin: '1' | '2' | '3' | '4' | '5' | null;
  bedroomsMax: '1' | '2' | '3' | '4' | '5' | null;
  bathroomsMin: '1' | '2' | '3' | '4' | '5' | null;
  bathroomsMax: '1' | '2' | '3' | '4' | '5' | null;
  yearBuiltMin:
    | '1900'
    | '1910'
    | '1920'
    | '1930'
    | '1940'
    | '1950'
    | '1960'
    | '1970'
    | '1980'
    | '1990'
    | '2000'
    | '2010'
    | '2020'
    | null;
  yearBuiltMax:
    | '1900'
    | '1910'
    | '1920'
    | '1930'
    | '1940'
    | '1950'
    | '1960'
    | '1970'
    | '1980'
    | '1990'
    | '2000'
    | '2010'
    | '2020'
    | null;
  propertyTypes: BuyBoxPropertyType[];
  hasHoa: YesNoPreference | null;
  hasSeptic: YesNoPreference | null;
  hasGarage: YesNoPreference | null;
  hasSolar: YesNoPreference | null;
  occupanciesAtClosing: BuyBoxOccupancy[] | null;
}

export interface GetBuyBoxResponseBody {
  success: boolean;
  data: BuyBoxDto;
}

export interface UpdateBuyBoxRequestBody {
  purchasePriceMin:
    | '100000'
    | '200000'
    | '300000'
    | '400000'
    | '500000'
    | '600000'
    | '700000'
    | '800000'
    | '900000'
    | '1000000'
    | '1250000'
    | '1500000'
    | '1750000'
    | '2000000'
    | null;
  purchasePriceMax:
    | '100000'
    | '200000'
    | '300000'
    | '400000'
    | '500000'
    | '600000'
    | '700000'
    | '800000'
    | '900000'
    | '1000000'
    | '1250000'
    | '1500000'
    | '1750000'
    | '2000000'
    | null;
  finishedSqFtMin:
    | '750'
    | '1000'
    | '1250'
    | '1500'
    | '1750'
    | '2000'
    | '2250'
    | '2500'
    | '2750'
    | '3000'
    | '4000'
    | '5000'
    | null;
  finishedSqFtMax:
    | '750'
    | '1000'
    | '1250'
    | '1500'
    | '1750'
    | '2000'
    | '2250'
    | '2500'
    | '2750'
    | '3000'
    | '4000'
    | '5000'
    | null;
  lotSizeSqFtMin: '2000' | '4000' | '6000' | '8000' | '10000' | '20000' | '50000' | '100000' | null;
  lotSizeSqFtMax: '2000' | '4000' | '6000' | '8000' | '10000' | '20000' | '50000' | '100000' | null;
  bedroomsMin: '1' | '2' | '3' | '4' | '5' | null;
  bedroomsMax: '1' | '2' | '3' | '4' | '5' | null;
  bathroomsMin: '1' | '2' | '3' | '4' | '5' | null;
  bathroomsMax: '1' | '2' | '3' | '4' | '5' | null;
  yearBuiltMin:
    | '1900'
    | '1910'
    | '1920'
    | '1930'
    | '1940'
    | '1950'
    | '1960'
    | '1970'
    | '1980'
    | '1990'
    | '2000'
    | '2010'
    | '2020'
    | null;
  yearBuiltMax:
    | '1900'
    | '1910'
    | '1920'
    | '1930'
    | '1940'
    | '1950'
    | '1960'
    | '1970'
    | '1980'
    | '1990'
    | '2000'
    | '2010'
    | '2020'
    | null;
  propertyTypes: BuyBoxPropertyType[];
  hasHoa?: YesNoPreference | null;
  hasSeptic?: YesNoPreference | null;
  hasGarage?: YesNoPreference | null;
  hasSolar?: YesNoPreference | null;
  occupanciesAtClosing?: BuyBoxOccupancy[] | null;
}

export interface CreatePropertyOfferRequestBody {
  propertySfId: string;
  offerAmount: number;
  isFinancingBridgeLending?: boolean;
  incrementAmount?: number;
  maxOfferAmount?: number;
  buyerName?: string;
}

export interface CreatePropertyOfferResponseBody {
  /** @format date */
  offerDate: string | null;
  /** @format date */
  createdDate: string | null;
  offerAmount: number | null;
  updatedAmount?: number;
  autoOfferMaxed?: boolean;
}

export interface SetPropertyFavoriteStatusRequestBody {
  isFavorite: boolean;
}

export enum OfferDashboardSortBy {
  PROPERTYSTATUS = 'PROPERTYSTATUS',
  PRICE = 'PRICE',
  OFFERDUEDATE = 'OFFERDUEDATE',
  CLOSEOFESCROW = 'CLOSEOFESCROW',
  BEDROOMS = 'BEDROOMS',
  BATHROOMS = 'BATHROOMS',
  SQUAREFEET = 'SQUAREFEET',
  YEARBUILT = 'YEARBUILT',
  OFFERAMOUNT = 'OFFERAMOUNT',
  OFFERDATE = 'OFFERDATE'
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum OfferStatus {
  ACTIVE = 'ACTIVE',
  WON = 'WON',
  LOST = 'LOST',
  PENDING = 'PENDING'
}

export enum PropertyStatus {
  COMINGSOON = 'COMING SOON',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  ALL = 'ALL'
}

export interface ListingListEntryOpportunityApiModel {
  purchasePrice?: number | null;
  closingDate?: string | null;
}

export interface ListingOfferApiModel {
  offerAmount: number;
  /** @format date-time */
  createdDate: string;
  autoOfferId?: string | null;
  isAutoOffer: boolean;
  hasSundaeFinancing: boolean;
}

export interface ListingListWithOfferEntryApiModel {
  id: string;
  isFavorite: boolean;
  propertyId: string;
  publishId: string;
  spId: string | null;
  territoryId: string;
  propertyStatus: PropertyStatus;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  isHighestAndBestProperty: boolean;
  isStandardAuctionProperty: boolean;
  isExpressProperty: boolean;
  isExtendedListing: boolean;
  buyBoxMatch: number;
  /** @format date */
  projectedCloseDate: string | null;
  price: number;
  longitude: number | null;
  latitude: number | null;
  /** @format date */
  closeOfEscrow: string;
  bedrooms: number;
  bathrooms: number;
  squareFeet: string;
  yearBuilt: number;
  opportunity?: ListingListEntryOpportunityApiModel;
  image?: string | null;
  imageThumb?: string | null;
  offerAccepted?: boolean | null;
  offers: ListingOfferApiModel[];
  autoOfferId?: string | null;
  offerAmount?: number;
  autoOfferIncrementAmount?: number | null;
  autoOfferMaxOfferAmount?: number | null;
  autoOfferIsEnabled?: boolean;
  offerDate?: string | null;
  offerCreatedDate?: string | null;
  offerStatus?: OfferStatus | null;
  depositAmount: number;
  buyerPremium: number;
}

export interface ListPropertyOffersResponseBody {
  propertyOffers: ListingListWithOfferEntryApiModel[];
  totalActiveOffers: number;
  totalActiveOfferSum: number;
  totalAmountDue: number;
}

export enum SavedOpportunitiesSortBy {
  PROPERTYSTATUS = 'PROPERTYSTATUS',
  PRICE = 'PRICE',
  OFFERDUEDATE = 'OFFERDUEDATE',
  CLOSEOFESCROW = 'CLOSEOFESCROW',
  BEDROOMS = 'BEDROOMS',
  BATHROOMS = 'BATHROOMS',
  SQUAREFEET = 'SQUAREFEET',
  YEARBUILT = 'YEARBUILT',
  OFFERAMOUNT = 'OFFERAMOUNT',
  OFFERDATE = 'OFFERDATE'
}

export interface ListSavedOpportunitiesResponseBodyItem {
  id: string;
  isFavorite: boolean;
  propertyId: string;
  publishId: string;
  spId: string | null;
  territoryId: string;
  propertyStatus: PropertyStatus;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  isHighestAndBestProperty: boolean;
  isStandardAuctionProperty: boolean;
  isExpressProperty: boolean;
  isExtendedListing: boolean;
  buyBoxMatch: number;
  /** @format date */
  projectedCloseDate: string | null;
  price: number;
  longitude: number | null;
  latitude: number | null;
  /** @format date */
  closeOfEscrow: string;
  bedrooms: number;
  bathrooms: number;
  squareFeet: string;
  yearBuilt: number;
  opportunity?: ListingListEntryOpportunityApiModel;
  image?: string | null;
  imageThumb?: string | null;
  offerAccepted?: boolean | null;
  offers: ListingOfferApiModel[];
  autoOfferId?: string | null;
  offerAmount?: number;
  autoOfferIncrementAmount?: number | null;
  autoOfferMaxOfferAmount?: number | null;
  autoOfferIsEnabled?: boolean;
  offerDate?: string | null;
  offerCreatedDate?: string | null;
  offerStatus?: OfferStatus | null;
  depositAmount: number;
  buyerPremium: number;
}

export enum ListingSortBy {
  PROPERTYSTATUS = 'PROPERTYSTATUS',
  PRICE = 'PRICE',
  PROJECTEDCLOSEDATE = 'PROJECTEDCLOSEDATE',
  CLOSEOFESCROW = 'CLOSEOFESCROW',
  BEDROOMS = 'BEDROOMS',
  BATHROOMS = 'BATHROOMS',
  SQUAREFEET = 'SQUAREFEET',
  YEARBUILT = 'YEARBUILT',
  DISTANCE = 'DISTANCE',
  PURCHASEPRICE = 'PURCHASEPRICE',
  CLOSINGDATE = 'CLOSINGDATE'
}

export interface ListPropertiesResponseBodyItem {
  id: string;
  isFavorite: boolean;
  propertyId: string;
  publishId: string;
  spId: string | null;
  territoryId: string;
  propertyStatus: PropertyStatus;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  isHighestAndBestProperty: boolean;
  isStandardAuctionProperty: boolean;
  isExpressProperty: boolean;
  isExtendedListing: boolean;
  buyBoxMatch: number;
  /** @format date */
  projectedCloseDate: string | null;
  price: number;
  longitude: number | null;
  latitude: number | null;
  /** @format date */
  closeOfEscrow: string;
  bedrooms: number;
  bathrooms: number;
  squareFeet: string;
  yearBuilt: number;
  opportunity?: ListingListEntryOpportunityApiModel;
  image?: string | null;
  imageThumb?: string | null;
  distance: number;
  numberOfInvestors?: number | null;
  numberOfOffers?: number | null;
}

export interface ListingDetailDtoImageApiModel {
  image: string;
  imageThumb: string;
}

export interface PropertyDetailsResponseBody {
  id: string;
  isFavorite: boolean;
  propertyId: string;
  publishId: string;
  spId: string | null;
  territoryId: string;
  propertyStatus: PropertyStatus;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  isHighestAndBestProperty: boolean;
  isStandardAuctionProperty: boolean;
  isExpressProperty: boolean;
  isExtendedListing: boolean;
  buyBoxMatch: number;
  /** @format date */
  projectedCloseDate: string | null;
  price: number;
  longitude: number | null;
  latitude: number | null;
  /** @format date */
  closeOfEscrow: string;
  bedrooms: number;
  bathrooms: number;
  squareFeet: string;
  yearBuilt: number;
  opportunity?: ListingListEntryOpportunityApiModel;
  image?: string | null;
  imageThumb?: string | null;
  matterPortUrl?: string | null;
  description?: string | null;
  investorShowingDetails?: string | null;
  propertyType?: 'Condo' | 'Manufactured home' | 'Multifamily' | 'PUD' | 'SFR' | 'Other' | null;
  lotSize: number;
  offers: ListingOfferApiModel[];
  autoOfferId?: string | null;
  offerAmount?: number;
  autoOfferIncrementAmount?: number | null;
  autoOfferMaxOfferAmount?: number | null;
  autoOfferIsEnabled?: boolean;
  sellerInPossession?: string | null;
  occupancy?: string | null;
  homeInspection?: string | null;
  prelimTitleReport?: string | null;
  floorPlan?: string | null;
  videoTourUrl?: string | null;
  disclosuresReport?: string | null;
  sewerInspection?: string | null;
  thirdPartyInspectionOrdered: boolean;
  prelimTitleOrdered: boolean;
  floorPlanOrdered: boolean;
  disclosuresOrdered: boolean;
  septicReportOrdered: boolean;
  hasSolar: 'Yes' | 'No';
  hasSeptic: 'Yes' | 'No';
  investorShowings: 'Yes' | 'No';
  hasHOA: 'Yes' | 'No';
  depositAmount: number;
  buyerPremium: number;
  propertyOwnerName?: string | null;
  images: ListingDetailDtoImageApiModel[];
}

export interface SupportUser {
  name: string;
  phone: string;
  email: string;
  photoUrl: string;
  licenses: string[];
  isPrimary: boolean;
}

export interface GetPropertySupportResponseBody {
  success: boolean;
  data?: SupportUser;
}

export interface PhysicalMailUnsubscribeRequestBody {
  fullAddress: string;
}

export interface PhysicalMailUnsubscribeResponseBody {
  success: boolean;
}

export interface DisableAutoOffersByIdResponseBody {
  success: boolean;
}

export interface TerritoryWithPropertyCounts {
  sfId?: string;
  territoryId?: string;
  name?: string;
  latitude?: number;
  longitude?: number;
  isActive?: boolean;
  ownerId?: string;
  state?: string;
  stateShort?: string;
  isInActive?: boolean;
  isComingSoon?: boolean;
  investorAdvisorId?: object;
  numberOfActiveProperties?: number;
  numberOfPendingProperties?: number;
  numberOfComingSoonProperties?: number;
}

export interface StateTerritories {
  name: string;
  abbr: string;
  regions: TerritoryWithPropertyCounts[];
}

export interface TerritoryInfoResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: StateTerritories[];
}

export interface FindTerritoryAutoCompleteResponseBodyItem {
  sfId?: string;
  id: string;
  territoryId?: string;
  name?: string;
  latitude?: number;
  longitude?: number;
  isActive: boolean;
  ownerId?: string;
  state?: string;
  stateShort?: string;
  investorAdvisorId?: object;
}

export interface ZipCodeModel {
  isCovered: boolean;
  territoryName?: string;
  territoryId?: string;
  territoryPhoneNumber?: string;
}

export interface GetZipCodeResponseBody {
  success: boolean;
  data: ZipCodeModel;
}

export interface ConfirmMarketSelectionRequestBody {
  primaryMarketId: string;
  secondaryMarketIds: string[];
}

export interface ConfirmMarketSelectionResponseBody {
  success: boolean;
}

export interface UpdateActiveMarketsRequestBody {
  primaryMarketId: string;
  secondaryMarketIds: string[];
}

export interface UpdateActiveMarketsResponseBody {
  success: boolean;
}

export interface UpdateInterestedMarketsRequestBody {
  interestedMarketIds: string[];
}

export interface UpdateInterestedMarketsResponseBody {
  success: boolean;
}

export interface CreateSellerLeadRequestBody {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  smsOptOut: boolean;
  /** @pattern ^\d{12,13}$ */
  agreedToTermsAndConditionsOn: string;
  agreedToTermsAndConditions: boolean;
  utmCampaign?: string;
  utmTerm?: string;
  utmSource?: string;
  utmContent?: string;
  utmMedium?: string;
  utmReferrer?: string;
  utmPlacement?: string;
  landingPage?: string;
  gclId?: string;
  fbclId?: string;
  adDescriptor?: string;
  unbounceLandingPageVariant?: string;
  unbouncePageId?: string;
  unbouncePageVariant?: string;
  unbounceSubmissionDate?: number;
  unbounceSubmissionTime?: string;
  unbounceGuid?: string;
}

export interface CreateSellerLeadResponseData {
  isOOM: boolean;
  id: string;
  territoryName: string;
  territoryPhoneNumber: string;
}

export interface CreateSellerLeadResponseBody {
  success: boolean;
  data: CreateSellerLeadResponseData;
}

export interface UpdateSellerLeadRequestBody {
  timelineToSell?: string;
  whyAreYouSelling?: string;
  isListedOnMls?: boolean;
  howDidSellerHearAboutUs?: string;
}

export interface CreateSellerQuestionAnswerRequestBody {
  questionId: string;
  answer: string;
  totalAnswerWeight: number;
}

export interface SellerQuestionAnswer {
  id: string;
}

export interface CreateSellerQuestionAnswerResponseBody {
  success: boolean;
  data: SellerQuestionAnswer;
}

export interface Seller {
  introCallBookingUrl: string;
  territoryName: string;
  territoryId: string;
}

export interface FindSellerByIdResponseBody {
  success: boolean;
  data: Seller;
}

export interface SellerPropertyAddress {
  streetAddress: string;
  city: string;
  latitude: number;
  longitude: number;
  state: string;
  zip: string;
  status: string;
  uuid: string;
}

export interface SundaeContactInfo {
  name?: string;
  phone: string;
  email?: string;
  mobilePhone?: string;
  pictureUrl?: string;
  title: string;
  territory: string;
  territoryId: string;
  territorySFId: string;
}

export interface IntroCall {
  schedulingLink?: string;
  rescheduleLink?: string;
  cancelationLink?: string;
  /** @format date-time */
  startDateTime?: string;
  missedCall: boolean;
}

export interface Photo {
  url: string;
  internalId: string;
}

export interface OfferBuyer {
  firstName: string;
  lastName: string;
  id: string;
}

export interface Offer {
  amount: number;
  /** @format date-time */
  offerDate: string;
  buyer: OfferBuyer;
}

export interface Stat {
  total: number;
  totalForLast12Hours: number;
}

export interface OfferStats {
  views: Stat;
  favorites: Stat;
  bids: Stat;
  bidders: Stat;
}

export interface OfferRange {
  low: number;
  high: number;
}

export interface OfferInfo {
  offers: Offer[];
  stats: OfferStats;
  range: OfferRange;
}

export interface MarketplaceListing {
  /** @format date-time */
  auctionStartDateTime: string;
  /** @format date-time */
  auctionEndDateTime: string;
}

export interface SellerProfile {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  preferredName: string;
  userId: string;
}

export interface SellerReportLinks {
  matterportUrl: string;
  inspectionReportUrl: string;
}

export interface SellerListingInfo {
  hasSignedMpa: boolean;
  isMpaReady: boolean;
  /** @format date-time */
  psaSignedOn?: string;
  hasSignedPsa: boolean;
  /** @format date-time */
  projectedClosingDate: string;
  property: SellerPropertyAddress;
  sundaeContactInfo: SundaeContactInfo;
  introCall: IntroCall;
  photos: Photo[];
  offerInfo: OfferInfo;
  marketplaceListing: MarketplaceListing;
  myProfile: SellerProfile;
  reportLinks: SellerReportLinks;
}

export interface FindSellerListingInfoByLeadIdResponseBody {
  success: boolean;
  data: SellerListingInfo;
}

export interface SellerTerritoryInfoResponseBody {
  message?: string;
  errorCode?: number | string;
  errorType?: string;
  success?: boolean;
  originalError?: object;
  status?: number;
  data: TerritoryWithPropertyCounts;
}

export interface SellerMpaSignatureResponse {
  mpaSignatureLink: string;
}

export interface FindMpaLinkByUuidResponseBody {
  success: boolean;
  data: SellerMpaSignatureResponse;
}

export interface Market {
  type: string;
  territory: string;
  name: string;
}

export interface MarketExpert {
  photo: string;
  phone: string;
  name: string;
  email: string;
}

export interface License {
  id: string;
  type: string;
  state: string;
  license: string;
}

export interface Address {
  zip: string;
  street: string;
  state: string;
  city: string;
}

export interface Brokerage {
  id?: string;
  name: string;
  license: string;
  ein: string;
  broker: string;
  phone: string;
  email: string;
  address: Address;
}

export interface SalesforceSrnContactResponse {
  referralLink: string;
  markets: Market[];
  marketExpert: MarketExpert;
  licenses: License[];
  brokerages: Brokerage[];
  address: Address;
  accountStatus: string;
}

export type MarketplaceAccountMarket = object;

export interface BasicUser {
  id: string;
  marketplaceAccount?: MarketplaceAccountMarket;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
  cognitoUserId: string;
  cognitoUsername: string;
  cognitoPhoneVerified: boolean;
  cognitoEmailVerified: boolean;
  preferredTimezone: string;
  portalStatus: 'ALLOWED' | 'NOT_ALLOWED';
  marketplaceRegistrationStatus?: string;
  srnRegistrationStatus: string;
  sellerRegistrationStatus?: string;
  requestedNewEmail?: string;
  requestedNewPhone?: string;
  ssoUser: boolean;
  marketplaceBuyBoxes?: any[][];
  marketplaceFlags?: any[][];
  marketplaceMarkets?: any[][];
  marketplaceSubscriptionPeriods?: any[][];
}

export interface SrnUserResponseBody {
  account: SalesforceSrnContactResponse;
  user: BasicUser;
}

export interface SrnUpdateUserRequestBody {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

export interface OptionalAddress {
  zip: string;
  street: string;
  state: string;
  city: string;
}

export interface SrnUpdateAccountRequestBody {
  referralLink?: string;
  markets?: Market[];
  marketExpert?: MarketExpert;
  licenses?: License[];
  brokerages?: Brokerage[];
  address?: OptionalAddress;
  accountStatus?: string;
}

export interface SrnLeadResponseBody {
  data: string[];
}

export interface SubmitReferralRequestBody {
  street: string;
  city: string;
  state: string;
  zip: string;
}

export interface SalesforceSrnCreateLeadResponse {
  id: string;
  error: string;
}

export interface UpdateReferralRequestBody {
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  firstName?: string;
  lastName?: string;
  listingStatus?: string;
  closingTimeline?: string;
  reasonForSelling?: string;
  levelOfRepairs?: string;
  additionalInfo?: string;
  product?: string;
}

export interface SalesforceSrnUpdateLeadResponse {
  id: string;
  error: string;
}

export interface MediaUploadImagesRequestBody {
  relatedSfid: string;
}

export interface Image {
  url: string;
  sharinpixId: string;
}

export interface MediaUploadImagesResponseBody {
  success: boolean;
  images: Image[];
}

export interface MediaDeleteImagesRequestBody {
  images: Image[];
  relatedSfid: string;
}

export interface MediaDeleteImagesResponseBody {
  success: boolean;
}
