import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

import thankYouIcon from '../../../public/images/thankyou-image.png';
import { useModalByName } from '../../hooks/useModalByName';
import { SubmitButton } from '../SubmitButton';

import styles from './ResetPassword.module.scss';

interface SuccessComponentProps {
  loginUrl?: string;
}

export const SuccessComponent = ({ loginUrl }: SuccessComponentProps) => {
  const router = useRouter();
  const [, showLoginModal] = useModalByName('LoginModal');

  return (
    <div className={classNames(styles.formContent, styles.successContainer)}>
      <div className={styles.mainHeader}>
        <img className="mb-5" width="50%" src={thankYouIcon.src} alt="" />
        <div className={styles.title}>Your password has been reset successfully.</div>
      </div>
      <SubmitButton
        onClick={() => {
          if (loginUrl) {
            router.push(loginUrl);
          } else {
            showLoginModal();
          }
        }}
        className=" mt-4"
      >
        Back to Login
      </SubmitButton>
    </div>
  );
};
