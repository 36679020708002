import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { completeSignup } from '../../../api/auth';
import { getErrorCodeFromError, isApiErrorWithErrorCode } from '../../../api/user';
import { getDataObject } from '../../../helpers/signUpHelpers';
import { selectReferralInfo } from '../../../store/misc';
import { SignupClosingProps, SignUpFormData } from '../../../types/onBoarding';
import { setReferralPropertyId } from '../../../utils/localStorage.utils';
import { Checkbox } from '../../Checkbox';
import { SubmitButton } from '../../SubmitButton';
import SupportLink from '../../SupportLink';
import PreviousButton from '../PreviousButton';
import styles from '../SignUp.module.scss';

import ErrorTooltip from './ErrorTooltip';

interface TermsProps extends SignupClosingProps {
  setSuccess: (success: boolean) => void;
  setIsUserIdentityVerified: (isVerified: boolean) => void;
  setIsPrimaryMarketActive: (isActive: boolean) => void;
}

function Terms({
  stepNavigate,
  initialFormData,
  updateFormData,
  setSuccess,
  setIsUserIdentityVerified,
  setIsPrimaryMarketActive
}: TermsProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [termsAndConditionsAcceptedOn, setTermsAndConditionsAcceptedOn] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showReadTermsMessage, setShowReadTermsMessage] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [loader, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{ type: string } | null>(null);
  const [scrollHeight, setScrollHeight] = useState(500);
  const [offsetHeight, setOffsetHeight] = useState(500);

  const termContent = useRef<HTMLDivElement>(null);

  const referral = useSelector(selectReferralInfo);

  useEffect(() => {
    setTermsAndConditionsAcceptedOn(new Date().getTime() + '');
  }, [isChecked]);

  useEffect(() => {
    setScrollHeight(window.document.getElementById('scrollParentDiv')?.scrollHeight ?? 500);
    setOffsetHeight(window.document.getElementById('scrollParentDiv')?.offsetHeight ?? 500);
  }, []);

  useEffect(() => {
    showReadTermsMessage && termContent?.current?.focus();
  }, [showReadTermsMessage]);

  const saveData = async (formData: SignUpFormData) => {
    setIsLoading(true);
    setErrorMessage(null);

    const userAllData = { ...getDataObject(formData), referralToken: referral?.referralToken };

    await completeSignup(userAllData)
      .then((response) => {
        setIsLoading(false);
        setSuccess(true);
        setIsUserIdentityVerified(response.data.isUserVerified);
        setIsPrimaryMarketActive(response.data.isPrimaryMarketActive);

        const { referralPropertyId } = response.data;

        if (referralPropertyId) {
          setReferralPropertyId(referralPropertyId);
        }
      })
      .catch((error: unknown) => {
        setIsLoading(false);

        if (
          isApiErrorWithErrorCode(error) &&
          getErrorCodeFromError(error) === 'invalid_signup_company'
        ) {
          stepNavigate('back');
        } else {
          setErrorMessage({ type: 'defaultError' });
        }
      });
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    updateFormData({
      ...initialFormData,
      termsAndConditionsAcceptedOn: termsAndConditionsAcceptedOn
    });

    if (isChecked) {
      await saveData({
        ...initialFormData,
        termsAndConditionsAcceptedOn: termsAndConditionsAcceptedOn
      });
    }
  };

  const handleScroll = (e: React.ChangeEvent<EventTarget>) => {
    const target = e.target as HTMLDivElement;
    setShowReadTermsMessage(false);

    if (target.scrollTop >= scrollHeight - offsetHeight - 30) {
      setIsScrolled(true);
    }
  };

  const handleBack = () => {
    stepNavigate('back');
  };

  const hasReadAllTerms = () => {
    const scrollParentDiv = window.document.getElementById('scrollParentDiv');

    return isScrolled || scrollParentDiv?.scrollHeight === scrollParentDiv?.clientHeight;
  };

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.title}>Terms & Conditions</div>
        <span className={styles.subTitle}>
          Please review and scroll through the Sundae marketplace terms and conditions to proceed
        </span>
      </div>
      <div className={classNames(styles.formContainer, styles.termpage)}>
        <div className={styles.termsContainer}>
          <div
            id="scrollParentDiv"
            ref={termContent}
            className={styles.termsText}
            onScroll={handleScroll}
            tabIndex={0}
          >
            <p>
              These Terms and Conditions (these &quot;Terms&quot;) apply to all Sundae (Sundae, Inc.
              and its affiliates and subsidiaries, including but not limited to, Sundae Funding
              Inc., a licensed real estate broker wholly owned by Sundae Inc.)(collectively
              &quot;Sundae&quot;) customers who request access to information about properties
              listed on the Sundae Marketplace. These Terms are in addition to, and not in lieu of,
              Sundae&rsquo;s Terms of Service, and by requesting access as contemplated herein, you
              reaffirm your agreement to such Terms of Service, together with all exhibits, addenda
              and other materials that are a part thereof or incorporated therein. Without limiting
              the generality of the foregoing, you expressly agree that these Terms are subject to
              Sections 8, 9, 10, 12 and 14 of the Terms of Service, and that such provisions are
              incorporated into these Terms, mutatis mutandis. You further expressly agree and
              acknowledge that you have reviewed and understand Sundae&rsquo;s{' '}
              <a href="https://sundae.com/privacy-policy/" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>{' '}
              and the Licensing and Disclosures provided by Sundae on its website and incorporated
              by reference as a part of the{' '}
              <a href="https://sundae.com/terms-of-service/" target="_blank" rel="noreferrer">
                Terms of Service
              </a>
              .
            </p>
            <h4 className="mt-2">1. User Accounts</h4>
            <p>
              If you are requesting access to information about properties listed on the Sundae
              Marketplace (including our mailing and other distribution lists) on behalf of yourself
              only, this agreement is between Sundae and you, personally. If you are requesting
              access on behalf of your employer, as an independent contractor on behalf of your
              contracting principal, or as an agent for any entity, this agreement is between
              Sundae, you personally, and your company. In that case, &quot;you&quot; means you and
              your company, unless context requires otherwise. By requesting access as contemplated
              herein, you represent and warrant that you are duly authorized to represent your
              company and execute this Agreement on its behalf.
            </p>
            <p>
              In order to access some features of the Marketplace, you will have to create a login
              account. When creating your login account, you represent and warrant to us that the
              information you provide is accurate and complete in all respects. All registrations
              must be made by you, personally. Accounts registered by “bots” or other automated
              methods are not permitted under any circumstances. You may never use another&rsquo;s
              login account without permission, and may not permit anyone else to use your login
              account. Single login accounts shared by multiple persons are not permitted under any
              circumstances. You may register multiple login accounts by contacting us.
            </p>
            <p>
              You agree that you will be solely responsible for all activity that occurs on your
              account, whether or not authorized by you. Accordingly, it is extremely important that
              you engage in activity consistent with all laws, including, but not limited to,
              anti-spam and data privacy laws and regulations. Further, you are obligated to keep
              your password secure and you agree to notify us immediately in the event you become
              aware of, or suspect, any breach of security or unauthorized use of your account.
            </p>
            <h4 className="mt-2">2. Non-Circumvention</h4>
            <p>
              Sundae&rsquo;s Marketplace provides customers with the opportunity to purchase
              distressed real property assets on its web platform and through its network (the
              &quot;Assets&quot;), which Assets Sundae has spent considerable resources to locate
              and make available for purchase. Therefore, Customer agrees not to contact, directly
              or indirectly, the owner of any Asset (or any agent or representative thereof) for a
              period of one (1) year following the date when (i) the asset is listed for sale on
              Sundae&rsquo;s website; or (ii) Sundae otherwise informs the Customer of the
              Asset&rsquo;s availability for purchase, whichever is later.
            </p>
            <p>
              If a seller reports to Sundae that you have contacted them directly by any means,
              including but not limited to phone, email, or in-person at their property, you will be
              immediately removed from the web platform.
            </p>
            <p>
              In the event that you purchase an Asset, within one (1) year following the date when
              (i) the asset is listed for sale on Sundae&rsquo;s website; or (ii) Sundae otherwise
              informs the Customer of the Asset&rsquo;s availability for purchase, whichever is
              later, you agree that Sundae is entitled to compensation in connection with the
              purchase, and agree that you shall pay Sundae a commission of five percent (5%) of the
              purchase price of the Asset (&quot;Broker Commission&quot;). The Broker Commission is
              due upon the sale of the Asset, and you agree that Sundae shall be entitled to make a
              Demand to the escrow company and/or closing attorney (&quot;Closing Agent&quot;) and
              that Closing Agent is authorized and required to pay the Broker Commission to Sundae
              at that time. You will also be immediately removed Sundae&rsquo;s Marketplace without
              the possibility of reinstatement. This ban from our platform applies to any future
              business entity that you are a member of.
            </p>
            <p>
              You hereby understand and agree that if Sundae discovers that you have violated this
              section of the Agreement, your access to Sundae&rsquo;s Marketplace shall be
              immediately revoked with or without notice, and without the possibility of
              reinstatement. This ban from our platform applies to you individually as well as to
              any individuals and/or business entities with which you are affiliated. The
              determination as to affiliation shall be in Sundae&rsquo;s sole discretion.
            </p>
            <h4 className="mt-2">3. Confidentiality</h4>
            <p>
              Customer understands that by accessing the Sundae Marketplace to view investment
              properties (the &quot;Purpose&quot;), Customer may view or receive non-public
              information, which could reasonably be inferred to be confidential or proprietary in
              nature. &quot;Confidential Information&quot; means any information or materials
              disclosed by Sundae to Customer that: (i) if disclosed in writing or in the form of
              tangible materials, is marked &quot;confidential&quot; or &quot;proprietary&quot; at
              the time of such disclosure; (ii) if disclosed orally or by visual presentation, is
              identified as &quot;confidential&quot; or &quot;proprietary&quot; at the time of such
              disclosure, and is summarized in a writing sent by Sundae to Customer within thirty
              (30) days after any such disclosure; or (iii) due to its nature or the circumstances
              of its disclosure, a person exercising reasonable business judgment would understand
              to be &quot;confidential&quot; or &quot;proprietary&quot; or &quot;personally
              identifiable information,&quot; including any information relating to Assets or other
              customers that could potentially be used to identify a particular person or company.
              Customer agrees: (i) to maintain all Confidential Information in strict confidence;
              (ii) not to disclose Confidential Information to any third parties; and (iii) not to
              use any Confidential Information for any purpose except for the Purpose. Customer may
              disclose Confidential Information to its employees and consultants who have a bona
              fide need to know such Confidential Information for the Purpose, but solely to the
              extent necessary to pursue the Purpose, and for no other purpose; provided, that each
              such employee and consultant first executes a written agreement (or is otherwise
              already bound by a written agreement) that contains use and nondisclosure restrictions
              at least as protective of the Confidential Information as those set forth herein. All
              Confidential Information remains the sole and exclusive property of Sundae. Customer
              acknowledges and agrees that nothing in this Agreement will be construed as granting
              any rights to Customer, by license or otherwise, in or to any Confidential Information
              of Sundae, or any patent, copyright or other intellectual property or proprietary
              rights of Sundae, except as specified here. Customer acknowledges that the
              unauthorized use or disclosure of any Confidential Information would cause Sundae to
              incur irreparable harm and significant damages, the degree of which may be difficult
              to ascertain. Accordingly, Customer agrees that Sundae will have the right to obtain
              immediate equitable relief to enjoin any unauthorized use or disclosure of
              Confidential Information, in addition to any other rights or remedies that it may have
              at law or otherwise.
            </p>
            <h4 className="mt-2">4. Use of Certain Service Providers</h4>
            <p>
              To ensure a timely and efficient closing, seller and buyer may use certain Sundae
              Preferred vendors, service or product providers, whether referred by Sundae or
              selected by buyer, seller, or other person including but not limited to escrow, title,
              property insurance, home inspection, bridge or rental mortgage loan and resale
              brokerage (&quot;Service Providers&quot;). In the event that Customer obtains the
              right to purchase an Asset in the Sundae network, the Asset&rsquo;s purchase and sale
              agreement (&quot;PSA&quot;) will identify these Service Providers.
            </p>
            <p>
              Sundae does not guarantee the performance of any Service Providers. Buyer and Seller
              are not required to use any Service Providers referred by, and/or affiliated with,
              Sundae as a condition of the sale of the subject Asset, and may select any providers
              of their own choosing. The fees charged by these Service Providers will be disclosed
              on or before the close of escrow. Customer hereby assumes the obligation to
              investigate the fees charged by any Service Provider identified in the PSA before
              agreeing to assume Sundae&rsquo;s obligations under the PSA. If and when applicable,
              Sundae may advance fees to Service Providers on behalf, or for the benefit of, buyer,
              and buyer may reimburse Sundae for the same at the close of the transaction.
            </p>
            <h4 className="mt-2">5. Agency</h4>
            <p>
              Customer agrees and acknowledges that Sundae Inc. is acting on its own behalf, and is
              not acting as Customer&rsquo;s agent or broker in connection with the sale or purchase
              of an Asset, or in the brokering or arranging of any mortgage loan to Customer to
              facilitate the acquisition of an Asset.
            </p>
            <p>
              Notwithstanding the above, Sundae&rsquo;s fully-owned subsidiary, Sundae Funding Inc.,
              a licensed real estate broker, may establish single agency, dual agency, transaction
              broker, and/or other relationships as permitted by applicable law. For further details
              on your relationship or potential relationships with Sundae and its affiliated
              companies, including duties and potential conflicts of interest, you acknowledge you
              have reviewed and understand our regulatory{' '}
              <a href="https://sundae.com/disclosures/" target="_blank" rel="noreferrer">
                landing page
              </a>
              . Customer agrees and acknowledges Customer has sought Customer&rsquo;s own legal and
              professional advice before agreeing to these Terms.
            </p>
            <h4 className="mt-2">6. Lending</h4>
            <p>
              In addition to real estate brokerage services, Sundae Funding, Inc. also offers, makes
              or arranges loans in connection with real property. The loans are for business
              purposes only and not for personal, family or household use. Any lending or loan
              products offered on the Sundae Marketplace, or outside the scope thereof, are only
              applicable in California, Colorado, Georgia, Florida, Texas and Washington. Nothing
              represented in any marketing material distributed by Sundae Funding, Inc. shall be
              considered a commitment to lend. All potential loans are subject to underwriting and
              due diligence until a definitive loan agreement is signed. The rates, costs, and fees
              advertised do not apply to all loans made or negotiated, and loans made or negotiated
              by Sundae Funding, Inc. may have higher interest rates or additional costs and fees.
            </p>
            <p>
              Sundae Funding, Inc. is a licensed finance lender with the California Department of
              Financial Protection and Innovation, CFL #60DBO-122336. Loans made or arranged
              pursuant to a California Financing Law license.
            </p>
            <h4 className="mt-2">7. Business Purpose Representation</h4>
            <p>
              Customer represents and warrants that it is in the business of investing in
              residential real estate, and that Customer has no intention to, nor will it ever,
              occupy any Asset acquired through Sundae&rsquo;s network. Neither Customer, nor any
              member, officer, director, employee, agent, or any family member of the foregoing,
              will occupy the Asset or acquire the Asset for personal, family, or household use.
            </p>
            <h4 className="mt-2">8. Telephone Consent Contract and Recording Policy</h4>
            <p>
              By providing us with a telephone number for a mobile device, including a number that
              you later convert to a mobile device number, you are expressly consenting to receiving
              communications—including but not limited to prerecorded or artificial voice message
              calls, text messages, and calls or text messages made by an automatic telephone
              dialing system, and any other method of communication permitted by law—from us and our
              affiliates and agents at that number, about any product or services offered by Sundae.
              This express consent applies to each such telephone number that you provide to us now
              or in the future. Calls and messages may incur access fees from your mobile services
              provider. Your express consent applies to communications from us to a landline as well
              as a mobile, wireless, or similar device, even if you are charged fees by your service
              provider for the communications. You understand that you need not provide this consent
              as a condition of obtaining goods or services from Sundae, and that you may decline to
              provide or revoke your consent at any time by emailing <SupportLink /> or by any other
              method that reasonably ensures we receive your revocation.
            </p>
            <p>
              By providing us with a telephone number, you agree that Sundae may record any
              telephone conversation with you (or any another individual) during any telephone call
              to or from that number for training purposes, whether or not the call was initiated by
              you, and whether or not Sundae disclosed the fact that the call was recorded during
              the call.
            </p>
            <h4 className="mt-2">9. No Warranties; Limitation of Liability</h4>
            <p>
              YOU AGREE THAT YOUR USE OF THE MARKETPLACE SHALL BE AT YOUR SOLE RISK, AND THAT ALL
              CONTENT AND SERVICES ARE PROVIDED TO YOU “AS IS.” TO THE FULLEST EXTENT PERMITTED BY
              LAW, WE, OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES,
              EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICE AND YOUR USE THEREOF. WE MAKE NO
              WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
              MARKETPLACE&rsquo;S CONTENT OR THE CONTENT OF ANY SITES LINKED TO THERETO.
            </p>
            <p>
              IN NO EVENT SHALL WE BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR
              OTHER LEGAL THEORY (I) FOR ANY LOST PROFITS OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE
              OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, SUBSTITUTE GOODS OR SERVICES (HOWEVER
              ARISING) OR (II) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) $100. THE
              FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW
              IN THE APPLICABLE JURISDICTION.
            </p>
          </div>
        </div>

        <ErrorTooltip
          isOpen={showReadTermsMessage}
          target="scrollParentDiv"
          message={'Please read the entire terms'}
        />
        <div className="d-flex align-items-center mt-4" id="agreement">
          <Checkbox
            onChange={(e) =>
              hasReadAllTerms() ? setIsChecked(e.target.checked) : setShowReadTermsMessage(true)
            }
            label={
              <>
                I agree to Sundae&rsquo;s Customer Terms & Conditions,{' '}
                <a
                  href="https://sundae.com/privacy-policy"
                  className="text-dark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <u>Privacy Policy</u>
                </a>
                {' and '}
                <a
                  href="https://sundae.com/terms-of-service"
                  className="text-dark"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <u>Terms of Service</u>
                </a>
                {'.'}
              </>
            }
            checked={isChecked}
          />

          <ErrorTooltip
            isOpen={isSubmitting && !isChecked}
            target="agreement"
            message="Read all of the Terms & Conditions. Click to agree."
          />
        </div>
      </div>
      {errorMessage && errorMessage.type === 'defaultError' && (
        <div className="alert alert-danger fade show" role="alert">
          We are unable to create this account - please contact us at <SupportLink /> and we will be{' '}
          in touch soon.
        </div>
      )}
      <PreviousButton handleBack={handleBack}>
        <SubmitButton
          className={classNames('mt-3', 'mt-sm-0')}
          type="button"
          onClick={() => handleSubmit()}
          disabled={!isChecked}
        >
          {loader ? <Spinner /> : 'Next'}
        </SubmitButton>
      </PreviousButton>
    </>
  );
}

export default Terms;
