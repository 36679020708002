import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgBuyBoxSimple({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6.68c-.2.2-.5.2-.8.1l-1.7-.9h-.1l-1.6.9c-.1.1-.2.1-.3.1-.2 0-.4-.1-.5-.2-.2-.2-.3-.5-.2-.8l.7-1.5c.1-.1 0-.2 0-.2l-1.2-1c-.2-.2-.3-.5-.2-.7.1-.2.3-.4.6-.4h1.4s.1 0 .1-.1l.7-1.6c.1-.2.2-.3.4-.3.3-.2.7 0 .9.3l.7 1.6c0 .1 0 .1.1.1h1.3c.3 0 .5.2.6.4.1.2 0 .5-.2.6l-1.2 1.1v.2l.7 1.5c.1.3 0 .6-.2.8zm1.7-.4l2 .8c.183.062.255.198.305.294.032.061.056.107.095.107v3.4c0 .1-.1.2-.2.3l-6.2 3.1h-.3l-6.2-3.1c-.1-.1-.2-.2-.2-.3v-3.4c0-.2.1-.4.3-.5l1.9-.7h.1c.3-.1.5.1.7.3.1.3-.1.5-.3.6l-.7.3c-.05 0-.075 0-.087.012-.013.013-.013.038-.013.088 0 0 0 .1.1.1l4.5 1.8h.1l4.5-1.8c.1 0 .1 0 .1-.1 0 0 0-.1-.1-.1l-.7-.3c-.3-.1-.4-.4-.3-.6.1-.3.4-.4.6-.3z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBuyBoxSimple;
