import { combineReducers, createStore } from 'redux';

import { LOGOUT } from '../actions/types';

import mapPositionReducer from './mapPosition';
import miscReducer from './misc';
import mobileMenuReducer from './mobileMenu';
import modalsReducer from './modals';
import offersReducer from './offers';
import propertyQueryReducer from './propertyQuery';
import territoryReducer from './territory';

const appReducer = combineReducers({
  mobileMenu: mobileMenuReducer,
  offers: offersReducer,
  propertyQuery: propertyQueryReducer,
  mapPosition: mapPositionReducer,
  modals: modalsReducer,
  misc: miscReducer,
  territory: territoryReducer
});

export const rootReducer = (...[state, action]: Parameters<typeof appReducer>) => {
  if (action.type === LOGOUT) return appReducer(undefined, action);

  return appReducer(state, action);
};

const store = createStore(rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
