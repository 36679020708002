import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgHomeVisitYes({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="home_visit-yes_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.home_visit-yes_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="home_visit-yes_svg__cls-1"
        d="M15.18 54.32h.43v.43h-.43zM38.25 27.8a1 1 0 10-1.19-1.6 25.13 25.13 0 00-6.4 6.34 8.41 8.41 0 00-2.51-1.91 1 1 0 00-.93 1.77 5 5 0 012.5 2.36 1.14 1.14 0 00.94.77h.61l.33-.48a1.56 1.56 0 00.19-.39c.79-1.93 2.96-4.25 6.46-6.86z"
      />
      <path
        className="home_visit-yes_svg__cls-1"
        d="M54.46 24.22L30.89 5.31a1.44 1.44 0 00-1.78 0L5.54 24.22a1.47 1.47 0 00-.46 1.61 1.43 1.43 0 001.35 1h4.45v21.85a1.45 1.45 0 001.44 1.45h13.29a1.45 1.45 0 001.44-1.44l.07-6.59a12.32 12.32 0 003.67 1.17 11.69 11.69 0 001.19.12h.92l.1 5.3a1.45 1.45 0 001.44 1.44h6l2.31 4.1a1.53 1.53 0 001.24.77 1.6 1.6 0 00.82-.19 1.51 1.51 0 00.58-2.05l-1.49-2.63h3.84a1.45 1.45 0 001.44-1.45V26.81h4.45a1.43 1.43 0 001.35-1 1.47 1.47 0 00-.52-1.59zM31.24 40.3A9.69 9.69 0 0127.15 23a9.31 9.31 0 016.05-1.81 8.65 8.65 0 01.89.1 9.69 9.69 0 014.1 17.35 9.29 9.29 0 01-6.95 1.66zm4.56 6.92V43l.51-.13 2.46 4.35zM47.68 23.9a1.44 1.44 0 00-1.43 1.46v21.86h-4L39 41.63c.32-.19.63-.4.94-.62a12.69 12.69 0 00-5.43-22.74 12.28 12.28 0 00-9.19 2.26 12.73 12.73 0 00-1.09 19.6l-.07 7.09H13.75V25.36a1.44 1.44 0 00-1.43-1.46h-1.77L30 8.31 49.45 23.9z"
      />
    </svg>
  );
}

export default SvgHomeVisitYes;
