import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCheckmarkBoxFilled({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="checkmark_box-filled_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.checkmark_box-filled_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="checkmark_box-filled_svg__cls-1"
        d="M44.47 17.25C34.1 25 27.23 32.13 24 38.51c-1.23-2-3.63-4.8-8.66-7.46a1.42 1.42 0 00-1.91.59 1.4 1.4 0 00.57 1.91c6.26 3.31 7.83 6.77 8.42 8.07.27.59.61 1.32 1.51 1.41h.14a1.52 1.52 0 001.25-.67 4.56 4.56 0 00.42-.88c2.53-6.36 9.39-13.75 20.39-22a1.42 1.42 0 10-1.69-2.27z"
      />
      <path
        className="checkmark_box-filled_svg__cls-1"
        d="M47.92 5H12.08A7.08 7.08 0 005 12.08v35.84A7.08 7.08 0 0012.08 55h35.84A7.08 7.08 0 0055 47.92V12.08A7.08 7.08 0 0047.92 5zm4.25 42.92a4.25 4.25 0 01-4.25 4.25H12.08a4.25 4.25 0 01-4.25-4.25V12.08a4.25 4.25 0 014.25-4.25h35.84a4.25 4.25 0 014.25 4.25z"
      />
    </svg>
  );
}

export default SvgCheckmarkBoxFilled;
