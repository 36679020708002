import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCheckmarkRedX({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M6 12A6 6 0 106 0a6 6 0 000 12z" fill="#C41700" />
      <path
        d="M4.178 8.31a.285.285 0 01-.277-.235.285.285 0 01.043-.207c.105-.124.217-.242.336-.353.538-.544 1.8-1.811 3.628-3.987a.285.285 0 11.436.365c-1.843 2.2-3.116 3.482-3.66 4.032L4.4 8.21a.285.285 0 01-.223.1z"
        fill="#fff"
      />
      <path
        d="M8.318 8.124a.285.285 0 01-.168-.05c-.028-.02-3.184-2.25-4.397-4.019a.286.286 0 11.47-.325C5.38 5.41 8.45 7.586 8.48 7.606a.285.285 0 01-.162.518z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgCheckmarkRedX;
