/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from './http-client';

export class Homeowner<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Homeowner
   * @name HomeownerControllerPropertyDetails
   * @summary Get details for a property
   * @request GET:/homeowner/property/{sfId}
   */
  homeownerControllerPropertyDetails = (sfId: string, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/homeowner/property/${sfId}`,
      method: 'GET',
      ...params
    });
}
