import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgBath({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M51.37 32.42h-3.62V10.18A4.92 4.92 0 0046 6.34a6.81 6.81 0 00-5.72-1.18 6.23 6.23 0 00-5.13 6.36 6.91 6.91 0 00-6.08 6.13 1 1 0 00.25.78 1 1 0 00.75.33h11.72a1 1 0 001-1.11 6.89 6.89 0 00-5-5.93.7.7 0 000-.14 3.68 3.68 0 013-3.84 4.33 4.33 0 013.57.62 2.29 2.29 0 01.82 1.82v22.24H8.63A3.64 3.64 0 005 36.05v.35a3.62 3.62 0 001.9 3.18C6.91 48.23 10.7 55 15.54 55h28.92c4.84 0 8.63-6.77 8.64-15.42A3.62 3.62 0 0055 36.4v-.35a3.64 3.64 0 00-3.63-3.63zM40.08 16.75h-8.3a4.4 4.4 0 018.3 0zM8 36.05a.63.63 0 01.62-.62h42.75a.63.63 0 01.62.62v.35a.62.62 0 01-.62.62H8.63A.62.62 0 018 36.4zM44.46 52H15.54C13 52 10.08 47.08 10 40h40.1c-.18 7.08-3.05 12-5.64 12z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgBath;
