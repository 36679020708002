import { CHOOSE_ACTIVE_MARKETS_MODAL_NAME } from './ChooseActiveMarketsModal';
import { CHOOSE_INTERESTED_MARKETS_MODAL_NAME } from './ChooseInterestedMarketsModal';
import { CHOOSE_PRIMARY_MARKETS_MODAL_NAME } from './ChoosePrimaryMarketsModal';
import { EDIT_ACTIVE_MARKETS_MODAL_NAME } from './EditActiveMarketsModal';
import { EDIT_INTERESTED_MARKETS_MODAL_NAME } from './EditInterestedMarketsModal';
import { EDIT_MARKETS_INFO_MODAL_NAME } from './EditMarketsInfoModal';
import { EDIT_MARKETS_UPSELL_MODAL_NAME } from './EditMarketsUpsellModal';
import { EDIT_PRIMARY_MARKETS_MODAL_NAME } from './EditPrimaryMarketsModal';
import { ENABLED_SUCCESS_MODAL_NAME } from './EnabledSuccessModal';

export const MARKET_MANAGEMENT_MODAL_NAMES = {
  [CHOOSE_ACTIVE_MARKETS_MODAL_NAME]: CHOOSE_ACTIVE_MARKETS_MODAL_NAME,
  [CHOOSE_PRIMARY_MARKETS_MODAL_NAME]: CHOOSE_PRIMARY_MARKETS_MODAL_NAME,
  [CHOOSE_INTERESTED_MARKETS_MODAL_NAME]: CHOOSE_INTERESTED_MARKETS_MODAL_NAME,
  [ENABLED_SUCCESS_MODAL_NAME]: ENABLED_SUCCESS_MODAL_NAME,
  [EDIT_MARKETS_INFO_MODAL_NAME]: EDIT_MARKETS_INFO_MODAL_NAME,
  [EDIT_ACTIVE_MARKETS_MODAL_NAME]: EDIT_ACTIVE_MARKETS_MODAL_NAME,
  [EDIT_MARKETS_UPSELL_MODAL_NAME]: EDIT_MARKETS_UPSELL_MODAL_NAME,
  [EDIT_PRIMARY_MARKETS_MODAL_NAME]: EDIT_PRIMARY_MARKETS_MODAL_NAME,
  [EDIT_INTERESTED_MARKETS_MODAL_NAME]: EDIT_INTERESTED_MARKETS_MODAL_NAME
};
