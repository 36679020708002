import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgFloorPlanDetails({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#1d52a0" d="M35.43 36.93h14.71v18.15H35.43z" />
      <path
        d="M51.07 2.12H8.93a1 1 0 00-.93 1v53a1 1 0 00.93 1h42.14a1 1 0 00.93-1v-53a1 1 0 00-.93-1zm-9.73 2v7.93c-2.1-.77-7-3.08-7.68-7.93zm-5.16 50.96V40.81a.75.75 0 00-1.5 0v14.27H9.86V41.47h17.78v6.14a.75.75 0 001.5 0v-6.89a.76.76 0 00-.75-.75h-6.76V27.53a.75.75 0 00-1.5 0V40H9.86V21.76h23a.75.75 0 000-1.5h-23V4.15h22.3c.81 7.31 9.37 9.59 9.74 9.68a.63.63 0 00.19 0 .8.8 0 00.46-.16.75.75 0 00.29-.59v-9h7.3v16.18h-7.9a.74.74 0 00-.75.75v6.88a.75.75 0 001.5 0v-6.13h7.15v13.67H35.43a.75.75 0 000 1.5h14.71v18.15z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgFloorPlanDetails;
