import { AnyAction } from 'redux';

import {
  SET_PROPERTY_ORDER,
  SET_PROPERTY_PAGE,
  SET_PROPERTY_QUERY,
  SET_PROPERTY_REGION
} from '../actions/types';
import { PropertyStatus, SortOrder } from '../api/_base/generated/data-contracts';

import { RootState } from '.';

export interface PropertyQueryState {
  page: number;
  sortOrder: SortOrder;
  sortBy: string;
  statuses: string[];
  territoryId: string;
  regionName: string;
  size: number;
}

export interface PropertySortOrder {
  sortOrder: SortOrder;
  sortBy: string;
}

export interface Region {
  territoryId: string;
  regionName: string;
}

export const selectPropertyQueryState = (rootState: RootState) => rootState.propertyQuery;

const initialState: PropertyQueryState = {
  page: 0,
  sortOrder: SortOrder.DESC,
  sortBy: 'propertyStatus',
  statuses: [PropertyStatus.ACTIVE],
  territoryId: 'All',
  regionName: 'All',
  size: 1000
};

const propertyQueryReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_PROPERTY_ORDER:
      return {
        ...state,
        sortOrder: action.payload.sortOrder,
        sortBy: action.payload.sortBy
      };
    case SET_PROPERTY_REGION:
      return {
        ...state,
        territoryId: action.payload.territoryId,
        regionName: action.payload.regionName
      };
    case SET_PROPERTY_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case SET_PROPERTY_QUERY:
      return {
        ...state,
        page: action.payload.page,
        size: action.payload.size,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
        statuses: action.payload.statuses
      };
    default:
      return state;
  }
};

export default propertyQueryReducer;
