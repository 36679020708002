import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

import { SubscriptionProvider } from '../contexts/SubscriptionProvider';

const stripe =
  process.env.NEXT_PUBLIC_REACT_APP_STRIPE_PUBLISHABLE_KEY &&
  loadStripe(process.env.NEXT_PUBLIC_REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const StripeProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  if (!stripe) return null;

  return (
    <Elements stripe={stripe} options={{ appearance: { theme: 'flat' } }}>
      <SubscriptionProvider>{children}</SubscriptionProvider>
    </Elements>
  );
};
