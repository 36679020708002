import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgViewTable({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="view-table_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.view-table_svg__cls-1{fill:#4b4b4b}.view-table_svg__cls-2{fill:#dc3d55}'}</style>
      </defs>
      <path
        className="view-table_svg__cls-1"
        d="M6.52 7.93h42a1.47 1.47 0 100-2.93h-42a1.47 1.47 0 100 2.93z"
      />
      <path
        className="view-table_svg__cls-2"
        d="M53.37 7.81h.08a.1.1 0 00.07 0 5 5 0 001.38-1.42.94.94 0 00-.06-.95.82.82 0 00-.69-.34 1 1 0 00-.7.4 1 1 0 00-.71-.4.83.83 0 00-.69.34.94.94 0 00-.06.95 5 5 0 001.38 1.42z"
      />
      <path
        className="view-table_svg__cls-1"
        d="M6.52 16.48h42a1.47 1.47 0 100-2.93h-42a1.47 1.47 0 100 2.93z"
      />
      <path
        className="view-table_svg__cls-2"
        d="M53.37 16.36h.06a.11.11 0 00.07 0 5 5 0 001.38-1.42.94.94 0 00-.06-.95.82.82 0 00-.69-.34 1 1 0 00-.7.4 1 1 0 00-.71-.4.83.83 0 00-.69.34.94.94 0 00-.06.95 5 5 0 001.4 1.42zM53.37 24.91a.08.08 0 00.06 0 .11.11 0 00.07 0 4.81 4.81 0 001.38-1.42.93.93 0 00-.06-.94.81.81 0 00-.69-.35 1 1 0 00-.7.4 1 1 0 00-.71-.4.82.82 0 00-.69.35.93.93 0 00-.06.94 5 5 0 001.4 1.42zM53.37 33.46h.06a.11.11 0 00.07 0A4.81 4.81 0 0054.9 32a.93.93 0 00-.06-.94.81.81 0 00-.69-.35 1 1 0 00-.7.4 1 1 0 00-.71-.4.82.82 0 00-.69.35.93.93 0 00-.05.94 4.9 4.9 0 001.37 1.46zM53.37 42h.13a5.09 5.09 0 001.4-1.4.94.94 0 00-.06-1 .81.81 0 00-.69-.35 1 1 0 00-.7.4 1 1 0 00-.71-.4.82.82 0 00-.69.35.94.94 0 00-.06 1 5 5 0 001.38 1.4z"
      />
      <path
        className="view-table_svg__cls-1"
        d="M50 49.5a1.49 1.49 0 00-1.51-1.5h-1.91l-3.75-5.86H48.49a1.47 1.47 0 100-2.93h-2.62a12.43 12.43 0 002.23-5.62h.39a1.47 1.47 0 100-2.93h-.33a12.47 12.47 0 00-2-5.62h2.31a1.47 1.47 0 100-2.93h-5a12 12 0 00-15 0h-22a1.47 1.47 0 100 2.93h19.37a12.53 12.53 0 00-2 5.62H6.52a1.47 1.47 0 100 2.93h17.42a12.41 12.41 0 002.25 5.62H6.52a1.47 1.47 0 100 2.93h22.63a12.06 12.06 0 005.72 2.11h1.17a12 12 0 004.16-.75l2.88 4.5H6.52a1.47 1.47 0 100 2.93H45l2.14 3.35a1.48 1.48 0 001.25.68 1.45 1.45 0 00.79-.23 1.46 1.46 0 00.45-2L48.45 51A1.5 1.5 0 0050 49.5zM29.66 25h12.72a9.55 9.55 0 012.81 5.62H26.85A9.46 9.46 0 0129.66 25zm12.22 14.2H30.16a9.49 9.49 0 01-3.24-5.62h18.2a9.42 9.42 0 01-3.24 5.62z"
      />
      <path
        className="view-table_svg__cls-2"
        d="M54.84 48.48a.81.81 0 00-.69-.35 1 1 0 00-.7.41 1 1 0 00-.71-.41.84.84 0 00-.69.35.94.94 0 00-.06 1 5 5 0 001.38 1.42h.08a.1.1 0 00.07 0 4.82 4.82 0 001.38-1.42.94.94 0 00-.06-1z"
      />
    </svg>
  );
}

export default SvgViewTable;
