import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCheckmarkBlueCheck({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g data-name="Homepage-Option-2@2x-Copy">
        <circle cx={30} cy={30} r={21.07} fill="#1953a0" />
        <path
          d="M27.69 37.75h-.63a1.27 1.27 0 01-1-.93c-.35-.75-1.26-2.77-4.92-4.7a1 1 0 01.86-1.78 13.2 13.2 0 015 4.24c2-3.74 6-7.9 12-12.4a1 1 0 011.2 1.6c-6.49 4.85-10.54 9.2-12 12.94a2.93 2.93 0 01-.27.56z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgCheckmarkBlueCheck;
