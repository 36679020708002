import React from 'react';

import { ChooseActiveMarketsModal } from './ChooseActiveMarketsModal';
import { ChooseInterestedMarketsModal } from './ChooseInterestedMarketsModal';
import { ChoosePrimaryMarketsModal } from './ChoosePrimaryMarketsModal';
import { EditActiveMarketsModal } from './EditActiveMarketsModal';
import { EditInterestedMarketsModal } from './EditInterestedMarketsModal';
import { EditMarketsInfoModal } from './EditMarketsInfoModal';
import { EditMarketsUpsellModal } from './EditMarketsUpsellModal';
import { EditPrimaryMarketsModal } from './EditPrimaryMarketsModal';
import { EnabledSuccessModal } from './EnabledSuccessModal';

export const MarketManagementModals = () => {
  return (
    <>
      <ChooseActiveMarketsModal />
      <ChoosePrimaryMarketsModal />
      <ChooseInterestedMarketsModal />
      <EnabledSuccessModal />
      <EditMarketsInfoModal />
      <EditActiveMarketsModal />
      <EditMarketsUpsellModal />
      <EditPrimaryMarketsModal />
      <EditInterestedMarketsModal />
    </>
  );
};
