import classNames from 'classnames';
import React from 'react';

import styles from './InlineNotification.module.scss';

type InlineNotificationProps = React.PropsWithChildren<{
  className?: string;
}>;

export const InlineNotification: React.FC<InlineNotificationProps> = ({
  children,
  className
}: InlineNotificationProps) => {
  return <div className={classNames(styles.inlineNotification, className)}>{children}</div>;
};
