import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import modalStyles from '../../common/modal.module.scss';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/design-system';
import {
  BASE_TIER_MEMBERSHIP_NAME,
  StaticSubscription,
  SUBSCRIPTION_1_TIER_MEMBERSHIP_NAME,
  SUBSCRIPTION_2_TIER_MEMBERSHIP_NAME
} from '../../helpers/subscriptionHelper';
import { useModalByName } from '../../hooks/useModalByName';

import { MarketManagementModalContents } from './MarketManagementModalContents';

export const ENABLED_SUCCESS_MODAL_NAME = 'EnabledSuccessModal';

export const EnabledSuccessModal = () => {
  const [isOpen, , close, options] = useModalByName(ENABLED_SUCCESS_MODAL_NAME);
  const router = useRouter();

  const hide = () => {
    close();
    router.reload();
  };

  const subscription = options?.context as StaticSubscription;

  if (!subscription) {
    return null;
  }

  let headerText = `You are on our ${BASE_TIER_MEMBERSHIP_NAME} plan`;
  let bodyText = `You can browse all markets nationwide and place offers in only 1 market. You will not be able to access our premium benefits of membership.`;

  if (subscription.name === SUBSCRIPTION_2_TIER_MEMBERSHIP_NAME) {
    headerText = `Your ${subscription.name} membership is now active `;
    bodyText = `You now have access to place offers in all markets nationwide and plus the benefits of your ${subscription.name} membership.`;
  } else if (subscription.name === SUBSCRIPTION_1_TIER_MEMBERSHIP_NAME) {
    headerText = `Your ${subscription.name} membership is now active `;
    bodyText = `You now have access to place offers in up to 3 markets plus the benefits of your ${subscription.name} membership.`;
  }

  return (
    <Modal
      isShowing={isOpen}
      modalClassNames={classNames(modalStyles.modal, modalStyles.center)}
      hide={hide}
    >
      <MarketManagementModalContents close={hide} headerText={headerText}>
        <p className={modalStyles.text}>{bodyText}</p>
        <Link href="/">
          <Button size="large" onClick={hide} fullWidth>
            Search the Marketplace
          </Button>
        </Link>
      </MarketManagementModalContents>
    </Modal>
  );
};
