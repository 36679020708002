import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSort = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5.778 7.038a1 1 0 0 1-1.556 0L.254 2.128A1 1 0 0 1 1.032.5h7.936a1 1 0 0 1 .778 1.629L5.778 7.038Z"
      fill="#4A4A4A"
    />
  </svg>
);
export default SvgSort;
