import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGenericMe = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#generic-me_svg__a)">
      <path
        d="M48 23.99c0 13.248-10.753 24.568-23.99 23.99C10.182 47.374-.252 36.953.02 23.99.3 10.742 10.763 0 24.01 0 37.26 0 48 10.74 48 23.99Z"
        fill="#25B7D3"
        fillOpacity={0.2}
      />
      <path
        d="M31.418 25.31v-6.983a7.418 7.418 0 1 0-14.836 0v6.982c0 4.364-3.928 3.491-3.928 3.491s.437 3.491 6.11 3.491h10.472c5.673 0 6.11-3.49 6.11-3.49s-3.928.872-3.928-3.492Z"
        fill="#84462D"
      />
      <path
        d="M16.582 18.327v6.982c0 4.364-3.928 3.491-3.928 3.491s.437 3.491 6.11 3.491H24V10.909a7.418 7.418 0 0 0-7.418 7.418Z"
        fill="#9C6144"
      />
      <path
        d="M36.396 33.89a3.49 3.49 0 0 0-2.255-2.091l-7.087-1.69-3.054.437-3.055-.437-7.087 1.69a3.49 3.49 0 0 0-2.255 2.09l-1.567 4.075h27.927l-1.567-4.074Z"
        fill="#DB3D55"
      />
      <path
        d="M24 30.109v7.854h.873l3.49-7.418v-3.738c-1.24 1.918-2.93 2.824-4.363 3.302Z"
        fill="#F7B4B4"
      />
      <path
        d="M19.637 26.807v3.738l3.49 7.418H24V30.11c-1.433-.478-3.124-1.384-4.363-3.302Z"
        fill="#FBC8C8"
      />
      <path
        d="M30.546 19.637h-.437v-.01c-.001-.829-.023-1.637-.108-2.398a2.174 2.174 0 0 1-2.074 1.535 2.176 2.176 0 0 1-1.963-1.246A2.176 2.176 0 0 1 24 18.764a2.176 2.176 0 0 1-1.963-1.246 2.176 2.176 0 0 1-1.964 1.246A2.174 2.174 0 0 1 18 17.23c-.085.76-.107 1.57-.109 2.399v.009h-.436c-.437 0-.873.485-.873.97 0 1.348.377 3.11 1.823 3.36C19.471 27.94 21.991 29.44 24 30.11c2.01-.67 4.53-2.17 5.595-6.142 1.446-.25 1.823-2.013 1.823-3.362 0-.484-.436-.97-.872-.97Z"
        fill="#FBC8C8"
      />
      <path
        d="M22.037 17.518a2.176 2.176 0 0 1-1.964 1.246A2.174 2.174 0 0 1 18 17.23c-.085.76-.107 1.57-.109 2.399v.009h-.436c-.437 0-.873.485-.873.97 0 1.348.377 3.11 1.823 3.36C19.471 27.94 21.991 29.44 24 30.11V18.764a2.176 2.176 0 0 1-1.963-1.246Z"
        fill="#FBD7D7"
      />
      <path d="M21.818 25.31h4.364s0 1.309-2.182 1.309-2.182-1.31-2.182-1.31Z" fill="#F79392" />
      <path
        d="M19.636 29.236c0 .437 1.949 6.916 4.364 8.728h-1.309s-4.364-5.673-6.546-5.673c.437-2.182 3.491-3.055 3.491-3.055Zm8.728 0c0 .437-1.949 6.916-4.364 8.728h1.31s4.363-5.673 6.545-5.673c-.437-2.182-3.491-3.055-3.491-3.055Z"
        fill="#fff"
      />
      <path
        d="M22.694 20.49a.873.873 0 0 0-.873-.873h-.002l.002.027a.873.873 0 0 1-.87.873.871.871 0 1 0 1.743-.027Zm4.361 0a.873.873 0 0 0-.873-.873h-.002l.002.027a.873.873 0 0 1-.87.873.871.871 0 1 0 1.743-.027Z"
        fill="#3E3E3F"
      />
      <path
        d="M25.666 26.17h-3.333a.95.95 0 0 0-.798.435h-.59c-.905 0-2.618-.547-2.618-2.618v-4.35h-1.018a2.037 2.037 0 0 0 0 4.073h.145v.277c0 2.562 2.088 3.491 3.491 3.491h.507a.952.952 0 0 0 .882.595h3.332a.952.952 0 0 0 0-1.904Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="generic-me_svg__a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGenericMe;
