import { PostHog } from 'posthog-js';

import {
  ListPropertiesResponseBodyItem,
  ListingListWithOfferEntryApiModel,
  PropertyDetailsResponseBody
} from '../../api/_base/generated/data-contracts';
import { getUserInfo } from '../../utils/localStorage.utils';

export const recordPostHogEvent = (
  property:
    | PropertyDetailsResponseBody
    | ListPropertiesResponseBodyItem
    | ListingListWithOfferEntryApiModel,
  eventName: string,
  posthog: PostHog,
  extraParams?: { [key: string]: string }
) => {
  posthog.capture(eventName, {
    account_id: `${getUserInfo()?.sfId || ''}`,
    property_id: `${property.propertyId || ''}`,
    publish_id: `${property.publishId || ''}`,
    property_full_address: `${property?.streetAddress}, ${property?.city}, ${property?.state}, ${property?.zipCode}`,
    contact_email: `${getUserInfo()?.email || ''}`,
    sundae_user_id: `${getUserInfo()?.sundaeUserId || ''}`,
    property_spid: `${property.spId || ''}`,
    ...extraParams
  });
};
