import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerMpa = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-mpa_svg__a)">
      <path
        d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#25B7D3"
      />
      <path
        d="M38.982 43.636a1.164 1.164 0 0 1-1.164 1.164H14.546a1.164 1.164 0 0 1-1.164-1.164v-29.09a1.163 1.163 0 0 1 1.164-1.164h23.272a1.163 1.163 0 0 1 1.164 1.164v29.09Z"
        fill="#fff"
      />
      <path d="M15.709 16.873h20.945v23.273H15.71V16.873Z" fill="#3E3E3F" />
      <path
        d="M26.182 43.636a1.164 1.164 0 1 0 0-2.327 1.164 1.164 0 0 0 0 2.327Z"
        fill="#B6BCBD"
      />
      <path
        d="M43.636 38.982c0-6.982-4.654-11.636-4.654-11.636v9.309l-3.23-2.153a1.038 1.038 0 0 0-.575-.175.85.85 0 0 0-.645 1.403c1.916 2.718 3.286 5.58 6.195 7.907l1.746 6.981h8.145l-6.982-11.636Z"
        fill="#FBC8C8"
      />
      <path d="m15.709 36.55 19.677-19.677H15.71V36.55Z" fill="#5B5C5F" />
      <path d="M25.6 15.709a.582.582 0 1 0 0-1.164.582.582 0 0 0 0 1.164Z" fill="#3E3E3F" />
    </g>
    <defs>
      <clipPath id="seller-mpa_svg__a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerMpa;
