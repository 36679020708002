import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgOwnership({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="ownership_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.ownership_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="ownership_svg__cls-1"
        d="M21.67 47.58a10.46 10.46 0 009.76-7.1h1.63l1.82-.69 2.21.69h2l1.21-.63 1.4.63h1.5l1.68-.75c3.35-.08 3.86-1.9 3.86-3A3.93 3.93 0 0044.66 33H31.43a10.48 10.48 0 00-9.76-7.11 10.65 10.65 0 00-10.41 10.84 10.65 10.65 0 0010.41 10.85zm0-19.46A8.39 8.39 0 0129.79 35v.21h14.87a1.8 1.8 0 012 1.51c0 .62-1.07.75-2 .75h-.22l-1.67.75h-.59l-1.91-.86-1.64.86h-1.24l-2.57-.81-2.13.81h-2.87v.21a8.38 8.38 0 01-8.12 6.9 8.61 8.61 0 010-17.21z"
      />
      <path
        className="ownership_svg__cls-1"
        d="M17.62 39.19a2.47 2.47 0 10-2.49-2.46 2.48 2.48 0 002.49 2.46zm0-3.43a1 1 0 110 1.93 1 1 0 110-1.93z"
      />
      <path
        className="ownership_svg__cls-1"
        d="M49.81 7.58H44.7v-.21A2.65 2.65 0 0041.84 5 2.66 2.66 0 0039 7.37v.21H21v-.21A2.66 2.66 0 0018.16 5a2.65 2.65 0 00-2.86 2.37v.21h-5.11C7.33 7.58 5 10.59 5 14.3v34C5 52 7.33 55 10.19 55h39.62c2.86 0 5.19-3 5.19-6.72v-34c0-3.69-2.33-6.7-5.19-6.7zm-8.51-.21a.55.55 0 011.08 0v3.87a.55.55 0 01-1.08 0zm-23.68 0a.55.55 0 011.08 0v3.87a.55.55 0 01-1.08 0zm34.55 40.91c0 1.95-1.08 3.59-2.36 3.59H10.19c-1.28 0-2.36-1.64-2.36-3.59V22.37h44.34zm0-29H7.83V14.3c0-2 1.08-3.59 2.36-3.59h5.11v.53a2.65 2.65 0 002.86 2.37A2.66 2.66 0 0021 11.24v-.53h18v.53a2.66 2.66 0 002.87 2.37 2.65 2.65 0 002.86-2.37v-.53h5.11c1.28 0 2.36 1.64 2.36 3.59z"
      />
    </svg>
  );
}

export default SvgOwnership;
