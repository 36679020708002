import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgInspection({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="inspection_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.inspection_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="inspection_svg__cls-1"
        d="M54.72 52.12l-6.83-10.73c.31-.22.61-.44.9-.68a12.72 12.72 0 00-4-21.8v-5.85a5.34 5.34 0 00-5.38-5.29h-8.1a3 3 0 00-1-.49v-.67A1.64 1.64 0 0028.56 5h-7.42a1.64 1.64 0 00-1.68 1.61v.67a3 3 0 00-1 .49h-8.1A5.34 5.34 0 005 13.06v36.65A5.34 5.34 0 0010.34 55h29a5.34 5.34 0 005.38-5.29v-6.78l.5-.16 7 11a1.49 1.49 0 102.51-1.61zM21.45 7h6.8v.21h-6.8zm-1.24 2.13h9.28a.94.94 0 110 1.87h-9.28a.94.94 0 110-1.87zm21.6 40.58a2.42 2.42 0 01-2.45 2.39h-29a2.42 2.42 0 01-2.45-2.39V13.06a2.42 2.42 0 012.45-2.38h7A2.92 2.92 0 0020.21 13h9.28a2.92 2.92 0 002.87-2.28h7a2.42 2.42 0 012.45 2.38v5.3a12.09 12.09 0 00-8.59 2.78 12.69 12.69 0 006.62 22.34c.4 0 .79.05 1.19.05h.78zm5.08-11.31a9.17 9.17 0 01-6.76 2.1 9.69 9.69 0 01-5-17A9.08 9.08 0 0141 21.31a8.7 8.7 0 01.89 0 9.69 9.69 0 015 17z"
      />
      <path
        className="inspection_svg__cls-1"
        d="M46.24 25.68c-3.66 2.74-6.19 5.3-7.56 7.64a9.48 9.48 0 00-3-2.4 1 1 0 00-.93 1.77 5.94 5.94 0 013 2.87 1.16 1.16 0 001 .8h.61l.33-.48a1.72 1.72 0 00.21-.43c.93-2.35 3.49-5.1 7.61-8.2a1 1 0 00.2-1.4 1 1 0 00-1.47-.17z"
      />
    </svg>
  );
}

export default SvgInspection;
