import classNames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';

import modalStyles from '../../common/modal.module.scss';
import { AlertBox } from '../../components/AlertBox';
import { FormikInput } from '../../components/Form/FormikInput/FormikInput';
import { Modal } from '../../components/Modal';
import { ModalCloseButton } from '../../components/Modal/ModalCloseButton';
import { useModalByName } from '../../hooks/useModalByName';
import { CancelSaveButtonGroup } from '../AccountSettings/CancelSaveButtonGroup/CancelSaveButtonGroup';

import styles from './HomeownerModals.module.scss';

export const USER_INFO_MODAL_NAME = 'UserInfoModal';

const initialValues = {
  firstName: '',
  lastName: '',
  preferredName: '',
  phone: '',
  email: ''
};

const userInfoValidationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  preferredName: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().required()
});

export const UserInfoModal = () => {
  const [isModalOpen, , closeModal] = useModalByName(USER_INFO_MODAL_NAME);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  return (
    <Modal
      isShowing={isModalOpen}
      hide={closeModal}
      modalClassNames={classNames(modalStyles.modal, styles.modal)}
      overlayClassNames={styles.overlay}
    >
      <div className={styles.modalHeader}>My Info</div>

      <ModalCloseButton isAbsolute onClick={closeModal} />

      {errorMessage && <AlertBox>{errorMessage}</AlertBox>}

      <Formik
        initialValues={initialValues}
        validationSchema={userInfoValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // TODO need backend wiring
        }}
      >
        <Form className={styles.formContents}>
          <div className={styles.flexInputContainer}>
            <FormikInput
              label="First Name"
              id="firstName"
              name="firstName"
              size="small"
              type="string"
            />
            <FormikInput
              label="Last Name"
              id="lastName"
              name="lastName"
              size="small"
              type="string"
            />
          </div>
          <div>
            <FormikInput
              label="Preferred Name"
              id="preferredName"
              name="preferredName"
              size="small"
              type="string"
            />
          </div>
          <div>
            <FormikInput label="Phone" id="phone" name="phone" size="small" type="tel" />
          </div>
          <div>
            <FormikInput label="Email address" id="email" name="email" size="small" type="string" />
          </div>
          <div>
            <CancelSaveButtonGroup
              isSubmitting={false}
              isSaveDisabled={false}
              onSaveClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              onCancelClick={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
