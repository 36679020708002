import classNames from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import * as signupApi from '../../../api/auth/beginSignup';
import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { investmentStrategyOptions, rehabLevelOptions } from '../../../helpers/constants';
import { SignUpFormData, SignupProps } from '../../../types/onBoarding';
import { AlertBox } from '../../AlertBox';
import { Checkbox } from '../../Checkbox';
import { InputLabel } from '../../Form/InputLabel/InputLabel';
import { RadioAndCheckboxGroup } from '../../Form/RadioAndCheckboxGroup/RadioAndCheckboxGroup';
import { PrivacyPolicyLink } from '../../PrivacyPolicyLink';
import { SubmitButton } from '../../SubmitButton';
import { Input } from '../../design-system';
import PreviousButton from '../PreviousButton';
import styles from '../SignUp.module.scss';

function Strategy({ stepNavigate, initialFormData, updateFormData }: SignupProps) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [loader, setLoader] = useState(false);

  const initialValues = {
    investmentTypes: initialFormData.investmentTypes,
    rehabTypes: initialFormData.rehabTypes,
    lastTwelve: initialFormData.lastTwelve,
    nextTwelve: initialFormData.nextTwelve
  };

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({ defaultValues: initialValues });

  const handleBack = () => {
    stepNavigate('back');
  };

  const submitStrategy = async (data: SignUpFormData) => {
    setErrorMessage(undefined);
    updateFormData(data);
    setLoader(true);

    try {
      await signupApi.beginSignup({ ...initialFormData, ...data });
      setLoader(false);
      stepNavigate('next');
    } catch (error: unknown) {
      setLoader(false);

      if (isApiErrorWithMessage(error)) {
        setErrorMessage(getMessageFromError(error));
      } else {
        setErrorMessage('Unable to create account.');
      }
    }
  };

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.title}>Investment Strategy</div>
        <span className={styles.subTitle}>
          We&apos;ll recommend properties based on your strategy and approach.
        </span>
      </div>
      <form onSubmit={handleSubmit(submitStrategy)}>
        <div className={styles.formContainer}>
          <div className={styles.formSet}>
            <RadioAndCheckboxGroup
              type="checkbox"
              label="Investment Strategy*"
              helperText={Boolean(errors.investmentTypes) && 'Select investment strategy'}
              hasError={Boolean(errors.investmentTypes)}
            >
              {investmentStrategyOptions.map(({ value, label = value }) => (
                <div key={value} style={{ marginBottom: 2 }}>
                  <Checkbox
                    {...register('investmentTypes', { required: true })}
                    value={value}
                    label={label}
                    hasError={Boolean(errors.investmentTypes)}
                  />
                </div>
              ))}
            </RadioAndCheckboxGroup>
          </div>

          <div className={styles.formSet}>
            <RadioAndCheckboxGroup
              type="checkbox"
              label="Rehab Level*"
              helperText={Boolean(errors.rehabTypes) && 'Select rehab level'}
              hasError={Boolean(errors.rehabTypes)}
            >
              {rehabLevelOptions.map(({ value, label = value }) => (
                <div key={value} style={{ marginBottom: 2 }}>
                  <Checkbox
                    {...register('rehabTypes', { required: true })}
                    value={value}
                    label={label}
                    hasError={Boolean(errors.rehabTypes)}
                  />
                </div>
              ))}
            </RadioAndCheckboxGroup>
          </div>

          <div className={styles.formSet}>
            <InputLabel htmlFor="lastTwelve" error={Boolean(errors.lastTwelve)}>
              Number of purchases last 12 months*
            </InputLabel>
            <Input
              id="lastTwelve"
              size="small"
              {...register('lastTwelve', {
                required: true,
                min: { message: 'Must be greater than 0', value: 0 },
                max: { message: 'Must be less than 999', value: 999 }
              })}
              type="number"
              descriptor={
                errors.lastTwelve?.message || (Boolean(errors.lastTwelve) && 'Enter a number')
              }
              error={Boolean(errors.lastTwelve)}
              className={styles.shortInput}
            />
          </div>

          <div className={styles.formSet}>
            <InputLabel htmlFor="nextTwelve" error={Boolean(errors.nextTwelve)}>
              Target number of purchases next 12 months*
            </InputLabel>
            <Input
              id="nextTwelve"
              size="small"
              {...register('nextTwelve', {
                required: true,
                min: { message: 'Must be greater than 0', value: 0 },
                max: { message: 'Must be less than 999', value: 999 }
              })}
              type="number"
              descriptor={
                errors.nextTwelve?.message || (Boolean(errors.nextTwelve) && 'Enter a number')
              }
              error={Boolean(errors.nextTwelve)}
              className={styles.shortInput}
            />
          </div>
        </div>
        {errorMessage && <AlertBox className={styles.submitError}>{errorMessage}</AlertBox>}
        <div className={styles.storyBtn}>
          <PreviousButton handleBack={handleBack}>
            <SubmitButton type="submit" loading={loader}>
              Next
            </SubmitButton>
          </PreviousButton>
        </div>
        <div className={classNames(styles.signupInfo, 'text-center', 'p-0')}>
          <PrivacyPolicyLink linkText="Review our privacy policy." />
        </div>
      </form>
    </>
  );
}

export default Strategy;
