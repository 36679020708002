import React, { RefObject } from 'react';
import { Tooltip } from 'reactstrap';

interface ErrorTooltipProps {
  isOpen: boolean | undefined;
  target: string | RefObject<HTMLElement | null>;
  message: string;
  placement?: string;
}

const ErrorTooltip = ({ isOpen, target, message, placement = 'bottom' }: ErrorTooltipProps) => {
  if (typeof target !== 'string' && !target.current) return null;

  return (
    <Tooltip
      id="tooltip-top"
      className="mttooltip"
      placement={placement}
      isOpen={isOpen}
      target={typeof target === 'string' ? target : target.current}
    >
      {message}
    </Tooltip>
  );
};

export default ErrorTooltip;
