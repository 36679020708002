import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgLoved({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M30 52.5a1.55 1.55 0 01-.81-.23l-.11-.08C26 50.18 10.59 37.77 6.54 28.49c-2.43-5.56-2-11.28 1.27-15.68A12.15 12.15 0 0118.4 7.54a15.6 15.6 0 0111.6 7.1 15.58 15.58 0 0111.6-7.1 12.15 12.15 0 0110.59 5.27c3.24 4.4 3.7 10.12 1.27 15.68C49 38.81 32.78 50.85 31 52.19a1.7 1.7 0 01-1 .31zm-12.44-42a9.36 9.36 0 00-7.27 4.07c-2.6 3.54-2.94 8.19-.94 12.77C13 35.71 26.57 46.52 30 49.16c3.68-2.8 16.94-13.26 20.68-21.84 2-4.58 1.66-9.23-.94-12.77a9.16 9.16 0 00-7.88-4c-6 .52-8.8 4.91-10 6.79-.52.8-.93 1.43-1.85 1.43s-1.33-.64-1.86-1.44c-1.21-1.88-4.06-6.26-10-6.78a6.08 6.08 0 00-.62-.07z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgLoved;
