import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

import styles from './Radio.module.scss';

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode;
  error?: boolean;
}

export const Radio: React.FC<RadioProps> = ({ label, error, className, ...rest }) => {
  return (
    <label className={styles.radio}>
      <input className={classNames(className, { [styles.error]: error })} type="radio" {...rest} />
      <span className={styles.label}>{label}</span>
    </label>
  );
};
