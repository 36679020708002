import { AnyAction } from 'redux';

import { SET_MAP_POSITION } from '../actions/types';
import { RootState } from '../store';

export interface MapPosition {
  lat: number;
  long: number;
  zoom: number;
}

export const getMapPosition = (rootState: RootState) => rootState.mapPosition;

const initialState: MapPosition = { lat: 39.0997, long: -94.5786, zoom: 4.15 };

const mapPositionReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_MAP_POSITION:
      return {
        ...action.payload
      };
  }

  return state;
};

export default mapPositionReducer;
