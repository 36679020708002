import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerPhotos = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-photos_svg__a)">
      <path
        d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#25B7D3"
      />
      <path
        d="M46.545 25.41a6.234 6.234 0 0 0-3.91-5.784c-1.815-4.44-4.748-6.244-8.34-6.244a8.676 8.676 0 0 0-7.737 4.738c-2.283-.972-4.856.642-4.951 3.143a5.343 5.343 0 0 0 1.775 10.383H41.2s5.345-.992 5.345-6.237Z"
        fill="#F0F1F1"
      />
      <path
        d="M34.295 13.382a8.676 8.676 0 0 0-7.737 4.738c-2.283-.972-4.856.642-4.951 3.143a5.343 5.343 0 0 0-3.57 5.037 5.343 5.343 0 0 0 4.904 5.323l16.524-16.524c-1.483-1.184-3.229-1.717-5.17-1.717Z"
        fill="#fff"
      />
      <path
        d="M40.262 37.586h-1.63l-1.628-2.095h-4.888l-1.629 2.095h-6.516a1.63 1.63 0 0 0-1.63 1.629v9.774a1.629 1.629 0 0 0 1.63 1.63h16.29a1.629 1.629 0 0 0 1.63-1.63v-9.774a1.629 1.629 0 0 0-1.63-1.63Z"
        fill="#3E3E3F"
      />
      <path
        d="M28.044 36.77a.815.815 0 0 0-.815-.814h-1.63a.815.815 0 0 0-.814.815v.814h3.259v-.814ZM34.56 48.99a4.887 4.887 0 1 0 0-9.775 4.887 4.887 0 0 0 0 9.774Z"
        fill="#E2E4E5"
      />
      <path d="M34.56 47.36a3.258 3.258 0 1 0 0-6.516 3.258 3.258 0 0 0 0 6.516Z" fill="#40C9E7" />
      <path
        d="M26.764 48.99h-2.211a.584.584 0 0 1-.582-.582v-8.611c0-.32.262-.582.582-.582h2.21v9.774Z"
        fill="#5B5C5F"
      />
      <path
        d="m37.236 27.636-3.782-3.781-3.781 3.781h2.647v6.11h2.269v-6.11h2.647Z"
        fill="#FA5655"
      />
    </g>
    <defs>
      <clipPath id="seller-photos_svg__a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerPhotos;
