import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgGreenUpArrow = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.26 7.84 9.734.533A1.818 1.818 0 0 0 8.535 0c-.453.023-.88.214-1.197.533L.81 7.867a.94.94 0 0 0-.31.707.929.929 0 0 0 .33.698.958.958 0 0 0 .75.22.963.963 0 0 0 .664-.405l5.04-5.64a.17.17 0 0 1 .19-.047.169.169 0 0 1 .107.153V15.06a.928.928 0 0 0 .28.665.954.954 0 0 0 .674.275.965.965 0 0 0 .675-.275.94.94 0 0 0 .28-.665V3.553a.165.165 0 0 1 .107-.153.171.171 0 0 1 .19.047l5.04 5.64a.955.955 0 0 0 .657.317.97.97 0 0 0 .692-.234.943.943 0 0 0 .322-.648.929.929 0 0 0-.237-.682Z"
      fill="#21862F"
    />
  </svg>
);
export default SvgGreenUpArrow;
