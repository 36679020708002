import { AnyAction } from 'redux';

import { TOGGLE_MENU, CLOSE_MENU } from '../actions/types';

import { RootState } from './index';

export interface MenuState {
  mobileMenuOpen: boolean;
}

export const selectMenuState = (rootState: RootState) => rootState.mobileMenu;

export const selectMenuOpen = (rootState: RootState) => selectMenuState(rootState).mobileMenuOpen;

const initialState: MenuState = { mobileMenuOpen: false };

const mobileMenuReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        mobileMenuOpen: !state.mobileMenuOpen
      };
    case CLOSE_MENU:
      return {
        mobileMenuOpen: false
      };
  }

  return state;
};

export default mobileMenuReducer;
