import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgViewMap({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="view-map_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.view-map_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="view-map_svg__cls-1"
        d="M50.75 5H9.25A4.21 4.21 0 005 9.16v36.58a4.21 4.21 0 004.25 4.16h35.38l2.82 4.41a1.5 1.5 0 001.27.69 1.57 1.57 0 00.8-.23A1.49 1.49 0 0050 52.7l-1.79-2.8h2.56A4.21 4.21 0 0055 45.74V9.16A4.21 4.21 0 0050.75 5zM40 7.83L31.11 20a12.49 12.49 0 00-7.21 9.85l-1.81 2.49-4.45-3.56 15.88-21zm5.54 24.58a9.89 9.89 0 01-.44 2.11L31.05 23.47a9.17 9.17 0 015.11-1.56h.9a9.57 9.57 0 018.5 10.5zm-10.23 8.67a9.34 9.34 0 01-4.07-1.41l-1.65-1.32a9.71 9.71 0 01-2.76-7.75 9.14 9.14 0 01.34-1.82l2.71-3.7L44.29 36.4a9.6 9.6 0 01-2.18 2.6 9.22 9.22 0 01-6.8 2.08zm-11.4-7.85c0 .22.06.44.11.66l-.37-.3zM7.83 9.16a1.38 1.38 0 011.42-1.33H31L16.08 27.54l-8.25-6.6zm3.63 37.91H9.25a1.38 1.38 0 01-1.42-1.33V23.49L20.91 34l-9.28 12.64a1.14 1.14 0 00-.17.43zm2.33 0l8.68-11.87 2.78 2.22a12.44 12.44 0 007.85 6.28l4.21 3.37zm26.44 0a.9.9 0 00-.3-.45l-3.16-2.53a12 12 0 003.67-.74l2.38 3.72zm11.94-1.33a1.38 1.38 0 01-1.42 1.33h-4.37L43.12 42c.31-.21.61-.44.9-.68a12.51 12.51 0 002.65-3l5.5 4.32zm0-5.69l-4.57-3.58a12.73 12.73 0 00.95-3.78A12.57 12.57 0 0037.36 19a12.18 12.18 0 00-3.08.1L42.5 7.83h8.25a1.38 1.38 0 011.42 1.33z"
      />
      <rect
        className="view-map_svg__cls-1"
        x={12.92}
        y={19.27}
        width={5.24}
        height={5.24}
        rx={0.5}
        transform="rotate(-51.01 15.545 21.89)"
      />
      <rect
        className="view-map_svg__cls-1"
        x={9.86}
        y={36.75}
        width={5.24}
        height={5.24}
        rx={0.5}
        transform="rotate(-51.01 12.482 39.368)"
      />
      <rect
        className="view-map_svg__cls-1"
        x={31.53}
        y={31.34}
        width={7.03}
        height={7.03}
        rx={0.5}
        transform="rotate(-51 34.898 34.75)"
      />
    </svg>
  );
}

export default SvgViewMap;
