import classNames from 'classnames';
import React from 'react';

import { Subscription } from '../../contexts/SubscriptionProvider';
import {
  getIntervalString,
  getTierImageOrText,
  mapStaticSubscriptionToStripeSubscription,
  StaticSubscription
} from '../../helpers/subscriptionHelper';
import { Card } from '../Card';

import styles from './MembershipCard.module.scss';

interface MembershipCardProps {
  onMembershipSelection: (subscription: StaticSubscription) => void;
  staticSubscription: StaticSubscription;
  subscriptions: Array<Subscription>;
  selected?: boolean;
  disabled?: boolean;
}

export const MembershipCard = ({
  onMembershipSelection,
  staticSubscription,
  subscriptions,
  selected,
  disabled
}: MembershipCardProps) => {
  const subscription = mapStaticSubscriptionToStripeSubscription(subscriptions, staticSubscription);
  const yearlySubscription = mapStaticSubscriptionToStripeSubscription(
    subscriptions,
    staticSubscription,
    false
  );

  if (!subscription) {
    return null;
  }

  const subscriptionPrice = yearlySubscription?.amount
    ? Math.floor(yearlySubscription.amount / 12)
    : subscription.amount;

  return (
    <Card
      key={subscription.name}
      selected={selected}
      disabled={disabled}
      className={styles.cardContainer}
      onSelection={() => onMembershipSelection(staticSubscription)}
    >
      <div className={styles.cardContent}>
        <div>
          <div>
            <div>{getTierImageOrText(staticSubscription.name)}</div>
          </div>
          <div>
            {staticSubscription.markets === -1 ? (
              <span>Place offers in all markets nationwide</span>
            ) : (
              <span>
                Place offers in {staticSubscription.markets} market
                {staticSubscription.markets === 1 ? '' : 's'}
              </span>
            )}
          </div>
        </div>
        <div>
          {subscriptionPrice !== 0 ? (
            <b>
              ${subscriptionPrice}/<span>{getIntervalString(subscription.interval)}</span>
              <div className={classNames(styles.billedAnnually)}>Billed Annually</div>
            </b>
          ) : (
            <b className={subscriptionPrice === 0 ? 'hide' : ''}>Free</b>
          )}
        </div>
      </div>
    </Card>
  );
};
