import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../store/modals';
import { ModalOptions, selectModalsState } from '../store/modals';
import type { ModalName } from '../store/modals';
export type { ModalName } from '../store/modals';

export const useModalManager = () => {
  const dispatch = useDispatch();

  const modalState = useSelector(selectModalsState);

  const isModalShowing = (name: ModalName) => {
    return modalState[name]?.isShowing ?? false;
  };

  const getModalOptions = (name: ModalName) => {
    return modalState[name]?.options;
  };

  const showModal = useCallback((name: ModalName, options?: ModalOptions) => {
    dispatch(actions.showModal(name, options));
  }, []);

  const hideModal = (name: ModalName) => {
    dispatch(actions.hideModal(name));
  };

  const hideAllModals = () => {
    dispatch(actions.hideAllModals());
  };

  return {
    isModalShowing,
    showModal,
    hideModal,
    hideAllModals,
    getModalOptions
  };
};
