import { useCallback, useEffect, useState } from 'react';

import { runValidations, Validation } from '../utils/validation.utils';

export const useInputValidation = (validations: Validation[]) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isInvalidAmount, setIsInvalidAmount] = useState<boolean>(false);

  const handleValidation = useCallback((validations: Validation[]) => {
    const errorMessage = runValidations(validations);
    setErrorMessage(errorMessage);
    setIsInvalidAmount(!!errorMessage);
  }, []);

  useEffect(() => {
    handleValidation(validations);
  }, [handleValidation, validations]);

  return { errorMessage, isInvalidAmount, setIsInvalidAmount };
};
