import classNames from 'classnames';
import React from 'react';

import featuredImage from '../../../../public/images/featured-post-default-bg.png';
import SupportLink from '../../SupportLink';
import style from '../SignUp.module.scss';

interface IProps {
  onClose: () => void;
  featuredPost: {
    title: string;
    link: string;
    image: unknown;
  };
}

const IdentityVerificationFailedSuccessStep = ({ onClose, featuredPost }: IProps) => {
  const handleFeaturedPostClick = () => {
    onClose();
    window.open(
      'https://sundae.com/blog/investor/real-estate-investors-can-do-this-right-now-to-stay-ahead-of-the-competition/',
      '_blank',
      'noopener'
    );
  };

  return (
    <div className={style.sundaeScoop}>
      <main className={style.main}>
        <div className={classNames(style.container, style.stack)}>
          <h1 className={style.title}>
            Thank you for your interest in joining Sundae as a property investor!
          </h1>
          <p>
            A Sundae representative will contact you within 48 hours to further discuss your needs
            and grant access to our platform, so be on the lookout for a call from one of our team
            members.
          </p>
          <p>
            If you have any questions or need to access properties sooner, please email us at{' '}
            <SupportLink />.
          </p>
          <hr />
          <p>
            In the meantime, here&apos;s a valuable resource on how to maximize your buying
            strategy.
          </p>

          <a className={style.featuredPostLink} onClick={handleFeaturedPostClick} href="#">
            <span className={style.text}>{featuredPost.title}</span>
            <img src={featuredImage.src} aria-hidden />
          </a>
        </div>
      </main>
    </div>
  );
};

IdentityVerificationFailedSuccessStep.defaultProps = {
  featuredPost: {
    title: 'Real Estate Investors Can Do This Right Now to Stay Ahead of the Competition',
    link: 'https://sundae.com/blog/investor/real-estate-investors-can-do-this-right-now-to-stay-ahead-of-the-competition/'
  }
};

export default IdentityVerificationFailedSuccessStep;
