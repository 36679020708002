import { PostHog } from 'posthog-js';

import { PropertyDetailsResponseBody } from '../../api/_base/generated/data-contracts';

import { recordPostHogEvent } from './recordPostHogEvent';

interface RecordUserOfferNotConfirmedParams {
  property: PropertyDetailsResponseBody;
  offerAmount: string;
}

export const recordUserOfferNotConfirmedEventName = 'user_offer_notconfirmed';

export const recordUserOfferNotConfirmed = (
  { property, offerAmount }: RecordUserOfferNotConfirmedParams,
  posthog: PostHog
) => {
  // TODO: disabled temporarily
  // recordPostHogEvent(property, recordUserOfferNotConfirmedEventName, posthog, {
  //   action: 'notconfirmed',
  //   offer_amount: offerAmount,
  //   closing_date: `${property?.projectedCloseDate}`,
  //   address: `${property?.streetAddress}, ${property?.city}, ${property?.state}, ${property?.zipCode}`,
  //   territory_id: `${property?.territoryId}`,
  //   express_property: `${property?.isExpressProperty}`
  // });
};
