export const offerErrorMessages = {
  positive: 'Enter in a valid offer amount.',
  greaterThanLastOffer: (lastOffer: string) =>
    `The inputted offer amount is not greater than your current offer of ${lastOffer}. To lower your current offer amount, please contact your Investor Advisor.`
};

export const maxOfferErrorMessages = {
  greaterThanInitialOffer: (initialOffer: string) =>
    `The inputted offer amount is not greater than your current offer of ${initialOffer}`
};

export const minBidErrorMessage = {
  greaterThanMinBid: () => 'Minimum bid below threshold'
};

export const isPositive = (value: number | undefined): boolean => {
  return !!value && value > 0;
};

export const isGreaterThanValue = (
  value: number | undefined,
  otherValue: number | undefined
): boolean => {
  if (!otherValue) return true;

  return !!value && value > otherValue;
};

export const isGreaterThanOrEqualToValue = (
  value: number | undefined,
  otherValue: number | undefined
): boolean => {
  return isGreaterThanValue(value, otherValue) || value === otherValue;
};

export interface Validation {
  message: string;
  validator: () => boolean;
}

export const runValidations = (validations: Validation[]) => {
  for (const validation of validations) {
    if (!validation.validator()) return validation.message;
  }

  return '';
};
