import React from 'react';

import { ModalHeader } from '../ModalHeader';

import styles from './EditModalHeader.module.scss';

interface EditModalHeaderProps {
  onClickIcon: () => void;
  headerText: string;
}

export const EditModalHeader = ({ onClickIcon, headerText }: EditModalHeaderProps) => {
  return (
    <ModalHeader
      closeIconClassNames={styles.closeIcon}
      onClickIcon={onClickIcon}
      headerText={headerText}
    />
  );
};
