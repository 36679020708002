import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { ResendEmailCodeRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/resend-email-code
export const resendEmailCode = (body: ResendEmailCodeRequestBody) => {
  return api.authControllerResendEmailCode(body);
};

export const useResendEmailCode = () => {
  return useMutation({ mutationFn: resendEmailCode });
};
