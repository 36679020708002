import { setAccessToken, setIdToken } from '../../utils/localStorage.utils';
import { api } from '../_base';

// POST /oauth/token
export const getAuthToken = async (email: string, password: string) => {
  const response = await api.authControllerLogin({ email, password });

  // validate the minimal API response to accomplish logging the user in

  if (response.data.data.AccessToken) {
    setAccessToken(`Bearer ${response.data.data.AccessToken}`);
  }

  if (response.data.data.IdToken) {
    setIdToken(`Bearer ${response.data.data.IdToken}`);
  }

  return response.data;
};

export const getHomeownerAuthToken = async (email: string, password: string) => {
  const response = await api.authControllerHomeownerLogin({ email, password });

  if (response.data.data.AccessToken) {
    setAccessToken(`Bearer ${response.data.data.AccessToken}`);
  }

  if (response.data.data.IdToken) {
    setIdToken(`Bearer ${response.data.data.IdToken}`);
  }

  return response;
};
