import { addHours } from 'date-fns';
import Cookies from 'js-cookie';

import { LOGOUT } from '../actions/types';
import { GetUserInfoResponseBody } from '../api/_base/generated/data-contracts';
import { TargetMarket } from '../types/targetMarket';

export const SESSION_COOKIE_NAME = 'sundae_session_token';
export const ID_TOKEN_COOKIE_NAME = 'sundae_id_token';
export const SELLER_SESSION_COOKIE = 'sundae_homeowner_session_token';

export const ACCESS_TOKEN = 'AccessToken';
export const REFERRAL_PROPERTY_ID = 'referralPropertyId';
export const LAST_ROUTE = 'lastRoute';
const ATTRIBUTION_USER_ID = 'attributionUserId';
const SUGGESTED_MEMBERSHIP = 'suggestedMembership';

export const getCookieDomain = () => process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '.sundae.com';

export const getDomainName = () => {
  if (window.location.hostname === 'localhost') {
    return window.location.hostname;
  }

  const domainPartsReversed = window.location.hostname.split('.').reverse();

  return `${domainPartsReversed[1]}.${domainPartsReversed[0]}`;
};

// Secure cookies can only be set on https pages or when host is localhost.
// Without this hack cookies using secure flag wont be set on dev or stage, due to lack of TLS cert on those domains.
// This is a parameter hack to allow the cookies to be set on dev and stage.
// TODO: Add TLC cert to all domains, dev and stage.
export const getCookieIsSecure = () =>
  process.env.NEXT_PUBLIC_COOKIE_IS_SECURE === undefined ||
  process.env.NEXT_PUBLIC_COOKIE_IS_SECURE === null
    ? true
    : process.env.NEXT_PUBLIC_COOKIE_IS_SECURE === 'true'; // interprets the value of env var as boolean

export const getLocalStorage = () => {
  return typeof window !== 'undefined' && window.localStorage ? localStorage : null;
};

export const setCookie = (cookieName: string, cookieValue: string) => {
  Cookies.set(cookieName, cookieValue, {
    expires: addHours(new Date(), 24),
    domain: getDomainName(),
    secure: getCookieIsSecure()
  });
};

export const getCookie = (cookieName: string) => {
  return Cookies.get(cookieName);
};

export const clearCookie = (cookieName: string) => {
  Cookies.remove(cookieName, { domain: getDomainName() });
};

export const setLastRoute = (lastRoute: string) => {
  getLocalStorage()?.setItem(LAST_ROUTE, lastRoute);
};

export const getLastRoute = () => getLocalStorage()?.getItem(LAST_ROUTE);

export const clearLastRoute = () => getLocalStorage()?.removeItem(LAST_ROUTE);

export const setReferralPropertyId = (referralPropertyId: string) => {
  getLocalStorage()?.setItem(REFERRAL_PROPERTY_ID, referralPropertyId);
};

export const getReferralPropertyId = () => getLocalStorage()?.getItem(REFERRAL_PROPERTY_ID);

export const clearReferralPropertyId = () => {
  getLocalStorage()?.removeItem(REFERRAL_PROPERTY_ID);
};

export const setAccessToken = (token: string) => {
  setCookie(SESSION_COOKIE_NAME, token);
};

export const getAccessToken = () => getCookie(SESSION_COOKIE_NAME);

export const clearAccessToken = () => {
  clearCookie(SESSION_COOKIE_NAME);
};

export const setIdToken = (token: string) => {
  setCookie(ID_TOKEN_COOKIE_NAME, token);
};

export const getIdToken = () => getCookie(ID_TOKEN_COOKIE_NAME);

export const clearIdToken = () => {
  clearCookie(ID_TOKEN_COOKIE_NAME);
};

export const setUserInfo = (userInfo: GetUserInfoResponseBody) => {
  getLocalStorage()?.setItem('user', JSON.stringify(userInfo));
};

export const setShowOfferSummary = (showOfferSummary: boolean) => {
  getLocalStorage()?.setItem('showOfferSummary', showOfferSummary + '');
};

export const getShowOfferSummary = () => {
  const showOfferSummary = getLocalStorage()?.getItem('showOfferSummary');

  return showOfferSummary === 'true' || false;
};

export const getUserInfo = () => {
  const user = getLocalStorage()?.getItem('user');

  return user ? JSON.parse(user) : null;
};

export const getUserTargetMarkets = () => {
  const user = getUserInfo();
  const markets: TargetMarket[] = [];

  if (user && user.onboardingPrimaryOperatingMarket) {
    markets.push({ sfId: user.onboardingPrimaryOperatingMarket, isPrimary: true });
  }

  if (user.onboardingAdditionalOperatingMarket) {
    const secondary = user.onboardingAdditionalOperatingMarket.split(';');
    secondary.forEach((market: string) => {
      markets.push({ sfId: market, isPrimary: false });
    });
  }

  return markets;
};

export const getUserFullName = () => {
  const user = getUserInfo();

  return user?.name;
};

export const getAuth0UserId = () => {
  const user = getUserInfo();

  return user?.auth0UserId;
};

export const clearUserInfo = () => {
  getLocalStorage()?.removeItem('user');
};

export const logout = () => {
  clearIdToken();
  clearAccessToken();
  clearUserInfo();

  if (process.env.NODE_ENV !== 'test') {
    import('../store').then((x) => x.default.dispatch({ type: LOGOUT }));
  }
};

export const isUserAuthenticated = () => {
  return getAccessToken() !== null && getAccessToken() !== '' && !!getAccessToken();
};

export const isHomeownerAuthenticated = () => {
  const homeownerLoginToken = getCookie(SELLER_SESSION_COOKIE);

  return !!homeownerLoginToken;
};

export const getAttributionUserId = () => {
  return getLocalStorage()?.getItem(ATTRIBUTION_USER_ID);
};

export const setAttributionUserId = (attributionUserId: string) => {
  getLocalStorage()?.setItem(ATTRIBUTION_USER_ID, attributionUserId);
};

export const clearAttributionUserId = () => getLocalStorage()?.removeItem(ATTRIBUTION_USER_ID);

export const getSuggestedMembership = () => {
  return getLocalStorage()?.getItem(SUGGESTED_MEMBERSHIP);
};

export const setSuggestedMembership = (suggestedMembership: string) => {
  getLocalStorage()?.setItem(SUGGESTED_MEMBERSHIP, suggestedMembership);
};

export const clearSuggestedMembership = () => getLocalStorage()?.removeItem(SUGGESTED_MEMBERSHIP);
