import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgMapMarker({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 10.6 10.3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <circle style={{ fill: '#c5c5c5cc' }} cx="5.3" cy="5.1" r="4.9" />
      <circle
        style={{ fill: '#DB3C55', stroke: '#FFFFFF', strokeWidth: 0.5, strokeMiterlimit: 10 }}
        cx="5.3"
        cy="5.1"
        r="3.8"
      />
    </svg>
  );
}

export default SvgMapMarker;
