import React from 'react';

import { ChangedMembershipModal } from './ChangedMembershipModal';
import { DowngradeModal } from './DowngradeModal';

export const MembershipModals = () => {
  return (
    <>
      <DowngradeModal />
      <ChangedMembershipModal />
    </>
  );
};
