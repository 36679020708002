import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCellPhone({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="cell-phone_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.cell-phone_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="cell-phone_svg__cls-1"
        d="M22.5 49.17a1.9 1.9 0 101.9 1.9 1.9 1.9 0 00-1.9-1.9zm0 2.79a.9.9 0 11.9-.89.9.9 0 01-.9.93zM19.91 10.06h5.18a.8.8 0 100-1.59h-5.18a.8.8 0 100 1.59z"
      />
      <path
        className="cell-phone_svg__cls-1"
        d="M32.68 2.47H12.32A4.82 4.82 0 007.5 7.29v45.35a4.83 4.83 0 004.82 4.83h20.36a4.83 4.83 0 004.82-4.83V7.29a4.82 4.82 0 00-4.82-4.82zM12.32 4.4h20.36a2.9 2.9 0 012.89 2.89v5.3H9.43v-5.3a2.9 2.9 0 012.89-2.89zm20.36 51.14H12.32a2.9 2.9 0 01-2.89-2.9v-6.26h25.86v-1H9.43V13.59h26.14v39.05a2.9 2.9 0 01-2.89 2.9z"
      />
    </svg>
  );
}

export default SvgCellPhone;
