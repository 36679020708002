import React from 'react';

import { UserInfoModal } from './UserInfoModal';

export const HomeownerModals = () => {
  return (
    <>
      <UserInfoModal />
    </>
  );
};
