import { useQuery } from '@tanstack/react-query';

import { clearAccessToken, setUserInfo } from '../../utils/localStorage.utils';
import { api } from '../_base';

// GET /oauth/user-info
export const getUserInfo = async () => {
  const response = await api.authControllerGetUserInfo();

  if (response && response.data && !response.data.onboardingCompleted) {
    clearAccessToken();
  }

  setUserInfo(response.data);

  return response.data;
};

export const useUserInfo = () => {
  return useQuery({ queryKey: ['getUserInfo'], queryFn: getUserInfo });
};
