import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAuctionActivity = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#auction-activity_svg__a)">
      <path
        d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#FABC3D"
      />
      <path
        d="M45.964 15.71a2.327 2.327 0 0 0-2.327-2.328H20.364a2.327 2.327 0 0 0-2.327 2.327v32.582a2.327 2.327 0 0 0 2.327 2.327h23.273a2.327 2.327 0 0 0 2.327-2.327V15.709Z"
        fill="#fff"
      />
      <path d="M20.363 16.873h23.273V44.8H20.363V16.873Z" fill="#3E3E3F" />
      <path d="M32 48.873a1.164 1.164 0 1 0 0-2.328 1.164 1.164 0 0 0 0 2.328Z" fill="#CFD3D4" />
      <path
        d="M34.715 34.715a.776.776 0 1 0 0-1.552.776.776 0 0 0 0 1.552ZM37.236 33.745h2.91v.582h-2.91v-.582ZM34.715 38.4a.776.776 0 1 0 0-1.551.776.776 0 0 0 0 1.551ZM37.236 37.43h2.91v.582h-2.91v-.582Z"
        fill="#B6BCBD"
      />
      <path d="M32 15.709a.582.582 0 1 0 0-1.164.582.582 0 0 0 0 1.164Z" fill="#3E3E3F" />
      <path d="m20.37 38.982 22.103-22.104H20.369v22.104Z" fill="#5B5C5F" />
      <path d="M24.436 25.6h3.49v4.073h-3.49V25.6Z" fill="#FBE158" />
      <path d="M30.254 23.273h3.49v6.4h-3.49v-6.4Z" fill="#17B198" />
      <path d="M36.654 20.363h3.491v9.31h-3.49v-9.31Z" fill="#059BBF" />
      <path d="M27.346 41.31a4.655 4.655 0 1 0 0-9.31 4.655 4.655 0 0 0 0 9.31Z" fill="#FACB1B" />
      <path
        d="M32 36.654a4.653 4.653 0 0 1-7.257 3.86A4.654 4.654 0 0 1 23.03 38.4l8.6-3.58c.24.562.372 1.183.372 1.834Z"
        fill="#4AC3AF"
      />
      <path
        d="M32.008 36.656a4.654 4.654 0 0 1-2.133 3.914l-2.518-3.968 4.279-1.78c.24.562.372 1.183.372 1.834Z"
        fill="#21B2D1"
      />
      <path d="m31.628 34.82-4.282 1.784V32a4.665 4.665 0 0 1 4.282 2.82Z" fill="#FA5655" />
    </g>
    <defs>
      <clipPath id="auction-activity_svg__a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAuctionActivity;
