import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerIntroCall = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-intro-call_svg__a)">
      <path
        d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#25B7D3"
      />
      <path
        d="M48.529 45.186a4.654 4.654 0 0 0-3.007-2.788l-9.45-2.253-4.072.582-4.073-.581-9.449 2.252a4.654 4.654 0 0 0-3.007 2.788l-2.09 5.432h37.237l-2.09-5.432Z"
        fill="#3E3E3F"
      />
      <path
        d="M32 41.31c-1.909-.637-4.165-1.837-5.818-4.39v7.88l5.236 5.819h1.746l4.654-5.819v-7.894c-1.653 2.559-3.907 3.766-5.818 4.404Z"
        fill="#AF7653"
      />
      <path
        d="M26.182 36.92v7.88l5.236 5.818H32V41.31c-1.909-.636-4.165-1.837-5.818-4.39Z"
        fill="#BF8662"
      />
      <path
        d="M26.182 38.981c0 .582 2.598 9.221 5.818 11.637h-1.745s-5.819-7.564-8.728-7.564c.582-2.909 4.655-4.073 4.655-4.073Zm11.636 0c0 .582-2.598 9.221-5.818 11.637h1.745s5.819-7.564 8.728-7.564c-.582-2.909-4.655-4.073-4.655-4.073Z"
        fill="#fff"
      />
      <path
        d="M46.546 50.036c0 .32-.262.582-.582.582h-6.4a.584.584 0 0 1-.582-.582v-4.072c0-.32.262-.582.582-.582h6.4c.32 0 .582.262.582.582v4.072Z"
        fill="#fff"
      />
      <path
        d="M39.564 47.127h2.327v2.909h-2.327v-2.91Zm6.4-1.746h-2.328v-.29a.875.875 0 0 0-.872-.873.875.875 0 0 0-.873.872v.291h-2.327a.584.584 0 0 0-.582.582v.582h7.564v-.582a.583.583 0 0 0-.582-.582Z"
        fill="#9CE5F4"
      />
      <path
        d="M40.727 27.345h-.582v-.012c-.01-5.241-.595-9.879-8.145-9.879s-8.135 4.638-8.145 9.879c0 9.773 4.654 12.812 8.145 13.976 2.679-.893 6.04-2.894 7.46-8.19 1.928-.332 2.43-2.683 2.43-4.481 0-.647-.581-1.293-1.163-1.293Z"
        fill="#BF8662"
      />
      <path
        d="M32 17.454c-7.55 0-8.135 4.638-8.145 9.879 0 9.773 4.654 12.812 8.145 13.976V17.454Z"
        fill="#CC9978"
      />
      <path
        d="M35.42 17.574a3.492 3.492 0 1 0-6.84 0c-4.13.76-7.594 3.827-5.307 9.772.215.03.431.043.649.042l.368 6.629c.11 1.976.229 3.523 1.31 4.965V27.13c3.333-.988 6.4-4.438 6.4-4.438s3.067 3.45 6.4 4.438v11.853c1.081-1.442 1.2-2.989 1.31-4.966l.369-6.629c.217.002.433-.012.648-.042 2.287-5.944-1.177-9.011-5.306-9.77Z"
        fill="#3E3E3F"
      />
      <path
        d="M29.09 34.91h5.819s0 1.745-2.91 1.745c-2.908 0-2.908-1.746-2.908-1.746Z"
        fill="#84462D"
      />
      <path
        d="M30.258 28.51a1.164 1.164 0 0 0-1.163-1.164h-.004l.003.036a1.164 1.164 0 0 1-1.16 1.163 1.163 1.163 0 0 0 2.324-.036Zm5.815 0a1.164 1.164 0 0 0-1.164-1.164h-.003l.003.036a1.164 1.164 0 0 1-1.16 1.164 1.163 1.163 0 0 0 2.324-.037Z"
        fill="#3E3E3F"
      />
      <path
        d="M34.221 35.861h-4.443c-.447 0-.838.232-1.065.582h-.786c-1.207 0-3.49-.73-3.49-3.49V27.15h-1.358a2.715 2.715 0 1 0 0 5.431h.194v.37c0 3.415 2.783 4.655 4.654 4.655h.676a1.269 1.269 0 0 0 1.175.793h4.444a1.27 1.27 0 1 0 0-2.539Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="seller-intro-call_svg__a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerIntroCall;
