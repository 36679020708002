import * as Sentry from '@sentry/nextjs';

import { isAxiosError } from '../api/helpers';
import { isYupValidationError } from '../validation';

export const captureException = (error: unknown, extraResponseDetails?: unknown) => {
  // failure related to Sentry should not cause the caller to throw

  try {
    // eslint-disable-next-line no-console
    console.log('Sentry.captureException capturing', error);

    Sentry.withScope((scope) => {
      if (isYupValidationError(error)) {
        // eslint-disable-next-line no-console
        console.log('validation errors', error.errors);
        scope.setExtra('validation errors', error.errors);
      } else if (isAxiosError(error)) {
        const response = error.response;

        scope.setExtra('response', {
          data: response?.data,
          status: response?.status,
          statusText: response?.statusText
        });
      }

      if (extraResponseDetails) {
        scope.setExtra('extra response', extraResponseDetails);
      }

      Sentry.captureException(error);
    });
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.log('Sentry.captureException failed', e);
  }
};
