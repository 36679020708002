import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTrash = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#trash_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.333 2.834H11V1.667c0-.833-.667-1.5-1.5-1.5h-3c-.833 0-1.5.667-1.5 1.5v1.167H.667c-.267 0-.5.233-.5.5 0 .266.233.5.5.5h1.366l.9 10.633c.067.767.7 1.367 1.5 1.367H11.6c.767 0 1.433-.6 1.5-1.367L14 3.834h1.333c.267 0 .5-.234.5-.5 0-.267-.233-.5-.5-.5ZM6 1.667c0-.267.233-.5.5-.5h3c.267 0 .5.233.5.5v1.167H6V1.667Zm6.067 12.7c-.034.267-.234.467-.5.467H4.433c-.266 0-.466-.2-.5-.467l-.9-10.533H12.967l-.9 10.533ZM6.5 6.334c-.267 0-.5.233-.5.5v5c0 .266.233.5.5.5s.5-.234.5-.5v-5c0-.267-.233-.5-.5-.5Zm2.5.5c0-.267.233-.5.5-.5s.5.233.5.5v5c0 .266-.233.5-.5.5a.513.513 0 0 1-.5-.5v-5Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="trash_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTrash;
