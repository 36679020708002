import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../store';
import { selectMiscState } from '../../../store/misc';
import * as actions from '../../../store/misc';

export const useUserEmail = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userEmail = useSelector(selectMiscState).email;

  const setUserEmail = (email?: string) => {
    dispatch(actions.setUserEmail(email));
  };

  return [userEmail, setUserEmail] as const;
};
