import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import { getSubscription, updateSubscription } from '../../api/subscription';
// @todo: move to page. Using common modal styles until we move to page.
import modalStyles from '../../common/modal.module.scss';
import { ArrowLeftIcon } from '../../components/Icons';
import { Modal } from '../../components/Modal';
import { Subscription } from '../../contexts/SubscriptionProvider';
import {
  mapStaticSubscriptionToStripeSubscription,
  mapStripeSubscriptionToStaticSubscription,
  StaticSubscription
} from '../../helpers/subscriptionHelper';
import { useModalByName } from '../../hooks/useModalByName';
import { useModalManager } from '../../hooks/useModalManager';
import { CHOOSE_ACTIVE_MARKETS_MODAL_NAME } from '../../modals/MarketManagementModals/ChooseActiveMarketsModal';
import { CHANGE_MEMBERSHIP_MODAL_NAME } from '../../modals/MembershipModals/ChangedMembershipModal';
import { DOWNGRADE_MODAL_NAME } from '../../modals/MembershipModals/DowngradeModal';
import {
  clearAttributionUserId,
  clearSuggestedMembership,
  getAttributionUserId,
  getSuggestedMembership,
  getUserInfo
} from '../../utils/localStorage.utils';
import BackLink from '../BackLink/BackLink';
import { MembershipPayment } from '../MembershipPayment';
import { MembershipTierSelection } from '../MembershipTierSelection';
import { ModalCloseButton } from '../Modal/ModalCloseButton';

import styles from './MembershipModal.module.scss';

import { MEMBERSHIP_MODAL_NAME } from '.';

type MembershipModalProps = React.PropsWithChildren<{
  subscriptions: Array<Subscription>;
}>;

export const MembershipModal: React.FC<MembershipModalProps> = ({ subscriptions = [] }) => {
  const { showModal } = useModalManager();
  const [isOpen, , close, options] = useModalByName(MEMBERSHIP_MODAL_NAME);
  // Static subscription meant for payment screen.
  const [paymentStaticSubscription, setPaymentStaticSubscription] = useState<StaticSubscription>();
  // Static subscription that is updated on every change.
  const [latestStaticSubscription, setLatestStaticSubscription] = useState<StaticSubscription>();
  const [currentSubscription, setCurrentSubscription] = useState<Subscription>();
  // Static subscription that the user is currently on.
  const currentStaticSubscription =
    currentSubscription && mapStripeSubscriptionToStaticSubscription(currentSubscription);

  const isPaymentScreen = paymentStaticSubscription !== undefined;

  const user = getUserInfo();
  const isFirstLogin = user?.userIsConfirmingMarketsForFirstTime ?? false;

  const router = useRouter();

  useEffect(() => {
    if (!isOpen) return;

    // override subscription selection with suggested subscription if exists
    const suggestedMemberhsip = getSuggestedMembership();

    if (suggestedMemberhsip) {
      const suggestedSubscription: Subscription = { name: suggestedMemberhsip };
      setCurrentSubscription(suggestedSubscription);
      clearSuggestedMembership();
    } else {
      (async () => {
        const response = await getSubscription();

        if (response.data.success) {
          const subscription = subscriptions.find(({ name }) => name === response.data?.data?.tier);

          if (subscription) {
            setCurrentSubscription(subscription);
            const attributionUserId = getAttributionUserId();

            if (attributionUserId) {
              await updateSubscription({
                priceId: subscription.id as string,
                attributionLinkVisitedUserId: attributionUserId,
                attributionLinkVisitedOn: new Date().toISOString()
              });
              clearAttributionUserId();
            }
          }
        }
      })();
    }
  }, [subscriptions, isOpen]);

  const closeModalWithSubscriptionChange = async () => {
    options?.onComplete && options?.onComplete();

    close();
  };

  const handlePurchaseMembership = async () => {
    closeModalWithSubscriptionChange();
    showModal(CHOOSE_ACTIVE_MARKETS_MODAL_NAME, {
      context: latestStaticSubscription,
      onComplete: () => {
        // Reload page to avoid any stale subscription issues after upgrade
        router.reload();
      }
    });
    setPaymentStaticSubscription(undefined);
  };

  const handleBackClick = () => {
    if (paymentStaticSubscription) {
      setPaymentStaticSubscription(undefined);
    } else {
      close();
    }
  };

  const handleClose = () => {
    options?.onComplete && options?.onComplete();
    close();
  };

  const handleMembershipTierSelection = async (newSubscription: StaticSubscription) => {
    if (!currentSubscription) {
      console.log('Missing current subscription');
      toast.error('An error has occurred');

      return;
    }

    setLatestStaticSubscription(newSubscription);

    if (newSubscription.tier === 0 && currentStaticSubscription?.tier === 0) {
      handleClose();
    }

    if (
      newSubscription.tier < mapStripeSubscriptionToStaticSubscription(currentSubscription).tier
    ) {
      // Downgrade
      const newStripeSubscription = mapStaticSubscriptionToStripeSubscription(
        subscriptions,
        newSubscription,
        currentSubscription.interval === 'month'
      );

      if (!newStripeSubscription?.id) {
        console.log('Unable to find new subscription');
        toast.error('An error has occurred');

        return;
      }

      const response = await updateSubscription({
        priceId: newStripeSubscription.id
      });

      if (!response.data.success || !response.data.data) {
        toast.error('An error occurred while updating your subscription');

        return;
      }

      await closeModalWithSubscriptionChange();
      showModal(DOWNGRADE_MODAL_NAME, { context: newSubscription.downgradeString });
    } else if (
      newSubscription.tier === mapStripeSubscriptionToStaticSubscription(currentSubscription).tier
    ) {
      if (currentSubscription.interval === 'month') {
        // TODO, only allow upgrade to yearly (disable monthly option on PaymentModal)
        // Upgrade to yearly
        setPaymentStaticSubscription(newSubscription);
      } else {
        // Downgrade to monthly
        const newStripeMonthlySubscription = mapStaticSubscriptionToStripeSubscription(
          subscriptions,
          newSubscription,
          true
        );

        if (!newStripeMonthlySubscription?.id) {
          console.log('Unable to find new subscription');
          toast.error('An error has occurred');

          return;
        }

        const response = await updateSubscription({ priceId: newStripeMonthlySubscription.id });

        if (!response.data.success || !response.data.data) {
          toast.error('An error occurred while updating your subscription');

          return;
        }

        await closeModalWithSubscriptionChange();
        showModal(CHANGE_MEMBERSHIP_MODAL_NAME, { context: newSubscription });
      }
    } else {
      setPaymentStaticSubscription(newSubscription);
    }
  };

  return (
    <Modal isShowing={isOpen} hide={handleClose} modalClassNames={styles.modalContainer}>
      <div className={classNames(styles.membershipModal, isPaymentScreen && styles.wider)}>
        {isPaymentScreen ? (
          <BackLink
            slug="#"
            icon={<ArrowLeftIcon />}
            onClick={handleBackClick}
            className={styles.backLink}
          />
        ) : (
          <ModalCloseButton isAbsolute onClick={handleClose} />
        )}

        <h2 className={classNames(modalStyles.modalHeader, styles.header)}>
          {!isFirstLogin
            ? 'Manage Your Membership'
            : 'Pick a plan that works best for your business'}
        </h2>

        {!isFirstLogin && !isPaymentScreen && !options?.context && (
          <p className={styles.text}>
            Select a plan and enter your payment information to change your membership. If you
            choose Essential, there will be no further charges to your card.
          </p>
        )}

        {isPaymentScreen && (
          <p className={styles.text}>
            To confirm your membership, enter your payment information and select your billing
            method.
          </p>
        )}

        {!currentStaticSubscription ? (
          <Spinner />
        ) : isPaymentScreen ? (
          <MembershipPayment
            onPurchaseMembership={handlePurchaseMembership}
            subscriptions={subscriptions}
            staticSubscription={paymentStaticSubscription}
          />
        ) : (
          <MembershipTierSelection
            onMembershipTierSelection={handleMembershipTierSelection}
            currentUserSubscription={currentStaticSubscription}
            subscriptions={subscriptions}
            featureContext={options?.context as string}
            isFirstLogin={isFirstLogin}
          />
        )}
      </div>
    </Modal>
  );
};
