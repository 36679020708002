import {
  isGreaterThanOrEqualToValue,
  isPositive,
  minBidErrorMessage,
  offerErrorMessages
} from '../utils/validation.utils';

import { useInputValidation } from './useInputValidation';

export const useMinBidValidation = (offerAmount: string, minBid: number) => {
  const valueAsNumber = Number(offerAmount);
  const validations = [
    { message: offerErrorMessages.positive, validator: () => isPositive(valueAsNumber) },
    {
      message: minBidErrorMessage.greaterThanMinBid(),
      validator: () => isGreaterThanOrEqualToValue(valueAsNumber, minBid)
    }
  ];

  return useInputValidation(validations);
};
