import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgViewAsCard({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="view-as-card_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.view-as-card_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="view-as-card_svg__cls-1"
        d="M24.13 5H10.19A5.2 5.2 0 005 10.19v13.93a5.2 5.2 0 005.19 5.19h13.94a5.19 5.19 0 005.18-5.19V10.19A5.19 5.19 0 0024.13 5zm2.35 19.12a2.36 2.36 0 01-2.35 2.36H10.19a2.36 2.36 0 01-2.36-2.36V10.19a2.36 2.36 0 012.36-2.36h13.94a2.36 2.36 0 012.35 2.36zM49.81 5H35.87a5.19 5.19 0 00-5.18 5.19v13.93a5.19 5.19 0 005.18 5.19h13.94A5.2 5.2 0 0055 24.12V10.19A5.2 5.2 0 0049.81 5zm2.36 19.12a2.36 2.36 0 01-2.36 2.36H35.87a2.36 2.36 0 01-2.35-2.36V10.19a2.36 2.36 0 012.35-2.36h13.94a2.36 2.36 0 012.36 2.36zM24.13 30.69H10.19A5.2 5.2 0 005 35.88v13.93A5.2 5.2 0 0010.19 55h13.94a5.19 5.19 0 005.18-5.19V35.88a5.19 5.19 0 00-5.18-5.19zm2.35 19.12a2.36 2.36 0 01-2.35 2.36H10.19a2.36 2.36 0 01-2.36-2.36V35.88a2.36 2.36 0 012.36-2.36h13.94a2.36 2.36 0 012.35 2.36zM49.81 30.69H35.87a5.19 5.19 0 00-5.18 5.19v13.93A5.19 5.19 0 0035.87 55h13.94A5.2 5.2 0 0055 49.81V35.88a5.2 5.2 0 00-5.19-5.19zm2.36 19.12a2.36 2.36 0 01-2.36 2.36H35.87a2.36 2.36 0 01-2.35-2.36V35.88a2.36 2.36 0 012.35-2.36h13.94a2.36 2.36 0 012.36 2.36z"
      />
    </svg>
  );
}

export default SvgViewAsCard;
