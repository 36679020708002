import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgPhone({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M39.88 55c-6.39 0-13.34-5.28-20.66-15.68l-.45-.65c-4.94-7-7.83-13.31-8.57-18.68C9.23 13 12 9.21 14.48 7.26L16.11 6a4.5 4.5 0 013.6-.91 4.79 4.79 0 013.1 2l5.28 7.49a5.57 5.57 0 01-1 7.5l-1.25 1a.81.81 0 00-.06 1.07l8.66 12.31a.87.87 0 00.53.36.56.56 0 00.46-.11l1.25-1a4.89 4.89 0 017.08 1.16L49 44.36a5.26 5.26 0 01-.94 7.09l-1.64 1.28A10.53 10.53 0 0139.88 55zM16.24 9.4l-.17.13c-1.9 1.49-4 4.45-3.22 10 .68 4.9 3.39 10.76 8.07 17.41l.45.64c8.46 12 14.73 14.54 18.51 14.54a8 8 0 005-1.71l1.64-1.28a2.32 2.32 0 00.39-3.13l-5.28-7.5a2.31 2.31 0 00-3.35-.5L37 39a3.13 3.13 0 01-2.54.62 3.5 3.5 0 01-2.19-1.45l-8.64-12.36a3.74 3.74 0 01.61-5l1.25-1a2.63 2.63 0 00.45-3.54l-5.28-7.5a2.17 2.17 0 00-1.39-.9 2 2 0 00-1.57.39L16.25 9.4z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgPhone;
