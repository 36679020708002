import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCheckmarkBox({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M47.92 55H12.08A7.08 7.08 0 015 47.92V12.08A7.08 7.08 0 0112.08 5h35.84A7.08 7.08 0 0155 12.08v35.84A7.08 7.08 0 0147.92 55zM12.08 7.83a4.25 4.25 0 00-4.25 4.25v35.84a4.25 4.25 0 004.25 4.25h35.84a4.25 4.25 0 004.25-4.25V12.08a4.25 4.25 0 00-4.25-4.25z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgCheckmarkBox;
