import classNames from 'classnames';
import React from 'react';

import styles from './Tag.module.scss';

type TagVariants =
  | 'invertedGray'
  | 'gray'
  | 'gray20'
  | 'darkGray'
  | 'green'
  | 'mediumGreen'
  | 'darkGreen'
  | 'successLightGreen'
  | 'yellow'
  | 'orange'
  | 'brown'
  | 'red'
  | 'invertedRed'
  | 'blue'
  | 'infoLightBlue'
  | 'invertedBlue';

export interface TagProps {
  variant?: TagVariants;
  rounded?: boolean;
  small?: boolean;
  large?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Tag: React.FC<TagProps> = ({
  variant = 'gray',
  rounded = false,
  small = false,
  large = false,
  children,
  className,
  ...rest
}) => {
  return (
    <div
      className={classNames(styles.tag, styles[variant], className, {
        [styles.rounded]: rounded,
        [styles.small]: small,
        [styles.large]: large
      })}
      {...rest}
    >
      {children}
    </div>
  );
};
