import React from 'react';

import { Input } from '../../../components/design-system';

import styles from './EditCardModal.module.scss';

export interface BillingAddressInputGroupProps {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  onChange: (name: string, value: string) => void;
}

export const BillingAddressInputGroup = ({
  address1,
  address2,
  city,
  state,
  postalCode,
  onChange
}: BillingAddressInputGroupProps) => {
  return (
    <>
      <span className={styles.label}>Billing Address</span>
      <fieldset className={styles.tempInputs}>
        <div className={styles.inputField}>
          <Input
            className="fs-exclude"
            onChange={(e) => onChange('address1', e.target.value)}
            placeholder="Street Address"
            value={address1}
          />
        </div>
        <div className={styles.inputField}>
          <Input
            className="fs-exclude"
            onChange={(e) => onChange('address2', e.target.value)}
            placeholder="Street Address (Optional)"
            value={address2}
          />
        </div>
        <Input onChange={(e) => onChange('city', e.target.value)} placeholder="City" value={city} />
        <div className={styles.inputGroup}>
          <div className={styles.inputField}>
            <Input
              onChange={(e) => onChange('state', e.target.value)}
              placeholder="State"
              value={state}
            />
          </div>
          <div className={styles.inputField}>
            <Input
              onChange={(e) => onChange('postalCode', e.target.value)}
              placeholder="Zipcode"
              type="number"
              value={postalCode}
            />
          </div>
        </div>
      </fieldset>
    </>
  );
};
