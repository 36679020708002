import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Tooltip } from 'reactstrap';

import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { updatePassword } from '../../../api/user-account/accountSettingsApi';
import modalStyles from '../../../common/modal.module.scss';
import { AlertBox } from '../../../components/AlertBox';
import { InputLabel } from '../../../components/Form/InputLabel/InputLabel';
import { Modal } from '../../../components/Modal';
import { ModalCloseButton } from '../../../components/Modal/ModalCloseButton';
import { PasswordValidationPopOver } from '../../../components/PasswordValidationPopOver';
import ErrorTooltip from '../../../components/SignUp/QuestionSets/ErrorTooltip';
import PasswordInput from '../../../components/SignUp/QuestionSets/PasswordInput';
import { useModalByName } from '../../../hooks/useModalByName';
import { passwordRules, strengthCheck } from '../../../utils/common.utils';
import { CancelSaveButtonGroup } from '../CancelSaveButtonGroup/CancelSaveButtonGroup';

import styles from './ChangePasswordModal.module.scss';

import { CHANGE_PASSWORD_MODAL_NAME } from '.';

type FormInputs = {
  currentPassword: string;
  newPassword: string;
  confirm: string;
};

export const ChangePasswordModal = () => {
  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    setError,
    setValue,
    control,
    formState: { errors }
  } = useForm<FormInputs>({ mode: 'onChange' });

  const [isModalOpen, , closeModal] = useModalByName(CHANGE_PASSWORD_MODAL_NAME);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [newPwStrength, setNewPwStrength] = useState(passwordRules);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const watchNewPassword = watch('newPassword', '');

  useEffect(() => {
    return () => {
      clearErrors();
      setTooltipOpen(false);
    };
  }, [clearErrors]);

  const onSubmit = (data: FormInputs) => {
    setIsSubmitting(true);
    const tempData = {
      prevPassword: data.currentPassword,
      newPassword: data.newPassword
    };
    updatePassword(tempData)
      .then(() => {
        toast.success('Password updated!');
        setIsSubmitting(false);
        reset();
        closeModal();
      })
      .catch((error: unknown) => {
        setIsSubmitting(false);

        if (isApiErrorWithMessage(error)) {
          setErrorMessage(getMessageFromError(error));
        }
      });
  };

  const validatePasswordStrength = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    setTooltipOpen(false);

    let password: string;

    if (typeof e === 'string') {
      password = e;
    } else {
      password = e.target.value;
    }

    setTooltipOpen(true);
    setError('newPassword', {
      types: { pattern: 'Strong password' }
    });

    let psdValid = { ...newPwStrength };
    psdValid = strengthCheck(psdValid, password);

    if (psdValid.isValid) {
      setValue('newPassword', password);
      clearErrors('newPassword');
      setTooltipOpen(false);
    }

    setNewPwStrength(psdValid);
  };

  const useRegisterWithRef = (...args: Parameters<typeof register>) => {
    const ref = useRef<HTMLElement | null>(null);
    const field = register(...args);

    return [
      {
        ...field,
        ref(e: HTMLElement | null) {
          field.ref(e);
          ref.current = e;
        }
      },
      ref
    ] as const;
  };

  const [password, passwordRef] = useRegisterWithRef('newPassword', {
    required: true,
    validate: (v) => !!v && newPwStrength.isValid
  });

  return (
    <Modal
      isShowing={isModalOpen}
      hide={closeModal}
      modalClassNames={modalStyles.modal}
      overlayClassNames={styles.overlay}
    >
      <div className={styles.modalHeader}>Change Password</div>

      <ModalCloseButton isAbsolute onClick={closeModal} />

      {errorMessage && <AlertBox>{errorMessage}</AlertBox>}

      <form className="cform newcform" onSubmit={handleSubmit(onSubmit)}>
        <InputLabel htmlFor="currentPassword">Current password</InputLabel>
        <input
          id="currentPassword"
          type="password"
          className="inputField"
          {...register('currentPassword', { required: true })}
        />
        <Tooltip
          id="tooltip-top"
          className="mttooltip"
          placement="bottom"
          isOpen={errors.currentPassword}
          target="currentPassword"
        >
          {errors.currentPassword && errors.currentPassword.type === 'required'
            ? 'Enter your password'
            : ''}
        </Tooltip>
        <div className={styles.newPasswordWrapper}>
          <InputLabel htmlFor="newPassword">New password</InputLabel>

          <Controller
            name="newPassword"
            control={control}
            render={() => {
              return (
                <PasswordInput
                  ref={password.ref}
                  type="password"
                  handleOnChange={validatePasswordStrength}
                  hasErrors={errors.newPassword && errors.newPassword.type === 'required'}
                  inputFieldClassName="inputField"
                  passwordButtonClassName={styles.passwordButton}
                />
              );
            }}
          />
          <PasswordValidationPopOver
            passwordStrength={newPwStrength}
            tooltipOpen={
              tooltipOpen || (errors.newPassword && errors.newPassword.type === 'validate')
            }
            setTooltip={() => setTooltipOpen(!tooltipOpen)}
            id="newPassword"
            passwordToolIconClassName={styles.passwordToolIcon}
          />
          <ErrorTooltip
            isOpen={errors.newPassword && !tooltipOpen}
            target={passwordRef}
            message={
              errors.newPassword && errors.newPassword.type === 'pattern'
                ? 'Enter strong  password'
                : 'Enter your password'
            }
          />
        </div>
        <InputLabel htmlFor="confirm">Confirm password</InputLabel>
        <input
          type="password"
          id="confirm"
          className="inputField"
          {...register('confirm', {
            validate: (value) => value === watchNewPassword || 'Passwords do not match'
          })}
        />
        <Tooltip
          id="tooltip-top"
          className="mttooltip"
          placement="bottom"
          isOpen={errors.confirm}
          target="confirm"
        >
          {errors.confirm && errors.confirm.message}
        </Tooltip>
        <div className={styles.buttonWrapper}>
          <CancelSaveButtonGroup
            isSubmitting={isSubmitting}
            isSaveDisabled={false}
            onSaveClick={() => {
              // noop, save is handled by form submit
            }}
            onCancelClick={closeModal}
          />
        </div>
      </form>
    </Modal>
  );
};
