import classNames from 'classnames';
import React, { ElementRef, forwardRef, HTMLAttributes } from 'react';

import { VisuallyHidden } from '../VisuallyHidden';

import styles from './Spinner.module.scss';

export type SpinnerVariants = 'rotate' | 'pulse';
export type SpinnerProps = HTMLAttributes<HTMLDivElement> & {
  variant?: SpinnerVariants;
};

const DEFAULT_TAG = 'div';

export const Spinner = forwardRef<ElementRef<typeof DEFAULT_TAG>, SpinnerProps>(
  ({ className, variant = 'rotate', ...rest }, ref) => {
    return (
      <div className={classNames(styles.spinner, styles[variant], className)} {...rest} ref={ref}>
        <VisuallyHidden>Loading...</VisuallyHidden>
      </div>
    );
  }
);
