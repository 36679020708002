import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import * as authApi from '../../../api/auth';
import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { resendConfirmationCode } from '../../../api/user/resendConfirmationCode';
import { useConfirmationCodeRef } from '../../../hooks/store/misc/useConfirmationCodeRef';
import { SignupClosingProps, SignUpFormData } from '../../../types/onBoarding';
import { ConfirmationCodeScreen } from '../../ConfirmationCodeScreen/ConfirmationCodeScreen';
import styles from '../SignUp.module.scss';

type VerifyPhoneStepType = Pick<
  SignupClosingProps,
  'stepNavigate' | 'initialFormData' | 'jumpStep' | 'updateFormData'
>;

export const VerifyPhoneStep = ({
  stepNavigate,
  jumpStep,
  initialFormData,
  updateFormData
}: VerifyPhoneStepType) => {
  const confirmationCodeRef = useConfirmationCodeRef();
  const [failCounter, setFailCounter] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [loader, setLoader] = useState(false);
  const phoneNode = initialFormData.phone ? (
    <NumberFormat
      value={initialFormData.phone}
      format={'(###) ###-####'}
      mask={'\u2007'}
      displayType="text"
      className={styles.userContactInfo}
    />
  ) : (
    'your phone'
  );

  const handleConfirm = async () => {
    setLoader(true);

    try {
      await authApi.verifySmsCode({
        email: initialFormData.email,
        confirmationCode: confirmationCodeRef.current,
        password: initialFormData.password
      });
      setLoader(false);
      toast.success('Code confirmed.');
    } catch (error: unknown) {
      setLoader(false);
      const newFailCounter = failCounter + 1;
      setFailCounter(newFailCounter);

      if (isApiErrorWithMessage(error)) {
        setErrorMessage(getMessageFromError(error));
      } else {
        setErrorMessage('Invalid verification code provided, please try again.');
      }

      if (newFailCounter >= 3) {
        jumpStep('accessVerificationFailedStep');
      }

      return;
    }

    if (stepNavigate) {
      stepNavigate('next');
    }
  };

  const handleResendCode = async () => {
    try {
      await resendConfirmationCode(initialFormData.email);
      toast.success('Resent verification code.');
    } catch (error: unknown) {
      if (isApiErrorWithMessage(error)) {
        setErrorMessage(getMessageFromError(error));
      } else {
        setErrorMessage('Could not resend verification code.');
      }
    }
  };

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.title}>Verify your mobile number</div>
        <p className={styles.subTitle}>
          We just sent a verification code to {phoneNode}. Enter the code below to verify your
          device.
        </p>
      </div>
      <ConfirmationCodeScreen<SignUpFormData>
        codeLength={6}
        onConfirm={handleConfirm}
        onResendCode={handleResendCode}
        hideLogo
        headerText=""
        validationType="phone"
        errorMessage={errorMessage}
        initialFormData={initialFormData}
        updateFormData={updateFormData}
      />
    </>
  );
};
