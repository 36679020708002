import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMobilePhone = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.335 0H4.668a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6.667a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM8 14.98a.666.666 0 1 1 0-1.333.666.666 0 0 1 0 1.333Zm4-2.98a.666.666 0 0 1-.666.667H4.668A.667.667 0 0 1 4.001 12V2.353a.667.667 0 0 1 .667-.666h6.667a.667.667 0 0 1 .666.666V12Z"
      fill="#DB3D55"
    />
  </svg>
);
export default SvgMobilePhone;
