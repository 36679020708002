import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgStep1({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M30 4.65a25 25 0 1025 25 25 25 0 00-25-25zm5.94 33.45h-9.6v-1.7h3.85V24.19v-1.12L27 25.81a1 1 0 01-.25.15 1.2 1.2 0 01-.24 0 .74.74 0 01-.34-.08.62.62 0 01-.22-.2l-.7-1 5.32-4.61h1.81V36.4h3.53z"
        fill="#c9c9c9"
      />
    </svg>
  );
}

export default SvgStep1;
