import { useMutation, useQuery } from '@tanstack/react-query';

import { api } from '../_base';
import { UpdateBillingDetailsRequestBody } from '../_base/generated/data-contracts';

// GET /api/user/subscription/billing
export const getBillingDetails = () => {
  return api.subscriptionControllerBillingDetails();
};

export const useBillingDetails = () => {
  return useQuery({ queryKey: ['getBillingDetails'], queryFn: getBillingDetails });
};

// POST /api/user/subscription/billing
export const updateBillingDetails = (body: UpdateBillingDetailsRequestBody) => {
  return api.subscriptionControllerUpdateBillingDetails(body);
};

export const useUpdateBillingDetails = () => {
  return useMutation({ mutationFn: updateBillingDetails });
};
