import React from 'react';

import { StaticSubscription } from '../../helpers/subscriptionHelper';
import { useModalByName } from '../../hooks/useModalByName';

import { InterestedMarketsModal } from './InterestedMarketsModal';

export const CHOOSE_INTERESTED_MARKETS_MODAL_NAME = 'ChooseInterestedMarketsModal';

export const ChooseInterestedMarketsModal = () => {
  const [isOpen, , close, options] = useModalByName(CHOOSE_INTERESTED_MARKETS_MODAL_NAME);

  return (
    <InterestedMarketsModal
      isOpen={isOpen}
      close={close}
      headerText="Don't see a market you're interested in?"
      bodyText="Sundae continues to expand into new markets. Select markets outside of your target markets or even markets Sundae is not in yet and we will notify you once we are in that market."
      showSkip
      subscription={options?.context as StaticSubscription}
      onComplete={options?.onComplete}
    />
  );
};
