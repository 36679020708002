import React, { useCallback, useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import { getTerritorySearch, getTerritorySearchInterested } from '../../api/public';
import { captureApiException } from '../../logging';
import { AreaOption } from '../../types/onBoarding';

import styles from './TerritoryTypeahead.module.scss';

interface TerritoryTypeaheadProps {
  selectedMarkets: AreaOption[];
  setSelectedMarkets: (a: AreaOption[]) => void;
  activeOnly?: boolean;
}

export const TerritoryTypeahead = ({
  selectedMarkets,
  setSelectedMarkets,
  activeOnly = true
}: TerritoryTypeaheadProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [shownTerritories, setShownTerritories] = useState<AreaOption[]>([]);
  const [isTypeAheadOpen, setIsTypeAheadOpen] = useState(false);

  const handleSearch = useCallback(() => {
    setIsLoading(true);
    const territorySearchMethod = activeOnly ? getTerritorySearch : getTerritorySearchInterested;
    territorySearchMethod()
      .then((response) => {
        response.data.forEach((item: AreaOption) => {
          item.name = `${item.name}, ${item.stateShort} `;
        });
        setIsLoading(false);
        setShownTerritories(response.data);
      })
      .catch((e: unknown) => {
        setIsLoading(false);
        console.error(e);
        captureApiException(e);
      });
  }, [activeOnly]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const getFilteredLocations = useCallback(() => {
    const selectedMarketIds = selectedMarkets.map((market) => market.territoryId);
    // Remove items already selected in active markets.
    const filteredTerritories = shownTerritories.filter(
      (shownTerritory: AreaOption) => !selectedMarketIds.includes(shownTerritory.sfId)
    );

    return filteredTerritories;
  }, [shownTerritories, selectedMarkets]);

  return (
    <div className={styles.typeaheadContainer}>
      <Typeahead
        id="secondaryMarket"
        labelKey={(option) => `${option.name}`}
        isLoading={isLoading}
        multiple
        positionFixed
        selected={selectedMarkets}
        minLength={0}
        onChange={setSelectedMarkets}
        options={getFilteredLocations()}
        placeholder={'Select Area'}
        open={isTypeAheadOpen}
        onFocus={() => setIsTypeAheadOpen(true)}
        onBlur={() => setIsTypeAheadOpen(false)}
      />
    </div>
  );
};
