import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { VerifyEmailRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/homeowner/verify-email-code
export const homeownerVerifyEmailCode = (body: VerifyEmailRequestBody) => {
  return api.authControllerHomeownerVerifyEmailCode(body);
};

export const useHomeownerVerifyEmailCode = () => {
  return useMutation({ mutationFn: homeownerVerifyEmailCode });
};
