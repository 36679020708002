import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgPropertyPin({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="property-pin_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.8 11.92"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>
          {
            '.property-pin_svg__cls-1{fill:#dc3d55}.st2{fill:none;stroke:#FFFFFF;stroke-width:0.5;stroke-linejoin:round;stroke-miterlimit:10;'
          }
        </style>
      </defs>
      <path
        className="property-pin_svg__cls-1"
        d="M9.6,4.7c0,4-4.7,7.2-4.7,7.2S0.2,8.7,0.2,4.7c0-3.2,3.1-4.5,4.7-4.5C6.6,0.2,9.6,1.4,9.6,4.7z"
      />
      <path
        className="st2"
        d="M4.9,1.7L8,4.2H7.3v3.2H5.5V5.9c-0.2,0-1.3,0-1.3,0v1.5H2.4V4.2H1.7L4.9,1.7z"
      />
    </svg>
  );
}

export default SvgPropertyPin;
