import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'reactstrap';

import thankYouIcon from '../../../public/images/thankyou-image.png';

import styles from './ResetPassword.module.scss';

interface IProps {
  isLinkValid: boolean;
}

export const LoadingComponent = (props: IProps) => {
  const { isLinkValid } = props;

  return (
    <div className={styles.formContent}>
      <div className={styles.mainHeader}>
        <img className="mb-5" width="50%" src={thankYouIcon.src} alt="" />
        <div className={styles.title}>Verifying please wait</div>
        <div className={classNames(styles.subTitle, 'mt-3')}>
          {!isLinkValid ? <span className="text-danger">Link is not valid</span> : <Spinner />}
        </div>
      </div>
    </div>
  );
};
