import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { GetUserInfoResponseBody } from '../../api/_base/generated/data-contracts';
import { MEMBERSHIP_MODAL_NAME } from '../../components/MembershipModal';
import { StaticSubscription } from '../../helpers/subscriptionHelper';
import { useModalManager } from '../../hooks/useModalManager';
import { useTerritoryOptions } from '../../hooks/useTerritoryOptions';
import { setTypeaheadActiveMarkets } from '../../store/territory';
import { AreaOption } from '../../types/onBoarding';
import { getMaxMarketText } from '../../utils/formatter.utils';
import { getUserInfo } from '../../utils/localStorage.utils';

import { CHOOSE_PRIMARY_MARKETS_MODAL_NAME } from './ChoosePrimaryMarketsModal';
import { EDIT_PRIMARY_MARKETS_MODAL_NAME } from './EditPrimaryMarketsModal';
import styles from './MarketManagementModals.module.scss';
import { TypeAheadMarketSelectionModal } from './TypeAheadMarketSelectionModal';

export const MARKET_DEFAULT_MAX_ACTIVE = 1;

interface ActiveMarketsModalProps {
  isOpen: boolean;
  close: () => void;
  showCloseConfirm?: boolean;
  headerText: string;
  bodyText: string | JSX.Element;
  nextModalName: typeof EDIT_PRIMARY_MARKETS_MODAL_NAME | typeof CHOOSE_PRIMARY_MARKETS_MODAL_NAME;
  subscription?: StaticSubscription;
  onComplete?: () => void;
}

export const ActiveMarketsModal = ({
  isOpen,
  close,
  showCloseConfirm,
  headerText,
  bodyText,
  nextModalName,
  subscription,
  onComplete
}: ActiveMarketsModalProps) => {
  const userInfo: GetUserInfoResponseBody | undefined = getUserInfo();
  const dispatch = useDispatch();

  const { territoryOptions: activeMarketList } = useTerritoryOptions(
    userInfo?.onboardingAdditionalOperatingMarket || ''
  );

  useEffect(() => {
    dispatch(setTypeaheadActiveMarkets(activeMarketList));
  }, [activeMarketList, dispatch]);

  const [selectedMarkets, setSelectedMarkets] = useState<AreaOption[]>(activeMarketList);
  const [errorMessage, setErrorMessage] = useState<JSX.Element | null>(null);

  const isUnlimitedMarkets =
    (subscription?.markets || userInfo?.userSubscriptionTierInfo?.maxAllowedSecondaryMarkets) ===
    -1;

  const maxMarkets = isUnlimitedMarkets ? -1 : 6;
  // const maxMarkets =
  //   subscription?.markets ||
  //   userInfo?.userSubscriptionTierInfo?.maxAllowedSecondaryMarkets ||
  //   MARKET_DEFAULT_MAX_ACTIVE;

  const { showModal } = useModalManager();

  const getErrorMarketNumberErrorMessage = useCallback(
    (newMarketLength: number): JSX.Element | null => {
      if (newMarketLength < 1) {
        return <>Please select at least one market</>;
      } else if (!isUnlimitedMarkets && newMarketLength > maxMarkets) {
        const showUpgradeMembership = () => {
          close();
          showModal(MEMBERSHIP_MODAL_NAME);
        };

        return (
          <>
            Your plan currently only covers up to {maxMarkets} markets, and you’ve exceeded this
            selection.{' '}
            <a className={styles.upgradeText} onClick={showUpgradeMembership}>
              Upgrade your plan
            </a>
          </>
        );
      }

      return null;
    },
    [maxMarkets]
  );

  const handleSetSelectedMarkets = (newMarkets: AreaOption[]) => {
    setSelectedMarkets(newMarkets);
    setErrorMessage(getErrorMarketNumberErrorMessage(newMarkets.length));
  };

  useEffect(() => {
    if (activeMarketList.length) {
      setSelectedMarkets(activeMarketList);
      setErrorMessage(getErrorMarketNumberErrorMessage(activeMarketList.length));
    }
  }, [activeMarketList, getErrorMarketNumberErrorMessage]);

  const handleEditMarkets = () => {
    dispatch(setTypeaheadActiveMarkets(selectedMarkets));
    showModal(nextModalName, { context: subscription, onComplete });
    close();
  };

  return (
    <TypeAheadMarketSelectionModal
      isOpen={isOpen}
      close={close}
      showCloseConfirm={showCloseConfirm}
      headerText={headerText}
      bodyText={bodyText}
      selectedMarkets={selectedMarkets}
      errorMessage={errorMessage}
      isSubmitDisabled={!!errorMessage || selectedMarkets.length === 0}
      handleSetSelectedMarkets={handleSetSelectedMarkets}
      marketSelectionText={getMaxMarketText(maxMarkets)}
      onSubmit={handleEditMarkets}
    />
  );
};
