/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BeginRegistrationRequestBody,
  BeginRegistrationResponseBody,
  ChangePasswordV2RequestBody,
  ChangePasswordV2ResponseBody,
  CompleteRegistrationRequestBody,
  CompleteRegistrationResponseBody,
  ForgotPasswordV2RequestBody,
  ForgotPasswordV2ResponseBody,
  GetZipCodeResponseBody,
  LoginV2RequestBody,
  LoginV2ResponseBody,
  LogoutV2ResponseBody,
  RegistrationResendEmailCodeResponseBody,
  RegistrationResendPhoneCodeResponseBody,
  RegistrationVerifyEmailRequestBody,
  RegistrationVerifyEmailResponseBody,
  RegistrationVerifyPhoneRequestBody,
  RegistrationVerifyPhoneResponseBody,
  ResendUpdatedEmailCodeV2ResponseBody,
  ResendUpdatedPhoneCodeV2ResponseBody,
  ResetPasswordV2RequestBody,
  ResetPasswordV2ResponseBody,
  UpdateUserEmailV2RequestBody,
  UpdateUserEmailV2ResponseBody,
  UpdateUserPhoneV2RequestBody,
  UpdateUserPhoneV2ResponseBody,
  VerifyUpdatedEmailV2RequestBody,
  VerifyUpdatedEmailV2ResponseBody,
  VerifyUpdatedPhoneV2RequestBody,
  VerifyUpdatedPhoneV2ResponseBody
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class V2<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerLoginV2
   * @summary V2 login
   * @request POST:/v2/login
   */
  authV2ControllerLoginV2 = (data: LoginV2RequestBody, params: RequestParams = {}) =>
    this.http.request<LoginV2ResponseBody, any>({
      path: `/v2/login`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerLoginV2Seller
   * @summary V2 seller login
   * @request POST:/v2/login/seller
   */
  authV2ControllerLoginV2Seller = (data: LoginV2RequestBody, params: RequestParams = {}) =>
    this.http.request<LoginV2ResponseBody, any>({
      path: `/v2/login/seller`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerLogoutV2
   * @summary V2 logout
   * @request POST:/v2/logout
   * @secure
   */
  authV2ControllerLogoutV2 = (params: RequestParams = {}) =>
    this.http.request<LogoutV2ResponseBody, any>({
      path: `/v2/logout`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerChangePassword
   * @summary Change password
   * @request PUT:/v2/user/password
   * @secure
   */
  authV2ControllerChangePassword = (
    data: ChangePasswordV2RequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<ChangePasswordV2ResponseBody, any>({
      path: `/v2/user/password`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerUpdateUserEmail
   * @summary Update email for a user
   * @request PUT:/v2/user/email
   * @secure
   */
  authV2ControllerUpdateUserEmail = (
    data: UpdateUserEmailV2RequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<UpdateUserEmailV2ResponseBody, any>({
      path: `/v2/user/email`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerResendUpdatedEmailCode
   * @summary Resend email verification code
   * @request POST:/v2/user/email/resend-code
   * @secure
   */
  authV2ControllerResendUpdatedEmailCode = (params: RequestParams = {}) =>
    this.http.request<ResendUpdatedEmailCodeV2ResponseBody, any>({
      path: `/v2/user/email/resend-code`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerVerifyUpdatedEmail
   * @summary Submit email verification code
   * @request POST:/v2/user/email/verify
   * @secure
   */
  authV2ControllerVerifyUpdatedEmail = (
    data: VerifyUpdatedEmailV2RequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifyUpdatedEmailV2ResponseBody, any>({
      path: `/v2/user/email/verify`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerUpdateUserPhone
   * @summary Update phone for a user
   * @request PUT:/v2/user/phone
   * @secure
   */
  authV2ControllerUpdateUserPhone = (
    data: UpdateUserPhoneV2RequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<UpdateUserPhoneV2ResponseBody, any>({
      path: `/v2/user/phone`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerResendUpdatedPhoneCode
   * @summary Resend phone verification code
   * @request POST:/v2/user/phone/resend-code
   * @secure
   */
  authV2ControllerResendUpdatedPhoneCode = (params: RequestParams = {}) =>
    this.http.request<ResendUpdatedPhoneCodeV2ResponseBody, any>({
      path: `/v2/user/phone/resend-code`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerVerifyUpdatedPhone
   * @summary Submit phone verification code
   * @request POST:/v2/user/phone/verify
   * @secure
   */
  authV2ControllerVerifyUpdatedPhone = (
    data: VerifyUpdatedPhoneV2RequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifyUpdatedPhoneV2ResponseBody, any>({
      path: `/v2/user/phone/verify`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerForgotPassword
   * @summary Forgot password
   * @request POST:/v2/password/forgot
   */
  authV2ControllerForgotPassword = (
    data: ForgotPasswordV2RequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<ForgotPasswordV2ResponseBody, any>({
      path: `/v2/password/forgot`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthV2ControllerConfirmForgotPassword
   * @summary Reset password
   * @request POST:/v2/password/reset
   */
  authV2ControllerConfirmForgotPassword = (
    data: ResetPasswordV2RequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<ResetPasswordV2ResponseBody, any>({
      path: `/v2/password/reset`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Registration
   * @name RegistrationControllerBeginRegistration
   * @summary Begin registration
   * @request POST:/v2/registration
   */
  registrationControllerBeginRegistration = (
    data: BeginRegistrationRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<BeginRegistrationResponseBody, any>({
      path: `/v2/registration`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Registration
   * @name RegistrationControllerBeginRegistrationSeller
   * @summary Begin seller registration
   * @request POST:/v2/registration/seller
   */
  registrationControllerBeginRegistrationSeller = (
    data: BeginRegistrationRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<BeginRegistrationResponseBody, any>({
      path: `/v2/registration/seller`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Registration
   * @name RegistrationControllerResendPhoneCode
   * @summary Resend phone verification code
   * @request POST:/v2/registration/phone/resend-code
   * @secure
   */
  registrationControllerResendPhoneCode = (params: RequestParams = {}) =>
    this.http.request<RegistrationResendPhoneCodeResponseBody, any>({
      path: `/v2/registration/phone/resend-code`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Registration
   * @name RegistrationControllerVerifyPhone
   * @summary Submit phone verification code
   * @request POST:/v2/registration/phone/verify
   * @secure
   */
  registrationControllerVerifyPhone = (
    data: RegistrationVerifyPhoneRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<RegistrationVerifyPhoneResponseBody, any>({
      path: `/v2/registration/phone/verify`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Registration
   * @name RegistrationControllerResendEmailCode
   * @summary Resend email verification code
   * @request POST:/v2/registration/email/resend-code
   * @secure
   */
  registrationControllerResendEmailCode = (params: RequestParams = {}) =>
    this.http.request<RegistrationResendEmailCodeResponseBody, any>({
      path: `/v2/registration/email/resend-code`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Registration
   * @name RegistrationControllerVerifyEmail
   * @summary Submit email verification code
   * @request POST:/v2/registration/email/verify
   * @secure
   */
  registrationControllerVerifyEmail = (
    data: RegistrationVerifyEmailRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<RegistrationVerifyEmailResponseBody, any>({
      path: `/v2/registration/email/verify`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Registration
   * @name RegistrationControllerCompleteRegistration
   * @summary Complete registration
   * @request POST:/v2/registration/complete
   * @secure
   */
  registrationControllerCompleteRegistration = (
    data: CompleteRegistrationRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<CompleteRegistrationResponseBody, any>({
      path: `/v2/registration/complete`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Territory
   * @name TerritoryV2ControllerGetZipCode
   * @summary Get a zip code
   * @request GET:/v2/zip-codes/{zipCode}
   * @secure
   */
  territoryV2ControllerGetZipCode = (zipCode: string, params: RequestParams = {}) =>
    this.http.request<GetZipCodeResponseBody, any>({
      path: `/v2/zip-codes/${zipCode}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
}
