import React from 'react';

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
type SupportLinkProps = React.PropsWithChildren<AnchorProps>;

const SupportLink: React.FC<SupportLinkProps> = ({ children, ...otherProps }: SupportLinkProps) => {
  // const { children, ...elementProps } = props;

  return (
    <a href="mailto:support@sundae.com" {...otherProps}>
      {children ?? 'support@sundae.com'}
    </a>
  );
};

export default SupportLink;
