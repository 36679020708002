import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgFilterSort({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="filter-sort_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.filter-sort_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="filter-sort_svg__cls-1"
        d="M32.62 26.9l-2.81 3.76a1.42 1.42 0 00-.28.85l-.1 17.24L24 51.37l-.2-19.86a1.47 1.47 0 00-.22-.77L8.93 7.83H33.6a1.42 1.42 0 000-2.83H6.38a1.36 1.36 0 00-1.21.74 1.42 1.42 0 00.06 1.45L21.05 32l.2 21.65a1.39 1.39 0 00.64 1.18 1.31 1.31 0 00.74.22 1.33 1.33 0 00.58-.13l8.18-3.94a1.4 1.4 0 00.8-1.27l.1-17.71 2.52-3.38a1.43 1.43 0 00-.25-2 1.36 1.36 0 00-1.94.28zM54.42 8.71L50 5.31a1.47 1.47 0 00-1.79 0L43.79 8.7a1.52 1.52 0 00-.29 2.1 1.48 1.48 0 002.08.3l2-1.56v16.73a1.48 1.48 0 103 0V9.51l2 1.58a1.46 1.46 0 002.07-.28 1.5 1.5 0 00-.23-2.1z"
      />
      <path
        className="filter-sort_svg__cls-1"
        d="M42.6 21.68l-2 1.56V6.5a1.48 1.48 0 10-3 0v16.76l-2-1.57a1.47 1.47 0 00-2.08.27 1.52 1.52 0 00.27 2.11l4.38 3.39a1.47 1.47 0 001.79 0l4.46-3.39a1.53 1.53 0 00.26-2.07 1.47 1.47 0 00-2.08-.32z"
      />
    </svg>
  );
}

export default SvgFilterSort;
