import { PostHog } from 'posthog-js';

import { getUserInfo } from '../../utils/localStorage.utils';

export const recordMembershipPaymentFailed = (
  params: { errorMessage?: string } | undefined,
  posthog: PostHog
) => {
  const eventName = 'membership_payment_failed';

  posthog.capture(eventName, {
    account_id: `${getUserInfo()?.sfId || ''}`,
    contact_email: `${getUserInfo()?.email || ''}`,
    sundae_user_id: `${getUserInfo()?.sundaeUserId || ''}`,
    error_message: params?.errorMessage || ''
  });
};
