import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';
import { SortOrder } from '../_base/generated/data-contracts';

const territorySearchQuery = {
  limit: 500,
  offset: 0,
  sortBy: 'name',
  sortOrder: SortOrder.ASC
};

export const getTerritorySearch = () => {
  return api.territoryControllerFindTerritoryAutoComplete(territorySearchQuery);
};

export const useTerritorySearch = () => {
  return useQuery({ queryKey: ['getTerritorySearch'], queryFn: getTerritorySearch });
};

// GET /public/territory/autocomplete
export const getTerritorySearchInterested = () => {
  return api.territoryControllerFindTerritoryAutoComplete({
    ...territorySearchQuery,
    includeComingSoon: true,
    includeInactive: true
  });
};

export const useTerritorySearchInterested = () => {
  return useQuery({
    queryKey: ['getTerritorySearchInterested'],
    queryFn: getTerritorySearchInterested
  });
};
