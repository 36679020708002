import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgYearBuiltCalendar({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M49.81 7.58H44.7v-.21A2.65 2.65 0 0041.84 5 2.66 2.66 0 0039 7.37v.21H21v-.21A2.66 2.66 0 0018.16 5a2.65 2.65 0 00-2.86 2.37v.21h-5.11C7.33 7.58 5 10.59 5 14.3v34C5 52 7.33 55 10.19 55h39.62c2.86 0 5.19-3 5.19-6.72v-34c0-3.69-2.33-6.7-5.19-6.7zm-8.51-.21a.55.55 0 011.08 0v3.87a.55.55 0 01-1.08 0zm-23.68 0a.55.55 0 011.08 0v3.87a.55.55 0 01-1.08 0zm34.55 40.91c0 1.95-1.08 3.59-2.36 3.59H10.19c-1.28 0-2.36-1.64-2.36-3.59V22.37h44.34zm0-29H7.83V14.3c0-2 1.08-3.59 2.36-3.59h5.11v.53a2.65 2.65 0 002.86 2.37A2.66 2.66 0 0021 11.24v-.53h18v.53a2.66 2.66 0 002.87 2.37 2.65 2.65 0 002.86-2.37v-.53h5.11c1.28 0 2.36 1.64 2.36 3.59z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgYearBuiltCalendar;
