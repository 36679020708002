import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgYearBuilt({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="year-built_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.year-built_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="year-built_svg__cls-1"
        d="M54 17a1.51 1.51 0 00-1.66.42l-4.31 4.93a1.18 1.18 0 00-.27-.32L28.59 5.37a1.49 1.49 0 00-2 0L5.51 24.06a1.49 1.49 0 00-.41 1.65 1.5 1.5 0 001.4 1h3.77v20.71a1.5 1.5 0 001.5 1.5h11.91a1.51 1.51 0 001.5-1.49l.09-12.1h5.67v6.6l-9.23 10.58a1.52 1.52 0 00-.23 1.62 1.5 1.5 0 001.36.87l30.66-.29a1.49 1.49 0 001.5-1.5V18.43A1.51 1.51 0 0054 17zM33.9 33.8a1.51 1.51 0 00-1.5-1.49h-8.62a1.49 1.49 0 00-1.49 1.49l-.1 12.1h-8.92V25.18a1.5 1.5 0 00-1.5-1.5h-1.31L27.62 8.49l18.21 15.82a1.41 1.41 0 00.34.22L34 38.52zM52 51.73L26.17 52l4.44-5.08 2.67 2.22a.94.94 0 00.63.23 1 1 0 00.77-.36 1 1 0 00-.13-1.41l-2.63-2.18 3.66-4.18 2.76 2.29a1 1 0 00.64.23 1 1 0 00.77-.36 1 1 0 00-.13-1.4l-2.73-2.3 3.53-4 3 2.51a.94.94 0 00.63.23 1 1 0 00.77-.36 1 1 0 00-.13-1.41l-3-2.47 3.74-4.28 2.81 2.33a.94.94 0 00.63.23 1 1 0 00.77-.36 1 1 0 00-.13-1.41l-2.77-2.29 5.21-6z"
      />
      <path
        className="year-built_svg__cls-1"
        d="M48.36 38.92a1 1 0 00-1.1.28L40.42 47a1 1 0 00.75 1.66l6.83-.05a1 1 0 001-1v-7.75a1 1 0 00-.64-.94zM47 46.62h-3.62l3.62-4.1z"
      />
    </svg>
  );
}

export default SvgYearBuilt;
