import * as React from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
  fontSize?: string;
}

function SvgHelp({
  title,
  titleId,
  fontSize,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="help_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
      fontSize={fontSize ?? '1rem'}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#db3d55"
        d="M30 4.51a25 25 0 1025 25 25 25 0 00-25-25zm0 47.17a22.17 22.17 0 1122.13-22.17A22.2 22.2 0 0130 51.68z"
      />
      <path
        fill="#db3d55"
        className="help_svg__cls-1"
        d="M31.2 40.72a3.3 3.3 0 00-1.05-.72 3 3 0 00-1.27-.27 3.12 3.12 0 00-1.3.27 3.15 3.15 0 00-1 .71 3.42 3.42 0 00-.69 1.05A3.2 3.2 0 0025.6 43a3.27 3.27 0 00.25 1.3 3.42 3.42 0 00.69 1 3 3 0 001 .7 3.27 3.27 0 001.3.25 3.2 3.2 0 001.27-.25 3.12 3.12 0 001.05-.7 3.3 3.3 0 00.71-1 3.12 3.12 0 00.27-1.3 3 3 0 00-.27-1.27 3.3 3.3 0 00-.67-1.01zM36.54 14.89a8.92 8.92 0 00-2.95-1.65 11.78 11.78 0 00-3.74-.57 13 13 0 00-5.27 1.07 13.78 13.78 0 00-2.08 1.15 17.75 17.75 0 00-1.75 1.38L22 18.34a1.09 1.09 0 001 .63 1.47 1.47 0 00.8-.36 10.65 10.65 0 011.21-.79 10.49 10.49 0 011.87-.82 7.91 7.91 0 012.55-.36 6.38 6.38 0 012 .31 5.06 5.06 0 011.65.9 4.21 4.21 0 011.1 1.41 4.15 4.15 0 01.4 1.85 5.27 5.27 0 01-.6 2.59 8.33 8.33 0 01-1.47 1.95 13 13 0 01-1.91 1.56 20.66 20.66 0 00-1.86 1.44 6.93 6.93 0 00-1.36 1.59 3.09 3.09 0 00-.4 2l.35 4.12h3.33l.47-3.77a2.71 2.71 0 01.76-1.49 14.07 14.07 0 011.53-1.34l1.9-1.43a11.61 11.61 0 001.86-1.79 8.89 8.89 0 001.41-2.41 8.64 8.64 0 00.57-3.29 8.09 8.09 0 00-.7-3.39 7.77 7.77 0 00-1.92-2.56z"
      />
    </svg>
  );
}

export default SvgHelp;
