import { PostHog } from 'posthog-js';

import { PropertyDetailsResponseBody } from '../../api/_base/generated/data-contracts';
import { recordPropertyClickEvent } from '../realTimeEvents/recordPropertyEvent';

import { recordPostHogEvent } from './recordPostHogEvent';

interface RecordUserClickedDocumentParams {
  property: PropertyDetailsResponseBody;
  title: string;
}

export const recordUserClickedDocument = (
  { property, title }: RecordUserClickedDocumentParams,
  posthog: PostHog
) => {
  const key = title.toLowerCase().replace(/ /g, '_');

  recordPostHogEvent(property, `user_clicked_${key}`, posthog, { document_title: title });
  recordPropertyClickEvent(property, key, posthog);
};
