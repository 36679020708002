import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import {
  connectedInvestorsReferralSource,
  marketReferralSources
} from '../../../helpers/constants';
import { PasswordStrength, SignUpFormData } from '../../../types/onBoarding';
import {
  emailPattern,
  passwordRules,
  phonePattern,
  strengthCheck
} from '../../../utils/common.utils';
import { PasswordValidationPopOver } from '../../PasswordValidationPopOver';
import styles from '../SignUp.module.scss';

import ErrorTooltip from './ErrorTooltip';
import PasswordInput from './PasswordInput';
export interface startProps {
  initialFormData: SignUpFormData;
}

export interface StartScreenFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  marketingReferralSource: string;
  initialTermsAndConditionsAcceptedOn: string;
}

// TODO: reuse SimpleCreateAccountForm for this component. Holding off for the moment due to reduced
// testing capabilities in multiple environments. Jan 27, 2023.

function StartScreen({ initialFormData }: startProps) {
  const {
    register,
    control,
    clearErrors,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext<StartScreenFormData>();

  const [isPasswordTooltipOpen, setIsPasswordTooltipOpen] = useState(false);

  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>(passwordRules);

  const validatePasswordStrength = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    setIsPasswordTooltipOpen(false);

    let password: string;

    if (typeof e === 'string') {
      password = e;
    } else {
      password = e.target.value;
    }

    setIsPasswordTooltipOpen(true);
    setError('password', {
      types: { pattern: 'Strong password' }
    });

    let psdValid = { ...passwordStrength };
    psdValid = strengthCheck(psdValid, password);

    if (psdValid.isValid) {
      setValue('password', password);
      clearErrors('password');
      setIsPasswordTooltipOpen(false);
    }

    setPasswordStrength(psdValid);
  };

  useEffect(() => {
    setValue('firstName', initialFormData.firstName);
    setValue('lastName', initialFormData.lastName);
    setValue('email', initialFormData.email);
    setValue('marketingReferralSource', initialFormData.marketingReferralSource);
    if (initialFormData.password !== '') setValue('password', initialFormData.password);
    setValue('phone', initialFormData.phone);
    setValue(
      'initialTermsAndConditionsAcceptedOn',
      initialFormData.initialTermsAndConditionsAcceptedOn
    );
  }, []);

  useEffect(() => {
    // validatePasswordStrength(getValues('password'));
    clearErrors();
    setIsPasswordTooltipOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = () => setIsPasswordTooltipOpen(!isPasswordTooltipOpen);

  const useRegisterWithRef = (...args: Parameters<typeof register>) => {
    const ref = useRef<HTMLElement | null>(null);
    const field = register(...args);

    return [
      {
        ...field,
        ref(e: HTMLElement | null) {
          field.ref(e);
          ref.current = e;
        }
      },
      ref
    ] as const;
  };

  const [password, passwordRef] = useRegisterWithRef('password', {
    required: true,
    validate: (v) => !!v && passwordStrength.isValid
  });
  // Phone pattern regex is misleading here as it checks after the formatting
  const [phone, phoneRef] = useRegisterWithRef('phone', { required: true, pattern: phonePattern });
  const [firstName, firstNameRef] = useRegisterWithRef('firstName', {
    required: true,
    maxLength: 80
  });
  const [lastName, lastNameRef] = useRegisterWithRef('lastName', { required: true, maxLength: 80 });
  const [email, emailRef] = useRegisterWithRef('email', { required: true, pattern: emailPattern });
  const [marketingReferralSource, marketingReferralSourceRef] = useRegisterWithRef(
    'marketingReferralSource',
    { required: true }
  );

  return (
    <div>
      <div className={styles.formSet}>
        <input
          placeholder="First Name*"
          type="text"
          className={classNames(styles.inputField, {
            [styles.inputError]: errors.firstName
          })}
          {...firstName}
        />
        <ErrorTooltip
          isOpen={!!errors.firstName}
          target={firstNameRef}
          message={'Enter your first name'}
        />
      </div>
      <div className={styles.formSet}>
        <input
          placeholder="Last Name*"
          type="text"
          className={classNames(styles.inputField, {
            [styles.inputError]: errors.lastName
          })}
          {...lastName}
        />
      </div>
      <ErrorTooltip
        isOpen={!!errors.lastName}
        target={lastNameRef}
        message={'Enter your last name'}
      />
      <div className={styles.formSet}>
        <input
          placeholder="Email*"
          type="email"
          className={classNames(styles.inputField, {
            [styles.inputError]: errors.email && errors.email.type === 'required'
          })}
          {...email}
        />
      </div>
      <ErrorTooltip
        isOpen={!!errors.email}
        target={emailRef}
        message={
          errors.email && errors.email.type === 'pattern' ? 'Enter valid email' : 'Enter your email'
        }
      />
      <div className={styles.formSet}>
        <Controller
          render={({ field: { onChange, value } }) => (
            <NumberFormat
              className={classNames(styles.inputField, {
                [styles.inputError]: errors.phone && errors.phone.type === 'required'
              })}
              format={'(###) ###-####'}
              mask={'\u2007'}
              placeholder={'Mobile Phone*'}
              onValueChange={(v) => onChange(v.value)}
              value={value}
              getInputRef={phone.ref}
              title="Please input a mobile number capable of receiving SMS for verification purposes"
            />
          )}
          name="phone"
          rules={{ required: true }}
          control={control}
        />

        <ErrorTooltip
          isOpen={!!errors.phone}
          target={phoneRef}
          message={
            errors.phone && errors.phone.type === 'pattern'
              ? 'Enter valid number'
              : 'Enter your phone number'
          }
        />
      </div>
      <div className={styles.formSet}>
        <Controller
          name="password"
          control={control}
          render={() => {
            return (
              <PasswordInput
                ref={password.ref}
                placeholder="Password*"
                type="password"
                handleOnChange={validatePasswordStrength}
                hasErrors={errors.password && errors.password.type === 'required'}
              />
            );
          }}
        />
        <PasswordValidationPopOver
          passwordStrength={passwordStrength}
          tooltipOpen={isPasswordTooltipOpen}
          setTooltip={toggle}
          id="validationTooltip"
          className={styles.passwordInputPopover}
        />
      </div>
      <ErrorTooltip
        isOpen={errors.password && !isPasswordTooltipOpen}
        target={passwordRef}
        message={
          errors.password && errors.password.type === 'pattern'
            ? 'Enter strong  password'
            : 'Enter your password'
        }
      />
      {initialFormData.marketingReferralSource !== connectedInvestorsReferralSource && (
        <div
          className={classNames(
            styles.signUpDropDown,
            'd-sm-flex d-block justify-content-between align-items-center mt-4 '
          )}
        >
          <select
            className={classNames('form-control', {
              [styles.selectBoxError]:
                errors.marketingReferralSource && errors.marketingReferralSource.type === 'required'
            })}
            defaultValue={initialFormData.marketingReferralSource}
            id="marketingReferralSource"
            {...marketingReferralSource}
          >
            <option value="" disabled>
              How did you hear about Sundae?*
            </option>
            {marketReferralSources.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          {marketingReferralSourceRef.current && (
            <ErrorTooltip
              isOpen={!!errors.marketingReferralSource}
              target="marketingReferralSource"
              message={'Please tell us how you heard about Sundae'}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default StartScreen;
