import { useMutation, useQuery } from '@tanstack/react-query';

import { api } from '../_base';
import { UpdateSubscriptionRequestBody } from '../_base/generated/data-contracts';

// GET /api/user/subscription
export const getSubscription = () => {
  return api.subscriptionControllerGetSubscription();
};

export const useSubscription = () => {
  return useQuery({ queryKey: ['getSubscription'], queryFn: getSubscription });
};

type PreviewSubscriptionUpdateQuery = Parameters<
  typeof api.subscriptionControllerPreviewSubscriptionUpdate
>[0];

// GET /api/user/subscription/preview
export const getSubscriptionUpdatePreview = (params: PreviewSubscriptionUpdateQuery) => {
  return api.subscriptionControllerPreviewSubscriptionUpdate(params);
};

export const useSubscriptionUpdatePreview = (params: PreviewSubscriptionUpdateQuery) => {
  return useQuery({
    queryKey: ['getSubscriptionUpdatePreview'],
    queryFn: () => getSubscriptionUpdatePreview(params)
  });
};

// PATCH /api/user/subscription
export const updateSubscription = (
  body: Omit<UpdateSubscriptionRequestBody, 'agreedToMembershipTermsAndConditionsOn' | 'updatedOn'>
) => {
  return api.subscriptionControllerUpdateSubscription({
    ...body,
    agreedToMembershipTermsAndConditionsOn: body.hasAgreedToMembershipTermsAndConditions
      ? Date.now()
      : undefined,
    updatedOn: new Date().toISOString()
  });
};

export const useUpdateSubscription = () => {
  return useMutation({ mutationFn: updateSubscription });
};

// POST /api/user/subscription/cancel-downgrade
export const cancelSubscriptionDowngrade = () => {
  return api.subscriptionControllerCancelSubscriptionDowngrade();
};

export const useCancelSubscriptionDowngrade = () => {
  return useMutation({ mutationFn: cancelSubscriptionDowngrade });
};
