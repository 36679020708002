import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRedPhone = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m14.775 10.994-1.633-1.633a1.867 1.867 0 0 0-2.667 0l-.326.327a35.997 35.997 0 0 1-4.54-4.534l.333-.333a1.887 1.887 0 0 0 0-2.667L4.329.541a1.913 1.913 0 0 0-2.667 0l-.893.893a2.667 2.667 0 0 0-.334 3.334A36.16 36.16 0 0 0 10.55 14.88a2.667 2.667 0 0 0 3.333-.333l.893-.887a1.867 1.867 0 0 0 0-2.667Z"
      fill="#DB3D55"
    />
  </svg>
);
export default SvgRedPhone;
