import axios, { AxiosError, AxiosResponse } from 'axios';
import * as yup from 'yup';

import { captureApiException } from '../logging';

export const isAxiosError = (error: unknown): error is AxiosError<unknown> => {
  return axios.isAxiosError(error);
};

export interface GenericResponse {
  success: boolean;
  message?: string;
  statusCode?: number;
  errorCode?: string;
}

export interface GenericResponseWithData<T> extends GenericResponse {
  data: T;
}

export const getGenericResponseSchema: yup.SchemaOf<GenericResponse> = yup.object().shape({
  success: yup.mixed<boolean>().oneOf([false, true]).required(),
  statusCode: yup.number().optional(),
  errorCode: yup.string().optional(),
  message: yup.string().optional()
});

export const validateGenericResponse = (response: AxiosResponse<GenericResponse>) => {
  return getGenericResponseSchema.validate(response.data, { abortEarly: false });
};

export const validateAndCaptureGenericResponse = async (
  response: AxiosResponse<GenericResponse>
) => {
  try {
    await validateGenericResponse(response);
  } catch (error: unknown) {
    captureApiException(error);
  }
};
