import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Controller, useForm } from 'react-hook-form';

import arrowIcon from '../../../../public/icons/down-arrow.svg';
import { getTerritorySearch, getTerritorySearchInterested } from '../../../api/public';
import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { AreaOption, SignupProps } from '../../../types/onBoarding';
import { getSelectedIds } from '../../../utils/common.utils';
import { AlertBox } from '../../AlertBox';
import { PrivacyPolicyLink } from '../../PrivacyPolicyLink';
import { SubmitButton } from '../../SubmitButton';
import styles from '../SignUp.module.scss';

import ErrorTooltip from './ErrorTooltip';

const injectAreaOptionName = (areaOptions: AreaOption[]) => {
  areaOptions.forEach((item) => {
    item.name = `${item.name}, ${item.stateShort} `;
  });
};

function Market({ stepNavigate, initialFormData, updateFormData }: SignupProps) {
  const [options, setOptions] = useState<AreaOption[]>([]);
  const [interestedOptions, setInterestedOptions] = useState<AreaOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [primaryValue, setPrimaryValue] = useState<AreaOption[]>([]);
  const [secondaryValue, setSecondaryValue] = useState<AreaOption[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const primaryDD = useRef<Typeahead<AreaOption>>(null);
  const secondaryDD = useRef<Typeahead<AreaOption>>(null);

  useEffect(() => {
    if (initialFormData.primaryMarket.length) {
      setPrimaryValue(initialFormData.primaryMarket);
      setSecondaryValue(initialFormData.secondaryMarkets);
    }
  }, [initialFormData.primaryMarket, initialFormData.secondaryMarkets]);

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm();
  useEffect(() => {
    setSelectedIds(getSelectedIds(primaryValue, secondaryValue));
  }, [primaryValue, secondaryValue]);

  const handleSearch = useCallback(async () => {
    setIsLoading(true);

    try {
      const primaryMarketsResponse = await getTerritorySearch();
      const interestedMarketsResponse = await getTerritorySearchInterested();

      injectAreaOptionName(primaryMarketsResponse.data);
      setOptions(primaryMarketsResponse.data);

      injectAreaOptionName(interestedMarketsResponse.data);
      setInterestedOptions(interestedMarketsResponse.data);

      setIsLoading(false);
    } catch (e: unknown) {
      setIsLoading(false);

      if (isApiErrorWithMessage(e)) {
        setErrorMessage(getMessageFromError(e));
      }
    }
  }, []);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const getFilteredLocations = (options: AreaOption[]) => {
    return options.filter((e) => selectedIds.find((s) => s === e.sfId) == null);
  };

  const getFilteredPrimaryLocations = () => {
    return getFilteredLocations(options);
  };

  const getFilteredInterestedLocations = () => {
    return getFilteredLocations(interestedOptions);
  };

  const submitMarket = () => {
    setIsSubmitting(true);

    if (primaryValue.length) {
      updateFormData({
        ...initialFormData,
        primaryMarket: primaryValue,
        secondaryMarkets: secondaryValue
      });
      stepNavigate('next');
    }
  };

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.title}>Your Markets</div>
        <span className={styles.subTitle}>
          Tell us about where you operate to customize your experience. Your primary market is the
          market you are most active in. Markets of interest can be markets Sundae currently
          operates in or markets Sundae is not in yet.
        </span>
      </div>
      <form onSubmit={handleSubmit(submitMarket)}>
        <div className={classNames(styles.formContainer, styles.marketForm)}>
          {errorMessage && <AlertBox>{errorMessage}</AlertBox>}

          <div className={styles.formSet}>
            <label className={styles.marketLabel} htmlFor="primaryMarket">
              * SELECT PRIMARY MARKET
            </label>
            <div id="primaryMarket" className={styles.selectContainer}>
              <Typeahead
                id="primaryMarket"
                labelKey={(option) => `${option.name}`}
                isLoading={isLoading}
                selected={primaryValue}
                ref={primaryDD}
                minLength={0}
                inputProps={{ autoComplete: 'disabled' }}
                onBlur={primaryDD.current?.hideMenu}
                onChange={setPrimaryValue}
                options={getFilteredPrimaryLocations()}
                placeholder={'Type or select a Sundae market'}
                className={styles.selectInput}
                filterBy={(option, custProps) => {
                  if (custProps.selected.length) {
                    return true;
                  }

                  if (option.name === undefined) {
                    return false;
                  }

                  return option.name.toLowerCase().indexOf(custProps.text.toLowerCase()) !== -1;
                }}
              />
              <img src={arrowIcon.src} onClick={() => primaryDD?.current?.focus()} alt="" />
              <ErrorTooltip
                isOpen={isSubmitting && !primaryValue.length}
                target="primaryMarket"
                message={'Please select area'}
              />
            </div>
          </div>
          <div className={styles.formSet}>
            <label className={styles.marketLabel} htmlFor="secondaryMarket">
              SELECT MARKETS OF INTEREST (IF ANY)
            </label>
            <div className={styles.selectContainer}>
              <Controller
                name="secondaryMarket"
                control={control}
                render={() => {
                  return (
                    <Typeahead
                      id="secondaryMarket"
                      labelKey={(option) => `${option.name}`}
                      isLoading={isLoading}
                      selected={secondaryValue}
                      ref={secondaryDD}
                      minLength={0}
                      multiple
                      onBlur={secondaryDD.current?.hideMenu}
                      onChange={(e) => {
                        setSecondaryValue(e);
                      }}
                      inputProps={{ autoComplete: 'disabled' }}
                      options={getFilteredInterestedLocations()}
                      placeholder={'Type or select MSAs'}
                    />
                  );
                }}
              />
              <img src={arrowIcon.src} onClick={() => secondaryDD?.current?.focus()} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.marketsNextButtonWrapper}>
          <SubmitButton type="submit">Next</SubmitButton>
        </div>
        <div className={classNames(styles.signupInfo, 'text-center')}>
          <PrivacyPolicyLink linkText="Review our privacy policy." />
        </div>
      </form>
    </>
  );
}

export default Market;
