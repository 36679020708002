import React from 'react';

import SupportLink from '../../SupportLink';

import GeneralError from './GeneralError';

interface AccessVerificationFailedStepProps {
  onClose: () => void;
  emailFailed?: boolean;
}

export const AccessVerificationFailedStep = ({
  onClose,
  emailFailed = false
}: AccessVerificationFailedStepProps) => {
  const failurePoint = emailFailed ? 'email' : 'mobile device';

  return (
    <GeneralError
      onClose={onClose}
      variant="error"
      title="Sorry, verification failed"
      content={
        <>
          We are unable to verify your {failurePoint}. Please contact <SupportLink /> for next{' '}
          steps.
        </>
      }
      isContactVisible={false}
    />
  );
};
