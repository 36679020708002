import classNames from 'classnames';
import React from 'react';
interface IProps {
  linkText: string;
  bold?: boolean;
}

export const PrivacyPolicyLink = (props: IProps) => {
  const { linkText, bold = true } = props;

  return (
    <a
      className={classNames('sundaeGray', { 'font-weight-bold': bold })}
      href="https://sundae.com/privacy-policy/"
      rel="noopener noreferrer"
      target="_blank"
    >
      {linkText}
    </a>
  );
};
