import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { baseInputStyle } from '../../../components/MembershipPayment/stripeStyles';

import styles from './EditCardModal.module.scss';

export interface PaymentInformationInputGroupProps {
  brand?: string;
  last4?: string;
  expMonth?: number;
  expYear?: number;
  setSaveDisabled: (value: boolean) => void;
  setCardComplete: (value: boolean) => void;
}

export const PaymentInformationInputGroup = (props: PaymentInformationInputGroupProps) => {
  const [numberComplete, setNumberComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);
  const [cvcComplete, setCvcComplete] = useState(false);

  const anyComplete = numberComplete || expiryComplete || cvcComplete;
  const allComplete = numberComplete && expiryComplete && cvcComplete;
  useEffect(() => {
    props.setCardComplete(allComplete);
    props.setSaveDisabled(anyComplete && !allComplete);
  }, [anyComplete, allComplete]);

  const expMonth = props.expMonth?.toString().padStart(2, '0');
  const expYear = props.expYear?.toString().slice(-2);
  const expPlaceholder = numberComplete
    ? 'MM / YY'
    : expMonth && expYear && `${expMonth} / ${expYear}`;

  return (
    <>
      <span id="payment-info" className={styles.label}>
        Payment Information
      </span>
      <fieldset className={styles.tempInputs} aria-labelledby="payment-info">
        <CardNumberElement
          className={classNames(
            'align-self-stretch',
            styles.stripePaymentInputOverride,
            'fs-exclude'
          )}
          options={{
            style: baseInputStyle,
            placeholder: `**** **** **** ${props.last4}`
          }}
          onChange={(e) => setNumberComplete(e.complete)}
        />
        <div className={styles.inputGroup}>
          <CardExpiryElement
            className={classNames(
              styles.stripePaymentInputOverride,
              styles.inputField,
              'fs-exclude'
            )}
            options={{
              style: baseInputStyle,
              ...(expPlaceholder && {
                placeholder: expPlaceholder
              })
            }}
            onChange={(e) => setExpiryComplete(e.complete)}
          />
          <CardCvcElement
            className={classNames(
              styles.stripePaymentInputOverride,
              styles.inputField,
              'fs-exclude'
            )}
            options={{
              style: baseInputStyle
            }}
            onChange={(e) => setCvcComplete(e.complete)}
          />
        </div>
      </fieldset>
    </>
  );
};
