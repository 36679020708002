import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMpaSignature = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 109 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#mpa-signature_svg__a)">
      <path
        d="M54.5 108c29.823 0 54-24.177 54-54S84.323 0 54.5 0 .5 24.177.5 54s24.177 54 54 54Z"
        fill="#25B7D3"
      />
      <path
        d="M66.282 73.637a1.964 1.964 0 0 1-1.964 1.963H25.045a1.964 1.964 0 0 1-1.963-1.963V24.546a1.964 1.964 0 0 1 1.963-1.964h39.273a1.963 1.963 0 0 1 1.964 1.964v49.09Z"
        fill="#fff"
      />
      <path d="M27.009 28.473h35.345v39.272H27.01V28.473Z" fill="#3E3E3F" />
      <path
        d="M44.682 73.636a1.964 1.964 0 1 0 0-3.927 1.964 1.964 0 0 0 0 3.927Z"
        fill="#B6BCBD"
      />
      <path
        d="M74.136 65.782c0-11.782-7.854-19.636-7.854-19.636v15.709l-5.45-3.633a1.754 1.754 0 0 0-.971-.295 1.433 1.433 0 0 0-1.088 2.367c3.233 4.587 5.545 9.415 10.454 13.342l2.946 11.782h13.745L74.136 65.782Z"
        fill="#FBC8C8"
      />
      <path d="m27.009 61.678 33.205-33.205H27.009v33.205Z" fill="#5B5C5F" />
      <path d="M43.7 26.509a.982.982 0 1 0 0-1.964.982.982 0 0 0 0 1.964Z" fill="#3E3E3F" />
    </g>
    <defs>
      <clipPath id="mpa-signature_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h108v108H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMpaSignature;
