import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import modalStyles from '../../common/modal.module.scss';
import { Modal } from '../../components/Modal';
import { ModalCloseButton } from '../../components/Modal/ModalCloseButton';
import { Button } from '../../components/design-system/components/Button';
import { StaticSubscription } from '../../helpers/subscriptionHelper';
import { useModalByName } from '../../hooks/useModalByName';

export const CHANGE_MEMBERSHIP_MODAL_NAME = 'ChangeMembershipModal';

export const ChangedMembershipModal: React.FC = () => {
  const [isOpen, , close, options] = useModalByName(CHANGE_MEMBERSHIP_MODAL_NAME);

  const changedSubscription: StaticSubscription = options?.context as StaticSubscription;

  if (!changedSubscription) {
    return null;
  }

  return (
    <Modal
      hide={close}
      isShowing={isOpen}
      modalClassNames={classNames(modalStyles.modal, modalStyles.center)}
    >
      <div className={modalStyles.modalHeader}>Your membership has been changed</div>
      <ModalCloseButton isAbsolute onClick={close} />

      <p>Your payment change will reflect on the next billing cycle.</p>
      <p>
        You still will have access to place offers in all markets nationwide and plus the benefits
        of your {changedSubscription.name} membership.
      </p>
      <div className={classNames(modalStyles.buttonField, modalStyles.stack)}>
        <Link href="/">
          <Button size="large" onClick={close}>
            See the Marketplace
          </Button>
        </Link>
      </div>
    </Modal>
  );
};
