import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { VerifySmsCodeRequestBody } from '../_base/generated/data-contracts';

// POST /oauth/verify-sms-code
export const verifySmsCode = (body: VerifySmsCodeRequestBody) => {
  return api.authControllerVerifySmsCode(body);
};

export const useVerifySmsCode = () => {
  return useMutation({ mutationFn: verifySmsCode });
};
