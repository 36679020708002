import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgBidTotal({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="bid-total_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.bid-total_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="bid-total_svg__cls-1"
        d="M51.2 35.68v-5a4 4 0 00-3.55-3.86v-7.33a4.88 4.88 0 00-4.95-4.78H41l-2.69-6a1.41 1.41 0 00-1.7-.71l-1.68.58L33 5.61a1.41 1.41 0 00-1.87-.42L15.36 14.3a1.48 1.48 0 00-.45.41H10a4.88 4.88 0 00-5 4.78v27a4.87 4.87 0 005 4.79h25.48A11.19 11.19 0 0055 44a10.94 10.94 0 00-3.8-8.32zm-4.05-6.29a1.32 1.32 0 011.35 1.3v3.25a11.09 11.09 0 00-4.68-1 11.21 11.21 0 00-9 4.46H29a1.32 1.32 0 01-1.35-1.3v-5.41a1.32 1.32 0 011.35-1.3zM38 14.71H25.52L36.32 11zm-6.64-6.48l.85 1.25L24.8 12zM10 48.67a2.21 2.21 0 01-2.3-2.17v-27a2.21 2.21 0 012.3-2.18h32.7a2.21 2.21 0 012.3 2.17v7.29H29a4 4 0 00-4 3.91v5.38A4 4 0 0029 40h4.4a10.76 10.76 0 00.33 8.69zm33.87 3.38A8.1 8.1 0 1152 44a8.15 8.15 0 01-8.18 8.05z"
      />
      <path
        className="bid-total_svg__cls-1"
        d="M50.07 39.28a1 1 0 00-1.39-.2 26.91 26.91 0 00-7 7 8.9 8.9 0 00-2.8-2.15 1 1 0 00-1.34.42 1 1 0 00.41 1.32 5.52 5.52 0 012.79 2.62 1.12 1.12 0 00.94.77h.61l.33-.47a1.74 1.74 0 00.2-.41c.87-2.15 3.26-4.68 7.1-7.52a1 1 0 00.15-1.38z"
      />
    </svg>
  );
}

export default SvgBidTotal;
