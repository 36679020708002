/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ApiListingDbStatusCheckResponseBody,
  ApiOfferServicePing,
  ApiStatusCheckResponseBody,
  FindTerritoryAutoCompleteResponseBodyItem,
  SortOrder
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Public<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Health
   * @name HealthControllerApiStatusCheck
   * @summary Checking the connectivity status to listing DB
   * @request GET:/public/listing-db-status
   */
  healthControllerApiStatusCheck = (params: RequestParams = {}) =>
    this.http.request<ApiListingDbStatusCheckResponseBody, any>({
      path: `/public/listing-db-status`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Health
   * @name HealthControllerApiStatusCheck2
   * @summary Ping the offer service API
   * @request GET:/public/ping-offer-service-api
   * @originalName healthControllerApiStatusCheck
   * @duplicate
   */
  healthControllerApiStatusCheck2 = (params: RequestParams = {}) =>
    this.http.request<ApiOfferServicePing, any>({
      path: `/public/ping-offer-service-api`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Health
   * @name HealthControllerApiStatusCheck3
   * @summary Check API status
   * @request GET:/public/status
   * @originalName healthControllerApiStatusCheck
   * @duplicate
   */
  healthControllerApiStatusCheck3 = (params: RequestParams = {}) =>
    this.http.request<ApiStatusCheckResponseBody, any>({
      path: `/public/status`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Territory
   * @name TerritoryControllerFindTerritoryAutoComplete
   * @summary List territories for autocomplete
   * @request GET:/public/territory/autocomplete
   */
  territoryControllerFindTerritoryAutoComplete = (
    query?: {
      offset?: number;
      limit?: number;
      sortBy?: string;
      sortOrder?: SortOrder;
      includeInactive?: boolean;
      includeComingSoon?: boolean;
      page?: number;
      size?: number;
      sort?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<FindTerritoryAutoCompleteResponseBodyItem[], any>({
      path: `/public/territory/autocomplete`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
}
