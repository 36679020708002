import classNames from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';

import {
  contactTypeOptions,
  entityTypes,
  fundingSourcesOptions,
  individualEntityType
} from '../../../helpers/constants';
import { SignUpFormData, SignupProps } from '../../../types/onBoarding';
import { Checkbox } from '../../Checkbox';
import { InputLabel } from '../../Form/InputLabel/InputLabel';
import { RadioAndCheckboxGroup } from '../../Form/RadioAndCheckboxGroup/RadioAndCheckboxGroup';
import { PrivacyPolicyLink } from '../../PrivacyPolicyLink';
import { Radio } from '../../Radio';
import { SubmitButton } from '../../SubmitButton';
import { Input, Select } from '../../design-system';
import PreviousButton from '../PreviousButton';
import styles from '../SignUp.module.scss';

function Story({ stepNavigate, initialFormData, updateFormData, hidePreviousButton }: SignupProps) {
  const initialValues = {
    companyName: initialFormData.companyName,
    vestingEntityName: initialFormData.vestingEntityName,
    vestingEntityType: initialFormData.vestingEntityType,
    financialSources: initialFormData.financialSources,
    contactType: initialFormData.contactType
  };

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    watch
  } = useForm({ defaultValues: initialValues });

  const vestingEntityTypeField = register('vestingEntityType', { required: true });
  const contactTypeField = register('contactType', { required: true });

  const handleSelectVestingType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    vestingEntityTypeField.onChange(e);

    if (e.currentTarget.value === individualEntityType) {
      setValue('vestingEntityName', `${initialFormData.firstName} ${initialFormData.lastName}`);
    }
  };

  const handleBack = () => {
    stepNavigate('back');
  };

  const submitStory = (data: SignUpFormData) => {
    updateFormData(data);
    stepNavigate('next');
  };

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.title}>Investment Story</div>
        <span className={styles.subTitle}>
          Tell us about yourself so we can help you in your property search.
        </span>
      </div>
      <form onSubmit={handleSubmit(submitStory)}>
        <div className={styles.formContainer}>
          <div className={styles.formSet}>
            <InputLabel htmlFor="companyName" error={Boolean(errors.companyName)}>
              Company Name*
            </InputLabel>
            <Input
              id="companyName"
              size="small"
              {...register('companyName', { required: true, maxLength: 80 })}
              type="text"
              descriptor={Boolean(errors.companyName) && 'Enter your company name'}
              error={Boolean(errors.companyName)}
            />
          </div>

          <div className={styles.formSet}>
            <Select
              placeholder=""
              disablePlaceholder
              id="vestingEntityType"
              label="Entity Type*"
              size="small"
              {...vestingEntityTypeField}
              onChange={handleSelectVestingType}
              items={entityTypes}
              error={Boolean(errors.vestingEntityType)}
              helperText={Boolean(errors.vestingEntityType) && 'Select entity type'}
            ></Select>
          </div>

          <div className={styles.formSet}>
            <InputLabel htmlFor="vestingEntityName" error={Boolean(errors.vestingEntityName)}>
              Entity Name*
            </InputLabel>
            <Input
              id="vestingEntityName"
              size="small"
              {...register('vestingEntityName', { required: true, maxLength: 80 })}
              type="text"
              descriptor={Boolean(errors.vestingEntityName) && 'Enter your entity name'}
              error={Boolean(errors.vestingEntityName)}
            />
          </div>

          <div className={styles.formSet}>
            <RadioAndCheckboxGroup
              type="checkbox"
              label="Funding Sources*"
              helperText={Boolean(errors.financialSources) && 'Select funding sources'}
              hasError={Boolean(errors.financialSources)}
            >
              {fundingSourcesOptions.map(({ value, label = value }) => (
                <div key={value} style={{ marginBottom: 2 }}>
                  <Checkbox
                    {...register('financialSources', { required: true })}
                    value={value}
                    label={label}
                    hasError={Boolean(errors.financialSources)}
                  />
                </div>
              ))}
            </RadioAndCheckboxGroup>
          </div>

          <div className={styles.formSet}>
            <RadioAndCheckboxGroup
              type="radio"
              label="What's your main role?*"
              helperText={Boolean(errors.contactType) && 'Select funding sources'}
              hasError={Boolean(errors.contactType)}
            >
              {contactTypeOptions.map(({ value, label = value }) => (
                <div key={value} style={{ marginBottom: 2 }}>
                  <Radio
                    value={value}
                    label={label}
                    error={Boolean(errors.contactType)}
                    checked={watch().contactType === value}
                    onChange={() => {
                      setValue('contactType', value);
                    }}
                  />
                </div>
              ))}
            </RadioAndCheckboxGroup>
          </div>
        </div>
        <div className={styles.storyBtn}>
          <PreviousButton hideLeft={hidePreviousButton} handleBack={handleBack}>
            <SubmitButton type="submit">Next</SubmitButton>
          </PreviousButton>
        </div>
        <div className={classNames(styles.signupInfo, 'text-center', 'p-0')}>
          <PrivacyPolicyLink linkText="Review our privacy policy." />
        </div>
      </form>
    </>
  );
}

export default Story;
