import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectTerritorySearchMarkets } from '../store/territory';

// Takes in a semicolon delimited list of territory IDs or single territory ID
// and returns an array of AreaOption territories ready for a type ahead component
export const useTerritoryOptions = (territoryIdsSemicolonDelimited: string) => {
  const territorySearchMarkets = useSelector(selectTerritorySearchMarkets);

  const territoryOptions = useMemo(() => {
    const territoryIds = territoryIdsSemicolonDelimited
      ? territoryIdsSemicolonDelimited.split(';')
      : [];

    const territoryOptions = territoryIds.flatMap((territoryId) => {
      const foundTerritories = territorySearchMarkets.find(
        (territory) => territory.territoryId === territoryId
      );

      return foundTerritories || [];
    });

    return territoryOptions;
  }, [territoryIdsSemicolonDelimited, territorySearchMarkets]);

  return { territoryOptions };
};
