import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';

// POST /oauth/forgot-password
export const forgotPassword = (email: string) => {
  return api.authControllerForgotPassword({ email });
};

export const useForgotPassword = () => {
  return useMutation({ mutationFn: forgotPassword });
};
