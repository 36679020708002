import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';
import { CheckUserAvailabilityRequestBody } from '../_base/generated/data-contracts';

// POST /oauth
export const checkUserAvailability = (body: CheckUserAvailabilityRequestBody) => {
  return api.authControllerCheckUserAvailability(body);
};

export const useUserAvailability = (body: CheckUserAvailabilityRequestBody) => {
  return useQuery({
    queryKey: ['checkUserAvailability'],
    queryFn: () => checkUserAvailability(body)
  });
};
