import { PostHog } from 'posthog-js';

import { PropertyDetailsResponseBody } from '../../api/_base/generated/data-contracts';

import { recordPostHogEvent } from './recordPostHogEvent';

interface RecordUserOfferConfirmationModalParams {
  property: PropertyDetailsResponseBody;
}

export const recordUserOfferConfirmationModalEventName = 'user_offer_confirmation_modal';

export const recordUserOfferConfirmationModal = (
  { property }: RecordUserOfferConfirmationModalParams,
  posthog: PostHog
) => {
  recordPostHogEvent(property, recordUserOfferConfirmationModalEventName, posthog, {
    action: 'confirmation_modal',
    closing_date: `${property?.projectedCloseDate}`,
    address: `${property?.streetAddress}, ${property?.city}, ${property?.state}, ${property?.zipCode}`,
    territory_id: `${property?.territoryId}`
  });
};
