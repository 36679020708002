import { useField } from 'formik';
import React, { FC } from 'react';

import { Input, InputProps } from '../../design-system';

export type FormikInputProps = Omit<InputProps, 'id'> & { id: string };

export const FormikInput: FC<FormikInputProps> = ({ ...inputProps }) => {
  const [field, meta] = useField(inputProps.id);
  const hasError = Boolean(meta.touched && meta.error);

  return <Input {...inputProps} {...field} error={hasError} descriptor={hasError && meta.error} />;
};
