import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgDisclosuresDetails({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="disclosures-details_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>
          {
            '.disclosures-details_svg__cls-1{fill:#4b4b4b}.disclosures-details_svg__cls-2{fill:#1d52a0}'
          }
        </style>
      </defs>
      <path
        className="disclosures-details_svg__cls-1"
        d="M43.4 2.05H11.48a5.14 5.14 0 00-5 5.29v44.42a5.14 5.14 0 005 5.29H43.4a5.15 5.15 0 005-5.29V7.34a5.15 5.15 0 00-5-5.29zm3 49.71a3.1 3.1 0 01-3 3.18H11.48a3.1 3.1 0 01-3-3.18V7.34a3.09 3.09 0 013-3.17H43.4a3.09 3.09 0 013 3.17z"
      />
      <path
        className="disclosures-details_svg__cls-1"
        d="M40.26 12H14.62a.75.75 0 000 1.5h25.64a.75.75 0 000-1.5zM40.26 17.71H14.62a.75.75 0 000 1.5h25.64a.75.75 0 000-1.5zM40.26 23.45H14.62a.75.75 0 000 1.5h25.64a.75.75 0 000-1.5zM40.26 29.19H14.62a.75.75 0 000 1.5h25.64a.75.75 0 000-1.5zM40.26 34.94H14.62a.75.75 0 000 1.5h25.64a.75.75 0 000-1.5zM40.26 40.68H14.62a.75.75 0 000 1.5h25.64a.75.75 0 000-1.5zM40.26 46.42H14.62a.75.75 0 000 1.5h25.64a.75.75 0 000-1.5z"
      />
      <path
        className="disclosures-details_svg__cls-2"
        d="M33.52 38c-.39-.19-.77-.41-1.15-.64a14 14 0 014.38-25.47 13.49 13.49 0 0110.3 1.68 13.93 13.93 0 014.36 19.15 14 14 0 01-8.74 6.32A13.56 13.56 0 0133.52 38zm10.91-22.1a10.28 10.28 0 00-6.91-.78 10.73 10.73 0 00-3.4 19.42 10.22 10.22 0 007.79 1.27 10.73 10.73 0 003.39-19.38 9.25 9.25 0 00-.87-.49zm5.56 16zM40.41 37.76l.15 12.3"
      />
      <path
        className="disclosures-details_svg__cls-2"
        d="M39.68 51.17a1.3 1.3 0 01-.79-1.1l-.14-12.3a1.51 1.51 0 011.64-1.33 1.54 1.54 0 011.68 1.31l.15 12.3a1.52 1.52 0 01-1.65 1.33 2 2 0 01-.89-.21z"
      />
    </svg>
  );
}

export default SvgDisclosuresDetails;
