import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  hideAllModals,
  hideModal,
  ModalName,
  ModalOptions,
  selectModalsState,
  showModal
} from '../store/modals';

export type { ModalName } from '../store/modals';

type UseModalByNameResult = [
  boolean,
  (options?: ModalOptions) => void, // show,
  () => void, // hide
  ModalOptions | undefined
];

export const useModalByName = (name: ModalName, options?: ModalOptions): UseModalByNameResult => {
  const [instanceOptions] = useState<ModalOptions | undefined>(options);

  const dispatch = useDispatch();

  const modalState = useSelector(selectModalsState);

  const isShowing = modalState[name]?.isShowing ?? false;
  const currentOptions = modalState[name]?.options;

  const show = (options?: ModalOptions) => {
    dispatch(hideAllModals());
    dispatch(showModal(name, options ?? instanceOptions));
  };

  const hide = () => {
    dispatch(hideModal(name));
  };

  return [isShowing, show, hide, currentOptions];
};
