import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgTitleDetails({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="title-details_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.title-details_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="title-details_svg__cls-1"
        d="M46.76 2.5H13.24A5.27 5.27 0 008 7.79v44.42a5.27 5.27 0 005.24 5.29h33.52A5.27 5.27 0 0052 52.21V7.79a5.27 5.27 0 00-5.24-5.29zm3.14 49.71a3.16 3.16 0 01-3.14 3.17H13.24a3.16 3.16 0 01-3.14-3.17V7.79a3.16 3.16 0 013.14-3.17h33.52a3.16 3.16 0 013.14 3.17z"
      />
      <path
        d="M44.37 42.25a2.47 2.47 0 01-.64-.79 2.48 2.48 0 01.05-1.07 1.89 1.89 0 00-.07-1.4 1.84 1.84 0 00-1.31-.51 2.19 2.19 0 01-1-.28 2.27 2.27 0 01-.57-.87c-.22-.48-.45-1-.89-1.11a.66.66 0 00-.25 0 2.44 2.44 0 00-1.07.4 2.48 2.48 0 01-1 .38 2.49 2.49 0 01-1-.38 2.43 2.43 0 00-1.08-.4.64.64 0 00-.24 0c-.44.14-.67.63-.89 1.1a2.24 2.24 0 01-.57.88 2.23 2.23 0 01-1 .28 1.83 1.83 0 00-1.3.51 1.89 1.89 0 00-.07 1.4 2.48 2.48 0 01.05 1.07 2.47 2.47 0 01-.64.79c-.38.37-.78.75-.78 1.22s.4.84.78 1.21a2.37 2.37 0 01.64.8 2.48 2.48 0 01-.05 1.07 1.9 1.9 0 00.07 1.4 1.87 1.87 0 001.3.5 2.52 2.52 0 011 .28 2.24 2.24 0 01.57.88c.22.47.45 1 .89 1.11a.93.93 0 00.24 0 2.53 2.53 0 001.08-.39 2.35 2.35 0 011-.38 2.34 2.34 0 011 .38 2.53 2.53 0 001.08.39.93.93 0 00.24 0c.43-.15.67-.64.89-1.11a2.24 2.24 0 01.57-.88 2.52 2.52 0 011-.28 1.87 1.87 0 001.3-.5 1.9 1.9 0 00.07-1.4 2.48 2.48 0 01-.05-1.07 2.37 2.37 0 01.64-.8c.38-.37.78-.74.78-1.21s-.39-.85-.77-1.22z"
        fill="#1d52a0"
      />
      <path
        className="title-details_svg__cls-1"
        d="M16.54 13.92h26.92a.75.75 0 000-1.5H16.54a.75.75 0 000 1.5zM16.54 19.77h26.92a.75.75 0 000-1.5H16.54a.75.75 0 000 1.5zM16.54 25.62h26.92a.75.75 0 000-1.5H16.54a.75.75 0 000 1.5zM44.21 30.72a.75.75 0 00-.75-.75H16.54a.75.75 0 000 1.5h26.92a.76.76 0 00.75-.75z"
      />
    </svg>
  );
}

export default SvgTitleDetails;
