import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgInfo({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M95.5 50c0 25.129-20.371 45.5-45.5 45.5S4.5 75.129 4.5 50 24.871 4.5 50 4.5 95.5 24.871 95.5 50z"
        stroke="currentColor"
        strokeWidth={9}
      />
      <path d="M55 26.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0z" fill="currentColor" />
      <path d="M50 42v34.5" stroke="currentColor" strokeWidth={9} strokeLinecap="round" />
    </svg>
  );
}

export default SvgInfo;
