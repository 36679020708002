import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgDisclosures({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="disclosures_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.disclosures_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="disclosures_svg__cls-1"
        d="M54.76 49.86L47.9 39.13c.31-.22.61-.44.9-.68a12.71 12.71 0 00-1.49-20.61V9.92A5.09 5.09 0 0042.08 5H13.84a5.29 5.29 0 00-4.45 2.35A5.08 5.08 0 005 12.28V50a5.14 5.14 0 005.24 5h28.23a5.24 5.24 0 005-3.51 5 5 0 003.84-4.74v-3l4.93 7.7a1.49 1.49 0 002.07.45 1.48 1.48 0 00.45-2.04zm-16.29 2.31H10.24A2.29 2.29 0 017.86 50V12.28a2.1 2.1 0 01.75-1.58v36.05a5.09 5.09 0 005.23 4.92H40a2.48 2.48 0 01-1.53.5zm3.61-3.33H13.84a2.25 2.25 0 01-2.37-2.09V9.92a2.25 2.25 0 012.37-2.09h28.24a2.25 2.25 0 012.38 2.09v6.65a12.44 12.44 0 00-2.31-.46 12.13 12.13 0 00-7.42 1.68H16.55a.95.95 0 100 1.89h15.73a12.43 12.43 0 00-2.18 2.92H16.55a.95.95 0 100 1.89h12.73a12.89 12.89 0 00-.65 2.92H16.55a.95.95 0 100 1.89H28.6a12.91 12.91 0 00.48 2.92H16.55a.95.95 0 100 1.89H29.8A12.94 12.94 0 0031.72 37H16.55a.95.95 0 100 1.89h17.24a12.13 12.13 0 006 2.29 11.85 11.85 0 001.19.06 12.23 12.23 0 003.46-.51v6a2.25 2.25 0 01-2.36 2.11zm4.81-12.7a9.18 9.18 0 01-6.62 2.1.76.76 0 00.06-.26 1 1 0 00-.95-.95h-3a9.61 9.61 0 01-3.13-2.92h6.15a.95.95 0 100-1.89h-7.15a9.84 9.84 0 01-.65-2.92h7.78a.95.95 0 100-1.89h-7.72a9.65 9.65 0 01.85-2.92h6.87a.95.95 0 100-1.89h-5.7a9.68 9.68 0 011.38-1.41 9.29 9.29 0 012.59-1.51h1.73a1 1 0 00.88-.6h1.6a9.67 9.67 0 015 17.05z"
      />
      <path
        className="disclosures_svg__cls-1"
        d="M16.55 14.87h22.83a.95.95 0 100-1.89H16.55a.95.95 0 100 1.89zM39.38 41.84H16.55a.95.95 0 100 1.89h22.83a.95.95 0 100-1.89z"
      />
    </svg>
  );
}

export default SvgDisclosures;
