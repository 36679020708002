import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgSundaeBug({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <>
      <svg
        id="sundae-bug_svg__Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 936 936"
        width="1em"
        height="1em"
        aria-labelledby={titleId}
        {...props}
      >
        {' '}
        {title ? <title id={titleId}>{title}</title> : null}
        <circle fill="#dc3d55" cx="468" cy="468" r="432" />
        <path
          fill="#FFFFFF"
          d="M525.05,415.73c-3.58-2.4-7.21-4.74-10.85-7.07a314.91,314.91,0,0,1-30.23-21c-22.18-18.52-43.74-50.37-32.08-80.9a48.61,48.61,0,0,1,10.94-16.92,47.87,47.87,0,0,1,16.76-10.91c27.25-10.09,47.89,21.11,45.25,46.24A46.75,46.75,0,0,1,509.9,355a6.49,6.49,0,0,0-2.11,3.15c-.44,2.9,3.08,4.48,6.18,4.93,45,5.47,79.7-41.72,50.4-81.31-13-17.28-33.94-26.58-55-29.34a116.42,116.42,0,0,0-93.74,30.23c-20.68,19.44-35.37,47.4-32.59,76.79,3.08,31.61,22.62,58.79,45,79.64,13.66,12.75,29.18,22.17,44.73,31.59,10.29,6.25,20.58,12.49,30.38,19.73,44.65,32.89,65.05,49.7,65.05,82,0,17.63-7.92,38.31-20.3,50.32a111.37,111.37,0,0,1-48,27.81A118.62,118.62,0,0,1,445,651.66c-35.64-7.13-74.62-32.78-76.81-73.47-1-19,3.71-38.59,16.38-52.48a71.58,71.58,0,0,1,28.67-19.23c9.5-3.22,17-4.15,24.15-1.64,4.85,1.7,7.55-.91,6-5.9a47.23,47.23,0,0,0-12.92-20.36c-10.29-9.7-28.54-14.71-48.48-10.56-32.8,6.85-57.89,34-65.86,60.5-9,29.75-1.43,61.86,14.76,87.59,17.16,27.16,44.39,47.13,74.28,57.67,57.93,20.44,127.49,12.1,175.07-28.84a125.66,125.66,0,0,0,37.31-56c7.78-24.67,9-54.25-.67-78.56-15.5-40.51-57.72-72.41-91.81-94.68Z"
        />
        <path
          fill="#FFFFFF"
          d="M412,545.34c-7.86,11.07-15.12,48,1.62,48,6.47,0,12-9.42,17.81-19.53a114.73,114.73,0,0,1,10.95-16.51A34.56,34.56,0,0,1,457.9,546c3.34-1.27,5.3-2,6.16-6.81,1.16-6.62-5-14.19-18-14.19-16.17-.16-26.91,10.2-34.1,20.36Z"
        />
      </svg>
    </>
  );
}

export default SvgSundaeBug;
