import React, { useEffect, useState } from 'react';

import { PAYMENT_HISTORY_MODAL_NAME } from '../';
import { StripeHistoricalPayment } from '../../../api/_base/generated/data-contracts';
import { getPaymentHistory } from '../../../api/subscription';
import SvgBill from '../../../components/Icons/Bill';
import { Modal } from '../../../components/Modal';
import { Button, Spinner } from '../../../components/design-system';
import { useModalByName } from '../../../hooks/useModalByName';
import { formatStripeDate } from '../../../utils/dateTime.utils';
import { formatNumToCurrency } from '../../../utils/formatter.utils';
import { ModalHeader } from '../../ModalHeader';

import styles from './PaymentHistoryModal.module.scss';

export const PaymentHistoryModal = () => {
  const [isOpen, , close] = useModalByName(PAYMENT_HISTORY_MODAL_NAME);

  const initialPageSize = 3;
  const pageSize = 10;
  const [showViewMore, setShowViewMore] = useState(false);
  const [payments, setPayments] = useState<StripeHistoricalPayment[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setPayments([]);

      return;
    }

    setLoading(true);
    getPaymentHistory({ limit: initialPageSize }).then(({ data: { data } }) => {
      setPayments(data.payments);
      setShowViewMore(data.hasMore);
      setLoading(false);
    });
  }, [isOpen]);

  const handleViewMoreClick = () => {
    if (!payments) return;
    setLoading(true);
    getPaymentHistory({
      startingAfter: payments.slice(-1)[0]?.id,
      limit: pageSize
    }).then(({ data: { data } }) => {
      setPayments([...payments, ...data.payments]);
      setShowViewMore(data.hasMore);
      setLoading(false);
    });
  };

  return (
    <Modal hide={close} isShowing={isOpen} modalClassNames={styles.modal}>
      <ModalHeader
        closeIconClassNames={styles.closeIcon}
        onClickIcon={close}
        headerText="Payment History"
      />
      <table className={`table ${styles.table}`}>
        <thead>
          <tr>
            <td>Date</td>
            <td>Amount Paid</td>
            <td>Card</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {payments.map(({ id, created, amount, brand, last4, receiptUrl }) => {
            return (
              <tr key={id}>
                <td>{formatStripeDate(created)}</td>
                <td>{formatNumToCurrency(amount / 100, 2, 2)}</td>
                <td className={styles.card}>
                  {brand} *{last4}
                </td>
                <td>
                  <a href={receiptUrl} target="_blank" rel="noopener noreferrer">
                    <SvgBill />
                  </a>
                </td>
              </tr>
            );
          })}
          {loading && (
            <tr>
              <td colSpan={4} className="text-center">
                <Spinner />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showViewMore && !loading && (
        <Button variant="bare" className={styles.linkButton} onClick={handleViewMoreClick}>
          View More
        </Button>
      )}
      <Button className={styles.closeButton} variant="secondary" type="button" onClick={close}>
        Close
      </Button>
    </Modal>
  );
};
