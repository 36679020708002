import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

import { EDIT_PHONE_MODAL_NAME } from '..';
import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { updatePhone } from '../../../api/user-account/accountSettingsApi';
import { AlertBox } from '../../../components/AlertBox';
import { Modal } from '../../../components/Modal';
import { useModalByName } from '../../../hooks/useModalByName';
import { useModalManager } from '../../../hooks/useModalManager';
import { captureException } from '../../../logging';
import { phonePattern } from '../../../utils/common.utils';
import { getUserInfo } from '../../../utils/localStorage.utils';
import { CONFIRMATION_CODE_PHONE_INPUT_MODAL_NAME } from '../../ConfirmationCode';
import { CancelSaveButtonGroup } from '../CancelSaveButtonGroup/CancelSaveButtonGroup';
import styles from '../EditModal.module.scss';
import { EditModalHeader } from '../EditModalHeader';

const userInfo = getUserInfo();

export const EditPhoneModal = () => {
  const [isOpen, , close, options] = useModalByName(EDIT_PHONE_MODAL_NAME);
  const [phone, setPhone] = useState(userInfo?.phone);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(!!phone && !!phone.match(phonePattern));
  const { showModal, hideAllModals } = useModalManager();
  const [errorMessage, setErrorMessage] = useState('');

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const doesMatchPattern = newValue.match(phonePattern);
    setPhone(newValue);
    setIsPhoneValid(!!doesMatchPattern);
  };

  const handlePhoneSave = () => {
    setIsSubmitting(true);

    updatePhone(phone)
      .then(() => {
        setIsSubmitting(false);

        hideAllModals();
        showModal(CONFIRMATION_CODE_PHONE_INPUT_MODAL_NAME, { onComplete: options?.onComplete });
      })
      .catch((error: unknown) => {
        setIsSubmitting(false);

        captureException(error);

        if (isApiErrorWithMessage(error)) {
          setErrorMessage(getMessageFromError(error));
        } else {
          setErrorMessage('Invalid verification code provided, please try again.');
        }
      });
  };

  return (
    <Modal hide={close} isShowing={isOpen} modalClassNames={styles.modal}>
      <EditModalHeader onClickIcon={close} headerText="Edit Phone" />
      <div className={styles.modalContents}>
        <label className={'visually-hidden'} htmlFor="phoneInput">
          Please enter a new phone number
        </label>

        <NumberFormat
          name="phoneInput"
          className="form-control"
          format={'+# (###) ###-####'}
          mask={'\u2007'}
          placeholder={'Mobile Phone*'}
          onChange={handlePhoneChange}
          value={phone}
          title="Please enter a new phone number"
        />
        {errorMessage && <AlertBox>{errorMessage}</AlertBox>}
        <CancelSaveButtonGroup
          isSubmitting={isSubmitting}
          isSaveDisabled={isSubmitting || !isPhoneValid}
          onCancelClick={close}
          onSaveClick={handlePhoneSave}
        />
      </div>
    </Modal>
  );
};
