import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgSellerFaqFull = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#seller-faq-full_svg__a)">
      <path
        d="M12.5 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24Zm0 19a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1.6-6.08a1 1 0 0 0-.6.92 1 1 0 0 1-2 0 3 3 0 0 1 1.8-2.75 2 2 0 1 0-2.8-1.84 1 1 0 0 1-2 0 4 4 0 1 1 5.6 3.67Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="seller-faq-full_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSellerFaqFull;
