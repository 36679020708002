import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgBed({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M52.84 27.37v-16.7A5.48 5.48 0 0047.58 5H12.42a5.48 5.48 0 00-5.26 5.67v16.7A5.38 5.38 0 005 31.8v21.7a1.51 1.51 0 003 0v-6.87h44v6.87a1.51 1.51 0 003 0V31.8a5.38 5.38 0 00-2.16-4.43zm-42.67-16.7A2.51 2.51 0 0112.42 8h35.16a2.51 2.51 0 012.25 2.66V23.1a7.53 7.53 0 00-5.39-2.18h-.25V16.1a2 2 0 00-2-2h-9a2 2 0 00-2 2v4.82h-2.37V16.1a2 2 0 00-2-2h-9a2 2 0 00-2 2v4.82h-.25a7.53 7.53 0 00-5.39 2.18zm16.65 10.25h-9V16.1h9zm17.62 3a4 4 0 014 2.68h-36.9a4 4 0 014-2.68zm-2.25-3h-9V16.1h9zM52 43.63H8V31.8c0-1.25.74-2.18 1.41-2.18h41.17c.67 0 1.41.93 1.41 2.18z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgBed;
