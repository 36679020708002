import { useState } from 'react';

export const useConfirmDialog = (isDefaultVisible = false): [boolean, () => void, () => void] => {
  const [isShowing, setIsShowing] = useState(isDefaultVisible);

  const show = () => {
    setIsShowing(true);
  };

  const hide = () => {
    setIsShowing(false);
  };

  return [isShowing, show, hide];
};
