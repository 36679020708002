import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { clearReferralPropertyId, getReferralPropertyId } from '../utils/localStorage.utils';
import { getPropertyDetailsLink } from '../utils/url.utils';

export const useMoveToHomeScreen = () => {
  const router = useRouter();

  return useCallback(() => {
    const { query } = router;
    const referralPropertyId = getReferralPropertyId();

    if (referralPropertyId) {
      window.location.replace(getPropertyDetailsLink(referralPropertyId));
      clearReferralPropertyId();
    } else if (!isEmpty(query)) {
      const newL = window.location.search + window.location.hash;
      window.location.replace('/' + newL.replace('?next=/', ''));
    } else {
      router.reload();
    }
  }, [router]);
};
