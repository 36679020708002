import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgEye({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.068 11.867c-5.544 0-8.293-4.279-8.98-5.53a.692.692 0 010-.674c.687-1.25 3.436-5.53 8.98-5.53 5.543 0 8.296 4.28 8.98 5.53a.692.692 0 010 .675c-.684 1.25-3.437 5.53-8.98 5.53zm.007-9.9C6.78 1.967 4.921 3.775 4.921 6c0 2.226 1.862 4.034 4.154 4.034S13.23 8.226 13.23 6c0-2.229-1.862-4.033-4.154-4.033zm0 6.6C7.614 8.567 6.432 7.42 6.432 6c0-1.419 1.182-2.566 2.643-2.566 1.462 0 2.644 1.147 2.644 2.566 0 1.42-1.186 2.567-2.644 2.567z"
        fill="#4A4A4A"
      />
    </svg>
  );
}

export default SvgEye;
