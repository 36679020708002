import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import * as authApi from '../../../api/auth';
import { getMessageFromError, isApiErrorWithMessage } from '../../../api/user';
import { useConfirmationCode, useUserEmail } from '../../../hooks/store/misc';
import { useConfirmationCodeRef } from '../../../hooks/store/misc/useConfirmationCodeRef';
import { SignupClosingProps, SignUpFormData } from '../../../types/onBoarding';
import { ConfirmationCodeScreen } from '../../ConfirmationCodeScreen/ConfirmationCodeScreen';
import { HelpPopover } from '../../HelpPopover';
import SupportLink from '../../SupportLink';
import styles from '../SignUp.module.scss';

type VerifyEmailStepProps = SignupClosingProps;

function VerifyEmailStep({ initialFormData, jumpStep, stepNavigate }: VerifyEmailStepProps) {
  const [userEmail, setUserEmail] = useUserEmail();
  const [failCounter, setFailCounter] = useState(0);
  const confirmationCodeRef = useConfirmationCodeRef();
  const { previousConfirmationCode } = useConfirmationCode();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const emailNode = initialFormData.email ? (
    <span className={styles.userContactInfo}>{initialFormData.email}</span>
  ) : (
    'your email'
  );

  useEffect(() => {
    if (userEmail !== initialFormData.email) {
      setUserEmail(initialFormData.email);
    }
  }, [initialFormData.email, userEmail, setUserEmail]);

  const handleConfirm = async () => {
    try {
      await authApi.verifyEmailCode({
        email: initialFormData.email,
        confirmationCode: confirmationCodeRef.current,
        password: initialFormData.password
      });

      toast.success('Code confirmed.');
    } catch (error: unknown) {
      const newFailCounter = failCounter + 1;
      setFailCounter(newFailCounter);

      if (previousConfirmationCode === confirmationCodeRef.current) {
        setErrorMessage(
          'Looks like you entered your SMS code. Please enter the code in your inbox.'
        );
      } else if (isApiErrorWithMessage(error)) {
        setErrorMessage(getMessageFromError(error));
      } else {
        setErrorMessage('Invalid verification code provided, please try again.');
      }

      if (newFailCounter >= 3) {
        jumpStep('accessVerificationEmailFailedStep');
      }

      return;
    }

    try {
      await authApi.verifyIdentityWithPhoneNumber({
        firstName: initialFormData.firstName,
        lastName: initialFormData.lastName,
        phone: initialFormData.phone,
        email: initialFormData.email
      });

      stepNavigate('next');
    } catch (error: unknown) {
      jumpStep('verifySocialStep');
    }
  };

  const handleResendCode = async () => {
    try {
      await authApi.resendEmailCode({
        email: initialFormData.email,
        password: initialFormData.password
      });
      toast.success('Resent verification code.');
    } catch (error: unknown) {
      if (isApiErrorWithMessage(error)) {
        setErrorMessage(getMessageFromError(error));
      } else {
        setErrorMessage('Could not resend verification code.');
      }
    }
  };

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.title}>Now to verify your email address</div>
        <span className={styles.subTitle}>
          Thanks for verifying your device, we just sent a verification code to {emailNode}.
          <HelpPopover size="small">
            We verify both mobile phone and email to validate your most up-to-date contact
            information. Don&apos;t see the verification email? Check your promotions and spam
            folders. Please contact <SupportLink /> if you are still facing issues.
          </HelpPopover>{' '}
          Enter the code below to verify your account.
        </span>
      </div>
      <ConfirmationCodeScreen<SignUpFormData>
        codeLength={6}
        onResendCode={handleResendCode}
        onConfirm={handleConfirm}
        headerText=""
        hideLogo={true}
        errorMessage={errorMessage}
      />
    </>
  );
}

export default VerifyEmailStep;
