import { PostHog } from 'posthog-js';

import { RealTimeEventsLogEventRequestBody } from '../../api/_base/generated/data-contracts';
import { logEvent } from '../../api/real-time-events/logEvent';
import { getUserInfo } from '../../utils/localStorage.utils';

type EventLogProps = Pick<
  RealTimeEventsLogEventRequestBody,
  'listingId' | 'propertyId' | 'eventName' | 'applicationName' | 'userId'
>;

export const recordRealTimeEvent = async (
  { listingId, propertyId, eventName, applicationName, userId }: EventLogProps,
  posthog: PostHog
) => {
  const response = await logEvent({
    userId: userId || `${getUserInfo()?.sundaeUserId || ''}`,
    listingId: listingId ?? '',
    propertyId: propertyId ?? '',
    applicationName,
    eventName
  });
  posthog.capture(eventName, {
    userId: userId || `${getUserInfo()?.sundaeUserId || ''}`,
    listingId: listingId ?? '',
    propertyId: propertyId ?? '',
    applicationName
  });

  return response;
};

export type MarketplaceEventLogProps = Omit<EventLogProps, 'applicationName'>;

export const recordMarketplaceRealTimeEvent = async (
  { listingId, propertyId, eventName }: MarketplaceEventLogProps,
  posthog: PostHog
) => {
  return recordRealTimeEvent(
    { listingId, propertyId, eventName, applicationName: 'Marketplace' },
    posthog
  );
};

export const recordSellerRealTimeEvent = async (
  { listingId, propertyId, eventName, userId }: MarketplaceEventLogProps,
  posthog: PostHog
) => {
  return recordRealTimeEvent(
    {
      listingId,
      propertyId,
      eventName,
      applicationName: 'Seller',
      userId
    },
    posthog
  );
};
