import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCalendarFilled({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="calendar-filled_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.26 13.27"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>
          {
            '.calendar-filled_svg__cls-1{fill:none;stroke:#1d52a0;stroke-linecap:round;stroke-miterlimit:10;}.calendar-filled_svg__cls-2{fill:#1d52a0;}'
          }
        </style>
      </defs>
      <line className="calendar-filled_svg__cls-1" x1="4.68" y1="1.96" x2="11.45" y2="1.96" />
      <path
        className="calendar-filled_svg__cls-1"
        d="M4.78,2H2.19A1.15,1.15,0,0,0,1.07,3.13v7.76a1.14,1.14,0,0,0,1.12,1.17H14a1.15,1.15,0,0,0,1.12-1.17V3.13A1.16,1.16,0,0,0,14,2H11.4"
      />
      <line className="calendar-filled_svg__cls-1" x1="1.36" y1="5.62" x2="14.81" y2="5.62" />
      <rect
        className="calendar-filled_svg__cls-2"
        x="3.58"
        y="0.53"
        width="1.38"
        height="2.99"
        rx="0.69"
      />
      <rect
        className="calendar-filled_svg__cls-2"
        x="1.12"
        y="5.4"
        width="14.01"
        height="7.07"
        rx="1"
      />
      <rect
        className="calendar-filled_svg__cls-2"
        x="11.34"
        y="0.46"
        width="1.38"
        height="2.99"
        rx="0.69"
      />
    </svg>
  );
}

export default SvgCalendarFilled;
