import React from 'react';

import { BidIncrementModal } from './BidIncrementModal';
import { LearnMoreModal } from './LearnMoreModal';
import { SundaeFundingModal } from './SundaeFundingModal';

export const AutoOfferModals = () => {
  return (
    <>
      <LearnMoreModal />
      <BidIncrementModal />
      <SundaeFundingModal />
    </>
  );
};
