import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgExit2 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.647 9.953a1.2 1.2 0 1 1-1.696 1.696L6 7.699l-3.952 3.949A1.19 1.19 0 0 1 1.2 12a1.2 1.2 0 0 1-.848-2.05L4.304 6 .352 2.049A1.2 1.2 0 1 1 2.048.351L6 4.306 9.952.353a1.2 1.2 0 1 1 1.697 1.697L7.696 6.002l3.95 3.95Z"
      fill="#858585"
    />
  </svg>
);
export default SvgExit2;
