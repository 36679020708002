import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgAlert({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16A8 8 0 108 0a8 8 0 000 16zM7.293 3.393A1 1 0 007 4.1v3.2a1 1 0 102 0V4.1a1 1 0 00-1.707-.707zm-.182 9.237A1.6 1.6 0 108.89 9.971a1.6 1.6 0 00-1.778 2.66z"
        fill="var(--sds-color-error)"
      />
    </svg>
  );
}

export default SvgAlert;
