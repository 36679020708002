import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgSepticDetails({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M29.87 42.73c-5.17 0-5.17 1.88-10.34 1.88s-5.16-1.88-10.33-1.88V54l2.74 2.14 35.47.24L50.53 54V42.73c-5.16 0-5.16 1.88-10.33 1.88s-5.2-1.88-10.33-1.88z"
        fill="#1d52a0"
        stroke="#4b4b4b"
        strokeMiterlimit={10}
        strokeWidth={1.5}
      />
      <path
        d="M46.54 2.5H13.46A5.33 5.33 0 008 7.68v44.63a5.34 5.34 0 005.46 5.19h33.08A5.34 5.34 0 0052 52.31V7.68a5.33 5.33 0 00-5.46-5.18zm0 52.92H13.46a3.2 3.2 0 01-3.28-3.11V27.25h2.35a2.23 2.23 0 001.5-.59h3.41a2.38 2.38 0 011.29.38s.21.23.21 1v2.08a1.74 1.74 0 00-.62 1.34 1.77 1.77 0 001.77 1.76h6.47a1.76 1.76 0 001.18-3.07v-2.09a11 11 0 00-.78-4.2 9.17 9.17 0 00-2.17-3.2 9.57 9.57 0 00-2.69-1.79 11.13 11.13 0 00-2.41-.77 11.53 11.53 0 00-2.5-.23h-3.11a2.22 2.22 0 00-1.55-.64h-2.35V7.68a3.2 3.2 0 013.28-3.11h33.08a3.2 3.2 0 013.28 3.11v9.55h-2.43a2.2 2.2 0 00-1.54.64h-3.09a11.56 11.56 0 00-2.52.23 11.13 11.13 0 00-2.41.77 9.63 9.63 0 00-2.7 1.79A9.07 9.07 0 0033 23.85a11 11 0 00-.78 4.21v2.1a1.79 1.79 0 00-.59 1.32 1.76 1.76 0 001.76 1.76h6.48a1.76 1.76 0 001.76-1.76 1.74 1.74 0 00-.63-1.34v-2.08c0-.79.19-1 .21-1a2.37 2.37 0 011.28-.38h3.41a2.22 2.22 0 001.49.59h2.43v25.04a3.2 3.2 0 01-3.28 3.11zm-20-24.7v1a.24.24 0 010-.48h-6.45a.24.24 0 010 .48v-1h.85v-2.66a3.3 3.3 0 00-.81-2.45 4.4 4.4 0 00-2.73-.95H13l-.29.48a.21.21 0 01-.18.11h-2.14v-6h2.14a.23.23 0 01.19.11l.28.53h4.21a8.84 8.84 0 012.09.19 9.16 9.16 0 012 .63 7.7 7.7 0 012.13 1.42 7.08 7.08 0 011.7 2.5 9 9 0 01.63 3.45v2.66zm13.28 0v1a.24.24 0 010-.48h-6.46a.24.24 0 110 .48v-1h.83v-2.66a9 9 0 01.63-3.45 7.18 7.18 0 011.69-2.5 7.53 7.53 0 012.14-1.42 9 9 0 012-.63 9.52 9.52 0 012.11-.19h4.19l.28-.53a.21.21 0 01.18-.11h2.14v6h-2.16a.25.25 0 01-.18-.11l-.29-.48h-4.4a4.4 4.4 0 00-2.73.95 3.3 3.3 0 00-.79 2.47v2.66z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgSepticDetails;
