import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgStep4({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="Step4_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.Step4_svg__cls-1{fill:#c9c9c9}'}</style>
      </defs>
      <path
        className="Step4_svg__cls-1"
        d="M30 5a25 25 0 1025 25A25 25 0 0030 5zm6.75 27.56a.49.49 0 01-.12.34.47.47 0 01-.37.14H34v4.71h-2V33h-7.86a.64.64 0 01-.43-.15.65.65 0 01-.24-.35l-.22-1.14L32 19.84h2v11.45h2.71z"
      />
      <path className="Step4_svg__cls-1" d="M32.08 31.29V23.9v-.7a7 7 0 01.09-.77l-6.52 8.86z" />
    </svg>
  );
}

export default SvgStep4;
