import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgHourglass({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      id="hourglass_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>{'.hourglass_svg__cls-1{fill:#4b4b4b}'}</style>
      </defs>
      <path
        className="hourglass_svg__cls-1"
        d="M48.6 52.17h-1.41c-.37-9.84-4.6-18.44-10.66-22.12 6.08-3.68 10.32-12.34 10.67-22.22h1.4a1.42 1.42 0 000-2.83H11.4a1.42 1.42 0 000 2.83h1.4c.35 9.87 4.59 18.53 10.66 22.22-6.05 3.68-10.28 12.3-10.65 22.12H11.4a1.42 1.42 0 000 2.83h37.2a1.42 1.42 0 000-2.83zm-33 0C16 42 20.76 33.43 27.23 31.41a1.4 1.4 0 001-1.22v-.27a1.4 1.4 0 00-1-1.22C20.76 26.68 16 18.07 15.59 7.94h28.82C44 18 39.29 26.58 32.87 28.65a1.43 1.43 0 00-1.09 1 1.51 1.51 0 00-.05.42 1.58 1.58 0 00.05.43c0 .05 0 .09.07.14a.83.83 0 00.07.13 1.35 1.35 0 00.9.68c6.44 2 11.19 10.62 11.59 20.74z"
      />
      <path
        className="hourglass_svg__cls-1"
        d="M30 38.77C22.81 29 17.88 44.46 17.18 50.84h25.64C41.2 36.65 33.91 44.08 30 38.77zM36.53 23.86c0-2.26-3.64-2.64-6.81-.94-2 1.06-6.25-1.32-6.25.94S27.5 28 29.72 28s6.81-1.88 6.81-4.14z"
      />
    </svg>
  );
}

export default SvgHourglass;
