import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgArrowRight({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M20 56.5a1.5 1.5 0 01-1.17-2.44L38.08 30 18.83 5.94a1.5 1.5 0 012.34-1.88l20 25a1.5 1.5 0 010 1.88l-20 25a1.48 1.48 0 01-1.17.56z"
        fill="#4b4b4b"
      />
    </svg>
  );
}

export default SvgArrowRight;
