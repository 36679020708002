import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgBuyBox({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#buy-box_svg__a)">
        <path
          d="M10.5 20.5c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
          fill="#fff"
        />
        <path
          d="M10.5 21C4.7 21 0 16.3 0 10.5S4.7 0 10.5 0 21 4.7 21 10.5 16.3 21 10.5 21zm0-20C5.3 1 1 5.3 1 10.5S5.3 20 10.5 20s9.5-4.3 9.5-9.5S15.7 1 10.5 1z"
          fill="#DB3D55"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 10c-.2.2-.5.2-.8.1l-1.7-.9h-.1l-1.6.9c-.1.1-.2.1-.3.1-.2 0-.4-.1-.5-.2-.2-.2-.3-.5-.2-.8l.7-1.5c.1-.1 0-.2 0-.2l-1.2-1c-.2-.2-.3-.5-.2-.7.1-.2.3-.4.6-.4h1.4s.1 0 .1-.1l.7-1.6c.1-.2.2-.3.4-.3.3-.2.7 0 .9.3l.7 1.6c0 .1 0 .1.1.1h1.3c.3 0 .5.2.6.4.1.2 0 .5-.2.6l-1.2 1.1v.2l.7 1.5c.1.3 0 .6-.2.8zm1.7-.4l2 .8c.183.061.255.197.305.293.032.062.056.107.095.107v3.4c0 .1-.1.2-.2.3l-6.2 3.1h-.3l-6.2-3.1c-.1-.1-.2-.2-.2-.3v-3.4c0-.2.1-.4.3-.5l1.9-.7h.1c.3-.1.5.1.7.3.1.3-.1.5-.3.6l-.7.3c-.05 0-.075 0-.088.012-.012.013-.012.038-.012.088 0 0 0 .1.1.1l4.5 1.8h.1l4.5-1.8c.1 0 .1 0 .1-.1 0 0 0-.1-.1-.1l-.7-.3c-.3-.1-.4-.4-.3-.6.1-.3.4-.4.6-.3z"
          fill="#DB3D55"
        />
      </g>
      <defs>
        <clipPath id="buy-box_svg__a">
          <path fill="#fff" d="M0 0h21v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBuyBox;
