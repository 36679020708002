import React from 'react';

import { useModalByName } from '../../hooks/useModalByName';

import { InterestedMarketsModal } from './InterestedMarketsModal';

export const EDIT_INTERESTED_MARKETS_MODAL_NAME = 'EditInterestedMarketsModal';

export const EditInterestedMarketsModal = () => {
  const [isOpen, , close] = useModalByName(EDIT_INTERESTED_MARKETS_MODAL_NAME);

  return (
    <InterestedMarketsModal
      isOpen={isOpen}
      close={close}
      headerText="Edit your areas of interest"
      bodyText="Sundae continues to expand into new markets. Select markets outside of your target markets or even markets Sundae is not in yet and we will notify you once we are in that market."
      showSuccess={false}
    />
  );
};
