import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgActivityOpen({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 39.6 60.2"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}

      <path fill="#dc3d55" d="M30,30.1c0,16.6-13.4,30-30,30V0.1C16.6,0.1,30,13.5,30,30.1z" />
      <path
        fill="#FFFFFF"
        d="M0,29.5h15.3l-4.5-5.4c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.3,0.2-0.6,0.4-0.8c0.3-0.2,0.6-0.3,0.9-0.3
	c0.3,0,0.6,0.2,0.8,0.4l6.2,7.5c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.3-0.1,0.4
	c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2L12.7,39c-0.1,0.1-0.3,0.3-0.4,0.3c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.8-0.3
	c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0-0.3,0.1-0.5c0-0.2,0.1-0.3,0.2-0.4l4.5-5.4H0V29.5z"
      />
    </svg>
  );
}

export default SvgActivityOpen;
