import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgOffersActivity = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#offers-activity_svg__a)">
      <path
        d="M32 64c17.673 0 32-14.327 32-32C64 14.327 49.673 0 32 0 14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32Z"
        fill="#FABC3D"
      />
      <path
        d="M49.8 28.049a1.164 1.164 0 0 1-1.631.224l-16.17-12.267-16.17 12.267a1.167 1.167 0 0 1-1.637-.218 1.162 1.162 0 0 1 .231-1.636l16.873-12.8a1.164 1.164 0 0 1 1.407 0l16.873 12.8a1.164 1.164 0 0 1 .223 1.63Z"
        fill="#DF2C2C"
      />
      <path d="M35.492 37.818a3.49 3.49 0 1 0-6.982 0v5.819h6.982v-5.819Z" fill="#9C6144" />
      <path d="M32 16.006 18.038 26.6v17.037h27.927V26.6L32.001 16.006Z" fill="#fff" />
      <path
        d="M25.018 29.745a.51.51 0 0 1-.51.51h-3.054a.51.51 0 0 1-.509-.51v-3.054a.51.51 0 0 1 .51-.51h3.054a.51.51 0 0 1 .509.51v3.055Zm0 8.728a.51.51 0 0 1-.51.509h-3.054a.51.51 0 0 1-.509-.51v-3.054a.51.51 0 0 1 .51-.509h3.054a.51.51 0 0 1 .509.51v3.054Zm9.194-8.627a.523.523 0 0 1-.524.524h-3.14a.524.524 0 0 1-.524-.524v-3.14a.523.523 0 0 1 .524-.524h3.14a.524.524 0 0 1 .524.524v3.14Zm8.727-.201a.495.495 0 0 1-.494.494h-2.969a.495.495 0 0 1-.494-.494v-2.969c0-.273.221-.494.494-.494h2.969c.273 0 .494.222.494.494v2.969Z"
        fill="#40C9E7"
      />
      <path d="M33.746 40.146a.582.582 0 1 0 0-1.164.582.582 0 0 0 0 1.164Z" fill="#FACB1B" />
      <path
        d="M37.818 26.618c6.175 0 11.2 5.025 11.2 11.2 0 6.175-5.025 11.2-11.2 11.2-6.176 0-11.2-5.024-11.2-11.2 0-6.176 5.024-11.2 11.2-11.2Zm0-1.6c-7.07 0-12.8 5.731-12.8 12.8 0 7.07 5.73 12.8 12.8 12.8 7.069 0 12.8-5.73 12.8-12.8 0-7.069-5.731-12.8-12.8-12.8Z"
        fill="#FCF6C2"
      />
      <path
        d="M37.82 49.018c-6.176 0-11.2-5.024-11.2-11.2 0-6.175 5.024-11.2 11.2-11.2 6.175 0 11.2 5.025 11.2 11.2 0 6.176-5.025 11.2-11.2 11.2Z"
        fill="#FACB1B"
      />
      <path
        d="M37.82 26.618c-6.177 0-11.2 5.025-11.2 11.2 0 3.179 1.332 6.05 3.466 8.09l15.823-15.823a11.165 11.165 0 0 0-8.09-3.467Z"
        fill="#FBE158"
      />
      <path
        d="M26.618 37.818c0-6.175 5.024-11.2 11.2-11.2 3.17 0 6.034 1.326 8.074 3.45l1.135-1.135a12.759 12.759 0 0 0-9.21-3.915c-7.069 0-12.8 5.731-12.8 12.8 0 3.617 1.503 6.881 3.916 9.21l1.135-1.136a11.163 11.163 0 0 1-3.45-8.074Z"
        fill="#fff"
      />
      <path
        d="M38.103 29.832a.82.82 0 0 1 .825.826v.422a6.99 6.99 0 0 1 2.976 1.056c.307.173.576.46.576.922 0 .595-.48 1.056-1.075 1.056-.192 0-.384-.058-.576-.173-.672-.403-1.325-.691-1.978-.845v3.687c2.919.73 4.167 1.9 4.167 3.955 0 2.112-1.65 3.514-4.09 3.745v.4a.82.82 0 0 1-.825.826.836.836 0 0 1-.845-.826v-.439a8.31 8.31 0 0 1-3.937-1.497c-.326-.211-.518-.519-.518-.922a1.044 1.044 0 0 1 1.056-1.056c.228 0 .45.074.633.211.903.653 1.786 1.095 2.842 1.287v-3.783c-2.804-.73-4.109-1.785-4.109-3.955 0-2.055 1.632-3.476 4.032-3.668v-.403c0-.461.385-.826.846-.826Zm-.769 6.548V32.98c-1.229.116-1.843.768-1.843 1.613 0 .807.365 1.306 1.843 1.786Zm1.517 2.67v3.494c1.23-.135 1.901-.75 1.901-1.67 0-.846-.422-1.364-1.9-1.825Z"
        fill="#8E6134"
      />
    </g>
    <defs>
      <clipPath id="offers-activity_svg__a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgOffersActivity;
