import classNames from 'classnames';
import React, { InputHTMLAttributes, ChangeEvent, useState, forwardRef } from 'react';

import { EyeIcon } from '../../Icons';
import signUpStyles from '../SignUp.module.scss';

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
  handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  hasErrors?: boolean;
  inputFieldClassName?: string;
  passwordButtonClassName?: string;
}

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      handleOnChange,
      hasErrors,
      inputFieldClassName,
      passwordButtonClassName,
      type,
      ...rest
    }: PasswordInputProps,
    ref
  ) => {
    const defaultType = type?.toLowerCase() !== 'password';

    const [isPasswordVisible, setIsPasswordVisible] = useState(defaultType);
    const handleVisibilityToggle = () => setIsPasswordVisible(!isPasswordVisible);

    return (
      <div className={signUpStyles.passwordInput}>
        <p aria-live="polite" className="visually-hidden">
          {isPasswordVisible ? 'Password visible' : 'Password hidden'}
        </p>
        <button
          type="button"
          role="switch"
          aria-pressed={isPasswordVisible}
          aria-label="Toggle password input visibility"
          onClick={handleVisibilityToggle}
          className={classNames(passwordButtonClassName, 'bareButton', signUpStyles.toggleButton, {
            [signUpStyles.active]: isPasswordVisible
          })}
        >
          <EyeIcon className={signUpStyles.icon} />
        </button>
        <input
          ref={ref}
          type={isPasswordVisible ? 'text' : 'password'}
          onChange={handleOnChange}
          className={classNames(
            signUpStyles.inputField,
            {
              [signUpStyles.inputError]: hasErrors
            },
            inputFieldClassName
          )}
          {...rest}
        />
      </div>
    );
  }
);

export default PasswordInput;
