/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AppResponseBody,
  BeginSignUpRequestBody,
  BeginSignUpResponseBody,
  ChangePasswordRequestBody,
  ChangePasswordResponseBody,
  CheckUserAvailabilityRequestBody,
  CheckUserAvailabilityResponseBody,
  CompleteSignUpRequestBody,
  CompleteSignUpResponseBody,
  ConfirmForgotPasswordRequestBody,
  ConfirmForgotPasswordResponseBody,
  ExistingUserSignUpRequestBody,
  ForgotPasswordRequestBody,
  ForgotPasswordResponseBody,
  GetUserInfoResponseBody,
  HomeownerSignUpInfoResponseBody,
  HomeownerSignUpRequestBody,
  HomeownerSignUpResponseBody,
  LoginRequestBody,
  LoginResponseBody,
  LogoutResponseBody,
  ResendConfirmationCodeRequestBody,
  ResendConfirmationCodeResponseBody,
  ResendEmailCodeRequestBody,
  ResendEmailCodeResponseBody,
  SrnSignUpRequestBody,
  SrnVerifyEmailCodeRequestBody,
  SrnVerifySmsCodeRequestBody,
  VerifyEmailRequestBody,
  VerifyEmailResponseBody,
  VerifyIdentityWithPhoneNumberAndSocialRequestBody,
  VerifyIdentityWithPhoneNumberAndSocialResponseBody,
  VerifyIdentityWithPhoneNumberRequestBody,
  VerifyIdentityWithPhoneNumberResponseBody,
  VerifySmsCodeRequestBody,
  VerifySmsCodeResponseBody
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Oauth<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerLogin
   * @summary Log in
   * @request POST:/oauth/token
   */
  authControllerLogin = (data: LoginRequestBody, params: RequestParams = {}) =>
    this.http.request<LoginResponseBody, any>({
      path: `/oauth/token`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerLogout
   * @summary Log out
   * @request POST:/oauth/logout
   * @secure
   */
  authControllerLogout = (params: RequestParams = {}) =>
    this.http.request<LogoutResponseBody, any>({
      path: `/oauth/logout`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerForgotPassword
   * @summary Forgot password
   * @request POST:/oauth/forgot-password
   */
  authControllerForgotPassword = (data: ForgotPasswordRequestBody, params: RequestParams = {}) =>
    this.http.request<ForgotPasswordResponseBody, any>({
      path: `/oauth/forgot-password`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerChangePassword
   * @summary Change password
   * @request PATCH:/oauth/change-password
   * @secure
   */
  authControllerChangePassword = (data: ChangePasswordRequestBody, params: RequestParams = {}) =>
    this.http.request<ChangePasswordResponseBody, any>({
      path: `/oauth/change-password`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerCheckUserAvailability
   * @summary Check user availability
   * @request POST:/oauth
   */
  authControllerCheckUserAvailability = (
    data: CheckUserAvailabilityRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<CheckUserAvailabilityResponseBody, any>({
      path: `/oauth`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerVerifyIdentityWithPhoneNumber
   * @summary Verify phone number for a user
   * @request POST:/oauth/identity-verification-1
   */
  authControllerVerifyIdentityWithPhoneNumber = (
    data: VerifyIdentityWithPhoneNumberRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifyIdentityWithPhoneNumberResponseBody, any>({
      path: `/oauth/identity-verification-1`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerVerifyIdentityWithPhoneNumberAndSocial
   * @summary Verify phone number and SSN for a user
   * @request POST:/oauth/identity-verification-2
   */
  authControllerVerifyIdentityWithPhoneNumberAndSocial = (
    data: VerifyIdentityWithPhoneNumberAndSocialRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifyIdentityWithPhoneNumberAndSocialResponseBody, any>({
      path: `/oauth/identity-verification-2`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerBeginSignUp
   * @summary Begin sign up
   * @request POST:/oauth/begin-signup
   */
  authControllerBeginSignUp = (data: BeginSignUpRequestBody, params: RequestParams = {}) =>
    this.http.request<BeginSignUpResponseBody, any>({
      path: `/oauth/begin-signup`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerCompleteSignup
   * @summary Complete sign up
   * @request POST:/oauth/complete-signup
   */
  authControllerCompleteSignup = (data: CompleteSignUpRequestBody, params: RequestParams = {}) =>
    this.http.request<CompleteSignUpResponseBody, any>({
      path: `/oauth/complete-signup`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerHomeownerSignUpInfo
   * @summary Get signup info
   * @request GET:/oauth/homeowner/signup-info
   */
  authControllerHomeownerSignUpInfo = (
    query: {
      leadId: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<HomeownerSignUpInfoResponseBody, any>({
      path: `/oauth/homeowner/signup-info`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerExistingHomeownerSignUp
   * @summary signup existing user to seller
   * @request POST:/oauth/homeowner/existing-user-signup
   */
  authControllerExistingHomeownerSignUp = (
    data: ExistingUserSignUpRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<LoginResponseBody, any>({
      path: `/oauth/homeowner/existing-user-signup`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerHomeownerSignup
   * @summary Complete sign up
   * @request POST:/oauth/homeowner/signup
   */
  authControllerHomeownerSignup = (data: HomeownerSignUpRequestBody, params: RequestParams = {}) =>
    this.http.request<HomeownerSignUpResponseBody, any>({
      path: `/oauth/homeowner/signup`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerHomeownerLogin
   * @summary Homeowner log in
   * @request POST:/oauth/homeowner/login
   */
  authControllerHomeownerLogin = (data: LoginRequestBody, params: RequestParams = {}) =>
    this.http.request<LoginResponseBody, any>({
      path: `/oauth/homeowner/login`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerVerifySmsCode
   * @summary Verify SMS code for a user
   * @request POST:/oauth/verify-sms-code
   */
  authControllerVerifySmsCode = (data: VerifySmsCodeRequestBody, params: RequestParams = {}) =>
    this.http.request<VerifySmsCodeResponseBody, any>({
      path: `/oauth/verify-sms-code`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerHomeownerVerifySmsCode
   * @summary Verify SMS code for a homeowner user
   * @request POST:/oauth/homeowner/verify-sms-code
   */
  authControllerHomeownerVerifySmsCode = (
    data: VerifySmsCodeRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifySmsCodeResponseBody, any>({
      path: `/oauth/homeowner/verify-sms-code`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerHomeownerVerifyEmailCode
   * @summary Verify email code for a homeowner user
   * @request POST:/oauth/homeowner/verify-email-code
   */
  authControllerHomeownerVerifyEmailCode = (
    data: VerifyEmailRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifyEmailResponseBody, any>({
      path: `/oauth/homeowner/verify-email-code`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerSendEmailVerificationCode
   * @summary Send Email verification code for a user
   * @request POST:/oauth/send-email-verification-code
   */
  authControllerSendEmailVerificationCode = (
    data: VerifyEmailRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<AppResponseBody, any>({
      path: `/oauth/send-email-verification-code`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerConfirmForgotPassword
   * @summary Reset password
   * @request POST:/oauth/reset-password
   */
  authControllerConfirmForgotPassword = (
    data: ConfirmForgotPasswordRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<ConfirmForgotPasswordResponseBody, any>({
      path: `/oauth/reset-password`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerResendConfirmationCode
   * @summary Resend confirmation code
   * @request POST:/oauth/resend-confirmation-code
   */
  authControllerResendConfirmationCode = (
    data: ResendConfirmationCodeRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<ResendConfirmationCodeResponseBody, any>({
      path: `/oauth/resend-confirmation-code`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerGetUserInfo
   * @summary Get user info
   * @request GET:/oauth/user-info
   */
  authControllerGetUserInfo = (params: RequestParams = {}) =>
    this.http.request<GetUserInfoResponseBody, any>({
      path: `/oauth/user-info`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerGetSsoUser
   * @summary Get sso user
   * @request GET:/oauth/sso-user
   */
  authControllerGetSsoUser = (params: RequestParams = {}) =>
    this.http.request<GetUserInfoResponseBody, any>({
      path: `/oauth/sso-user`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerResendEmailCode
   * @summary Resend email code
   * @request POST:/oauth/resend-email-code
   */
  authControllerResendEmailCode = (data: ResendEmailCodeRequestBody, params: RequestParams = {}) =>
    this.http.request<ResendEmailCodeResponseBody, any>({
      path: `/oauth/resend-email-code`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerVerifyEmail
   * @summary Verify email for a user
   * @request POST:/oauth/verify-email-code
   */
  authControllerVerifyEmail = (data: VerifyEmailRequestBody, params: RequestParams = {}) =>
    this.http.request<VerifyEmailResponseBody, any>({
      path: `/oauth/verify-email-code`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerSrnSignup
   * @summary SRN sign up
   * @request POST:/oauth/srn/signup
   */
  authControllerSrnSignup = (data: SrnSignUpRequestBody, params: RequestParams = {}) =>
    this.http.request<HomeownerSignUpResponseBody, any>({
      path: `/oauth/srn/signup`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerSrnLogin
   * @summary SRN log in
   * @request POST:/oauth/srn/login
   */
  authControllerSrnLogin = (data: LoginRequestBody, params: RequestParams = {}) =>
    this.http.request<LoginResponseBody, any>({
      path: `/oauth/srn/login`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerSrnVerifySmsCode
   * @summary Verify SMS code for a SRN user
   * @request POST:/oauth/srn/verify-sms-code
   */
  authControllerSrnVerifySmsCode = (
    data: SrnVerifySmsCodeRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifySmsCodeResponseBody, any>({
      path: `/oauth/srn/verify-sms-code`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthControllerSrnVerifyEmailCode
   * @summary Verify email code for a SRN user
   * @request POST:/oauth/srn/verify-email-code
   */
  authControllerSrnVerifyEmailCode = (
    data: SrnVerifyEmailCodeRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<VerifyEmailResponseBody, any>({
      path: `/oauth/srn/verify-email-code`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
}
