import React, { FC, useEffect } from 'react';

import { useConfirmationCode } from '../../hooks/store/misc';
import { NumberInput } from '../NumberInput/NumberInput';

const PLACEHOLDER = 'Enter verification code';

type ConfirmationCodeInputProps = {
  codeLength: number;
  disableCallback?: (b: boolean) => void;
  inputType?: 'number' | 'password';
  codeInputStyle?: string;
};

export const ConfirmationCodeInput: FC<ConfirmationCodeInputProps> = ({
  codeLength,
  disableCallback,
  codeInputStyle
}) => {
  const { confirmationCode, setConfirmationCode, clearConfirmationCode } = useConfirmationCode();
  useEffect(() => {
    clearConfirmationCode();
  }, []);

  return (
    <NumberInput
      inputLength={codeLength}
      disableCallback={disableCallback}
      inputValue={confirmationCode}
      setInputValue={setConfirmationCode}
      inputStyle={codeInputStyle}
      placeholder={PLACEHOLDER}
      label="Confirmation Code"
    />
  );
};
