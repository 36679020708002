import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHomesNearMeMapMarker = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m11.677 27.382.323.273.323-.273c3.517-2.974 6.178-5.763 7.963-8.37C22.07 16.409 23 13.955 23 11.66 23 4.89 17.768.5 12 .5 6.231.5 1 4.89 1 11.66c0 2.294.93 4.748 2.714 7.353 1.785 2.606 4.446 5.395 7.963 8.369Z"
      fill="#DB3D55"
      stroke="#fff"
    />
    <g clipPath="url(#homes-near-me-map-marker_svg__a)" fill="#fff">
      <path d="M13.62 10.936c-.102-.072-.205-.141-.308-.211a8.859 8.859 0 0 1-.861-.628c-.626-.552-1.242-1.503-.911-2.415.07-.19.176-.361.313-.504a1.36 1.36 0 0 1 .476-.33c.774-.3 1.36.631 1.285 1.38a1.427 1.427 0 0 1-.424.898.19.19 0 0 0-.06.094c-.012.082.087.134.175.147 1.278.165 2.263-1.245 1.431-2.427-.369-.516-.963-.793-1.565-.875a3.155 3.155 0 0 0-1.426.142c-.46.156-.881.417-1.235.763-.584.577-1.002 1.416-.923 2.289.088.943.642 1.755 1.277 2.377.391.38.829.659 1.27.943.292.187.584.373.86.59 1.269.981 1.848 1.48 1.848 2.447 0 .526-.225 1.144-.576 1.502a3.13 3.13 0 0 1-1.366.83c-.51.14-1.042.15-1.557.034-1.008-.217-2.115-.979-2.178-2.191-.027-.566.106-1.152.466-1.564.222-.259.502-.456.814-.575.27-.097.481-.125.686-.05.137.051.214-.027.17-.176a1.418 1.418 0 0 0-.367-.607c-.293-.293-.81-.443-1.376-.32-.932.205-1.644 1.014-1.87 1.807-.256.887-.042 1.846.419 2.614.486.81 1.26 1.406 2.113 1.722 1.643.61 3.62.36 4.97-.862a3.761 3.761 0 0 0 1.06-1.67c.22-.736.255-1.62-.02-2.345-.444-1.209-1.642-2.16-2.61-2.829Z" />
      <path d="M10.409 14.81c-.223.328-.43 1.432.046 1.432.183 0 .34-.281.506-.583.09-.174.195-.339.313-.494a.99.99 0 0 1 .442-.338c.095-.038.15-.06.174-.203.033-.197-.143-.424-.51-.424-.46-.005-.765.305-.969.608l-.002.001Z" />
    </g>
    <defs>
      <clipPath id="homes-near-me-map-marker_svg__a">
        <path fill="#fff" transform="translate(7.5 6)" d="M0 0h9v13H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHomesNearMeMapMarker;
