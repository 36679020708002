import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgProfile({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M30 5a25 25 0 1025 25A25 25 0 0030 5zM17.69 48.56a1.55 1.55 0 00.07-.43 12.24 12.24 0 0124.48 0 1.55 1.55 0 00.07.43 22.23 22.23 0 01-24.62 0zm5.17-23.93A7.14 7.14 0 1130 31.77a7.16 7.16 0 01-7.14-7.14zm22 21.94a15 15 0 00-11-12.87 9.85 9.85 0 10-7.72 0 15 15 0 00-11 12.87 22.28 22.28 0 1129.74 0z"
        fill="#dc3d55"
      />
    </svg>
  );
}

export default SvgProfile;
