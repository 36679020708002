import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgPhotos({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="1em"
      height="1em"
      data-stroke="true"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath="url(#clip0_420_271)">
        <path d="M9.87509 5.49998H9.88134" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M1 3.33333C1 2.71449 1.24583 2.121 1.68342 1.68342C2.121 1.24583 2.71449 1 3.33333 1H12.6667C13.2855 1 13.879 1.24583 14.3166 1.68342C14.7542 2.121 15 2.71449 15 3.33333V12.6667C15 13.2855 14.7542 13.879 14.3166 14.3166C13.879 14.7542 13.2855 15 12.6667 15H3.33333C2.71449 15 2.121 14.7542 1.68342 14.3166C1.24583 13.879 1 13.2855 1 12.6667V3.33333Z"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 11L4.84615 7.47251C5.56 6.8425 6.44 6.8425 7.15385 7.47251L11 11"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 9.36501L10.7143 8.54752C11.3771 7.81749 12.1943 7.81749 12.8571 8.54752L15 11"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_420_271">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPhotos;
