// @ts-nocheck
import React, { createContext, useContext, useEffect, useState } from 'react';

import { getSubscriptionTiers } from '../api/subscription';
import { getStaticSubscriptionMetaDataByNameWithInterval } from '../helpers/subscriptionHelper';
import { getUserInfo } from '../utils/localStorage.utils';

export type Subscription = {
  id?: string;
  name?: string;
  amount?: number;
  markets?: number;
  interval?: 'month' | 'year';
};

export const SubscriptionContext = createContext<Record<string, Array<Subscription>>>({
  subscriptions: []
});

export const SubscriptionProvider = ({
  children
}: React.HTMLAttributes<HTMLElement>): React.ReactElement => {
  const [subscriptions, setSubscriptions] = useState<Array<Subscription>>([]);

  useEffect(() => {
    if (!getUserInfo()) return;
    getSubscriptionTiers().then((result) => {
      const payload = result.data.data;
      const newSubscriptions = Object.keys(payload)
        .map((key) => {
          const { id } = payload[key];
          const { markets } = getStaticSubscriptionMetaDataByNameWithInterval(id);

          return {
            id,
            interval: payload[key].interval,
            name: key,
            amount: payload[key].amount / 100,
            markets
          };
        })
        .reverse();

      setSubscriptions(newSubscriptions);
    });
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptions
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = (): Record<string, unknown> =>
  useContext(SubscriptionContext);
