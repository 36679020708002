import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWhenWillHighestOffer = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 109 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#when-will-highest-offer_svg__a)">
      <path
        d="M54.5 108c29.823 0 54-24.177 54-54S84.323 0 54.5 0 .5 24.177.5 54s24.177 54 54 54Z"
        fill="#32BEA6"
      />
      <path
        d="M27.01 81.491V24.546a1.963 1.963 0 1 0-3.928 0v60.872h60.872a1.964 1.964 0 1 0 0-3.927H27.01Z"
        fill="#F0F1F1"
      />
      <path
        d="M32.9 65.782h11.782V81.49H32.9V65.78Zm17.673-10.8h11.781V81.49H50.573V54.98Zm17.672-21.6h11.782V81.49H68.245V33.38Z"
        fill="#0484AB"
      />
      <path
        d="M69.493 43.853c.396-8.946-6.534-16.518-15.479-16.914-8.945-.396-16.518 6.534-16.914 15.48a16.141 16.141 0 0 0 1.603 7.782L24.665 64.275c1.13 3.37 3.38 5.616 6.754 6.736L45.214 57.18a16.127 16.127 0 0 0 7.366 2.153c8.945.395 16.518-6.535 16.913-15.48Z"
        fill="#9CE5F4"
      />
      <path
        d="M54.014 26.94c-8.945-.398-16.518 6.533-16.914 15.478a16.144 16.144 0 0 0 1.603 7.783L24.665 64.275c.629 1.878 1.62 3.393 2.945 4.572l37.15-37.15c-2.766-2.774-6.525-4.571-10.746-4.758Z"
        fill="#C9F2F8"
      />
      <path
        d="M53.297 54.716c6.396 0 11.58-5.185 11.58-11.58 0-6.397-5.184-11.581-11.58-11.581-6.395 0-11.58 5.184-11.58 11.58s5.185 11.58 11.58 11.58Z"
        fill="#40C9E7"
      />
      <path
        d="M53.809 31.566c-6.39-.282-11.799 4.668-12.081 11.057-.15 3.385 1.178 6.485 3.402 8.703l16.358-16.359a11.524 11.524 0 0 0-7.68-3.4Z"
        fill="#6FDAF1"
      />
      <path
        d="M38.703 50.201 24.665 64.275c1.13 3.37 3.38 5.615 6.754 6.736L45.214 57.18a16.286 16.286 0 0 1-6.511-6.979Z"
        fill="#84462D"
      />
      <path
        d="M38.703 50.201 24.665 64.275c.629 1.878 1.62 3.393 2.945 4.572l14.248-14.248a16.202 16.202 0 0 1-3.155-4.398Z"
        fill="#9C6144"
      />
      <path
        d="m42.233 60.167 2.98-2.988a16.27 16.27 0 0 1-6.51-6.978l-3.223 3.23 6.753 6.736Z"
        fill="#F3B607"
      />
      <path
        d="m38.703 50.201-3.223 3.23 3.777 3.769 2.6-2.6a16.238 16.238 0 0 1-3.154-4.399Z"
        fill="#FBE158"
      />
    </g>
    <defs>
      <clipPath id="when-will-highest-offer_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h108v108H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWhenWillHighestOffer;
