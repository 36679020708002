import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgNotLiked({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <>
      <svg
        id="not_liked_svg__Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 45"
        width="1em"
        height="1em"
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <defs>
          <style>
            {
              '.not_liked_svg__cls-1{fill:none;stroke:#4b4b4b;stroke-linejoin:round;stroke-width:2px;}'
            }
          </style>
        </defs>
        <path
          className="not_liked_svg__cls-1"
          d="M35.06,5.2C27.92,5.8,25.39,12,25,12S22.08,5.8,14.94,5.2C8.84,4.68,2,11.9,6.07,20.77,9.82,29,24.93,39.82,25,39.8l0,0,0,0C26.2,39,40.18,29,43.93,20.77,48,11.9,41.16,4.68,35.06,5.2Z"
        />
      </svg>
    </>
  );
}

export default SvgNotLiked;
