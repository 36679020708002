import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgCheckmarkGreen({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M6 12A6 6 0 106 0a6 6 0 000 12z" fill="#7DBB73" />
      <path
        d="M5.342 8.207h-.18a.362.362 0 01-.284-.265c-.1-.213-.359-.789-1.401-1.338a.285.285 0 01.245-.507 3.759 3.759 0 011.424 1.207c.57-1.065 1.708-2.25 3.417-3.53a.285.285 0 01.342.455C7.056 5.61 5.903 6.849 5.487 7.914a.835.835 0 01-.076.16l-.069.133z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgCheckmarkGreen;
