export { EditNameModal } from './EditNameModal/EditNameModal';
export { EditEmailModal } from './EditEmailModal/EditEmailModal';
export { EditCardModal } from './EditCardModal/EditCardModal';
export { PaymentHistoryModal } from './PaymentHistoryModal/PaymentHistoryModal';

export const EDIT_NAME_MODAL_NAME = 'EditNameModal';
export const EDIT_EMAIL_MODAL_NAME = 'EditEmailModal';
export const EDIT_PHONE_MODAL_NAME = 'EditPhoneModal';
export const EDIT_CARD_MODAL_NAME = 'EditCardModal';
export const PAYMENT_HISTORY_MODAL_NAME = 'PaymentHistoryModal';
