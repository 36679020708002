import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgActivitiesModalHeader = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 109 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#activities-modal-header_svg__a)">
      <path
        d="M54.5 108c29.823 0 54-24.177 54-54S84.323 0 54.5 0 .5 24.177.5 54s24.177 54 54 54Z"
        fill="#32BEA6"
      />
      <path
        d="M84.773 76.755c-.16-.16-23.266-23.572-23.4-23.39 2.447-3.294 4.108-7.269 5.073-12.05l1.893-1.886 1.48 1.487c.766.769 2.01.77 2.777.005l.695-.693a1.962 1.962 0 0 0 .005-2.778L59.435 23.53a1.964 1.964 0 0 0-2.777-.005l-.695.693a1.965 1.965 0 0 0-.005 2.778l1.297 1.302-1.897 1.89c-12.346 2.446-19.765 9.838-22.255 22.176l-1.787 1.781-1.244-1.25a1.965 1.965 0 0 0-2.778-.005l-.695.694a1.964 1.964 0 0 0-.005 2.776l13.861 13.921a1.964 1.964 0 0 0 2.777.005l.695-.693a1.964 1.964 0 0 0 .005-2.777l-5.774-5.8 4.241 4.26 1.791-1.785c4.531-.898 8.393-2.468 11.596-4.699L79.21 82.299a3.928 3.928 0 0 0 5.554.01c.053-.052.09-.114.138-.168 1.382-1.54 1.35-3.902-.13-5.386Zm-61.691 8.663a3.928 3.928 0 0 1 3.927-3.927h23.564a3.927 3.927 0 0 1 3.927 3.927H23.082Z"
        fill="#692E19"
      />
      <path
        d="M50.573 80.51a3.927 3.927 0 0 0-3.927-3.928H30.937a3.927 3.927 0 0 0-3.927 3.927v.982h23.563v-.982Z"
        fill="#F0F1F1"
      />
      <path
        d="M57.277 28.271 68.365 39.4l-1.923 1.916-11.088-11.129 1.923-1.916ZM31.313 54.148l1.787-1.781 11.088 11.128-1.788 1.781-11.087-11.128Z"
        fill="#9C6144"
      />
      <path
        d="M38.792 76.582h-7.855a3.927 3.927 0 0 0-3.927 3.927v.982h11.782v-4.909Z"
        fill="#fff"
      />
      <path
        d="M55.357 30.187c-12.346 2.447-19.764 9.839-22.255 22.176l5.637 5.658 22.215-22.216-5.597-5.618Z"
        fill="#84462D"
      />
      <path
        d="m31.314 54.144 5.639 5.662 1.786-1.785-5.637-5.658-1.787 1.781ZM60.947 35.796 55.35 30.18l1.923-1.916 5.597 5.617-1.923 1.916Z"
        fill="#AF7653"
      />
      <path
        d="m56.658 23.525-.695.693a1.964 1.964 0 0 0-.005 2.777l6.889 6.918 3.471-3.472-6.882-6.912a1.964 1.964 0 0 0-2.778-.004Zm-26.587 29.37a1.965 1.965 0 0 0-2.778-.006l-.695.694a1.964 1.964 0 0 0-.005 2.776l6.888 6.918 3.472-3.472-6.882-6.91Zm8.72 28.596H27.01a3.927 3.927 0 0 0-3.927 3.927h15.71v-3.927Z"
        fill="#84462D"
      />
    </g>
    <defs>
      <clipPath id="activities-modal-header_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h108v108H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgActivitiesModalHeader;
