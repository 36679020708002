import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgWhyCantIViewOffers = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 109 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#why-cant-i-view-offers_svg__a)">
      <path
        id="Vector"
        d="M54.5 108C84.3234 108 108.5 83.8234 108.5 54C108.5 24.1766 84.3234 0 54.5 0C24.6766 0 0.5 24.1766 0.5 54C0.5 83.8234 24.6766 108 54.5 108Z"
        fill="#32BEA6"
      />
      <path
        id="Vector_2"
        d="M65.5868 80.018H83.3567L77.5679 66.7045C77.1084 65.6477 76.4219 64.7049 75.5573 63.943C74.6926 63.1812 73.6708 62.6189 72.5645 62.2962L55.1913 57.229C54.3578 56.9852 53.4619 57.0783 52.6963 57.4883C51.9308 57.8984 51.3569 58.5925 51.098 59.4215C50.8391 60.2504 50.9159 61.1478 51.312 61.9207C51.7081 62.6935 52.3917 63.28 53.2158 63.5539L64.3182 67.2543H47.9052C47.0687 67.2543 46.2361 67.1473 45.4271 66.9362L26.6724 61.8622C25.9756 61.6788 25.2352 61.7646 24.5989 62.1027C23.9626 62.4407 23.477 63.0062 23.239 63.6862C23.0009 64.3663 23.0279 65.1111 23.3145 65.7722C23.6011 66.4333 24.1262 66.9622 24.7853 67.2534L46.7064 77.207C48.4972 77.9051 50.1447 78.2684 50.6945 78.0534H61.2196C62.0446 78.0562 62.8598 78.2326 63.6122 78.571C64.3646 78.9095 65.0373 79.4025 65.5868 80.018Z"
        fill="#F7B4B4"
      />
      <path
        id="Vector_3"
        d="M85.9176 80.5092C85.9176 80.2488 85.8142 79.999 85.6301 79.8149C85.4459 79.6308 85.1962 79.5273 84.9358 79.5273H64.189C64.0112 79.5273 63.8368 79.5755 63.6843 79.6669C63.5318 79.7582 63.407 79.8892 63.3232 80.046C63.2393 80.2027 63.1996 80.3793 63.2082 80.5568C63.2168 80.7343 63.2735 80.9062 63.3721 81.0541L65.9906 84.9813C66.0801 85.1158 66.2014 85.226 66.3437 85.3022C66.4861 85.3784 66.6451 85.4183 66.8065 85.4183H84.9358C85.1962 85.4183 85.4459 85.3148 85.6301 85.1307C85.8142 84.9466 85.9176 84.6968 85.9176 84.4364V80.5092Z"
        fill="white"
      />
      <path
        id="Vector_4"
        d="M36.8275 50.0729C44.4189 50.0729 50.5729 43.9189 50.5729 36.3275C50.5729 28.7361 44.4189 22.582 36.8275 22.582C29.2361 22.582 23.082 28.7361 23.082 36.3275C23.082 43.9189 29.2361 50.0729 36.8275 50.0729Z"
        fill="#FACB1B"
      />
      <path
        id="Vector_5"
        d="M36.8275 22.582C29.2361 22.582 23.082 28.7361 23.082 36.3275C23.082 40.1193 24.6176 43.5517 27.0996 46.0377L46.5377 26.5996C45.2632 25.3239 43.7494 24.3123 42.0831 23.6228C40.4168 22.9334 38.6308 22.5797 36.8275 22.582Z"
        fill="#FBE158"
      />
      <path
        id="Vector_6"
        d="M37.0585 26.3242C37.6123 26.3242 38.0521 26.7631 38.0521 27.3178V27.8264C39.3269 27.9716 40.5516 28.4062 41.6328 29.0969C42.002 29.305 42.326 29.6516 42.326 30.2053C42.326 30.9211 41.7487 31.4758 41.0329 31.4758C40.8022 31.4758 40.5705 31.4061 40.3398 31.2677C39.5317 30.7827 38.7453 30.4361 37.9608 30.2515V34.6873C41.4728 35.5651 42.974 36.974 42.974 39.4462C42.974 41.9872 40.9878 43.6739 38.0531 43.9508V45.3371C38.0531 45.8919 37.6142 46.3307 37.0595 46.3307C36.5048 46.3307 36.0424 45.8919 36.0424 45.3371V43.9056C34.3354 43.7218 32.7045 43.1014 31.307 42.104C30.9143 41.8497 30.6836 41.4805 30.6836 40.9955C30.6836 40.2798 31.2373 39.7251 31.9541 39.7251C32.2309 39.7251 32.5088 39.8173 32.716 39.9793C33.8018 40.7648 34.8642 41.296 36.1346 41.5267V36.976C32.7621 36.0982 31.1912 34.8277 31.1912 32.2171C31.1912 29.7449 33.1548 28.0355 36.0424 27.8048V27.3198C36.0424 26.7631 36.5038 26.3242 37.0585 26.3242ZM36.1346 34.2013V30.1121C34.656 30.2505 33.9167 31.036 33.9167 32.0521C33.9167 33.0232 34.3556 33.624 36.1346 34.2013ZM37.9598 37.4129V41.617C39.4385 41.455 40.2475 40.7167 40.2475 39.6072C40.2465 38.5911 39.7379 37.9666 37.9598 37.4129Z"
        fill="#84462D"
      />
      <path
        id="Vector_7"
        d="M72.5639 62.2962L55.1906 57.229C54.3571 56.9852 53.4612 57.0783 52.6957 57.4883C51.9302 57.8984 51.3562 58.5925 51.0973 59.4215C50.8384 60.2504 50.9153 61.1478 51.3114 61.9207C51.7075 62.6935 52.3911 63.28 53.2152 63.5539L64.3176 67.2543H47.9045C47.068 67.2543 46.2354 67.1473 45.4264 66.9362L26.6717 61.8622C25.9759 61.6821 25.2379 61.7701 24.6039 62.1086C23.9699 62.4472 23.4863 63.0116 23.2488 63.6899C23.0114 64.3683 23.0375 65.1111 23.3219 65.7711C23.6064 66.4311 24.1285 66.9602 24.7847 67.2534L46.7057 77.207C47.4362 77.4918 48.1382 77.7166 48.7568 77.8737L74.3675 63.0865C73.8015 62.7491 73.1956 62.4836 72.5639 62.2962Z"
        fill="#FBC8C8"
      />
      <path
        id="Vector_8"
        d="M55.2378 58.5319C54.6906 58.361 54.0979 58.4144 53.5901 58.6805C53.0823 58.9465 52.7009 59.4034 52.53 59.9507C52.359 60.4979 52.4125 61.0906 52.6785 61.5984C52.9446 62.1062 53.4015 62.4876 53.9487 62.6585L54.4818 62.8362L55.9732 58.746L55.2378 58.5319Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="why-cant-i-view-offers_svg__a">
        <rect width="108" height="108" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWhyCantIViewOffers;
