import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgRedEmail = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.273 10.087a.487.487 0 0 1-.273.08H4a.48.48 0 0 1-.267-.08L.26 7.854a.18.18 0 0 0-.173 0A.173.173 0 0 0 0 8v6.667A1.333 1.333 0 0 0 1.333 16h13.334A1.334 1.334 0 0 0 16 14.667V8a.166.166 0 0 0-.087-.146.16.16 0 0 0-.173 0l-3.467 2.233Z"
      fill="#DB3D55"
    />
    <path
      d="M3.413 8.667a.167.167 0 0 0 .167 0 .16.16 0 0 0 .093-.147V1.5a.193.193 0 0 1 .047-.12.193.193 0 0 1 .113-.047h8.333a.166.166 0 0 1 .167.167v7.047a.16.16 0 0 0 .087.146.16.16 0 0 0 .173 0l2.74-1.74a.166.166 0 0 0 .073-.14.187.187 0 0 0-.073-.146l-1.667-1.134v-4.06A1.407 1.407 0 0 0 12.333 0H3.666a1.413 1.413 0 0 0-1.333 1.473v4.06L.666 6.667a.187.187 0 0 0-.066.14.173.173 0 0 0 .073.14l2.74 1.72Z"
      fill="#DB3D55"
    />
    <path
      d="M4.913 6.401A3.147 3.147 0 0 0 8 8.835a3.06 3.06 0 0 0 .813-.107.5.5 0 0 0-.253-.967c-.355.09-.726.09-1.08 0a2.153 2.153 0 0 1-1.594-2.573 2.153 2.153 0 0 1 2.167-1.687 2.253 2.253 0 0 1 2.113 2.294v.373a.333.333 0 1 1-.666 0v-.5a1.5 1.5 0 1 0-1.5 1.5c.268 0 .53-.074.76-.213a1.333 1.333 0 0 0 2.406-.787v-.373A3.26 3.26 0 0 0 8.08 2.5a3.14 3.14 0 0 0-2.527 1.16 3.18 3.18 0 0 0-.64 2.74ZM8 6.168a.5.5 0 1 1 .5-.5.507.507 0 0 1-.5.5Z"
      fill="#DB3D55"
    />
  </svg>
);
export default SvgRedEmail;
