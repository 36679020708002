import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCashAdvance = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 111 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#casg-advance_svg__a)">
      <path
        d="M55.5 110c30.376 0 55-24.624 55-55S85.876 0 55.5 0 .5 24.624.5 55s24.624 55 55 55Z"
        fill="#25B7D3"
      />
      <path
        d="M47.1 24.52c-.18-.31-.19-.7-.02-1.01.17-.31.49-.51.84-.51H63.1c.35 0 .67.2.84.51.17.31.16.7-.02 1.01-1.13 1.91-2.65 5.9-2.65 13.48 0 14 17.25 14 17.25 30 0 11-8.62 19-23 19s-23-9-23-19c0-16 17.25-16 17.25-30 0-7.58-1.53-11.57-2.65-13.48h-.02Z"
        fill="#F0F1F1"
      />
      <path
        d="M47.91 23c-.35 0-.67.2-.84.51-.17.32-.16.7.02 1.01 1.13 1.91 2.65 5.9 2.65 13.48 0 14-17.25 14-17.25 30 0 10 8.62 19 23 19V23H47.9h.01Z"
        fill="#fff"
      />
      <path d="M23.5 31h8v16h-8V31Z" fill="#3E3E3F" />
      <path d="M31.5 31h4v16h-4V31Z" fill="#fff" />
      <path d="M33.5 45a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill="#F3B607" />
      <path
        d="M56.5 29c0 1.1-.9 2-2 2h-5.31c.33 1.89.56 4.2.56 7 0 3.81-1.41 6.72-3.02 8.74L35.5 45V34l6.73-4.98A7.986 7.986 0 0 1 47.54 27h6.96c1.1 0 2 .9 2 2Z"
        fill="#F7B4B4"
      />
      <path
        d="M56.5 30h-3c-1.1 0-2 .9-2 2s.9 2 2 2c-1.1 0-2 .9-2 2s.9 2 2 2c-1.1 0-2 .9-2 2s.9 2 2 2c-1.1 0-2 .9-2 2s.9 2 2 2h8c1.1 0 2-.9 2-2V32c0-1.1-.9-2-2-2h-5Z"
        fill="#F7B4B4"
      />
      <path
        d="M55.54 56.53c.64 0 1.15.51 1.15 1.15v.59c1.48.17 2.9.67 4.16 1.48.43.24.81.64.81 1.29 0 .83-.67 1.48-1.5 1.48-.29 0-.56-.09-.81-.24-.94-.56-1.85-.97-2.76-1.18v5.15c4.08 1.02 5.82 2.66 5.82 5.53s-2.31 4.91-5.71 5.23v1.61c0 .64-.51 1.15-1.15 1.15-.64 0-1.18-.51-1.18-1.15v-1.66c-2.01-.21-3.86-.94-5.5-2.09-.46-.29-.72-.72-.72-1.29 0-.83.64-1.47 1.47-1.47.32 0 .64.11.88.29 1.26.91 2.49 1.53 3.97 1.8v-5.28c-3.92-1.02-5.74-2.5-5.74-5.53 0-2.87 2.28-4.85 5.63-5.12v-.56c0-.64.54-1.15 1.18-1.15v-.03Zm-1.07 9.15v-4.75c-1.72.16-2.58 1.07-2.58 2.25s.51 1.82 2.58 2.5Zm2.12 3.73v4.88c1.72-.19 2.66-1.05 2.66-2.33 0-1.18-.59-1.9-2.66-2.55Z"
        fill="#3E3E3F"
      />
      <path
        d="M54.94 27.05c-.15-.03-.29-.05-.44-.05h-6.96c-1.96 0-3.85.72-5.31 2.02L35.5 34v11l1.3.2 12.64-12.55c-.08-.63-.15-1.1-.25-1.66H51l3.95-3.95-.01.01Z"
        fill="#FBC8C8"
      />
    </g>
    <defs>
      <clipPath id="casg-advance_svg__a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h110v110H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCashAdvance;
