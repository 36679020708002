import classNames from 'classnames';
import React from 'react';

import modalStyles from '../../common/modal.module.scss';
import { Modal } from '../../components/Modal';
import { Button } from '../../components/design-system';
import { useModalByName } from '../../hooks/useModalByName';

import { MarketManagementModalContents } from './MarketManagementModalContents';

export const EDIT_MARKETS_UPSELL_MODAL_NAME = 'EditMarketsUpsellModal';

export const EditMarketsUpsellModal = () => {
  const [isOpen, , close] = useModalByName(EDIT_MARKETS_UPSELL_MODAL_NAME);

  const handleEditMarkets = () => {
    close();
    // @todo link to membership
  };

  return (
    <Modal hide={close} isShowing={isOpen} modalClassNames={modalStyles.modal}>
      <MarketManagementModalContents
        close={close}
        headerText="Sorry, you can’t edit your markets at this time."
      >
        <p>
          This member already used up all their edit credits for the year, do we want to try and
          up-sell here? can they reach out to support and purchase a 1-time change? unclear what/how
          we want to handle this case for now.
        </p>

        <div className={classNames(modalStyles.buttonField, modalStyles.stack)}>
          <Button size="large" onClick={handleEditMarkets}>
            Upgrade membership
          </Button>

          <Button variant="secondary" size="large" onClick={close}>
            Cancel
          </Button>
        </div>
      </MarketManagementModalContents>
    </Modal>
  );
};
