import { AxiosError, AxiosResponse } from 'axios';
import * as yup from 'yup';

import { isAxiosError } from './helpers';

interface AxiosErrorWithResponse<T> extends AxiosError<T> {
  response: NonNullable<AxiosError['response']>;
}

interface GetAuthTokenErrorResponse {
  success: false;
  statusCode?: number;
  errorCode?: string;
  message?: string;
}

// this is the schema for our "expected errors" as opposed to an API server unexpected exception
const getAuthTokenErrorResponseSchema: yup.SchemaOf<GetAuthTokenErrorResponse> = yup
  .object()
  .shape({
    success: yup.mixed<false>().oneOf([false]).required(),
    statusCode: yup.number().optional(),
    errorCode: yup.string().optional(),
    message: yup.string().optional()
  });

export function isGetAuthTokenErrorResponse(
  response: AxiosResponse<unknown>
): response is AxiosResponse<GetAuthTokenErrorResponse> {
  return getAuthTokenErrorResponseSchema.isValidSync(response.data);
}

export type ApiValidationErrorResponseData = {
  error: string;
};

export interface ApiValidationError extends AxiosError<ApiValidationErrorResponseData> {
  response: AxiosResponse<ApiValidationErrorResponseData>;
}

export function isApiValidationError(error: unknown): error is ApiValidationError {
  if (!isAxiosError(error)) {
    return false;
  }

  if (!error.response) {
    return false;
  }

  const unknownResponseData = error.response.data as undefined | null | { error?: string };

  return typeof unknownResponseData?.error === 'string';
}

export const isGetAuthTokenError = (
  error: unknown
): error is AxiosError<GetAuthTokenErrorResponse> => {
  if (!isAxiosError(error)) {
    return false;
  }

  if (!error.response) {
    return false;
  }

  const response = error.response;

  if (isGetAuthTokenErrorResponse(response)) {
    const { success, errorCode, message } = response.data;

    return !success || !!errorCode || !!message;
  } else {
    return false;
  }
};

// this is a more specific error than isGetAuthTokenError
export const isUserNotConfirmedError = (
  error: unknown
): error is AxiosError<GetAuthTokenErrorResponse> => {
  if (!isAxiosError(error)) {
    return false;
  }

  if (!error.response) {
    return false;
  }

  const response = error.response;

  if (isGetAuthTokenErrorResponse(response)) {
    const { errorCode } = response.data;

    // we could also verify success and statusCode but if this condition is met that's sufficient
    return errorCode === 'UserNotConfirmedException';
  } else {
    return false;
  }
};

interface HasErrorMessage {
  message: string;
}

export const isApiErrorWithMessage = (
  error: unknown
): error is AxiosErrorWithResponse<HasErrorMessage> => {
  if (!isAxiosError(error)) {
    return false;
  }

  const response = error.response;

  if (!response || !response.data) {
    return false;
  }

  return typeof (response.data as HasErrorMessage)?.message === 'string';
};

export const getMessageFromError = (error: AxiosErrorWithResponse<HasErrorMessage>) => {
  return error.response.data.message;
};

interface HasErrorCode {
  errorCode: string;
}

export const isApiErrorWithErrorCode = (
  error: unknown
): error is AxiosErrorWithResponse<HasErrorCode> => {
  if (!isAxiosError(error)) {
    return false;
  }

  const response = error.response;

  if (!response || !response.data) {
    return false;
  }

  return typeof (response.data as HasErrorCode)?.errorCode === 'string';
};

export const getErrorCodeFromError = (error: AxiosErrorWithResponse<HasErrorCode>) => {
  return error.response.data.errorCode;
};
