import classNames from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Tooltip } from 'reactstrap';

import { AlertBox } from '../../components/AlertBox';
import { SubmitButton } from '../../components/SubmitButton';
import SupportLink from '../../components/SupportLink';

import styles from './Forgot.module.scss';
import SuccessMessage from './SuccessMessage';

export type FormField = {
  email: string;
};

export type ErrorType = {
  error: boolean;
  message: string;
} | null;

interface ForgotPasswordContentProps {
  isLinkSent: boolean;
  loading: boolean;
  isError: ErrorType;
  onSubmit: (data: FormField) => void;
  customHeader?: boolean;
}

export const ForgotPasswordContent = ({
  isLinkSent,
  loading,
  isError,
  onSubmit,
  customHeader
}: ForgotPasswordContentProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<FormField>();

  return (
    <>
      {!isLinkSent && (
        <>
          <div className={styles.formContent}>
            {!customHeader && (
              <>
                <div className={styles.title}>Forgot your password?</div>
                <div className={styles.subTitle}>
                  If your account exists we’ll send you an email with instructions to reset it.
                </div>
              </>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                id="email"
                type="email"
                placeholder="Email"
                className={classNames('inputField', {
                  inputError: errors.email && errors.email.type === 'required'
                })}
                {...register('email', { required: true })}
              />
              <Tooltip
                className="mttooltip"
                placement="bottom"
                isOpen={errors.email && errors.email.type === 'required'}
                target="email"
              >
                Please enter your email
              </Tooltip>

              {isError && isError.error ? (
                <AlertBox>
                  {isError.message ? (
                    <div>{isError.message}</div>
                  ) : (
                    <div>
                      Unable to send reset password link. Please contact <SupportLink /> if you have
                      any questions.
                    </div>
                  )}
                </AlertBox>
              ) : null}
              <SubmitButton disabled={loading} loading={loading} type="submit" className="mt-2">
                Reset Password
              </SubmitButton>
            </form>
          </div>
        </>
      )}
      {isLinkSent && <SuccessMessage />}
    </>
  );
};
