import {
  PopoverClose as PrimitivePopoverClose,
  PopoverContent as PrimitivePopoverContent,
  PopoverTrigger as PrimitivePopoverTrigger,
  PopoverAnchor as PrimitivePopoverAnchor,
  Root,
  PopoverContentProps as PrimitivePopoverContentProps,
  PopoverTriggerProps as PrimitivePopoverTriggerProps
} from '@radix-ui/react-popover';
import classNames from 'classnames';
import React, { forwardRef, ElementRef, ReactNode } from 'react';

import { Button } from '../Button/Button';

import styles from './Popover.module.scss';

/**
 * Usage: 
 * 
 *  import { Popover, PopoverTrigger, PopoverContent } from './Popover';

    export default () => (
        <Popover>
            <PopoverTrigger>Popover trigger</PopoverTrigger>
            <PopoverContent>Popover content</PopoverContent>
        </Popover>
    );
 */

export type PopoverContentProps = {
  children?: ReactNode;
  className?: any;
} & PrimitivePopoverContentProps;

export type PopoverTriggerProps = {
  children?: ReactNode;
  className?: any;
} & PrimitivePopoverTriggerProps;

// Expose underlying API for customization
export const Popover = Root;
export const PopoverAnchor = PrimitivePopoverAnchor;
export const PopoverClose = PrimitivePopoverClose;

export const PopoverTrigger = forwardRef<
  ElementRef<typeof PrimitivePopoverTrigger>,
  PopoverTriggerProps
>(({ children, className, ...props }, forwardedRef) => (
  <PrimitivePopoverTrigger {...props} ref={forwardedRef} asChild={true} className={className}>
    <Button variant="bare">{children}</Button>
  </PrimitivePopoverTrigger>
));

export const PopoverContent = forwardRef<
  ElementRef<typeof PrimitivePopoverContent>,
  PopoverContentProps
>(({ children, className, ...props }, forwardedRef) => (
  <PrimitivePopoverContent
    sideOffset={5}
    {...props}
    ref={forwardedRef}
    className={classNames(className, styles.content)}
  >
    {children}
  </PrimitivePopoverContent>
));
