import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { ExclamationDangerIcon, ExclamationWarnIcon } from '../../Icons';
import SupportLink from '../../SupportLink';
import style from '../SignUp.module.scss';

interface GeneralErrorProps {
  onClose: () => void;
  title?: string;
  content?: ReactNode;
  isContactVisible?: boolean;
  variant?: 'warn' | 'error';
}

const GeneralError = ({
  onClose,
  title,
  content,
  isContactVisible,
  variant
}: GeneralErrorProps) => {
  const handleClick = () => {
    onClose && onClose();
  };

  const Icon = () => {
    switch (variant) {
      case 'warn':
        return (
          <ExclamationWarnIcon className={classNames(style.headerIcon)} title="" aria-hidden />
        );
      case 'error':
        return (
          <ExclamationDangerIcon className={classNames(style.headerIcon)} title="" aria-hidden />
        );
      default:
        return (
          <ExclamationWarnIcon className={classNames(style.headerIcon)} title="" aria-hidden />
        );
    }
  };

  return (
    <div className={classNames(style.sundaeScoop)}>
      <main className={classNames(style.main)}>
        <div className={classNames(style.container, style.stack)}>
          <Icon />

          {title && <h1 className={classNames(style.title)}>{title}</h1>}

          {content && <p>{content}</p>}

          {isContactVisible && (
            <p>
              Need help? <SupportLink onClick={handleClick}>Contact Sundae</SupportLink>
            </p>
          )}
        </div>
      </main>
    </div>
  );
};

GeneralError.defaultProps = {
  title: 'Oops! Looks like something went wrong',
  isContactVisible: true,
  variant: 'warn'
};

export default GeneralError;
