import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgGroupCirclePink({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="1em"
      height="1em"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g opacity={0.9}>
        <circle cx={30} cy={30} r={22.73} fill="#f4cccc" />
        <path
          d="M30 5a25 25 0 1025 25A25 25 0 0030 5zm0 45.45A20.45 20.45 0 1150.45 30 20.47 20.47 0 0130 50.45z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgGroupCirclePink;
