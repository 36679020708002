import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMobileHomeownerHouse = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#mobile-homeowner-house_svg__a)">
      <path
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z"
        fill="#25B7D3"
      />
      <path d="m20 10.004-8.727 6.62v10.649h17.454V16.624L20 10.004Z" fill="#fff" />
      <path
        d="M31.125 17.531a.727.727 0 0 1-1.02.14L20 10.005 9.894 17.67a.726.726 0 1 1-.88-1.159l10.546-8a.728.728 0 0 1 .88 0l10.545 8a.727.727 0 0 1 .14 1.019Z"
        fill="#DF2C2C"
      />
      <path d="M22.182 23.636a2.182 2.182 0 0 0-4.364 0v3.636h4.364v-3.636Z" fill="#9C6144" />
      <path
        d="M15.636 18.59a.318.318 0 0 1-.318.319H13.41a.318.318 0 0 1-.318-.318v-1.91c0-.175.142-.318.318-.318h1.91c.175 0 .317.143.317.319v1.909Zm0 5.455a.318.318 0 0 1-.318.318H13.41a.318.318 0 0 1-.318-.318v-1.909c0-.176.142-.318.318-.318h1.91c.175 0 .317.142.317.318v1.91Zm5.747-5.392a.327.327 0 0 1-.328.328h-1.963a.327.327 0 0 1-.327-.328v-1.962a.327.327 0 0 1 .327-.328h1.963a.328.328 0 0 1 .328.328v1.963Zm5.454-.125a.31.31 0 0 1-.31.309h-1.854a.309.309 0 0 1-.31-.31v-1.855c0-.17.14-.309.31-.309h1.855c.17 0 .309.139.309.31v1.855Z"
        fill="#40C9E7"
      />
      <path d="M21.09 25.09a.364.364 0 1 0 0-.727.364.364 0 0 0 0 .728Z" fill="#FACB1B" />
      <path
        d="M31.188 17.271H18.355a.448.448 0 0 0-.317.767l.898.898a.452.452 0 0 0 .131.087v1.84h.898v-1.796h5.387v1.796h.898v-1.796h3.142c.248 0 .45.201.45.449v11.672c0 .248.2.448.448.448h.898a.447.447 0 0 0 .448-.448V17.72a.45.45 0 0 0-.448-.45Z"
        fill="#84462D"
      />
      <path
        d="M28 28.557a.898.898 0 0 1-.898.897h-8.978a.898.898 0 0 1-.898-.897v-6.285a.897.897 0 0 1 .898-.898h8.978a.898.898 0 0 1 .898.898v6.285Z"
        fill="#DF2C2C"
      />
      <path
        d="M27.102 21.374a.896.896 0 0 1 .898.898v6.284a.896.896 0 0 1-.898.898h-8.978a.896.896 0 0 1-.898-.898v-6.284a.897.897 0 0 1 .898-.898h8.978Zm0-.727h-8.978a1.627 1.627 0 0 0-1.625 1.625v6.284a1.627 1.627 0 0 0 1.625 1.625h8.978a1.627 1.627 0 0 0 1.625-1.625v-6.284a1.627 1.627 0 0 0-1.625-1.625Z"
        fill="#fff"
      />
      <path
        d="M18.124 21.374a.898.898 0 0 0-.898.898v6.285c0 .414.282.76.664.863l8.046-8.046h-7.812Z"
        fill="#FA5655"
      />
      <path
        d="m22.464 24.838-.288-.65c-.055-.123-.151-.197-.286-.197h-.03a.306.306 0 0 0-.29.197l-.785 1.774a.257.257 0 0 0-.029.106c0 .129.1.232.229.232.01 0 .016-.007.026-.008a.241.241 0 0 0 .202-.147l.042-.097.116-.273h.992l.15.354c.046.103.123.171.239.171a.235.235 0 0 0 .235-.238.234.234 0 0 0-.026-.1l-.497-1.123Zm-.498.499h-.411l.312-.744.25.594.063.15h-.214Zm2.823.493h-.917v-1.574a.247.247 0 0 0-.343-.23.244.244 0 0 0-.153.23v1.777c0 .138.11.248.248.248h1.165a.224.224 0 0 0 .209-.312.225.225 0 0 0-.209-.14Zm2.091.01h-1.02v-.474h.859a.22.22 0 0 0 .157-.376.223.223 0 0 0-.157-.065h-.86v-.457h1.005a.217.217 0 0 0 .156-.063.222.222 0 0 0 .066-.156.223.223 0 0 0-.222-.222h-1.25a.248.248 0 0 0-.229.153.244.244 0 0 0-.018.095v1.758c0 .138.109.248.248.248h1.265a.22.22 0 0 0 .222-.22.22.22 0 0 0-.222-.221Zm-7.098-.908c-.39-.1-.486-.148-.486-.297v-.006c0-.11.1-.197.29-.197.154 0 .309.055.473.152a.239.239 0 0 0 .29-.03.227.227 0 0 0 .067-.163.226.226 0 0 0-.11-.197 1.31 1.31 0 0 0-.714-.2c-.46 0-.79.271-.79.68v.006c0 .448.294.573.748.69.377.096.454.16.454.286v.007c0 .131-.123.212-.325.212a.951.951 0 0 1-.577-.203.24.24 0 0 0-.305.02.228.228 0 0 0-.068.164.23.23 0 0 0 .093.186c.247.176.544.271.847.27.486 0 .828-.25.828-.698v-.006c0-.393-.257-.558-.715-.676Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="mobile-homeowner-house_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMobileHomeownerHouse;
