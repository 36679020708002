import { createSelector } from 'reselect';

import { StateTerritories } from '../api/_base/generated/data-contracts';
import { AreaOption } from '../types/onBoarding';

import { RootState } from '.';

export interface TerritoryState {
  territories: StateTerritories[];
  territorySearchMarkets: AreaOption[];
  typeaheadActiveMarkets: AreaOption[];
}

export const selectTerritoryState = (rootState: RootState) => rootState.territory;

export const selectTypeaheadActiveMarkets = createSelector(
  selectTerritoryState,
  (rootState) => rootState.typeaheadActiveMarkets
);

export const selectTerritorySearchMarkets = createSelector(
  selectTerritoryState,
  (rootState) => rootState.territorySearchMarkets
);

export const selectTypeaheadActiveMarketIds = createSelector(
  selectTypeaheadActiveMarkets,
  (markets) => markets.map((market) => market.sfId)
);

const initialState: TerritoryState = {
  territories: [],
  territorySearchMarkets: [],
  typeaheadActiveMarkets: []
};

const SET_TERRITORIES = 'SET_TERRITORIES' as const;
const SET_TYPEAHEAD_ACTIVE_MARKETS = 'SET_TYPEAHEAD_ACTIVE_MARKETS' as const;
const SET_TERRITORY_SEARCH_MARKETS = 'SET_TERRITORY_SEARCH_MARKETS' as const;

export const setTerritoriesAction = (territories: StateTerritories[]) => {
  return {
    type: SET_TERRITORIES,
    payload: { territories }
  };
};

export const setTypeaheadActiveMarkets = (typeaheadActiveMarkets: AreaOption[]) => {
  return {
    type: SET_TYPEAHEAD_ACTIVE_MARKETS,
    payload: { typeaheadActiveMarkets }
  };
};

export const setTerritorySearchMarkets = (territorySearchMarkets: AreaOption[]) => {
  return {
    type: SET_TERRITORY_SEARCH_MARKETS,
    payload: { territorySearchMarkets }
  };
};

type TerritoryAction =
  | ReturnType<typeof setTerritoriesAction>
  | ReturnType<typeof setTypeaheadActiveMarkets>
  | ReturnType<typeof setTerritorySearchMarkets>;

const territoryReducer = (state = initialState, action: TerritoryAction): TerritoryState => {
  switch (action.type) {
    case SET_TERRITORIES:
      return {
        ...state,
        territories: action.payload.territories
      };
    case SET_TYPEAHEAD_ACTIVE_MARKETS:
      return {
        ...state,
        typeaheadActiveMarkets: action.payload.typeaheadActiveMarkets
      };
    case SET_TERRITORY_SEARCH_MARKETS:
      return {
        ...state,
        territorySearchMarkets: action.payload.territorySearchMarkets
      };

    default:
      return state;
  }
};

export default territoryReducer;
