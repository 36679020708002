import { useRef } from 'react';

import { useConfirmationCode } from './useConfirmationCode';

export const useConfirmationCodeRef = () => {
  const { confirmationCode } = useConfirmationCode();
  const confirmationCodeRef = useRef<string>(confirmationCode);
  confirmationCodeRef.current = confirmationCode;

  return confirmationCodeRef;
};
