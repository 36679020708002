import { useMutation, useQuery } from '@tanstack/react-query';

import { api } from '../_base';

// POST /api/user/subscription/begin-payment
export const beginPayment = () => {
  return api.subscriptionControllerBeginStripePayment();
};

export const useBeginPayment = () => {
  return useMutation({ mutationFn: beginPayment });
};

type PaymentHistoryQuery = Parameters<typeof api.subscriptionControllerPaymentHistory>[0];

// GET /api/user/subscription/history
export const getPaymentHistory = (query: PaymentHistoryQuery) => {
  return api.subscriptionControllerPaymentHistory(query);
};

export const usePaymentHistory = (query: PaymentHistoryQuery) => {
  return useQuery({ queryKey: ['getPaymentHistory'], queryFn: () => getPaymentHistory(query) });
};
