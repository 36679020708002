import React from 'react';

import { ConfirmDialog } from '../ConfirmDialog';

type ConfirmModalClose = {
  onCancel: () => void | Promise<void>;
  onConfirm: () => void | Promise<void>;
  isDialogShowing: boolean;
  isCentered?: boolean; // @todo: remove this. Temp fix to support various modal styles. See confirmDialog
};

const ConfirmModalClose: React.FC<ConfirmModalClose> = ({
  onCancel,
  onConfirm,
  isDialogShowing,
  isCentered
}) => {
  return (
    <ConfirmDialog
      isShowing={isDialogShowing}
      title="Are you sure?"
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelText={'No, Continue Signup'}
      confirmText={'Yes, Exit'}
      isCentered={isCentered}
    >
      Exiting now will require restarting the signup process.
    </ConfirmDialog>
  );
};

export default ConfirmModalClose;
