import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';

// GET /api/user/subscription/tiers
export const getSubscriptionTiers = () => {
  return api.subscriptionControllerGetSubscriptionTiers();
};

export const useSubscriptionTiers = () => {
  return useQuery({ queryKey: ['getSubscriptionTiers'], queryFn: getSubscriptionTiers });
};
