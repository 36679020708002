import React from 'react';

import { Button } from '../../../components/design-system';

import styles from './CancelSaveButtonGroup.module.scss';

interface CancelSaveButtonGroupProps {
  isSubmitting: boolean;
  isSaveDisabled: boolean;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

export const CancelSaveButtonGroup = ({
  isSubmitting,
  isSaveDisabled,
  onCancelClick,
  onSaveClick
}: CancelSaveButtonGroupProps) => {
  return (
    <div className={styles.buttonWrap}>
      <Button disabled={isSubmitting} variant="secondary" type="button" onClick={onCancelClick}>
        Cancel
      </Button>

      <Button
        disabled={isSaveDisabled}
        loading={isSubmitting}
        variant="primary"
        type="submit"
        onClick={onSaveClick}
      >
        Save
      </Button>
    </div>
  );
};
